import React, { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { COLOR_PRIMARY, MAIN_URL } from './Constants';
import { Nav, NavItem, NavLink, Modal, ModalBody, Table, Dropdown, FormControl } from 'react-bootstrap';
import ElectionHeaderAdmin from './ElectionHeaderAdmin';
import useResponsive from './useResponsive';
import PostListAdmin from './PostListAdmin';
import Table_PollingStationAdmin from './Table_PollingStationAdmin';
import Loader from './Loader';
import { useAuth } from './contexts/AuthContext';

const PollingStation_Admin = () => {
    const locate = useLocation()
    const { ps_code, cs_code } = useParams();
    const { ps_name, cs_name } = locate?.state;
    const [data, setData] = useState({ presidential: [], parliamentary: [] });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeTab, setActiveTab] = useState('presidential');
    const [modalOpen, setModalOpen] = useState(false);
    const [modalImage, setModalImage] = useState(null);
    const isMobile = useResponsive();
    const [pollingStations, setPollingStations] = useState([]);
    const [selectedPollingStation, setSelectedPollingStation] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [pollingStationCode, setPollingStationCode] = useState("");
    const navigate = useNavigate();

    const { user } = useAuth();

    // window.location.reload()


    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                setSelectedPollingStation(null)

                setData({ presidential: [], parliamentary: [] });
                const ps = await axios.get(`${MAIN_URL}get/polling-stations/${cs_code}`);
                setPollingStations(ps.data);
                console.log(JSON.stringify(ps.data));
                const response = await axios.get(`${MAIN_URL}constituency/result/admin/${cs_code}`);
                setData(response.data);
            } catch (err) {
                setError('Error loading election results');
            } finally {
                setLoading(false);
            }
        };

        if (ps_code) fetchData();
    }, [ps_code]);

    const handlePollingStationSelect = (pollingStation) => {
        setSelectedPollingStation(pollingStation);
        const { pollingname, pollingcode } = pollingStation;
        setPollingStationCode(pollingStation.pollingcode)

        // navigate(`/ps/results/admin/${cs_code}/${cs_name}/${pollingcode}/${pollingname}`)
        navigate(`/ps/results/admin/${cs_code}/${pollingcode}`, { state: { cs_name, ps_name: pollingname } })


    };

    const filteredPollingStations = pollingStations.filter(station =>
        station.pollingname.toLowerCase().includes(searchQuery.toLowerCase())
    ); // Filter stations based on search query



    if (loading) return <Loader />;
    if (!user) {
        return <Navigate to="/login" />;
    }

    return (
        <div className="container">
            <ElectionHeaderAdmin resultType={"constituency"} />
            <Nav variant="tabs" className="custom-tabs" style={{ borderBottom: '0.5px solid #ccc', marginTop: isMobile ? "36%" : "7%", marginLeft: "2%" }}>

                <NavItem>
                    <Dropdown>
                        <Dropdown.Toggle id="polling-station-dropdown" style={{ backgroundColor: '#eee', borderColor: "#eee", color: "#666" }}>
                            {selectedPollingStation ? selectedPollingStation.pollingname : 'Select Polling Station'}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            style={{
                                maxHeight: '500px',
                                overflowY: 'auto',
                                position: 'absolute',
                                backgroundColor: "#eee",
                                width: isMobile ? "100%" : '300px', // Reduced size for the dropdown
                                zIndex: 1000,
                                padding: 5,
                                marginTop: '5px' // Ensures the dropdown appears below the toggle
                            }}>
                            <FormControl
                                type="text"
                                placeholder="Search Polling Station..."
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                style={{ marginBottom: '10px', padding: '5px' }}
                            />
                            {filteredPollingStations.map((station, index) => (
                                <Dropdown.Item
                                    key={index}
                                    onClick={() => handlePollingStationSelect(station)}
                                    style={{ padding: '10px', fontSize: 13 }} // Alternating row colors
                                >
                                    {station.pollingname}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </NavItem>

                <NavItem>
                    <span style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start', // Aligns text to the start of the column
                        color: "#555",
                        paddingStart: 10,
                        fontFamily: 'verdana',
                        marginTop: -5,
                        fontSize: 22,
                        marginLeft: 15,
                        fontWeight: "bold",
                        marginRight: 20,
                    }}>
                        {data && (data.constituency_name ?? ps_name)}
                        <span style={{
                            fontSize: 13,  // Smaller font for the bottom text
                            color: "#666",
                            fontWeight: 'normal',  // Optional to differentiate from the top text
                            marginTop: -2,  // Adds space between the top and bottom text
                        }}>
                            ( {cs_name} CONSTITUENCY )
                        </span>
                    </span>
                </NavItem>


                <NavItem>
                    <NavLink
                        className={`tab-item ${activeTab === 'presidential' ? 'active-tab' : ''}`}
                        onClick={() => setActiveTab('presidential')}
                        style={{
                            fontWeight: activeTab === 'presidential' ? 'bold' : 'normal',
                            backgroundColor: activeTab === 'presidential' ? '#007bff' : '#f8f9fa',
                            color: activeTab === 'presidential' ? '#ffffff' : '#007bff',
                            borderRadius: '5px 5px 0 0',
                            padding: '10px 20px'
                        }}
                    >
                        PRESIDENTIAL RESULTS
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={`tab-item ${activeTab === 'parliamentary' ? 'active-tab' : ''}`}
                        onClick={() => setActiveTab('parliamentary')}
                        style={{
                            fontWeight: activeTab === 'parliamentary' ? 'bold' : 'normal',
                            backgroundColor: activeTab === 'parliamentary' ? '#007bff' : '#f8f9fa',
                            color: activeTab === 'parliamentary' ? '#ffffff' : '#007bff',
                            borderRadius: '5px 5px 0 0',
                            padding: '10px 20px'
                        }}
                    >
                        PARLIAMENTARY RESULTS
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={`tab-item ${activeTab === 'incidents' ? 'active-tab' : ''}`}
                        onClick={() => setActiveTab('incidents')}
                        style={{
                            fontWeight: activeTab === 'incidents' ? 'bold' : 'normal',
                            backgroundColor: activeTab === 'incidents' ? '#007bff' : '#f8f9fa',
                            color: activeTab === 'incidents' ? '#ffffff' : '#007bff',
                            borderRadius: '5px 5px 0 0',
                            padding: '10px 20px'
                        }}
                    >
                        INCIDENT REPORTS
                    </NavLink>
                </NavItem>
            </Nav>

            <div className="tab-content">
                {activeTab === 'presidential' && <Table_PollingStationAdmin tableType={"presidential"} code={ps_code} />}
                {activeTab === 'parliamentary' && <Table_PollingStationAdmin tableType={"parliamentary"} code={ps_code} />}
                {activeTab === 'incidents' && <PostListAdmin code={ps_code} />}
            </div>

            {modalOpen && (
                <Modal show={modalOpen} onHide={() => setModalOpen(false)}>
                    <ModalBody>
                        <img
                            src={modalImage}
                            alt="Supporting document"
                            className="img-fluid"
                            style={{ maxHeight: '80vh', objectFit: 'contain' }}
                        />
                    </ModalBody>
                </Modal>
            )}
        </div>
    );
};

export default PollingStation_Admin;
