import React, { useEffect, useState } from 'react';
import { Table, Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import { MAIN_URL } from './Constants';

const Table_PollingStationAdmin = ({ tableType, code }) => {
    const [data, setData] = useState([]);
    const [isModalVisible, setModalVisible] = useState(false);
    const [modalImage, setModalImage] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${MAIN_URL}ps/result/admin/${code}`);
                setData(response.data[tableType]);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [code, tableType]);

    // Organize data based on institutions
    const institutionsData = data.reduce((acc, item) => {
        const institution = item.sender_institution;
        if (!acc[institution]) {
            acc[institution] = { votes: {}, files: item.files[0] };
        }
        item.results.forEach(result => {
            const { party, name, votes } = result;
            if (!acc[institution].votes[party]) {
                acc[institution].votes[party] = { votes: 0, candidate: name };
            }
            acc[institution].votes[party].votes += votes;
        });
        return acc;
    }, {});

    const institutions = Object.keys(institutionsData);
    const parties = [...new Set(data.flatMap(item => item.results.map(result => result.party)))];

    const getTotalVotes = (institution) => {
        return Object.values(institutionsData[institution].votes).reduce((acc, { votes }) => acc + votes, 0);
    };

    const openModal = (image) => {
        setModalImage(image);
        setModalVisible(true);
    };

    return (
        <div className="container mt-4">
            {data.length === 0 ? (
                <div style={{ color: 'red', textAlign: 'center', margin: '20px 0' }}>No Results Yet</div>
            ) : (
                <Table borderedless hover responsive>
                    <thead style={{ color: '#ffffff' }}>
                        <tr><th colSpan={2} style={{ borderWidth: 0, borderTopWidth: 0 }}></th><th style={{
                            color: "#555", borderTopLeftRadius: 5, borderTopRightRadius: 5, backgroundColor: '#ddd', borderLeftWidth: 1, borderLeftColor: "white",
                            paddingLeft: 15, textAlign: 'center'
                        }}>INSTITUTIONS</th></tr>
                        <tr>
                            <th style={{
                                color: "#ddd", backgroundColor: '#555', borderLeftWidth: 1,
                                 borderLeftColor: "white", paddingLeft: 15,borderTopLeftRadius:5
                            }}>PARTY</th>
                            <th style={{
                                color: "#ddd", backgroundColor: '#555', borderLeftWidth: 1, borderLeftColor: "white", paddingLeft: 15,
                            }}>CANDIDATE</th>
                            {institutions.map((institution, index) => (
                                <th key={index} style={{
                                    backgroundColor: "#777", color: '#fff', fontFamily: 'verdana'
                                    , textDecorationLine: 'underline', cursor: 'pointer', borderLeftWidth: 1, borderColor: "#ddd"
                                }}>{institution}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {parties.map((party, partyIndex) => (
                            <tr key={partyIndex}>
                                <td style={{ borderLeftWidth: 1,paddingLeft:15}}>{party}</td>
                                <td style={{ borderLeftWidth: 1}}>{institutionsData[institutions[0]].votes[party]?.candidate || 'N/A'}</td>
                                {institutions.map((institution, instIndex) => (
                                    <td key={instIndex} style={{ borderLeftWidth: 1}}>{institutionsData[institution].votes[party]?.votes || 0}</td>
                                ))}
                            </tr>
                        ))}
                        <tr>
                            <td><strong>Total</strong></td>
                            <td></td>
                            {institutions.map((institution, index) => (
                                <td key={index}><strong>{getTotalVotes(institution)}</strong></td>
                            ))}
                        </tr>
                        <tr>
                            <td>Supporting Documents</td>
                            <td></td>
                            {institutions.map((institution, index) => (
                                <td key={index}>
                                    <img
                                        src={institutionsData[institution].files}
                                        alt="Supporting Document"
                                        onClick={() => openModal(institutionsData[institution].files)}
                                        style={{ width: '40px', height: '60px', cursor: 'pointer' }}
                                    />
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </Table>
            )}

            <Modal show={isModalVisible} onHide={() => setModalVisible(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Supporting Document</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalImage && <img src={modalImage} alt="Supporting Document" style={{ width: '100%' }} />}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setModalVisible(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Table_PollingStationAdmin;
