import { useAuth } from '../contexts/AuthContext';
import { Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Card } from '@mui/material';
import '../App.css';
import { COLOR_PRIMARY, COLOR_SECONDARY, fetchSeatsData, MAIN_URL, PRESIDENTIAL_CANDIDATES } from '../Constants';
import axios from 'axios';
import Swal from 'sweetalert2'; // For confirmation popups
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import ImageIcon from '@mui/icons-material/Image';
import { useNavigate } from 'react-router-dom'; // Assuming React Router for navigation
import moment from 'moment';
import ConstituencyList from '../ConstituencyList';
import ConstituencyResultsTable from '../ConstituencyResultsTable';
import ConstituencyMapSvg from '../ConstituencyMapSvg';
import ConstituencyMap from '../ConstituencyMap';
import ElectionHeader from '../ElectionHeader';
import HeaderVoteCounts from '../HeaderVoteCounts';
import PostList from '../PostList';


const ParliamentaryResults = ({ userType }) => {
    const { user } = useAuth();
    const { user_type } = user && user.user ? user.user : { user_type: null };
    const [presidentialData, setPresidentialData] = useState(null);


    const [pollingStations, setPollingStations] = useState(0);
    const [validVotes, setValidVotes] = useState(0);
    const [rejectedVotes, setRejectedVotes] = useState(0);
    const [posts, setPosts] = useState([]);
    const [selectedPosts, setSelectedPosts] = useState([]);
    const [constituencyData, setConstituencyData] = useState([])

    const navigate = useNavigate();


    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    const [constituencies, setConstituencies] = useState([])
    const [seatsData, setSeatsData] = useState({})



    // Fetching data from the API
    useEffect(() => {


        const getElectionResults = async () => {
            try {
                const response = await axios.get(`${MAIN_URL}election-results/constituencies/parliamentary/all`);

                setConstituencies(response.data)
                // Handle the response
                const electionResults = response.data;

                const allData = response.data;
                const { number_of_polling_stations, total_rejected_ballots,
                    total_valid_votes } = allData;
                setValidVotes(total_valid_votes);
                setRejectedVotes(total_rejected_ballots);
                setPollingStations(number_of_polling_stations)
                setConstituencyData(response.data);

                const seats_ = await fetchSeatsData("");
                setSeatsData(seats_);

            } catch (error) {
                console.error('Error fetching election results:', error);
                // Handle error response
            }
        };
        getElectionResults()
        axios.get(`${MAIN_URL}load/posts/main`)
            .then(response => {
                console.log(JSON.stringify(response.data))
                setPosts(response.data)
            })


    }, []);



    if (!user || user_type !== "admin") {
        return <Navigate to="/login" />;
    }


    const handleSearch = async (event) => {
        const query = event.target.value;
        setSearchQuery(query);

        // Perform search only when query length is at least 3 characters
        if (query.length >= 3) {
            try {
                const response = await axios.get(`${MAIN_URL}election-results/search/${query}`);
                setSearchResults(response.data);
            } catch (error) {
                console.error('Error fetching search results', error);
            }
        } else {
            // Clear search results if query length is less than 3
            setSearchResults([]);
        }
    };


    const barHeight = 40;
    const barSpacing = 30;
    const svgWidth = 600;
    const svgHeight = presidentialData ? (barHeight + barSpacing) * presidentialData.length : 0;

    return (
        <div className="admin-page">
            <div className="left-column">

                <ElectionHeader
                    ionHeader searchQuery={searchQuery}
                    searchResults={searchResults}
                    type="parliamentary"
                    navigate={navigate}
                    setSearchQuery={setSearchQuery}
                    handleSearch={handleSearch}
                    userType={userType} />


                <HeaderVoteCounts counted={(validVotes + rejectedVotes).toLocaleString()}
                    registered={"N/A"} ps={pollingStations.toLocaleString()}
                    valid={validVotes.toLocaleString()} rejected={rejectedVotes.toLocaleString()}
                    seats={seatsData}
                />


                <div style={{ padding: 10, flexDirection: "column", display: 'flex' }}>
                    <h3 style={{ textAlign: 'center', color: COLOR_PRIMARY, margin: 20 }}>
                        CONSTITUENCY RESULTS
                    </h3>
                    {<ConstituencyResultsTable constituencyData={constituencyData} />}


                    {<ConstituencyMap constituencyData={constituencyData} />}
                    {/* {constituencies && <ConstituencyMapSvg constituencyResults={constituencyData} />} */}


                </div>

            </div>
            <div className="right-column">

                <PostList />

            </div>


        </div>
    );
};

export default ParliamentaryResults;
