import React, { useEffect, useState } from 'react';
import { select } from 'd3-selection';
import { polygonCentroid } from 'd3-polygon';
import REGIONS_WITH_CONSTITUENCIES from './ConstituencyData';
import { useNavigate } from 'react-router-dom';

const getColor = (results) => {
    if (!results || results.length === 0) return '#ddd'; // Grey for empty results
    const ndcVotes = results.find(r => r.party === 'NDC')?.votes || 0;
    const nppVotes = results.find(r => r.party === 'NPP')?.votes || 0;
    if (ndcVotes > nppVotes) return 'green';  // NDC wins
    if (nppVotes > ndcVotes) return 'blue';   // NPP wins
    return 'goldenrod'; // Neutral case
};

const RegionalMap = ({ regionName, regionResults, userType }) => {
    console.log("Region results: ", JSON.stringify(regionResults));
    const [transform, setTransform] = useState(null);
    const constituencies = REGIONS_WITH_CONSTITUENCIES[regionName] || [];

    const navigate = useNavigate();

    useEffect(() => {
        // Set up scaling based on the main SVG container size
        const width = 500; // Container width
        const height = 500; // Container height

        setTransform({
            translate: [0, 0], // No translation
            scale: Math.min(width / 500, height / 500) * 0.9 * 1.9 // Scale to fit in container and increase size
        });
    }, []);

    const goToConstituency = (cName, code) => {

        userType === "admin" ? navigate("/constituency/admin/result/" + code, { state: { name: cName } }) : navigate("/constituency/result/" + code, { state: { name: cName } })

    }

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 500 500"
            width="100%"
            height="100%"
            style={{ border: '0px solid #999', marginTop: '-15%' }}
        >
            <g transform={transform ? `translate(${transform.translate}) scale(${transform.scale})` : null}>
                {/* Constituency paths */}
                {constituencies.map((constituency, idx) => {
                    // Skip constituencies without a valid path
                    if (!constituency.path || constituency.path.trim() === "") {
                        return null;
                    }

                    // Find results for the specific constituency
                    const results = regionResults?.list.find(item => item.constituency_code === constituency.code)?.results || [];
                    const regionColor = getColor(results); // Get the color based on constituency results

                    // Abbreviate constituency name
                    const abbreviatedName = constituency.name.split(' ').map(word => word[0]).join(''); // E.g., "OFORIKROM" to "O"
                    const { code, name } = constituency;
                    // console.log(code + " , " + name)
                    return (
                        <g key={idx}>
                            <path
                                d={constituency.path}
                                fill={regionColor}
                                stroke="white" // Set stroke color to white
                                strokeWidth="1.5" // Increase stroke width for visibility
                                onClick={() => goToConstituency(name, code)} // Call the click handler
                            />
                            {/* Tooltip for full constituency name */}
                            <title>{constituency.name}</title>
                            {/* Text labels for each constituency */}
                            <text
                                x={polygonCentroid(constituency.path.split(/[MLZ ]/).filter(Boolean).map(coord => coord.split(',').map(Number)))[0]} // Get centroid
                                y={polygonCentroid(constituency.path.split(/[MLZ ]/).filter(Boolean).map(coord => coord.split(',').map(Number)))[1]}
                                fontSize="6" // Increase font size for better readability
                                fill="white" // Set text color to white
                                fontWeight="bold" // Set text to bold
                                textAnchor="middle"
                            >
                                {""} {/* Display abbreviated name */}
                            </text>
                        </g>
                    );
                })}
            </g>
        </svg>
    );
};

export default RegionalMap;
