import { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import {
    Box,
    Card,
    CardContent,
    Typography,
    TextField,
    Button,
    Alert,
    InputAdornment,
    IconButton,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { MAIN_URL } from '../Constants.js';

const Login = () => {
    const { login } = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false); // Add loading state

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Set loading to true on form submit

        try {
            const response = await axios.post(MAIN_URL + 'media-watch/login', {
                email: email.trim(),
                password
            });

            // Store all login data and handle login
            login(response.data); // Store the whole response data
            localStorage.setItem('userData', JSON.stringify(response.data)); // Store all login data

            // Redirect to admin dashboard after successful login
            
            window.location.href = '/admin';  
        } catch (err) {
            setLoading(false); // Reset loading state
            if (err.response && err.response.status === 401) {
                setError('Invalid username or password');
            } else {
                setError('An unusual error has occurred while signing in. Try again later');
            }
            console.error("\n\nLogin error:", err.message);
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Box
            sx={{
                minHeight: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#f4f6f8',
            }}
        >
            <Card sx={{ maxWidth: 400, boxShadow: 3 }}>
                <img
                    src='/truekie_imprint.png'
                    width={100}
                    height={100}
                    style={{ alignSelf: 'center', marginLeft: "38%", marginTop: 30 }}
                />

                <CardContent>
                    <Typography
                        variant="h4"
                        align="center"
                        gutterBottom
                        sx={{ color: '#1976d2', fontWeight: 'bold' }}
                    >
                        Sign In
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        align="center"
                        sx={{ color: '#757575', marginBottom: 4 }}
                    >
                        Please sign in to your account
                    </Typography>

                    {error && (
                        <Alert severity="error" sx={{ marginBottom: 2 }}>
                            {error}
                        </Alert>
                    )}

                    <form onSubmit={handleSubmit}>
                        <TextField
                            label="Email"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <TextField
                            label="Password"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={togglePasswordVisibility}>
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                            sx={{ marginTop: 3, padding: 1, fontSize: 16 }}
                            disabled={loading} // Disable button while loading
                        >
                            {loading ? 'Logging In...' : 'Log In'}
                        </Button>
                    </form>

                    <Typography align="center" sx={{ marginTop: 2 }}>
                        Don’t have an account? <a href="/signup">Sign up</a>
                    </Typography>
                </CardContent>
            </Card>
        </Box>
    );
};

export default Login;
