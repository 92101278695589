import React, { useEffect, useState } from "react";
import axios from "axios";
import { Dropdown, ButtonGroup, Button, Form, Row, Col, Container, Card, Modal } from "react-bootstrap";
import ReactPlayer from "react-player";
import SweetAlert from 'sweetalert2';
import { COLOR_PRIMARY, MAIN_URL } from "../Constants";
import { Delete, Home, Settings, Share } from '@mui/icons-material';
import ElectionHeader from "../ElectionHeader";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const ManageIncidents = () => {
    const [posts, setPosts] = useState([]);
    const [selectedPosts, setSelectedPosts] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [currentPost, setCurrentPost] = useState(null);

    const navigate = useNavigate()

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const response = await axios.get(`${MAIN_URL}load/posts/main`);
                console.log(JSON.stringify(response.data))
                setPosts(response.data);
            } catch (error) {
                console.error("Error loading posts:", error);
            }
        };

        fetchPosts();
    }, []);

    const handleSelectPost = (postId) => {
        setSelectedPosts((prevSelectedPosts) =>
            prevSelectedPosts.includes(postId)
                ? prevSelectedPosts.filter((id) => id !== postId)
                : [...prevSelectedPosts, postId]
        );
    };

    const handleDelete = async (postIds) => {
        const result = await SweetAlert.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
        });

        if (result.isConfirmed) {
            try {
                await Promise.all(postIds.map(id => axios.delete(`${MAIN_URL}incident/delete/${id}`)));
                setPosts(posts.filter(post => !postIds.includes(post.incident_id))); // Update local state
                setSelectedPosts([]); // Clear selected posts
                SweetAlert.fire('Deleted!', 'Your selected posts have been deleted.', 'success');
            } catch (error) {
                console.error("Error deleting posts:", error);
                SweetAlert.fire('Error!', 'There was a problem deleting your posts.', 'error');
            }
        }
    };

    const handleBulkPublish = async (postIds, visibility) => {

        console.log(JSON.stringify(postIds))
        const result = await SweetAlert.fire({
            title: 'Are you sure?',
            text: `This will ${visibility ? 'publish' : 'hide'} the selected posts.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: `Yes, ${visibility ? 'publish' : 'hide'} it!`
        });

        if (result.isConfirmed) {
            try {
                await Promise.all(postIds.map(id => handlePublish(id, visibility))); // Handle publish for each post
                SweetAlert.fire('Success!', 'Selected posts have been updated.', 'success');
                window.location.reload();

            } catch (error) {
                console.error("Error updating post visibility:", error);
                SweetAlert.fire('Error!', 'There was a problem updating the visibility of your posts.', 'error');
            }
        }
    };
    const isPostSelected = (postId) => selectedPosts.includes(postId);

    const handleBulkDelete = async (postIds) => {
        const result = await SweetAlert.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
        });

        if (result.isConfirmed) {
            try {
                await Promise.all(postIds.map(id => axios.delete(`${MAIN_URL}delete/post/${id}`)));
                setPosts(posts.filter(post => !postIds.includes(post._id))); 
                SweetAlert.fire('Deleted!', 'Your post has been deleted.', 'success');
            } catch (error) {
                console.error("Error deleting posts:", error);
                SweetAlert.fire('Error!', 'There was a problem deleting your posts.', 'error');
            }
        }
    };
    const handleSingleDelete = async (postId) => {
        const result = await SweetAlert.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
        });

        if (result.isConfirmed) {
            try {
                await axios.delete(`${MAIN_URL}incident/delete/${postId}`); // Make a DELETE request for the single post
                setPosts(posts.filter(post => post.incident_id !== postId)); // Update local state to remove the deleted post
                SweetAlert.fire('Deleted!', 'Your post has been deleted.', 'success');
            } catch (error) {
                console.error("Error deleting post:", error);
                SweetAlert.fire('Error!', 'There was a problem deleting your post.', 'error');
            }
        }
    };


    const handlePublish = async (postId, visibility) => {
        try {
            const response = await axios.put(`${MAIN_URL}incident/update/${postId}/${visibility ? 'yes' : 'no'}`);
            console.log("ids", postId)
            // Update local state with the new visibility status
            setPosts(posts.map(post =>
                post.incident_id === postId ? { ...post, visible: visibility } : post
            ));

            SweetAlert.fire('Success!', 'Visibility has been updated.', 'success');
        } catch (error) {
            console.error("Error updating post visibility:", error);
            SweetAlert.fire('Error!', 'There was a problem updating the visibility.', 'error');
        }
    };


    const handleShare = (postId) => {
        console.log("Share post with ID:", postId);
    };

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedPosts([]);
        } else {
            setSelectedPosts(posts.map(post => post.incident_id));
        }
        setSelectAll(!selectAll);
    };

    const filterPostsByDate = () => {
        if (fromDate && toDate) {
            return posts.filter(post => {
                const postDate = new Date(post.date_posted);
                return postDate >= new Date(fromDate) && postDate <= new Date(toDate);
            });
        }
        return posts;
    };

    const filteredPosts = filterPostsByDate();

    const handleShowModal = (post) => {
        setCurrentPost(post);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setCurrentPost(null);
    };

    return (
        <Container fluid style={{ paddingTop: '80px' }}>
            {/* Fixed Action Bar for Select All and Bulk Actions */}
            <Row className="fixed-top bg-light py-2 shadow-sm"  >
                <Col md={{ span: 8, offset: 2 }} className="d-flex justify-content-between align-items-center"
                >
                    <div style={{ display: "flex", flexDirection: 'row' }}  >
                        <span onClick={() => navigate("/")}>  <Home
                            color={COLOR_PRIMARY}

                            style={{ width: 30, height: 30, marginRight: 29, color: COLOR_PRIMARY }}
                        />
                        </span>
                        <Settings
                            style={{
                                width: 15, height: 15,
                                marginRight: 5, marginTop: 7,
                                color: '#333'

                            }} />
                        <h4 style={{ color: "#777" }}>  MANAGE INCIDENT FILES</h4>
                    </div>

                    <div className="d-flex align-items-center">
                        <Form.Group controlId="fromDate" className="me-2">
                            <Form.Label>From</Form.Label>
                            <Form.Control
                                type="date"
                                value={fromDate}
                                onChange={(e) => setFromDate(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="toDate" className="me-2">
                            <Form.Label>To</Form.Label>
                            <Form.Control
                                type="date"
                                value={toDate}
                                onChange={(e) => setToDate(e.target.value)}
                            />
                        </Form.Group>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Form.Check
                            type="checkbox"
                            label="Select All"
                            checked={selectAll}
                            onChange={handleSelectAll}
                            className="me-2"
                            style={{ marginTop: 10, marginRight: 20 }}
                        />

                        {/* Conditionally render the Delete and Publish buttons */}
                        {selectedPosts.length > 0 && (
                            <>
                                <Button
                                    variant="danger"
                                    className="me-2"
                                    onClick={() => handleDelete(selectedPosts)}
                                >
                                    Delete Selected
                                </Button>
                                <Dropdown as={ButtonGroup}>
                                    <Dropdown.Toggle variant="primary">
                                        Publish Selected
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => {
                                            handleBulkPublish(selectedPosts, true)
                                        }}>
                                            Show
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleBulkPublish(selectedPosts, false)}>
                                            Hide
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </>
                        )}
                    </div>
                </Col>
            </Row>

            {/* Post Cards */}
            <Row className="justify-content-center" style={{ marginTop: '100px' }}>
                {filteredPosts.map((post) => (
                    <Col md={4} key={post._id} className="mb-3">
                        <Card
                            style={{
                                marginBottom: "15%", backgroundColor: "white",
                                cursor: "pointer"
                            }}
                        >
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h5 className="mb-0">{""}</h5>
                                <Form.Check
                                    type="checkbox"
                                    checked={isPostSelected(post.incident_id)}
                                    onChange={(e) => {
                                        e.stopPropagation();
                                        handleSelectPost(post.incident_id);
                                    }}
                                />
                            </div>
                            <Card.Body onClick={() => handleShowModal(post)}>
                                {/* Media (Video or Image) */}
                                {post.files.map((file, index) =>
                                    file.fileType.includes("video") ? (
                                        <ReactPlayer
                                            key={index}
                                            url={file.url}
                                            controls
                                            muted
                                            width="100%"
                                            className="mb-3"
                                        />
                                    ) : (
                                        <img
                                            key={index}
                                            src={file.url}
                                            alt="media"
                                            className="img-fluid rounded mb-3"
                                        />
                                    )
                                )}
                                <p>{post.description}</p>
                                <p className="text-muted mb-1">Uploaded by: {post.posted_name}</p>
                                <p className="text-muted mb-1">Date : {moment(post.date_posted).format("DD/MM/YYYY hh:mm A")}</p>

                            </Card.Body>
                            <div className="d-flex justify-content-between">
                                <div style={{ display: 'flex', flexDirection: 'column', flex: 2 }}>
                                    <Dropdown as={ButtonGroup} className="mb-3"
                                        style={{ display: 'flex', flex: 0.5, marginRight: '64%' }}>
                                        <Dropdown.Toggle variant={post.visible ? "success" : "secondary"}>
                                            {post.visible ? "Visible" : "Hidden"}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => handlePublish(post.incident_id, true)}>
                                                Show
                                            </Dropdown.Item>
                                            <Dropdown.Item onClick={() => handlePublish(post.incident_id, false)}>
                                                Hide
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <Button
                                            variant="primary"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleShare(post._id);
                                            }}
                                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}
                                        >
                                            <Share style={{ marginRight: 5 }} />
                                            Share
                                        </Button>
                                        <Button
                                            variant="danger"
                                            onClick={(e) => {
                                                e.stopPropagation();

                                                handleSingleDelete(post.incident_id);
                                            }}
                                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}
                                        >
                                            <Delete style={{ marginRight: 5 }} />
                                            Delete
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </Col>
                ))}
            </Row>

            {/* Modal for Post Details */}
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{currentPost ? currentPost.title : ""}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {currentPost && (
                        <>
                            <h5>Description</h5>
                            <p>{currentPost.description}</p>
                            <h5>Files</h5>
                            {currentPost.files.map((file, index) =>
                                file.fileType.includes("video") ? (
                                    <ReactPlayer
                                        key={index}
                                        url={file.url}
                                        controls
                                        muted
                                        width="100%"
                                        className="mb-3"
                                    />
                                ) : (
                                    <img
                                        key={index}
                                        src={file.url}
                                        alt="media"
                                        className="img-fluid rounded mb-3"
                                    />
                                )
                            )}
                        </>
                    )}
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default ManageIncidents;
