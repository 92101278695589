import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { COLOR_SELECTED, REGIONS } from './Constants';
import { CONSTITUENCIES } from './ConstituencyList';
import { useNavigate } from 'react-router-dom';
import { FaBuilding, FaUserFriends } from 'react-icons/fa';
import { Tooltip } from '@mui/material';
import { Building2, Factory, House, HouseIcon, LogOut, LucideBuilding, User, UsersRound } from 'lucide-react';

const ElectionHeaderAdmin = ({ resultType }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isRegionDropdownOpen, setIsRegionDropdownOpen] = useState(false);
    const [constituencySearch, setConstituencySearch] = useState("");
    const [regionSearch, setRegionSearch] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [userType, setUserType] = useState("admin");  // Assuming "admin" userType by default; update as needed
    const [type, setType] = useState("national");
    const navigate = useNavigate();

    useEffect(() => {
        setUserType("admin");
        setType(resultType);
    }, []);

    const handleRegionClick = (region) => {
        navigate(`/region/results/admin/${region}`);
        setIsRegionDropdownOpen(false);
    };

    const handleConstituencySearch = (e) => {
        setConstituencySearch(e.target.value);
    };

    const handleRegionSearch = (e) => {
        setRegionSearch(e.target.value);
    };

    const logout = () => {
        localStorage.removeItem('userData');
        navigate('/login');
    };

    const handleSearch = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        const results = CONSTITUENCIES.filter(
            item => item.name.toLowerCase().includes(query.toLowerCase())
        );
        setSearchResults(results);
    };

    const filteredConstituencies = CONSTITUENCIES.filter(constituency =>
        constituency.name.toLowerCase().includes(constituencySearch.toLowerCase()) ||
        constituency.code.toLowerCase().includes(constituencySearch.toLowerCase())
    );

    const filteredRegions = REGIONS.filter(region =>
        region.toLowerCase().includes(regionSearch.toLowerCase())
    );

    const menuItems = [
        { name: 'INCIDENTS', href: "/admin", selected: type === 'incidents', bgColor: COLOR_SELECTED },

        { name: 'NATIONAL', href: "/election-results", selected: type === 'national', bgColor: COLOR_SELECTED },
        { name: 'REGIONAL', href: '#', selected: type === 'regional', bgColor: COLOR_SELECTED, isDropdown: true },
        { name: 'CONSTITUENCY', href: '#', selected: type === 'constituency', bgColor: COLOR_SELECTED, isDropdown: true }
    ];

    return (
        <header className="shadow-sm" style={{
            width: '100%',
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: 1000,
            padding: '10px 20px',
            backgroundColor: '#f9f9f9',
            borderBottom: '1px solid #ddd',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        }}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 col-md-4 d-flex align-items-center">
                        <img src='/truekie_imprint.png' alt="Logo"
                            style={{ width: 40, height: 40, marginRight: 15 }}
                            onClick={() => navigate("/admin")}
                        />
                        <h4 style={{ color: "#666", marginBottom: 0, fontWeight: 'bold', textTransform: 'uppercase' }}>
                            Elections 2024
                        </h4>
                        <div className="d-flex align-items-center" style={{ fontSize: '14px', fontWeight: 'bold', color: 'salmon', marginLeft: 20 }}>
                            <span style={{
                                display: 'inline-block',
                                width: '12px',
                                height: '12px',
                                borderRadius: '50%',
                                backgroundColor: '#ff0000',
                                marginRight: '5px',
                                animation: 'pulse 1.5s infinite',
                            }}></span>
                            LIVE
                        </div>

                    </div>

                    <div className="col-12 col-md-8 d-flex flex-wrap align-items-center mt-3 mt-md-0">
                        <div className="d-flex flex-wrap">
                            {menuItems.map((item, index) => (
                                <div key={index} className="position-relative mx-2" style={{ zIndex: 1200 }}>
                                    <a
                                        href={item.name === "NATIONAL" ? item.href : (item.name === "INCIDENTS" ? item.href : undefined)}
                                        className="text-decoration-none px-3 py-2"
                                        style={{
                                            backgroundColor: item.selected ? "#fff" : 'transparent',
                                            borderRadius: '4px',
                                            fontSize: '16px',
                                            color: item.selected ? "#000" : '#666',
                                            border: item.selected ? "1.3px solid teal" : null,
                                            fontWeight: item.selected ? 'bold' : 'bold',
                                            cursor: item.isDropdown ? 'pointer' : 'auto',
                                        }}
                                        onClick={item.isDropdown ? () => {
                                            setIsDropdownOpen(item.name === 'CONSTITUENCY' ? !isDropdownOpen : false);
                                            setIsRegionDropdownOpen(item.name === 'REGIONAL' ? !isRegionDropdownOpen : false);
                                        } : null}
                                    >
                                        {item.name}
                                    </a>

                                    {item.isDropdown && (item.name === 'CONSTITUENCY' ? isDropdownOpen : isRegionDropdownOpen) && (
                                        <ul className="list-unstyled position-absolute mt-2" style={{
                                            background: '#fff',
                                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                            borderRadius: '8px',
                                            width: '250px',
                                            zIndex: 20,
                                            maxHeight: '300px',
                                            overflowY: 'auto',
                                            fontSize: '13px',
                                        }}>
                                            {item.name === 'CONSTITUENCY' && (
                                                <>
                                                    <li className="p-2">
                                                        <input type="text" placeholder="Search Constituency..." value={constituencySearch} onChange={handleConstituencySearch} style={{
                                                            borderRadius: '4px',
                                                            padding: '5px',
                                                            width: '100%',
                                                            border: '1px solid #ddd',
                                                            marginBottom: '5px',
                                                        }} />
                                                    </li>
                                                    {filteredConstituencies.map((constituency, idx) => (
                                                        <li key={idx} className="p-2" style={{ cursor: 'pointer', color: '#000', background: '#f5f5f5', padding: '10px' }}
                                                            onClick={() => {
                                                                const { code, name } = constituency;
                                                                navigate(`/constituency/results/admin/${code}/${name.replaceAll("/","-")}?reload=${new Date().getTime()}`, { state: { name } });

                                                            }}
                                                        >
                                                            {constituency.name} ({constituency.code})
                                                        </li>
                                                    ))}
                                                </>
                                            )}
                                            {item.name === 'REGIONAL' && (
                                                <>
                                                    <li className="p-2">
                                                        <input type="text" placeholder="Search Region..." value={regionSearch} onChange={handleRegionSearch} style={{
                                                            borderRadius: '4px',
                                                            padding: '5px',
                                                            width: '100%',
                                                            border: '1px solid #ddd',
                                                            marginBottom: '5px',
                                                        }} />
                                                    </li>
                                                    {filteredRegions.map((region, idx) => (
                                                        <li key={idx} className="p-2" style={{ cursor: 'pointer', color: '#000', background: '#f5f5f5', padding: '10px' }}
                                                            onClick={() => handleRegionClick(region)}
                                                        >
                                                            {region}
                                                        </li>
                                                    ))}
                                                </>
                                            )}
                                        </ul>
                                    )}
                                </div>
                            ))}
                        </div>

                        <div className="position-relative mt-3 mt-md-0" style={{
                            width: '100%', maxWidth: '250px', top: 2, marginLeft: "3%",
                            alignSelf: "flex-end", display: 'flex'
                        }}>
                            <input type="text" className="form-control" placeholder="Search..." value={searchQuery} onChange={handleSearch} style={{
                                borderRadius: '12px',
                                padding: '10px 20px',
                                border: '1px solid #ddd',
                                fontSize: '15px',
                                backgroundColor: '#fff',
                            }} />
                            {searchResults.length > 0 && (
                                <ul className="list-unstyled position-absolute mt-2" style={{
                                    zIndex: 10,
                                    background: '#fff',
                                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                    borderRadius: '8px',
                                    top: 40,
                                    width: '100%',
                                    maxHeight: '200px',
                                    overflowY: 'auto',
                                    fontSize: '12px',
                                }}>
                                    {searchResults.map((result, index) => (
                                        <li key={index} className="p-3 border-bottom" onClick={() => {
                                            setSearchQuery(result.name);
                                            setSearchResults([]);
                                            // navigate(`/constituency/results/admin/${result.code}?reload=${new Date().getTime()}`, { state: { name: result.name } });
                                            navigate(`/constituency/results/admin/${result.code}/${result.name.replaceAll("/","-")}?reload=${new Date().getTime()}`, { state: { name: result.name } });

                                        }} style={{ cursor: 'pointer' }}>
                                            {result.name} ({result.code})
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                        {/* <Tooltip title="Sign Out">
                            <div
                                style={{
                                    backgroundColor: "#fff", // Light card background
                                    padding: 15,
                                    borderRadius: 10,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                                    marginLeft: 20,
                                    width: 40,
                                    height: 40,
                                    cursor: "pointer",
                                }} onClick={() => { logout() }}
                            >
                                <span
                                    style={{
                                        width: 40,
                                        height: 40,
                                        borderRadius: 20,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <LogOut
                                        color="#444"
                                        size={20}
                                    />
                                </span>
                            </div>
                        </Tooltip> */}

                    </div>
                </div>
            </div>
        </header>
    );
};

export default ElectionHeaderAdmin;
