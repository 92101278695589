import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Grid, List, ListItem, ListItemText } from '@mui/material';
import QRCode from "react-qr-code";
import ReactPlayer from 'react-player';
import { useLocation, useNavigate } from 'react-router-dom';
import ElectionHeaderAdmin from './ElectionHeaderAdmin.js';
import HeaderMain from './HeaderMain.js';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import ImageIcon from '@mui/icons-material/Image';
import PublishIcon from '@mui/icons-material/Publish';
import ShareIcon from '@mui/icons-material/Share';
import ForwardIcon from '@mui/icons-material/Forward';
import DeleteIcon from '@mui/icons-material/Delete';
import FlagIcon from '@mui/icons-material/Flag';
import { Checkbox, Tooltip } from '@mui/material';
import moment from 'moment';
import { GOOGLE_MAPS_API_KEY, MAIN_URL } from './Constants';
import { useAuth } from './contexts/AuthContext';
import Modal from '@mui/material/Modal';
import Swal from 'sweetalert2';
import { LocalActivity, LocationOn, Phone, Unpublished } from '@mui/icons-material';
import SharePost from './SharePost';
import Loader from './Loader';
import { Forward } from 'lucide-react';
import withReactContent from "sweetalert2-react-content";


const ViewIncident = () => {
    const location = useLocation();
    const navigate = useNavigate();

    // Get post data from location state
    const post = location.state?.post;

    const [similarPosts, setSimilarPosts] = useState([]);
    const [currentFile, setCurrentFile] = useState(post?.files?.[0] || {});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // Coordinates (lat, lng) - You can adjust this depending on the post data
    const lat = post?.files?.[0]?.latitude;
    const lng = post?.files?.[0]?.longitude;



    const [posts_, setPosts_] = useState([]);
    const [selectedPosts, setSelectedPosts] = useState([]);
    const [selectionMode, setSelectionMode] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const { user } = useAuth();

    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [showShare, setShowShare] = useState(false);
    const [link, setLink] = useState("");
    const [title, setTitle] = useState("")

    const ContactPoster = withReactContent(Swal);



    // State for filtering
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filteredPosts, setFilteredPosts] = useState([]);
    const [selectedPost, setSelectedPost] = useState(null);

    const [posterData, setPosterData] = useState(null)

    // Fetch related posts
    const fetchPosts = async () => {
        setLoading(true);
        setError(null);
        try {
            let url = lat && lng
                ? `${MAIN_URL}load/posts/main/${lat}/${lng}`
                : `${MAIN_URL}load/posts/main/t`;

            // You can also adjust based on region or other criteria
            const response = await axios.get(url);
            const newPosts = response.data;

            setLoading(false);
            setSimilarPosts(newPosts);  // Set the related posts

        } catch (err) {
            setError('Failed to load posts');
            console.error(err);
            setLoading(false);
        }
    };

    // Call fetchPosts on component mount
    useEffect(() => {
        fetchPosts();
    }, []);

    const ForwardPosts = () => {

    }

    const showContactOptions = (phone_number, email) => {
        ContactPoster.fire({
            title: "Contact Options",
            text: "Choose your preferred method of contact:",
            icon: "info",
            showCancelButton: true,
            showConfirmButton: false,
            html: `
            <button id="call-button" style="padding: 10px; margin: 5px; background-color: #4CAF50; color: white; border: none; border-radius: 5px; cursor: pointer;">Call</button>
            <button id="whatsapp-button" style="padding: 10px; margin: 5px; background-color: #25D366; color: white; border: none; border-radius: 5px; cursor: pointer;">WhatsApp</button>
            <button id="email-button" style="padding: 10px; margin: 5px; background-color: #0072C6; color: white; border: none; border-radius: 5px; cursor: pointer;">Email</button>
          `,
            didOpen: () => {


                document.getElementById("call-button").addEventListener("click", () => {
                    window.location.href = `tel:${phone_number}`;
                });

                // Handle WhatsApp action
                document.getElementById("whatsapp-button").addEventListener("click", () => {
                    const whatsappURL = `https://wa.me/${phone_number?.replace(/\D/g, "")}`;
                    window.open(whatsappURL, "_blank");
                });

                // Handle Email action
                document.getElementById("email-button").addEventListener("click", () => {
                    window.location.href = `mailto:${email}`;
                });
            },
        });
    };
    const getUser = async (id) => {

        try {


            const response = await axios.get(MAIN_URL + "media-watch/user/get/" + id)
            if (response.data) {
                setPosterData(response.data?.user)
                // alert(JSON.stringify(response.data.user))
                const { phone_number, email } = response.data.user;
                showContactOptions(phone_number, email)

            }

        } catch (e) {
            console.log("Error occured" + e)
        }

    }
    const performSharing = (id) => {
        setShowShare(!showShare);
        setLink(MAIN_URL + "post/" + id)

        // console.log("/post/" + id)

    };
    const singleDelete = async (postId) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
        });

        if (result.isConfirmed) {
            try {
                await axios.delete(`${MAIN_URL}incident/delete/${postId}`);
                setPosts_(posts_.filter(post => post.incident_id !== postId));
                Swal.fire('Deleted!', 'Your post has been deleted.', 'success').then(() => {
                    // Reload the page after the success message is closed
                    window.location.reload();
                });
            } catch (error) {
                console.error("Error deleting post:", error);
                Swal.fire('Error!', 'There was a problem deleting your post.', 'error');
            }
        }
    };





    const Publish = async (postId, visibility) => {
        try {
            const response = await axios.put(`${MAIN_URL}incident/update/${postId}/${visibility ? 'yes' : 'no'}`);
            console.log("ids", postId);

            // Update local state with the new visibility status
            setPosts_(posts_.map(post =>
                post._id === postId ? { ...post, visible: visibility } : post
            ));

            Swal.fire({
                title: 'Success!',
                text: 'Visibility has been updated.',
                icon: 'success',
                confirmButtonText: 'OK'
            }).then((result) => {

            });
        } catch (error) {
            console.error("Error updating post visibility:", error);
            Swal.fire('Error!', 'There was a problem updating the visibility.', 'error');
        }
    };

    // If no post is found, render fallback UI
    if (!post) {
        return (
            <Box sx={{ p: 4, textAlign: 'center' }}>
                <Typography variant="h6" color="text.secondary">
                    No incident data found.
                </Typography>
                <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
                    Go Back
                </Button>
            </Box>
        );
    }

    const {
        address,
        date_posted,
        description,
        phone_number,
        posted_name,
        email,
        orientation = "none",
    } = post;

    // Handle file changes when user clicks a similar post
    const handleFileChange = (post) => {
        // const newFile = newPost.files?.[0] || {};
        // setCurrentFile(newFile);  
        navigate(`/post/incident/view/${post._id}`, { state: { post } });
        window.location.reload();


    };

    return (

        <div>
            {<HeaderMain />}
            {showShare && (
                <div style={{ position: 'fixed', top: '40%', left: '50%', transform: 'translateX(-50%)', zIndex: 1000 }}>
                    <SharePost url={link} text={title} onCancel={() => setShowShare(false)} />
                </div>
            )}
            <Box
                sx={{
                    p: 4,
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: 10,
                    justifyContent: 'space-between',
                    flexWrap: 'wrap',
                }}
            >
                {/* Main Content: Original Selected Post */}
                <Box sx={{
                    flex: 1, position: 'relative', paddingTop: '56.25%', backgroundColor: '#000',
                    marginRight: 2
                }}>
                    {currentFile?.url ? (
                        currentFile.fileType.startsWith('video') ? (
                            <ReactPlayer
                                url={currentFile.url}
                                controls
                                width="100%"
                                height="100%"
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    objectFit: 'contain',
                                }}
                            />
                        ) : (
                            <img
                                src={currentFile.url}
                                alt="Post file"
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                            />
                        )
                    ) : (
                        <Typography>No file available</Typography>
                    )}
                    {/* Watermark */}
                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: 60,
                            right: 15,
                            backgroundColor: 'rgba(0, 0, 0, 0.05)',
                            color: 'white',
                            padding: '4px 8px',
                            borderRadius: 1,
                            fontSize: '0.8rem',
                            textShadow: '2px 2px 5px black'
                        }}
                    >
                        <Typography>{address}</Typography>
                        <Typography>
                            {moment(date_posted).format('DD/MM/YYYY, h:mm:ss a')}
                        </Typography>
                    </Box>

                </Box>

                {/* Right Side Content: Similar Posts */}
                <Box sx={{ flexBasis: '300px', display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>Related Posts</Typography>
                    <List sx={{ maxHeight: '500px', overflowY: 'auto', padding: 0 }}>
                        {loading ? (
                            <Typography>Loading...</Typography>
                        ) : error ? (
                            <Typography color="error">{error}</Typography>
                        ) : (
                            similarPosts.map((similarPost) => (
                                <div
                                    key={similarPost._id}
                                    style={{
                                        position: 'relative',
                                        marginBottom: '0',
                                        padding: '5px 0',
                                        border: 'none',
                                        borderRadius: '5px',
                                        cursor: 'pointer',
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                    onClick={() => handleFileChange(similarPost)}
                                >
                                    {similarPost.files && similarPost.files[0] && (
                                        <div
                                            style={{
                                                position: 'relative',
                                                backgroundColor: 'black',
                                                paddingTop: '56.25%',
                                            }}
                                        >
                                            {similarPost.files[0].fileType.startsWith('video') ? (
                                                <>
                                                    <video
                                                        src={similarPost.files[0].url || 'path_to_default_video_thumbnail'}
                                                        style={{
                                                            position: 'absolute',
                                                            top: 0,
                                                            left: 0,
                                                            width: '100%',
                                                            height: '100%',
                                                            objectFit: 'cover',
                                                        }}
                                                    />
                                                    <PlayCircleFilledWhiteIcon
                                                        style={{
                                                            position: 'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform: 'translate(-50%, -50%)',
                                                            color: '#fff',
                                                            fontSize: '25px',
                                                            opacity: 0.7,
                                                        }}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <img
                                                        src={similarPost.files[0].url}
                                                        alt="Image thumbnail"
                                                        style={{
                                                            position: 'absolute',
                                                            top: 0,
                                                            left: 0,
                                                            width: '100%',
                                                            height: '100%',
                                                            objectFit: 'cover',
                                                        }}
                                                    />
                                                    <ImageIcon
                                                        style={{
                                                            position: 'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            transform: 'translate(-50%, -50%)',
                                                            color: '#fff',
                                                            fontSize: '30px',
                                                            opacity: 0.7,
                                                        }}
                                                    />
                                                </>
                                            )}
                                        </div>
                                    )}
                                    <div style={{
                                        display: "flex", flexDirection: 'column',
                                        position: 'absolute', zIndex: 10,
                                        backgroundColor: 'rgba(0,0,0,0.0)', width: '100%', height: '40%',
                                        top: '70%', padding: 5, paddingTop: 0
                                    }}>
                                        <p style={{ color: "#fff", fontSize: '13px', padding: '3px 0', margin: '0' }}>
                                            {similarPost.address + "\n\n" + moment(similarPost.date_posted).format('DD/MM/YYYY, h:mm:ss a')}
                                        </p>
                                    </div>
                                </div>
                            ))
                        )}
                    </List>
                </Box>


            </Box>
            <div style={{ paddingLeft: 35 }}>
                <p>Posted By: <strong>{posted_name} </strong><br /> <span style={{ color: '#888' }}>{description} </span> </p>



            </div>
            <div style={{
                padding: '10px', color: '#333',
                fontSize: '12px', flexDirection: 'row', display: 'flex', justifyContent: 'space-evenly'
            }}>
                {/* {
                JSON.stringify(post)
            } */}

                <Tooltip title={'Location'}>
                    <LocationOn color='info' onClick={() => {
                        const { latitude, longitude } = post.files[0];
                        const url = `https://www.google.com/maps?q=${latitude},${longitude}`;
                        window.open(url, '_blank');
                    }} />
                </Tooltip>
                <Tooltip title={post.visible ? 'Unpublished: Click to publish' : 'Published'} onClick={() => {
                    let isPublished = !post.visible ? true : false;
                    console.log(post._id, isPublished)
                    Publish(post._id, isPublished)
                }}>
                    {post.visible ? <Unpublished /> : <PublishIcon />}
                </Tooltip>
                <Tooltip title={'Delete'} onClick={() => singleDelete(post._id)}>

                    <DeleteIcon />
                </Tooltip>
                <Tooltip title={'Share'} onClick={() => performSharing(post._id)}>

                    <ShareIcon />
                </Tooltip>
                <Tooltip title={'Forward'} onClick={ForwardPosts}>

                    {/* <ForwardIcon /> */}
                    <Forward />
                </Tooltip>
                <Tooltip title="Call" onClick={() => {
                    // alert(post.posted_by)
                    getUser(post.posted_by)
                }}>
                    <Phone color='success' />
                </Tooltip>
            </div>
        </div>
    );
};

export default ViewIncident;
