import React from 'react';
import { useNavigate } from 'react-router-dom';
import './App.css';

const REGIONS = [
    "AHAFO", "ASHANTI", "BONO", "BONO EAST", "CENTRAL", "EASTERN",
    "GREATER ACCRA", "NORTHERN", "NORTH EAST", "OTI", "SAVANNAH",
    "UPPER EAST", "UPPER WEST", "VOLTA", "WESTERN", "WESTERN NORTH"
];

const PARTIES = [
    "NDC", "NPP", "IND1", "IND2", "GCPP", "IND3", "IND4", "GFP",
    "GUM", "CPP", "APC", "NDP", "LPG"
];

const PARTY_ROW_STYLES = {
    NDC: { backgroundColor: 'green', color: 'white' },
    NPP: { backgroundColor: 'lightblue', color: 'white' },
    DEFAULT: { backgroundColor: 'white', color: 'black' },
    EMPTY: { backgroundColor: '#eee', color: 'black' }, // For rows with all zero values
};

const TableRegional = ({ resultsData }) => {
    const navigate = useNavigate();

    // Function to determine the row background color based on the top party
    const getRowBackgroundColor = (results) => {
        if (results.length === 0 || results.every(result => result.votes === 0)) {
            return PARTY_ROW_STYLES.EMPTY; // Light grey for rows with all zeros
        }

        const sortedResults = [...results].sort((a, b) => b.votes - a.votes);
        const topParty = sortedResults[0]?.party;

        return PARTY_ROW_STYLES[topParty] || PARTY_ROW_STYLES.DEFAULT; // Default is white background
    };

    // Function to style NDC and NPP cells: bold, slightly larger, and color based on row background
    const getPartyCellStyle = (party, rowColor, defaultTextColor) => {
        const isNDCOrNPP = party === 'NDC' || party === 'NPP';
        return {
            fontWeight: isNDCOrNPP ? 'bold' : 'normal',
            fontSize: isNDCOrNPP ? '1.1em' : '1em',
            color: rowColor === 'white' ? 'black' : defaultTextColor, // Black text for rows with white background, else white text
            textAlign: 'center', // Percentages are centered
        };
    };

    // Function to get the percentage for each party
    const getPartyData = (results, party, rowStyle) => {
        const partyResult = results.find(result => result.party === party);
        const percentage = partyResult ? `${partyResult.percentage}%` : '0%';

        return (
            <td key={party} style={{ ...getPartyCellStyle(party, rowStyle.backgroundColor, rowStyle.color), borderBottom: '1px solid #ddd' }}>
                {percentage}
            </td>
        );
    };

    // Styles for the table and cells with horizontal borders only
    const tableStyle = {
        width: '100%',
        borderCollapse: 'collapse',
        margin: '20px 0',
    };

    const headerStyle = {
        backgroundColor: '#333',
        color: 'white',
        padding: '10px',
        textAlign: 'center',
        fontSize: "16px",
        borderBottom: '2px solid #ddd', // Horizontal bottom border for header
    };

    const regionCellStyle = {
        padding: '8px',
        fontSize: '14px',
        textAlign: 'left', // Regions should be left-aligned
        borderBottom: '1px solid #ddd',
    };

    const cellStyle = {
        borderBottom: '1px solid #ddd', // Horizontal border for all cells
        padding: '8px',
    };

    return (
        <table style={tableStyle}>
            <thead>
                <tr>
                    <th style={headerStyle}>Region</th>
                    {PARTIES.map((party) => (
                        <th key={party} style={headerStyle}>{party}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {REGIONS.map((region) => {
                    const regionData = resultsData[region] || {};
                    const regionResults = regionData.results || [];

                    // Determine row style based on leading party
                    const rowStyle = getRowBackgroundColor(regionResults);

                    return (
                        <tr key={region} style={rowStyle} onClick={() => {
                            navigate("/election-results/region/" + region)
                        }}>
                            <td style={{ ...regionCellStyle, color: rowStyle.color }}>{region}</td>
                            {PARTIES.map((party) => getPartyData(regionResults, party, rowStyle))}
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default TableRegional;
