import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext'; // Adjust the path based on your structure
import Home from './Home';

const HomePage = () => {
    const { user } = useAuth(); // Assuming you have a user object in your Auth context
    const navigate = useNavigate();

    useEffect(() => {
        // if (!user) {
        //     navigate('/login');
        // } else {
        //     navigate('/admin');
        // }
    }, [user, navigate]);

    return <Home type={"public"} />
};

export default HomePage;
