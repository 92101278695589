import React from 'react';

const partyColors = {
    NPP: '#0047ab',
    NDC: 'green',
    GCPP: '#228b22',
    GFP: '#32CD32',
    GUM: '#ff4500',
    LPG: '#800080',
    NDP: '#FFD700',
    CPP: '#ff6455',
    APC: '#ff6347',
    IND1: '#808080',
    IND2: '#808080',
    IND3: '#000080',
    IND4: '#8b0000',
};

const ChartsParliamentary = ({ data, candidates }) => {
    // Function to get candidate image by party name
    const getCandidateImage = (partyName) => {
        const candidate = candidates.find(c => c.partyName === partyName);
        return candidate ? candidate.imageOfCandidate : null;
    };

    const getPartyFlag = (partyName) => {
        const candidate = candidates.find(c => c.partyName === partyName);
        return candidate ? candidate.partyImage : null;
    };

    const getPartyColor = (partyName) => {
        return partyColors[partyName] || '#000000'; // Default to black if no color is found
    };

    // Calculate total votes for percentage calculation
    const totalVotes = data ? data.reduce((sum, result) => sum + result.votes, 0) : 0;

    const barHeight = 40;
    const barSpacing = 30;
    const svgWidth = 600;
    const svgHeight = data ? (barHeight + barSpacing) * data.length : 0;

    return (
        <div style={{ padding: 10, flexDirection: "column", display: 'flex' }}>
            <div style={{ padding: 10, display: 'flex', justifyContent: 'center', backgroundColor: '#f5f5f5', borderRadius: 8 }}>
                {data ? (
                    <svg width={svgWidth} height={svgHeight / 2}>
                        {data.map((result, index) => {
                            const percentage = parseFloat(result.percentage.replace("%", ""));
                            const barWidth = (percentage / 100) * svgWidth;
                            const yPosition = index * (barHeight + barSpacing);
                            const partyColor = getPartyColor(result.party);
                            const candidateImage = getCandidateImage(result.party);
                            const partyFlag = getPartyFlag(result.party);

                            return (
                                <g key={result.party}>
                                    {/* Candidate Image */}
                                    <image
                                        href={candidateImage || "/mp.png"}
                                        x="5"
                                        y={yPosition + 1}
                                        width={55}
                                        height="55"
                                    />

                                    {/* Party Name */}
                                    <text
                                        x="65"
                                        y={yPosition + (barHeight / 2)}
                                        alignmentBaseline="middle"
                                        fontSize="16"
                                        fill="#aaa"
                                    >
                                        {result.party}
                                    </text>

                                    {/* Candidate Name */}
                                    <text
                                        x="10"
                                        y={yPosition + (barHeight / 0.42)}
                                        alignmentBaseline="middle"
                                        fontSize="15"
                                        fill="#333"
                                    >
                                        {result.candidate}
                                    </text>

                                    {/* Party Flag */}
                                    <image
                                        x="65"
                                        y={yPosition + (barHeight / 1.4)}
                                        href={partyFlag}
                                        width="35"
                                        height="30"
                                    />

                                    {/* Bar */}
                                    <rect
                                        x="150"
                                        y={yPosition}
                                        width={barWidth}
                                        height={barHeight}
                                        fill={partyColor}
                                    />

                                    {/* Percentage Text */}
                                    <text
                                        x={150 + barWidth + 10}
                                        y={yPosition + (barHeight / 2)}
                                        alignmentBaseline="middle"
                                        fontSize="15"
                                        fill="#555"
                                        fontWeight="bold"
                                    >
                                        {result.percentage}
                                    </text>

                                    {/* Votes Text */}
                                    <text
                                        x="150"
                                        y={yPosition + barHeight + 15}
                                        fontSize="15"
                                        fill="#777"
                                    >
                                        {result.votes.toLocaleString()}
                                    </text>
                                </g>
                            );
                        })}
                    </svg>
                ) : (
                    <p style={{ marginLeft: 10 }}>Loading results...</p>
                )}
            </div>
        </div>
    );
};

export default ChartsParliamentary;
