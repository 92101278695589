import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import ReactPlayer from 'react-player';
import '../App.css';
import { COLOR_PRIMARY, COLOR_SECONDARY, GOOGLE_MAPS_API_KEY, MAIN_URL } from '../Constants.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faShare } from '@fortawesome/free-solid-svg-icons';
import QRCode from "react-qr-code";
import { Card } from '@mui/material';



function IncidentPage({ post1, id }) {
    let { incident_id } = useParams();
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [itemLink, setItemLink] = useState("");



    useEffect(() => {
        post1 && setPost(post1);
        if (id) {
            incident_id = id;
        }

        axios.get(`${MAIN_URL}incident/${incident_id}`)
            .then(response => {
                setPost(response.data);
                setItemLink(`${MAIN_URL}incident/${incident_id}`);
                setLoading(false);
            })
            .catch(error => {
                setError('Failed to load post');
                setLoading(false);
            });


        const handleDeepLink = () => {
            const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            const appScheme = `mediawatch://post/${incident_id}`;
            const webUrl = `${window.location.origin}/post/${incident_id}`;

            if (isMobile) {
                const urlParams = new URLSearchParams(window.location.search);
                if (!urlParams.has('fallback')) {
                    window.location.href = appScheme;
                    setTimeout(() => {
                        window.location.href = `${webUrl}?fallback=true`;
                    }, 1500);
                }
            }
        };

        handleDeepLink();
    }, [incident_id]);

    function openLocation() {
        const { latitude, longitude } = post?.files[0];
        const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
        window.open(url, '_blank');
    }

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <body>
            <Card style={{
                height: "10%", display: 'flex',
                flexDirection: 'row', padding: 10, marginBottom: 5,
                paddingLeft: "15%", backgroundColor: "rgba(0, 128, 128,0.2)"
            }}>
                <span style={{ display: "flex", flexDirection: 'row', flex: 1 }}>
                    <span> <a href='/'>
                        <img src='/truekie_imprint.png' style={{ width: 50, height: 50, marginRight: "30%" }} />
                    </a>
                    </span>
                    <h4 style={{
                        textAlign: 'center', flex: 2,
                        display: 'flex', color: "#777", marginLeft: "20%", fontFamily: 'verdana'
                    }}>ELECTION INCIDENT</h4>
                </span>
            </Card>
            <div className="incident-page-container" style={{
                display: 'flex',
                padding: '20px'
                , paddingLeft: "20%", backgroundColor: "white"
            }}>

                {post && (
                    <>
                        {/* Left Column: Media */}
                        <div style={{ flex: 2, marginRight: '20px', backgroundColor: '#fff' }}>
                            {post.files.map(file => (
                                <div key={file._id} style={{ height: "70%" }}>
                                    {file.fileType.startsWith('image') && (
                                        <img src={file.url} alt="file" style={{ width: '100%', height: 'auto' }} />
                                    )}
                                    {file.fileType.startsWith('video') && (
                                        <ReactPlayer
                                            url={file.url}
                                            controls
                                            width="80%"
                                            height="70%"
                                        />
                                    )}
                                    {file.fileType.startsWith('audio') && (
                                        <audio controls>
                                            <source src={file.url} type={file.fileType} />
                                            Your browser does not support the audio tag.
                                        </audio>
                                    )}
                                </div>
                            ))}

                            {/* Description Below the Media */}
                            <div className="description-section" style={{ marginTop: '15px', color: 'white' }}>
                                <p><strong>Description:</strong> {post.description}</p>
                                <p><strong>Captured on:</strong> {post.files[0].dateTime}</p>
                            </div>
                        </div>

                        {/* Right Column: Map, Details, and QR Code */}
                        <div className="details-section" style={{ flex: 1 }}>
                            {/* Map */}
                            <iframe
                                title="Google Street View"
                                width="100%"
                                height="250"
                                style={{ border: 0, marginBottom: '10px' }}
                                loading="lazy"
                                allowFullScreen
                                src={`https://www.google.com/maps/embed/v1/streetview?key=${GOOGLE_MAPS_API_KEY}&location=${post.files[0].latitude},${post.files[0].longitude}&heading=210&pitch=10&fov=35`}
                            ></iframe>


                            {/* File Details */}
                            <div className="file-details" style={{ color: 'black', marginBottom: '20px' }}>
                                <p><FontAwesomeIcon icon={faMapMarkerAlt} /> <strong>Location:</strong> {post.address}</p>
                                <p><strong>Captured on:</strong> {post.files[0].dateTime}</p>

                                <p><strong>Posted By:</strong> {post.posted_name}</p>
                            </div>

                            {/* Locate & Share Buttons */}
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <button onClick={openLocation} style={buttonStyle}>
                                    <FontAwesomeIcon icon={faMapMarkerAlt} /> Locate
                                </button>
                                <button style={buttonStyle}>
                                    <FontAwesomeIcon icon={faShare} /> Share
                                </button>
                            </div>

                            {/* QR Code */}
                            <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
                                <QRCode
                                    size={150}
                                    value={itemLink}
                                />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </body>
    );
}

const buttonStyle = {
    backgroundColor: 'rgba(0, 128, 128, 0.8)',
    color: 'white',
    padding: '10px',
    borderRadius: '5px',
    cursor: 'pointer',
    border: 'none',
    width: '45%',
    textAlign: 'center',
};

export default IncidentPage;
