import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Card, Container, Row, Col, ListGroup, Spinner, Alert, Accordion, Modal, Button, Table } from 'react-bootstrap';
import { COLOR_PRIMARY, MAIN_URL } from '../Constants';
import { useAuth } from '../contexts/AuthContext';
import Loader from '../Loader';
import ElectionHeaderAdmin from '../ElectionHeaderAdmin';

const InstitutionResults = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [expandedSection, setExpandedSection] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const { user } = useAuth();
    const location = useLocation();
    const { id } = location.state || {};

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`${MAIN_URL}institution/get/${id}`);
                setData(response.data);
            } catch (err) {
                setError('Failed to fetch data. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [id]);

    if (loading) return <Loader />;
    if (!user) return <Navigate to="/login" />;
    if (error) return <Alert variant="danger" className="my-4">{error}</Alert>;

    const removeDuplicates = (data, keyField) => {
        const seen = new Set();
        return data.filter(item => {
            const key = item[keyField];
            if (seen.has(key)) return false;
            seen.add(key);
            return true;
        });
    };

    const renderResultItem = (item) => (
        <ListGroup.Item key={item._id} className="d-flex justify-content-between align-items-center">
            <div>
            {/* <p>{JSON.stringify(item)}</p> */}

                <span><b>{item.party}</b>   - {item.name}</span>
            </div>
            <div>
                <span >{item.votes} votes</span>
            </div>
        </ListGroup.Item>
    );

    const renderPollingStationResults = () => {
        const uniquePollingStationResults = removeDuplicates(data?.polling_station_results?.presidential || [], 'polling_station_code');
        return uniquePollingStationResults.map((item, index) => (
            <Accordion.Item eventKey={index} key={index}>
                <Accordion.Header>
                    {item.polling_station_name}
                </Accordion.Header>
                <Accordion.Body>
                    <Card.Text>
                        <strong>Constituency:</strong> {item.constituency_name} ({item.constituency_code})<br />
                        <strong>Region:</strong> {item.region}<br />
                        <strong>Polling Station Code:</strong> {item.polling_station_code}
                    </Card.Text>
                    <h5 style={{ color: "#555", paddingLeft: 15 }}>Presidential Results</h5>
                    <ListGroup variant="flush">
                        {item?.results?.map((result) => renderResultItem(result))}
                    </ListGroup>
                    <h5 style={{ color: "#555", paddingLeft: 15 }} className="mt-3">Parliamentary Results</h5>
                    <ListGroup variant="flush">
                        {item?.parliamentary?.map((result) => renderResultItem(result))}
                    </ListGroup>
                </Accordion.Body>
            </Accordion.Item>
        ));
    };

    const renderConstituencyResults = () => {
        const uniqueConstituencyResults = removeDuplicates(data?.constituency_results?.presidential || [], 'constituency_code');
        return uniqueConstituencyResults.map((item, index) => (
            <Accordion.Item eventKey={index} key={index}>
                <Accordion.Header>
                    {item.constituency_name}
                </Accordion.Header>
                <Accordion.Body>
                    <Card.Text>
                        <strong>Region:</strong> {item.region}<br />
                        <strong>Constituency Code:</strong> {item.constituency_code}
                    </Card.Text>
                    <h5 style={{ color: "#555", paddingLeft: 15 }}>PRESIDENTIAL</h5>
                    <ListGroup variant="flush">
                        {item?.results?.map((result) => renderResultItem(result))}
                    </ListGroup>
                    <h5 style={{ color: "#555", paddingLeft: 15 }} className="mt-3">PARLIAMENTARY</h5>
                    <ListGroup variant="flush">
                        {item?.parliamentary?.map((result) => renderResultItem(result))}
                    </ListGroup>
                </Accordion.Body>
            </Accordion.Item>
        ));
    };

    const renderRegionalResults = () => {
        const uniqueRegionalResults = removeDuplicates(data?.regional_results?.presidential || [], 'region');
        return uniqueRegionalResults.map((item, index) => (
            <Accordion.Item eventKey={index} key={index}>
                <Accordion.Header>
                    {item.region}
                </Accordion.Header>
                <Accordion.Body>
                    <ListGroup variant="flush">
                        {item?.results?.map((result) => renderResultItem(result))}
                    </ListGroup>
                </Accordion.Body>
            </Accordion.Item>
        ));
    };

    const handleShowModal = (result) => {
        setModalData(result);
        setShowModal(true);
    };

    const handleCloseModal = () => setShowModal(false);

    return (
        <Container className="my-5">
            <ElectionHeaderAdmin />
            <Row style={{ marginTop: "10%" }}>
                <Col lg={4}>
                    <h4 className="text-uppercase mb-3" style={{ color: COLOR_PRIMARY }}>{data.sender_institution}</h4>
                    <div style={{ padding: 20 }}>
                        <p><strong>Phone:</strong> {data.sender_phone}</p>
                        <p><strong>Name:</strong> {data.sender_name}</p>
                        <p><strong>Email:</strong> {data.email}</p>
                        <p><strong>Institution:</strong> {data.sender_institution}</p>
                    </div>
                </Col>
                <Col lg={8}>
                    <h4 className="text-uppercase mb-3">Polling Station Results</h4>
                    <Accordion defaultActiveKey="0" className="mb-4">{renderPollingStationResults()}</Accordion>

                    <h4 className="text-uppercase mb-3">Constituency Results</h4>
                    <Accordion defaultActiveKey="0" className="mb-4">{renderConstituencyResults()}</Accordion>

                    <h4 className="text-uppercase mb-3">Regional Results</h4>
                    <Accordion defaultActiveKey="0" className="mb-4">{renderRegionalResults()}</Accordion>

                    {/* Modal for detailed result view */}
                    <Modal show={showModal} onHide={handleCloseModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Detailed Result</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {modalData ? (
                                <>
                                    <h5>{modalData.name} - {modalData.party}</h5>
                                    <p><strong>Votes:</strong> {modalData.votes}</p>
                                    <Button variant="secondary" onClick={handleCloseModal}>Close</Button>
                                </>
                            ) : (
                                <Spinner animation="border" />
                            )}
                        </Modal.Body>
                    </Modal>
                </Col>
            </Row>
        </Container>
    );
};

export default InstitutionResults;
