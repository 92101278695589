// src/components/Navbar.js
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const Navbar = () => {
    const { user, logout } = useAuth();

    return (
        <nav>
            {/* <Link to="/">Home</Link> */}
            {/* {user ? (
                <>
                    <Link to="/admin">Admin</Link>
                    <button onClick={logout}>Logout</button>
                </>
            ) : (
                <Link to="/login">Login</Link>
            )} */}
        </nav>
    );
};

export default Navbar;
