export const CONSTITUENCIES = [
    {
      "region": "WESTERN",
      "code": "A0101",
      "name": "JOMORO",
      "num": 1,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "PAUL ESSIEN"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "DORCAS TOFFEY"
        },
        {
          "pid": 3,
          "party": "CPP",
          "name": "NANA BLAY MIENZAH"
        },
        {
          "pid": 4,
          "party": "IND",
          "name": " NKRUMAH SAMIA YABA CHRISTINA"
        }
      ]
    },
    {
      "region": "WESTERN",
      "code": "A0201",
      "name": "ELLEMBELE",
      "num": 2,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "KWASI BONZOH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "EMMANUEL ARMAH-KOFI BUAH"
        },
        {
          "pid": 3,
          "party": "PPP",
          "name": "ASMAH JOHN NKUM"
        }
      ]
    },
    {
      "region": "WESTERN",
      "code": "A0301",
      "name": "EVALUE AJOMORO GWIRA",
      "num": 3,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "CATHERINE ABELEMA AFEKU"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "NOKOE KOFI ARKO"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "ELISHA JOSHUA KABENLAH"
        }
      ]
    },
    {
      "region": "WESTERN",
      "code": "A0401",
      "name": "AHANTA WEST",
      "num": 4,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "FRANCIS ERIC POBEE"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "MAVIS KUUKUA BISSUE"
        },
        {
          "pid": 3,
          "party": "PNC",
          "name": "DOMINIC AKALGA"
        }
      ]
    },
    {
      "region": "WESTERN",
      "code": "A0501",
      "name": "TAKORADI",
      "num": 5,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "KWABENA OKYERE DARKO-MENSAH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "FAIDOO FREDERICK FAUSTINUS"
        }
      ]
    },
    {
      "region": "WESTERN",
      "code": "A0601",
      "name": "SEKONDI",
      "num": 6,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ANDREW KOFI EGYAPA MERCER"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "BLAY NYAMEKE ARMAH"
        }
      ]
    },
    {
      "region": "WESTERN",
      "code": "A0701",
      "name": "ESSIKADU-KETAN",
      "num": 7,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "CHARLES CROMWELL NANABANYIN ONUAWONTO BISSUE"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "GRACE AYENSU-DANQUAH"
        }
      ]
    },
    {
      "region": "WESTERN",
      "code": "A0801",
      "name": "EFFIA",
      "num": 8,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ISAAC BOAMAH-NYARKO"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ABDUL-MAJEED IDDRISU NASSAM"
        }
      ]
    },
    {
      "region": "WESTERN",
      "code": "A0802",
      "name": "KWESIMINTIM",
      "num": 9,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "DR. PRINCE HAMIDU ARMAH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "BUCKMAN PHILIP FIIFI"
        },
        {
          "pid": 3,
          "party": "LGP",
          "name": "BENJAMIN KWESI MANU"
        },
        {
          "pid": 4,
          "party": "IND",
          "name": "JOSEPH MENSAH"
        }
      ]
    },
    {
      "region": "WESTERN",
      "code": "A0901",
      "name": "SHAMA",
      "num": 10,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ISAAC KWAMINA AFFUL"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "EMELIA ARTHUR"
        }
      ]
    },
    {
      "region": "WESTERN",
      "code": "A1001",
      "name": "WASSA EAST",
      "num": 11,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "MONA GERTRUDE EFFAH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ISAAC ADJEI MENSAH"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "HENRY TWUMASI ABOAGYE"
        }
      ]
    },
    {
      "region": "WESTERN",
      "code": "A1101",
      "name": "MPOHOR",
      "num": 12,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "JOHN KOBINA ABBAM ABOAH SANIE"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "BENTIL GODFRED HENRY"
        },
        {
          "pid": 3,
          "party": "LGP",
          "name": "THERESA KWAW"
        }
      ]
    },
    {
      "region": "WESTERN",
      "code": "A1201",
      "name": "TARKWA NSUAEM",
      "num": 13,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "GEORGE MIREKU DUKER"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ISSA SALIFU TAYLOR"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "JOY JOYCELYN ANDOH"
        }
      ]
    },
    {
      "region": "WESTERN",
      "code": "A1301",
      "name": "PRESTEA HUNI-VALLEY",
      "num": 14,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "BARBARA OTENG-GYASI"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ROBERT WISDOM CUDJOE"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "HAMMOND ERIC ODURO"
        }
      ]
    },
    {
      "region": "WESTERN",
      "code": "A1401",
      "name": "WASSA AMENFI EAST",
      "num": 15,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ERNEST FRIMPONG"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "NICHOLAS AMANKWAH"
        }
      ]
    },
    {
      "region": "WESTERN",
      "code": "A1501",
      "name": "AMENFI CENTRAL",
      "num": 16,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "DR ALBERT WIREDU ARKOH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "JOANA GYAN CUDJOE"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "PETER YAW KWAKYE-ACKAH"
        },
        {
          "pid": 4,
          "party": "IND",
          "name": "DR KARL MARK ARHIN"
        }
      ]
    },
    {
      "region": "WESTERN",
      "code": "A1601",
      "name": "AMENFI WEST",
      "num": 17,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "IGNATIUS KWASI AFRIFA"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ERIC AFFUL"
        },
        {
          "pid": 3,
          "party": "LGP",
          "name": "MORRIS BOADI BENJAMIN"
        }
      ]
    },
    {
      "region": "CENTRAL",
      "code": "B0101",
      "name": "KOMENDA EDINA EGUAFO ABREM",
      "num": 18,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "DENNIS PERCYVAL QUAICOE"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "SAMUEL ATTA MILLS"
        },
        {
          "pid": 3,
          "party": "PPP",
          "name": "EMMANUEL KOFI DUKU"
        }
      ]
    },
    {
      "region": "CENTRAL",
      "code": "B0230",
      "name": "CAPE COAST SOUTH",
      "num": 19,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ARTHUR ERNEST"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "RICKETTS-HAGAN GEORGE KWEKU"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "MANU ISAAC"
        },
        {
          "pid": 4,
          "party": "IND",
          "name": "MENSAH EMMANUEL ANDOH PERRY"
        }
      ]
    },
    {
      "region": "CENTRAL",
      "code": "B0231",
      "name": "CAPE COAST NORTH",
      "num": 20,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "EWUSI HORACE EKOW"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "NYARKU KWAMENA MINTA"
        },
        {
          "pid": 3,
          "party": "PPP",
          "name": "ANDOH THOMAS"
        }
      ]
    },
    {
      "region": "CENTRAL",
      "code": "B0301",
      "name": "ABURA/ASEBU/KWAMANKESE",
      "num": 21,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ERIC KOBINA NYANTEH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "FELIX KWAKYE OFOSU"
        },
        {
          "pid": 3,
          "party": "GUM",
          "name": "JOHN ANNSMANN-ANSAH-PSALMS"
        }
      ]
    },
    {
      "region": "CENTRAL",
      "code": "B0401",
      "name": "MFANTSEMAN",
      "num": 22,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "OPHELIA MENSAH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ARHIN EBENEZER PRINCE"
        }
      ]
    },
    {
      "region": "CENTRAL",
      "code": "B0501",
      "name": "EKUMFI",
      "num": 23,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "STELFA NANA ADU OKUMKOM DONKOR"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "KWAINOE EKOW OTHNIEL"
        }
      ]
    },
    {
      "region": "CENTRAL",
      "code": "B0601",
      "name": "AJUMAKO ENYAN ESIAM",
      "num": 24,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ETUAFUL RASHID KWESI"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "FORSON CASSIEL ATO BAAH"
        }
      ]
    },
    {
      "region": "CENTRAL",
      "code": "B0701",
      "name": "GOMOA WEST",
      "num": 25,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "BISMARK BAISIE NKUM"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "RICHARD GYAN-MENSAH"
        }
      ]
    },
    {
      "region": "CENTRAL",
      "code": "B0801",
      "name": "GOMOA CENTRAL",
      "num": 26,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "EYIAH NAANA"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "YAWSON MUHAMMED NAEEM-DEEN"
        },
        {
          "pid": 3,
          "party": "CPP",
          "name": "APPIAH GRACE IGNOPHIA"
        },
        {
          "pid": 4,
          "party": "IND",
          "name": "OBENG KWAME ASARE"
        }
      ]
    },
    {
      "region": "CENTRAL",
      "code": "B0901",
      "name": "GOMOA EAST",
      "num": 27,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ASEMANYI KOJO"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "PAITOO DESMOND DE-GRAFT"
        },
        {
          "pid": 3,
          "party": "APC",
          "name": "ISSAH MOHAMMED"
        }
      ]
    },
    {
      "region": "CENTRAL",
      "code": "B1001",
      "name": "EFFUTU",
      "num": 28,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ALEXANDER AFENYO-MARKIN"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ANNAN JAMES KOFI"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "BUABENG LOUISA"
        }
      ]
    },
    {
      "region": "CENTRAL",
      "code": "B1101",
      "name": "AWUTU SENYA WEST",
      "num": 29,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ARHIN EUGENE KOFI BENTUM"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "TETTEH-AGBOTUI GIZELLA"
        }
      ]
    },
    {
      "region": "CENTRAL",
      "code": "B1201",
      "name": "AWUTU SENYA EAST",
      "num": 30,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "KOOMSON MAVIS HAWA"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "PHILLIS NAA KORYOO OKUNOR"
        },
        {
          "pid": 3,
          "party": "GCPP",
          "name": "PETER KWAO LARTEY"
        }
      ]
    },
    {
      "region": "CENTRAL",
      "code": "B1301",
      "name": "AGONA WEST",
      "num": 31,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ARTHUR CHRISTOPHER "
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "DANGBEY ERNESTINA OFORI"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "MORRISON CYNTHIA MAMLE"
        }
      ]
    },
    {
      "region": "CENTRAL",
      "code": "B1401",
      "name": "AGONA EAST",
      "num": 32,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": " ASAMOAH HANNAH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "SAWYERR QUEENSTAR POKUAH"
        },
        {
          "pid": 3,
          "party": "LGP",
          "name": "ESSIEN ERIC"
        }
      ]
    },
    {
      "region": "CENTRAL",
      "code": "B1501",
      "name": "ASIKUMA/ODOBEN/BRAKWA",
      "num": 33,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "WINIFRED ABAIDOO-AYIN"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "GHANSAH ALHASSAN KOBINA"
        },
        {
          "pid": 3,
          "party": "GUM",
          "name": "PRECIOUS MAWULORM AMEVOR"
        }
      ]
    },
    {
      "region": "CENTRAL",
      "code": "B1601",
      "name": "ASSIN CENTRAL",
      "num": 34,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ANEWU GODFRED"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "MIGYIMAH SHAIBU NUREIN"
        }
      ]
    },
    {
      "region": "CENTRAL",
      "code": "B1701",
      "name": "ASSIN NORTH",
      "num": 35,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "OPOKU CHARLES"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "QUAYSON JAMES GYAKYE"
        }
      ]
    },
    {
      "region": "CENTRAL",
      "code": "B1801",
      "name": "ASSIN SOUTH",
      "num": 36,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "FORDJOUR JOHN NTIM"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "BAIDOO STEPHEN KOFI"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "DAMTSE JOSEPH KOFI"
        }
      ]
    },
    {
      "region": "CENTRAL",
      "code": "B1901",
      "name": "TWIFO ATTI MORKWA",
      "num": 37,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "DWAMENA OBENG EBENEZER"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "VONDEE T.D. DAVID"
        },
        {
          "pid": 3,
          "party": "GUM",
          "name": "DONKOH ADOWA"
        }
      ]
    },
    {
      "region": "CENTRAL",
      "code": "B2001",
      "name": "HEMANG LOWER DENKYIRA",
      "num": 38,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "LAWRENCE AGYINSAM"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "AGYAPONG-MENSAH SETH"
        },
        {
          "pid": 3,
          "party": "CPP",
          "name": "SAMPSON KWAME OWONNA"
        },
        {
          "pid": 4,
          "party": "IND",
          "name": "BENJAMIN BIMPONG DONKOR"
        }
      ]
    },
    {
      "region": "CENTRAL",
      "code": "B2101",
      "name": "UPPER DENKYIRA EAST",
      "num": 39,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "DR. FESTUS AWUAH KWOFIE"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "LAWYER ANKOMAH EMELIA"
        },
        {
          "pid": 3,
          "party": "PNC",
          "name": "DR (DR) OFFIN AMANIAMPONG OWUSU"
        }
      ]
    },
    {
      "region": "CENTRAL",
      "code": "B2201",
      "name": "UPPER DENKYIRA WEST",
      "num": 40,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "RUDOLF AMOAKO-GYAMPAH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "DARKO DANIEL OHENE"
        },
        {
          "pid": 3,
          "party": "GUM",
          "name": "EMMANUEL GADJO"
        }
      ]
    },
    {
      "region": "GREATER ACCRA",
      "code": "C0101",
      "name": "BORTIANOR-NGLESHIE AMANFRO",
      "num": 41,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "SYLVESTER M.TETTEH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "OKLE FELIX AKWETEY "
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "FRANK MENSAH "
        }
      ]
    },
    {
      "region": "GREATER ACCRA",
      "code": "C0102",
      "name": "DOMEABRA/OBOM",
      "num": 42,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "MOHAMMED ABDUL-WAHAB"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ISAAC AWUKU YIBOR"
        }
      ]
    },
    {
      "region": "GREATER ACCRA",
      "code": "C0201",
      "name": "WEIJA / GBAWE",
      "num": 43,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "JERRY AHMED SHAIB"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "FELIX ODARTEY LAMPTEY"
        },
        {
          "pid": 3,
          "party": "CPP",
          "name": "SAVIOUR GBEDZE"
        },
        {
          "pid": 4,
          "party": "LPG",
          "name": "OFORI ERIC KWAKU"
        },
        {
          "pid": 5,
          "party": "APC",
          "name": "OBENG FYNN GEORGE"
        }
      ]
    },
    {
      "region": "GREATER ACCRA",
      "code": "C0301",
      "name": "ANYAA/SOWUTUOM",
      "num": 44,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "EMMANUEL TOBBIN"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ALLOTEY EMMANUEL ADOTEY"
        },
        {
          "pid": 3,
          "party": "PNP",
          "name": "SNYPER AIKINS"
        }
      ]
    },
    {
      "region": "GREATER ACCRA",
      "code": "C0401",
      "name": "TROBU",
      "num": 45,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "GLORIA OWUSU"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "JOHN KOFI HALM"
        },
        {
          "pid": 3,
          "party": "CPP",
          "name": "SARFO HAMMOND ADU"
        },
        {
          "pid": 4,
          "party": "LGP",
          "name": "OLIVER GYEPI-GARBRAH"
        },
        {
          "pid": 5,
          "party": "APC",
          "name": "FRANCIS ANSAH TAWIAH"
        },
        {
          "pid": 6,
          "party": "IND",
          "name": "NANA AGYENIM BOATENG"
        }
      ]
    },
    {
      "region": "GREATER ACCRA",
      "code": "C0501",
      "name": "AMASAMAN",
      "num": 46,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "YAHYA KASSIM ATTA"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "SEDEM KWAKU AFENYO"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "JERRY-JOHNSON NII ARMAH ASHITEY"
        },
        {
          "pid": 4,
          "party": "IND",
          "name": "SAMUEL KWAME DENOO"
        }
      ]
    },
    {
      "region": "GREATER ACCRA",
      "code": "C0601",
      "name": "DOME/KWABENYA",
      "num": 47,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "MICHAEL AARON YAW NII NORTEY OQUAYE"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "AKURUGU FAUSTINA ELIKPLIM"
        },
        {
          "pid": 3,
          "party": "PNP",
          "name": "DORA NYARKO"
        },
        {
          "pid": 4,
          "party": "PPP",
          "name": "REMY PAA KOW EDMUNDSON"
        },
        {
          "pid": 5,
          "party": "IND",
          "name": "ISSAH ABUBAKAR"
        }
      ]
    },
    {
      "region": "GREATER ACCRA",
      "code": "C0701",
      "name": "MADINA",
      "num": 48,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "LAMPTEY ROBERT"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "FRANCIS-XAVIER KOJO SOSU"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "CHORO ADAMS PADOH"
        },
        {
          "pid": 4,
          "party": "IND",
          "name": "AWAL MOHAMMED"
        }
      ]
    },
    {
      "region": "GREATER ACCRA",
      "code": "C0801",
      "name": "AYAWASO EAST",
      "num": 49,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ZAK RAHMAN"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "MAHAMA NASER TOURE"
        }
      ]
    },
    {
      "region": "GREATER ACCRA",
      "code": "C0901",
      "name": "AYAWASO NORTH",
      "num": 50,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "SANNIE IBRAHIM"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "YUSSIF ISSAKA JAJAH"
        }
      ]
    },
    {
      "region": "GREATER ACCRA",
      "code": "C1001",
      "name": "AYAWASO CENTRAL",
      "num": 51,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "HENRY QUARTEY"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ABDUL RAUF TONGYM TUBAZU"
        },
        {
          "pid": 3,
          "party": "NDP",
          "name": "CHARLES KWAME ADAMS"
        }
      ]
    },
    {
      "region": "GREATER ACCRA",
      "code": "C1101",
      "name": "AYAWASO WEST WUOGON",
      "num": 52,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "LYDIA SEYRAM ALHASSAN"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "JOHN SETOR DUMELO"
        },
        {
          "pid": 3,
          "party": "PNC",
          "name": "GIFTY BOTCHWAY "
        },
        {
          "pid": 4,
          "party": "IND",
          "name": "ABDUL RAUF ABUGRI MUMUNI"
        }
      ]
    },
    {
      "region": "GREATER ACCRA",
      "code": "C1201",
      "name": "OKAIKWEI SOUTH",
      "num": 53,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "DAKOA NEWMAN"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ERNEST ADOMAKO"
        }
      ]
    },
    {
      "region": "GREATER ACCRA",
      "code": "C1202",
      "name": "ABLEKUMA SOUTH",
      "num": 54,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "SAMUEL SARBAH LARTEY"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ALFRED OKOE VANDERPUIJE"
        },
        {
          "pid": 3,
          "party": "LGP",
          "name": "JOSEPH COMMEY"
        },
        {
          "pid": 4,
          "party": "IND",
          "name": "JAMES LANKWEI LAMPTEY"
        }
      ]
    },
    {
      "region": "GREATER ACCRA",
      "code": "C1401",
      "name": "ODODODIODIOO",
      "num": 55,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "SOWAH ABDUL MANNAF"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ALFRED NII KOTEY ASHIE"
        },
        {
          "pid": 3,
          "party": "CPP",
          "name": "RICHARD DZATEI ABBEY"
        },
        {
          "pid": 4,
          "party": "PPP",
          "name": "ALFRED ANETTEY ABBEY "
        }
      ]
    },
    {
      "region": "GREATER ACCRA",
      "code": "C1501",
      "name": "OKAIKWEI CENTRAL",
      "num": 56,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "PATRICK YAW BOAMAH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ABDULAI ABU BABA ABUBAKAR SADIQ"
        }
      ]
    },
    {
      "region": "GREATER ACCRA",
      "code": "C1302",
      "name": "OKAIKWEI NORTH",
      "num": 57,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "NANA AMA DOKUA ASIAMAH-ADJEI"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "THERESA LARDI AWUNI"
        },
        {
          "pid": 3,
          "party": "CPP",
          "name": "ABDUL HAMIDU IBRAHIM FUTA"
        }
      ]
    },
    {
      "region": "GREATER ACCRA",
      "code": "C1601",
      "name": "ABLEKUMA NORTH",
      "num": 58,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "NANA AKUA OWUSU AFRIYIEH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "EWURABENA AUBYNN"
        }
      ]
    },
    {
      "region": "GREATER ACCRA",
      "code": "C1701",
      "name": "ABLEKUMA CENTRAL",
      "num": 59,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "JEFFERSON KWAMINA SACKEY"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ABDUL-LATIF DAN"
        }
      ]
    },
    {
      "region": "GREATER ACCRA",
      "code": "C1801",
      "name": "ABLEKUMA WEST",
      "num": 60,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "URSULA GIFTY OWUSU - EKUFUL"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "KWEKU ADDO"
        }
      ]
    },
    {
      "region": "GREATER ACCRA",
      "code": "C1901",
      "name": "KORLE KLOTTEY",
      "num": 61,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "VALENTINO NII NOI NORTEY"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ZANETOR AGYEMAN-RAWLINGS"
        }
      ]
    },
    {
      "region": "GREATER ACCRA",
      "code": "C2001",
      "name": "DADEKOTOPON",
      "num": 62,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "JOSEPH KWASHIE ADDO"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "RITA NAA ODOLEY SOWAH"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "ABBEY DAVID ANERTEY"
        },
        {
          "pid": 4,
          "party": "IND",
          "name": "VINCENT SOWAH ODOTEI"
        }
      ]
    },
    {
      "region": "GREATER ACCRA",
      "code": "C2101",
      "name": "LEDZOKUKU",
      "num": 63,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "BERNARD OKOE BOYE"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "AYIKU BENJAMIN NARTEH"
        },
        {
          "pid": 3,
          "party": "CPP",
          "name": "TAGOE SETH NII AMARTEY"
        },
        {
          "pid": 4,
          "party": "NDP",
          "name": "LUCKY MENSAH"
        },
        {
          "pid": 5,
          "party": "IND",
          "name": "TEKPOR ELLIOT PROSPER KWAME"
        },
        {
          "pid": 6,
          "party": "IND",
          "name": "OKAI RICHARD DANSO"
        }
      ]
    },
    {
      "region": "GREATER ACCRA",
      "code": "C2201",
      "name": "KROWOR",
      "num": 64,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "EMMANUEL LARYEA ODAI"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "AGNES NAA MOMO LARTEY"
        },
        {
          "pid": 3,
          "party": "NDP",
          "name": "AZUMAH COURAGE KWAME MENSAH"
        },
        {
          "pid": 4,
          "party": "IND",
          "name": "DUKE AFOTEY MENSAH"
        }
      ]
    },
    {
      "region": "GREATER ACCRA",
      "code": "C2301",
      "name": "TEMA EAST",
      "num": 65,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "YOHANE AMARH ASHITEY"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ODAMTTEN ISAAC ASHAI"
        }
      ]
    },
    {
      "region": "GREATER ACCRA",
      "code": "C2102",
      "name": "TEMA CENTRAL",
      "num": 66,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "CHARLES FORSON"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "EBI BRIGHT"
        },
        {
          "pid": 3,
          "party": "CPP",
          "name": "ERIC JERRY AIDOO"
        },
        {
          "pid": 4,
          "party": "INP",
          "name": "FREDERICK ANIAGYEI"
        }
      ]
    },
    {
      "region": "GREATER ACCRA",
      "code": "C2401",
      "name": "TEMA-WEST",
      "num": 67,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "DENIS AMFO SEFAH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "JAMES ENU"
        }
      ]
    },
    {
      "region": "GREATER ACCRA",
      "code": "C2501",
      "name": "KPONE KATAMANSO",
      "num": 68,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "WILLIAM OFOSU ASANTE"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "JOSEPH AKUERTEH TETTEY"
        },
        {
          "pid": 3,
          "party": "PPP",
          "name": "AGBANE SAMSON"
        },
        {
          "pid": 4,
          "party": "IND",
          "name": "EDWARD AMONKWAAH ANANG"
        }
      ]
    },
    {
      "region": "GREATER ACCRA",
      "code": "C2601",
      "name": "ASHAIMAN",
      "num": 69,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "JUSTICE KING ESSIEL"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ERNEST HENRY NORGBEY"
        },
        {
          "pid": 3,
          "party": "PPP",
          "name": "MANTEY EMMANUEL FELIX"
        },
        {
          "pid": 4,
          "party": "LGP",
          "name": "HEYMANN EMMANUEL KOFI-YESU"
        },
        {
          "pid": 5,
          "party": "IND",
          "name": "KUDAH GADAFI ADAM"
        },
        {
          "pid": 6,
          "party": "IND",
          "name": "JOHN HOPE ALORMASOR"
        }
      ]
    },
    {
      "region": "GREATER ACCRA",
      "code": "C2701",
      "name": "ADENTAN",
      "num": 70,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "AKOSUA ASAA MANU"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "MOHAMMED ADAMU RAMADAN"
        },
        {
          "pid": 3,
          "party": "CPP",
          "name": "RAZAK AL-HASSAN"
        },
        {
          "pid": 4,
          "party": "IND",
          "name": "HAGAR ASIEDU"
        }
      ]
    },
    {
      "region": "GREATER ACCRA",
      "code": "C2801",
      "name": "SHAI-OSUDOKU",
      "num": 71,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "BENJAMIN NARGEH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "LINDA OBENEWAA AKWELEY OCLOO"
        },
        {
          "pid": 3,
          "party": "PPP",
          "name": "MINIMADEY ISAAC GABRIEL ANGMORTEY"
        }
      ]
    },
    {
      "region": "GREATER ACCRA",
      "code": "C2901",
      "name": "NINGO PRAMPRAM",
  
      "num": 72,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "MICHAEL TETTEH-EKU"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "SAMUEL NARTEY GEORGE"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "EVANS TETTEH NII NARTEY"
        }
      ]
    },
    {
      "region": "GREATER ACCRA",
      "code": "C3001",
      "name": "SEGE",
      "num": 73,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "DODZIE NUMEKEVOR"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "DANIEL KESHI BESSEY"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "LASI EUNICE"
        }
      ]
    },
    {
      "region": "GREATER ACCRA",
      "code": "C3101",
      "name": "ADA",
      "num": 74,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "SUZETTE NAA NORLEY DORNUKIE NORTEYE"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "COMFORT DOYOE CUDJOE"
        }
      ]
    },
    {
      "region": "VOLTA",
      "code": "D0101",
      "name": "KETA",
      "num": 75,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "COURAGE HOPE GOLDBERG-GRIMM LEKETTEY"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "KWAME DZUDZORLI GAKPEY"
        },
        {
          "pid": 3,
          "party": "LGP",
          "name": "YAYRA KWASHIE KWAWU"
        },
        {
          "pid": 4,
          "party": "IND",
          "name": "DUGAH STANLEY COURAGE"
        }
      ]
    },
    {
      "region": "VOLTA",
      "code": "D0201",
      "name": "ANLO",
      "num": 76,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "SETH KWASHIE YORMEWU"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "RICHARD KWAMI SEFE"
        }
      ]
    },
    {
      "region": "VOLTA",
      "code": "D0301",
      "name": "KETU SOUTH",
      "num": 77,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "SAMUEL WISDOM DOE HALIGAH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ABLA DZIFA GOMASHIE"
        },
        {
          "pid": 3,
          "party": "PAG",
          "name": "EVANS AMEDZIKO"
        }
      ]
    },
    {
      "region": "VOLTA",
      "code": "D0401",
      "name": "KETU NORTH",
      "num": 78,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "AMEGBLETOR ENOCH KWABLA"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ERIC EDEM AGBANA"
        },
        {
          "pid": 3,
          "party": "NDP",
          "name": "HADZAH SHELTA ZIODOFE KWESI"
        },
        {
          "pid": 4,
          "party": "LPG",
          "name": "PASCALINE KASSAH"
        }
      ]
    },
    {
      "region": "VOLTA",
      "code": "D0501",
      "name": "AKATSI SOUTH",
      "num": 79,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "EGOS MAWULI OCLOO"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "BERNARD AHIAFOR"
        },
        {
          "pid": 3,
          "party": "PAG",
          "name": "DZAMESHIE DONALD BROWN"
        }
      ]
    },
    {
      "region": "VOLTA",
      "code": "D0601",
      "name": "AKATSI NORTH",
      "num": 80,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "SIMON PETER KOFI OFOSU"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "PETER KWASI NORTSU-KOTOE"
        }
      ]
    },
    {
      "region": "VOLTA",
      "code": "D0701",
      "name": "SOUTH TONGU",
      "num": 81,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "LADY ELIZABETH SEGBENU AGAH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "MAXWELL KWAME LUKUTOR"
        },
        {
          "pid": 3,
          "party": "PAG",
          "name": "OLIVIA SOSU QUARSHIE"
        }
      ]
    },
    {
      "region": "VOLTA",
      "code": "D0801",
      "name": "CENTRAL TONGU",
      "num": 82,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "GODWIN AYIKPA"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ALEXANDER ROOSEVELT HOTTORDZE"
        },
        {
          "pid": 3,
          "party": "NDP",
          "name": "AKAFUA-HOTOR COURAGE"
        },
        {
          "pid": 4,
          "party": "LGP",
          "name": "AVUDOAHOR LAWRENCIA ESENAM"
        },
        {
          "pid": 5,
          "party": "IND",
          "name": "DZRAMADO SELORM DRAMANI"
        }
      ]
    },
    {
      "region": "VOLTA",
      "code": "D0901",
      "name": "NORTH TONGU",
      "num": 83,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "DR JOHN SAVIOUR YAW ELEBLU"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "SAMUEL OKUDZETO ABLAKWA"
        }
      ]
    },
    {
      "region": "VOLTA",
      "code": "D1001",
      "name": "ADAKLU",
      "num": 84,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "BRIGHT KWAME NYATSIKOR"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "AGBODZA KWAME GOVERNS"
        },
        {
          "pid": 3,
          "party": "NDP",
          "name": "MORTI JOHN SHADRACK"
        }
      ]
    },
    {
      "region": "VOLTA",
      "code": "D1101",
      "name": "AGOTIME-ZIOPE",
      "num": 85,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "AGBOBLI KENTLE SETH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "AGBEVE CHARLES AKWASI"
        },
        {
          "pid": 3,
          "party": "NDP",
          "name": "JOHN TEPE"
        }
      ]
    },
    {
      "region": "VOLTA",
      "code": "D1201",
      "name": "HO CENTRAL",
      "num": 86,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "DIVINE RICHARD KOMLA BOSSON"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "RICHMOND EDEM KOFI KPOTOSU"
        },
        {
          "pid": 3,
          "party": "NDP",
          "name": "NELSON KOFI VIDE"
        },
        {
          "pid": 4,
          "party": "IND",
          "name": "MAWULORM KWAME KLUTSE"
        }
      ]
    },
    {
      "region": "VOLTA",
      "code": "D1301",
      "name": "HO WEST",
      "num": 87,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ERIC NICK YAO GONYUIE"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "EMMANUEL KWASI BEDZRAH"
        },
        {
          "pid": 3,
          "party": "NDP",
          "name": "KUKAH JULIUS JONATHAN"
        },
        {
          "pid": 4,
          "party": "IND",
          "name": "ADZOGBLE FRANCISCA AFUA"
        }
      ]
    },
    {
      "region": "VOLTA",
      "code": "D1401",
      "name": "SOUTH DAYI",
      "num": 88,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "GODWIN KWAME DADZAWA"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ROCKSON-NELSON ETSE KWAMI DAFEAMEKPOR"
        },
        {
          "pid": 3,
          "party": "LGP",
          "name": "SAFO ROSE"
        },
        {
          "pid": 4,
          "party": "IND",
          "name": "AWALIME DAVID"
        }
      ]
    },
    {
      "region": "VOLTA",
      "code": "D1501",
      "name": "KPANDO",
      "num": 89,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "OKLU ANTOINETTE ABENA"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "SEBASTIAN FRED DEH"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "KODZOSIKA SAMUEL"
        }
      ]
    },
    {
      "region": "VOLTA",
      "code": "D1601",
      "name": "NORTH DAYI",
      "num": 90,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "EDMUND ATTAH KUDJOH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "JOYCELYN TETTEH"
        },
        {
          "pid": 3,
          "party": "LGP",
          "name": "GLORIA YAYRA AGBENORTO"
        }
      ]
    },
    {
      "region": "VOLTA",
      "code": "D1701",
      "name": "HOHOE",
      "num": 91,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "JOHN-PETER AMEWU"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "THOMAS WORLANYO TSEKPO"
        },
        {
          "pid": 3,
          "party": "PND",
          "name": "STEPHEN DZIDEFO ADZRAKU"
        },
        {
          "pid": 4,
          "party": "NDP",
          "name": "ESTHER AMEVOR"
        }
      ]
    },
    {
      "region": "VOLTA",
      "code": "D1801",
      "name": "AFADJATO SOUTH",
      "num": 92,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ETORNAM JAMES FLOLU"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "FRANK AFRIYIE"
        },
        {
          "pid": 3,
          "party": "LGP",
          "name": "TEGBEY TRACY SEMANU"
        }
      ]
    },
    {
      "region": "EASTERN",
      "code": "E0101",
      "name": "ASUOGYAMAN",
      "num": 93,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "PIUS ENAM HADZIDE"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "THOMAS AMPEM NYARKO"
        }
      ]
    },
    {
      "region": "EASTERN",
      "code": "E0201",
      "name": "LOWER MANYA KROBO",
      "num": 94,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "SIMON KWEKU TETTEH"
        },
        {
          "pid": 2,
          "party": "NPP",
          "name": "EBENEZER OKLETEY TERLABI"
        }
      ]
    },
    {
      "region": "EASTERN",
      "code": "E0301",
      "name": "UPPER MANYA KROBO",
      "num": 95,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "JOSEPH TETTEH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "BISMARK TETTEH NYARKO"
        }
      ]
    },
    {
      "region": "EASTERN",
      "code": "E0401",
      "name": "YILO KROBO",
      "num": 96,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "RICHARD TWUM BARIMAH KORANTENG"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ALBERT TETTEH NYAKOTEY"
        },
        {
          "pid": 3,
          "party": "CPP",
          "name": "ASENI EBENZER TETTEH "
        }
      ]
    },
    {
      "region": "EASTERN",
      "code": "E0501",
      "name": "NEW JUABEN SOUTH",
      "num": 97,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "MICHAEL OKYERE BAAFI"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "'OFFEI MARTIN OTU"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "APPIAH EVANS"
        }
      ]
    },
    {
      "region": "EASTERN",
      "code": "E0601",
      "name": "NEW JUABEN NORTH",
      "num": 98,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "NANA OSEI-ADJEI"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "SAMUEL ADONGO"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "EMMANUEL OFOSU YEBOAH"
        }
      ]
    },
    {
      "region": "EASTERN",
      "code": "E0701",
      "name": "AKROPONG",
      "num": 99,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "SAMUEL AWUKU"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "JOHN EVANS KUMORDZI"
        }
      ]
    },
    {
      "region": "EASTERN",
      "code": "E0801",
      "name": "OKERE",
      "num": 100,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "DANIEL NANA ADDO-KENNETH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "PRINCE HENRY ANIM - OWIREDU"
        },
        {
          "pid": 3,
          "party": "CPP",
          "name": "JOSEPH KWADWO AFARI - YEBOAH"
        }
      ]
    },
    {
      "region": "EASTERN",
      "code": "E0901",
      "name": "AKUAPEM SOUTH",
      "num": 101,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ERIC YEBOAH APEADU"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "LAWRENCIAL DZIWORNU"
        }
      ]
    },
    {
      "region": "EASTERN",
      "code": "E1001",
      "name": "NSAWAM/ADOAGYIRI",
      "num": 102,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ANNOH-DOMPREH FRANK"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "FUMMEY PHILIBERT AMENORPE"
        },
        {
          "pid": 3,
          "party": "GFP",
          "name": "PARICK TETTEH"
        },
        {
          "pid": 4,
          "party": "IND",
          "name": "MUSTAPHA RASHEED"
        },
        {
          "pid": 5,
          "party": "IND",
          "name": "GOLO KOJO"
        }
      ]
    },
    {
      "region": "EASTERN",
      "code": "E1101",
      "name": "SUHUM",
      "num": 103,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "FRANK ASEIDU BEKOE"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "PRINCE ADDO"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "FREDERICK KUMI"
        },
        {
          "pid": 4,
          "party": "IND",
          "name": "EMMANUEL DEDE WIAFE"
        },
        {
          "pid": 5,
          "party": "IND",
          "name": "KWADJO ASANTE"
        }
      ]
    },
    {
      "region": "EASTERN",
      "code": "E1201",
      "name": "AYENSUANO",
      "num": 104,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "IDA ADJOA ASIEDU"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ADDI SAFORI TEDDY"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "ABROKWA ABOAGYE SINTIM"
        },
        {
          "pid": 4,
          "party": "IND",
          "name": "LEARNED OBIRI EBENEZER YIRENKYI"
        }
      ]
    },
    {
      "region": "EASTERN",
      "code": "E1301",
      "name": "LOWER WEST AKIM",
      "num": 105,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "CHARLES ACHEAMPONG"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "OWEN KWAME FRIMPONG"
        },
        {
          "pid": 3,
          "party": "PNC",
          "name": "HAADI MOHAMMED KASIMU"
        },
        {
          "pid": 4,
          "party": "IND",
          "name": "GIFTY KLENAM"
        }
      ]
    },
    {
      "region": "EASTERN",
      "code": "E1401",
      "name": "UPPER WEST AKIM",
      "num": 106,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "FREDERICK OBENG ADOM"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "DRAH EMMANUEL"
        }
      ]
    },
    {
      "region": "EASTERN",
      "code": "E1501",
      "name": "AKIM ODA",
      "num": 107,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ALEXANDER AKWASI ACQUAH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "JONES ASANTE"
        }
      ]
    },
    {
      "region": "EASTERN",
      "code": "E1601",
      "name": "ASENE/AKROSO/MANSO",
      "num": 108,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "GEORGE KWAME ABOAGYE"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ERIC AHINAKWA"
        }
      ]
    },
    {
      "region": "EASTERN",
      "code": "E1701",
      "name": "AKIM SWEDRU",
      "num": 109,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "OSEI KENNEDY NYARKO"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "TAAJU ABDU RAHIM"
        }
      ]
    },
    {
      "region": "EASTERN",
      "code": "E1801",
      "name": "ACHIASE",
      "num": 110,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "KOFI AHENKORAH MARFO"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "SAMUEL  OWUSU BRAKO"
        }
      ]
    },
    {
      "region": "EASTERN",
      "code": "E1901",
      "name": "OFOASE/AYIREBI",
      "num": 111,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "KOJO OPPONG NKRUMAH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ALFRED OSEI-POKU"
        }
      ]
    },
    {
      "region": "EASTERN",
      "code": "E2001",
      "name": "KADE",
      "num": 112,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "AGYARE ALEXANDER"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "EMMANUEL KOFI NTI"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "OHEMENG-TINYASE KWABENA"
        }
      ]
    },
    {
      "region": "EASTERN",
      "code": "E2101",
      "name": "AKWATIA",
      "num": 113,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ERNEST KUMI"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "HENRY YIADOM BOAKYE"
        }
      ]
    },
    {
      "region": "EASTERN",
      "code": "E2201",
      "name": "ABIREM",
      "num": 114,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "CHARLES ASUAKO OWIREDU"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "NURUDEEN FUSEINI"
        }
      ]
    },
    {
      "region": "EASTERN",
      "code": "E2301",
      "name": "ABUAKWA SOUTH",
      "num": 115,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "KINGSLEY AGYEMANG"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "NANA ADU SARPONG AIKINS"
        }
      ]
    },
    {
      "region": "EASTERN",
      "code": "E2401",
      "name": "ABUAKWA NORTH",
      "num": 116,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ADD0-FREMPONG NANA AMPAW KWAME"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "CHARLES YEBOAH DARKO"
        }
      ]
    },
    {
      "region": "EASTERN",
      "code": "E2501",
      "name": "ATIWA WEST",
      "num": 117,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "LAURETTE KORKOR ASANTE"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "DR.OFOSUAPEA DENNIS OWUSU-APPIAH"
        }
      ]
    },
    {
      "region": "EASTERN",
      "code": "E2601",
      "name": "ATIWA EAST",
      "num": 118,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ABENA OSEI-ASARE"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "KENNETH AGYARE"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "NANA ADJEI KYEREMA"
        }
      ]
    },
    {
      "region": "EASTERN",
      "code": "E2701",
      "name": "FANTEAKWA NORTH",
      "num": 119,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "KWAME APPIAH KODUA"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "APAW-WIREDU HAROUN"
        }
      ]
    },
    {
      "region": "EASTERN",
      "code": "E2801",
      "name": "FANTEAKWA SOUTH",
      "num": 120,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "DUKE WILLIAM ALLEN KWAME AMOAKO-ATTA OFORI-ATTA"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "KINGSLEY OWUSU NEWMAN"
        }
      ]
    },
    {
      "region": "EASTERN",
      "code": "E2901",
      "name": "NKAWKAW",
      "num": 121,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "JOSEPH FREMPONG"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "FREDERICK SOMUAH OBENG"
        },
        {
          "pid": 3,
          "party": "HOPE UNITED",
          "name": "LAWOEY BENJIMAIN KWAME ANSAH"
        }
      ]
    },
    {
      "region": "EASTERN",
      "code": "E3001",
      "name": "MPRAESO",
      "num": 122,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "DAVIS ANSAH OPOKU"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "MUHAYADEEN ADAM KALEEM"
        },
        {
          "pid": 3,
          "party": "CPP",
          "name": "OSEI MICHAEL"
        },
        {
          "pid": 4,
          "party": "GUM",
          "name": "OFOSU-ASANTE AUGUSTINA"
        }
      ]
    },
    {
      "region": "EASTERN",
      "code": "E3101",
      "name": "KWAHU EAST (ABETIFI)",
      "num": 123,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "DR. BRYAN ACHEAMPONG"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ADDO ISAAC AMOAFO"
        }
      ]
    },
    {
      "region": "EASTERN",
      "code": "E3201",
      "name": "AFRAM PLAINS NORTH",
      "num": 124,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ASAIMAH K. ANIM"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "BETTY NANA EFUA KROSBI MENSAH"
        },
        {
          "pid": 3,
          "party": "NDP",
          "name": "AGBAKPE HILLARY"
        },
        {
          "pid": 4,
          "party": "IND",
          "name": "WORLASE KPELI"
        }
      ]
    },
    {
      "region": "EASTERN",
      "code": "E3301",
      "name": "AFRAM PLAINS SOUTH",
      "num": 125,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "JACOB ZINEYELE"
        },
        {
          "pid": 2,
          "party": "PAG",
          "name": "IDDRISU SEIDU IBN-SWALAH"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "GRACE NUDONU ABRA ADIEPENA"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F0101",
      "name": "NEW EDUBIASE",
      "num": 126,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "GEORGE BOAHEN ODURO"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ABDUL - SALAM ADAMS"
        },
        {
          "pid": 3,
          "party": "NDP",
          "name": "RICHARD APPIAH-DANSO"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F0201",
      "name": "AKROFUOM",
      "num": 127,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ALEX BLANKSON"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "JOSEPH AZUMAH"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F0301",
      "name": "FOMENA",
      "num": 128,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ANDREW ASIAMAH AMOAKO"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "OGBEH CHARLES KOFI"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F0401",
      "name": "ADANSI ASOKWA",
      "num": 129,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "KOBINA TAHIR HAMMOND"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "GODWIN ANIMLI DORGBADZI-DORANI"
        },
        {
          "pid": 3,
          "party": "CPP",
          "name": "MENSAH ABRAHAM JUSTICE"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F0501",
      "name": "OBUASI WEST",
      "num": 130,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "KWAKU AGYEMAN KWATENG"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "APPIAH KANNIN FAUSTILOVE"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "ANNIN KOFI APPIANIN"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F0601",
      "name": "OBUASI EAST",
      "num": 131,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "PATRICK BOAKYE-YIADOM"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "SAMUEL ABOAGYE"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "ADJEI OWUSU AFRIYIE"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F0701",
      "name": "BEKWAI",
      "num": 132,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "RALPH POKU-ADUSEI"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "PREMPEH JUNIOR SAMUEL"
        },
        {
          "pid": 3,
          "party": "PNC",
          "name": "YAHAYA NASIRU"
        },
        {
          "pid": 4,
          "party": "LPG",
          "name": "LOVISTA OWUSU"
        },
        {
          "pid": 5,
          "party": "IND",
          "name": "KWASI AMOFA-AGYEMANG"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F0801",
      "name": "BOSOME FREHO",
      "num": 133,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "NANA ASAFO-ADJEI AYEH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "CHARLES APPIAH-KUBI"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F0901",
      "name": "ODOTOBIRI",
      "num": 134,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ANTHONY MMIEH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "EMMANUEL OBENG AGYEMANG"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "ISAAC KOFI GYAMFI"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F1001",
      "name": "MANSO NKWANTA",
      "num": 135,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "TWENEBOA KODUA FOKUO"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "SAMUEL ADJEI"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F1101",
      "name": "MANSO ADUBIA",
      "num": 136,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "YAW FRIMPONG ADDO"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "BENJAMIN MARFO"
        },
        {
          "pid": 3,
          "party": "PNC",
          "name": "SELINA ADOM"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F1201",
      "name": "ATWIMA NWABIAGYA SOUTH",
      "num": 137,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "SHIRLEY KYEI"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "WISDOM OSEL BOAMAH"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F1301",
      "name": "ATWIMA NWABIAGYA NORTH",
      "num": 138,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "FRANK YEBOAH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "MBA ZECHARIAH ALENBILLA"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "KANDIIRE JOACHIM"
        },
        {
          "pid": 2,
          "party": "IND",
          "name": "EMMANUAL OSEI GYAMFI"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F1401",
      "name": "ATWIMA MPONUA",
      "num": 139,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "SETH OSEI-AKOTO"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "STEPHEN YEBOAH"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F1501",
      "name": "BOSOMTWE",
      "num": 140,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "DR. YAW OSEI ADUTWUM"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ABDULLAH HAMIDU"
        },
        {
          "pid": 3,
          "party": "LPG",
          "name": "ADUSEI-AKWABOAH SAMUEL"
        },
        {
          "pid": 4,
          "party": "CPP",
          "name": "OSEI BOATENG ERIC"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F1601",
      "name": "ATWIMA KWANWOMA",
      "num": 141,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "KOFI AMANKWA-MANU"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "GRACE AGYEMANG ASAMOAH"
        },
        {
          "pid": 3,
          "party": "PNC",
          "name": "OBIBA JAMES ANNAN"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F1701",
      "name": "BANTAMA",
      "num": 142,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "FRANCIS    ASENSO-BOAKYE"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "SIMEON ADDAI DAPAAH"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F1801",
      "name": "NHYIAESO",
      "num": 143,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "STEPHEN AMOAH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "FAUSTINA BAYOR DERY"
        },
        {
          "pid": 3,
          "party": "NDC",
          "name": "JOAN ABUNYEWAH"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F1901",
      "name": "MANHYIA SOUTH",
      "num": 144,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "NANA AGYEI BAFFOUR AWUAH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "RITA AMONU GYAMFUA ANTWI"
        },
        {
          "pid": 3,
          "party": "LPG",
          "name": "MARFO KWAKU"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F1910",
      "name": "MANHYIA NORTH",
      "num": 145,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "AKWASI KONADU"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "HAMZA SWALLAH"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F2001",
      "name": "SUBIN",
      "num": 146,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "KOFI OBIRI YEBOAH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "NANA AKWASI DENKYI AGYEKUM"
        },
        {
          "pid": 3,
          "party": "LPG",
          "name": "BENEDICTA KUSI AFRAM"
        },
        {
          "pid": 2,
          "party": "IND",
          "name": "JOSEPH BOAKYE - DANQUAH"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F2101",
      "name": "KWADASO",
      "num": 147,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "PROF. KINGSLEY NYARKO"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ERIC ASIBEY"
        },
        {
          "pid": 3,
          "party": "PNC",
          "name": "ADONGO AKWASI DAVID"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F2201",
      "name": "OLD TAFO",
      "num": 148,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "VINCENT EKWOW ASSAFUAH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "SAHMUDEEN MOHAMMED KAMIL"
        },
        {
          "pid": 3,
          "party": "GFP",
          "name": "OBIRI YEBOAH FRANK"
        },
        {
          "pid": 4,
          "party": "IND",
          "name": "DANIEL KUSI ASEIDU"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F2301",
      "name": "SUAME",
      "num": 149,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "JOHN DARKO"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "FRANK OWUSU ANSAH"
        },
        {
          "pid": 3,
          "party": "APC",
          "name": "MOHAMMED MUBARAK"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F2401",
      "name": "ASOKWA",
      "num": 150,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "PATRICIA APPIAGYEI"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "AMOH KAMEL"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F2501",
      "name": "OFORIKROM",
      "num": 151,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "MICHAEL KWASI AIDOO"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ANWEL SADAT AHMED"
        },
        {
          "pid": 3,
          "party": "CPP",
          "name": "FRANCIS DERY"
        },
        {
          "pid": 4,
          "party": "IND",
          "name": "BENJAMIN AFRANE AMANKWA"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F2601",
      "name": "ASAWASE",
      "num": 152,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "MANAF IBRAHIM"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "MUNTAKA MOHAMMED-MUBARAK"
        },
        {
          "pid": 3,
          "party": "PPP",
          "name": "ISSAH BABA KOMPO"
        },
        {
          "pid": 4,
          "party": "LPG",
          "name": "NAOMI NSOR"
        },
        {
          "pid": 5,
          "party": "IND",
          "name": "ALHASSAN RAFIK"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F2701",
      "name": "KWABRE EAST",
      "num": 153,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ONYINA-ACHEAMPONG AKWASI GYAMFI"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "JOSEPH AMANKWAH "
        },
        {
          "pid": 3,
          "party": "GFP",
          "name": "BENCHA GEORGE"
        },
        {
          "pid": 2,
          "party": "IND",
          "name": "LORD FOSTER ADOM NYAMEKYE "
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F2801",
      "name": "AFIGYA KWABRE SOUTH",
      "num": 154,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "DAMATA AMA APPIANIMAA SALAM"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "NUZAGL VIVIEN NYUZAGLA"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "IDAHOSAH BRIGHT BENSON"
        },
        {
          "pid": 4,
          "party": "IND",
          "name": "OFORI DANIEL"
        },
        {
          "pid": 5,
          "party": "IND",
          "name": "ABDUL WAHAB DIYAL WILHAK"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F2901",
      "name": "AFIGYA KWABRE NORTH",
      "num": 155,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "COLLINS ADOMAKO-MENSAH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "EMMANUEL JACKSON AGUMAH"
        },
        {
          "pid": 3,
          "party": "PNC",
          "name": "JOYCE KONAMAH ODURO"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F3001",
      "name": "JUABEN",
      "num": 156,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "FRANCIS KWABENA BEREPONG OWUSU-AKYAW"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "EUNICE OHENEWAA ANSU"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F3101",
      "name": "EJISU",
      "num": 157,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "KWABENA BOATENG"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "JERRYNE ASANTE"
        },
        {
          "pid": 3,
          "party": "CPP",
          "name": "OSEI ESTHER"
        },
        {
          "pid": 4,
          "party": "IND",
          "name": "OKAI KWANIN"
        },
        {
          "pid": 5,
          "party": "IND",
          "name": "GEORGE GYAPAH"
        },
        {
          "pid": 6,
          "party": "IND",
          "name": "FREDUA AGYEMANG JOSEPH"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F3201",
      "name": "ASANTE AKIM SOUTH",
      "num": 158,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "KWAKU ASANTE-BOATENG"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "MAAME SARFOAH APPIAH"
        },
        {
          "pid": 3,
          "party": "APC",
          "name": "INUSAH ISSAH"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F3301",
      "name": "ASANTE AKIM CENTRAL",
      "num": 159,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "KWAME ANYIMADU-ANTWI"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "EBENEZER EKOW AIDOO"
        },
        {
          "pid": 3,
          "party": "GFP",
          "name": "ISAAC DZAMESHIE"
        },
        {
          "pid": 4,
          "party": "IND",
          "name": "RICHARD ADU DARKO"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F3401",
      "name": "ASANTE AKIM NORTH",
      "num": 160,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ANDY KWAME APPIAH-KUBI"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "KOFI ASAMOAH"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "OHENE KWAME FRIMPONG"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F3501",
      "name": "EFFIDUASE / ASOKORE",
      "num": 161,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "DR. NANA AYEW AFRIYIE"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "RAYMOND OPOKU AGYEMAN"
        },
        {
          "pid": 3,
          "party": "PNC",
          "name": "LOVIA BERKOH"
        },
        {
          "pid": 4,
          "party": "IND",
          "name": "QUARSHIE ALFRED"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F3601",
      "name": "KUMAWU",
      "num": 162,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ERNEST YAW ANIM"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "KWASI AMANKWAA"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F3701",
      "name": "SEKYERE AFRAM PLAINS",
      "num": 163,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "GEORGE AKOM"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "NASIRA AFRAH GYEKYE"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F3801",
      "name": "NSUTA/KWAMANG/BEPOSO",
      "num": 164,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ADELAIDE NTIM"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "OFORI ATTAH ABOAGYE"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "KWANKYE AARON PROSPER"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F3901",
      "name": "MAMPONG",
      "num": 165,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "KWAKU AMPRATWUM-SARPONG"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "YAKUBU ISSIFU"
        },
        {
          "pid": 3,
          "party": "PNC",
          "name": "AHMED SALLAH IBRAHIM"
        },
        {
          "pid": 4,
          "party": "IND",
          "name": "SARKODIE PETER ABUM"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F4001",
      "name": "EJURA SEKYEDUMASE",
      "num": 166,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "GIFTY NDOMA"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "MUHAMMAD BAWAH BRAIMAH"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F4101",
      "name": "AFIGYA SEKYERE EAST",
      "num": 167,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "MAVIS NKANSAH-BOADU"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "TWUMASI EVANS AMOH"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F4201",
      "name": "OFFINSO SOUTH",
      "num": 168,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ISAAC YAW OPOKU"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ASARE BEDIAKO VINCENT"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F4301",
      "name": "OFFINSO NORTH",
      "num": 169,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "DR. FRED KYEI ASAMOAH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "CEASAR ACHEAMPONG OFOSU"
        },
        {
          "pid": 3,
          "party": "PNC",
          "name": "ABDUL NASIR DEEN"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F4401",
      "name": "AHAFO ANO SOUTH WEST",
      "num": 170,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "OSEI MENSAH DAPAAH ELVIS"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "SEDIK ABUBAKAR"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F4501",
      "name": "AHAFO ANO SOUTH EAST",
      "num": 171,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "FREDERICK ACHEAMPONG"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "YAKUBU MOHAMMED"
        }
      ]
    },
    {
      "region": "ASHANTI",
      "code": "F4601",
      "name": "AHAFO ANO NORTH",
      "num": 172,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ERIC NANA AGYEMANG-PREMPEH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "KWASI ADUSEI"
        }
      ]
    },
    {
      "region": "AHAFO",
      "code": "H0101",
      "name": "ASUNAFO SOUTH",
      "num": 173,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "FRANK ADUSE POKU"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "OPOKU ERIC"
        }
      ]
    },
    {
      "region": "AHAFO",
      "code": "H0201",
      "name": "ASUNAFO NORTH",
      "num": 174,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "EVANS OPOKU BOBIE"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "MOHAMMED HARUNA "
        }
      ]
    },
    {
      "region": "AHAFO",
      "code": "H0301",
      "name": "ASUTIFI SOUTH",
      "num": 175,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "YAW OWUSU-BREMPONG"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "COLLINS DAUDA"
        },
        {
          "pid": 3,
          "party": "NDP",
          "name": "ANDREWS KOFI AHIAMATA"
        }
      ]
    },
    {
      "region": "AHAFO",
      "code": "H0401",
      "name": "ASUTIFI NORTH",
      "num": 176,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "PATRICK BANOR"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "EBENEZER KWAKU ADDO"
        }
      ]
    },
    {
      "region": "AHAFO",
      "code": "H0501",
      "name": "TANO SOUTH",
      "num": 177,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "HON. DR. BENJAMIN YEBOAH SEKYERE"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "CHARLES ASIEDU"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "KOFI NTI CHRISTOPHER"
        }
      ]
    },
    {
      "region": "AHAFO",
      "code": "H0601",
      "name": "TANO NORTH",
      "num": 178,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "DR. GIDEON BOAKO"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "PIUS OPOKU SAMPSON"
        }
      ]
    },
    {
      "region": "BONO",
      "code": "J0101",
      "name": "SUNYANI EAST",
      "num": 179,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "KWASI AMEYAW-CHEREMEH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "SEID MUBARAK"
        },
        {
          "pid": 3,
          "party": "PNC",
          "name": "ALANYINA SAMPANA SAMPSON"
        },
        {
          "pid": 4,
          "party": "IND",
          "name": "RANSFORD ANTWI"
        }
      ]
    },
    {
      "region": "BONO",
      "code": "J0201",
      "name": "SUNYANI WEST",
      "num": 180,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "AWUAH IGNATIUS BAFFOUR"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "MILLICENT YEBOAH AMANKWAH"
        },
        {
          "pid": 3,
          "party": "PNC",
          "name": "ADONGO AKATU MATILDA"
        }
      ]
    },
    {
      "region": "BONO",
      "code": "J0301",
      "name": "DORMAA WEST",
      "num": 181,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ALI MAIGA HALIDU"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "VINCENT OPPONG ASAMOAH"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "UMAR YUSIF"
        }
      ]
    },
    {
      "region": "BONO",
      "code": "J0401",
      "name": "DORMAA CENTRAL",
      "num": 182,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "KWAKU AGYEMAN-MANU"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ADU JACK JOHN KWAME"
        },
        {
          "pid": 3,
          "party": "GCPP",
          "name": "JOCHEE UROY EDEM"
        },
        {
          "pid": 4,
          "party": "IND",
          "name": "YEBOAH ERIC KWAKU"
        }
      ]
    },
    {
      "region": "BONO",
      "code": "J0501",
      "name": "DORMAA EAST",
      "num": 183,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "PAUL APEREKU TWUM-BARIMAH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "OWUSUAH RACHEL AMMA"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "EMMANUEL TWIH GYABAAH"
        }
      ]
    },
    {
      "region": "BONO",
      "code": "J0601",
      "name": "BEREKUM EAST",
      "num": 184,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "NELSON KYEREMEH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "SIMON AMPAABENG KYEREMEH"
        },
        {
          "pid": 3,
          "party": "PNC",
          "name": "ABIGAIL BAIFI"
        },
        {
          "pid": 4,
          "party": "PAG",
          "name": "BLESSING EMMANUEL KWAME ADARKWA"
        }
      ]
    },
    {
      "region": "BONO",
      "code": "J0701",
      "name": "BEREKUM WEST",
      "num": 185,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "KWAKU AGYENIM-BOATENG"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "DICKSON KYERE-DUAH"
        }
      ]
    },
    {
      "region": "BONO",
      "code": "J0801",
      "name": "JAMAN SOUTH",
      "num": 186,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "KWADWO DAMOAH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "OKOFO-DATEH WILLIAMS"
        },
        {
          "pid": 3,
          "party": "NDP",
          "name": "BEDIAKO JOYCE ASARE"
        }
      ]
    },
    {
      "region": "BONO",
      "code": "J0901",
      "name": "JAMAN NORTH",
      "num": 187,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ENOCK NYARKO"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "AHENKWAH FREDERICK YAW"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "MARTIN KWAME ANTWI"
        }
      ]
    },
    {
      "region": "BONO",
      "code": "J1001",
      "name": "BANDA",
      "num": 188,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "JOE DANQUAH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "IBRAHIM AHMED"
        },
        {
          "pid": 3,
          "party": "GCPP",
          "name": "PAUL NYANKAMAGO"
        },
        {
          "pid": 4,
          "party": "NDP",
          "name": "YEBOAH ABBREY"
        },
        {
          "pid": 5,
          "party": "IND",
          "name": "ISAAC K. ANNORHENE"
        }
      ]
    },
    {
      "region": "BONO",
      "code": "J1101",
      "name": "TAIN",
      "num": 189,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ABABIO ALEXANDEER"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ADAMA SULEMANA"
        },
        {
          "pid": 3,
          "party": "NDP",
          "name": "SAH JOB KWABENA"
        }
      ]
    },
    {
      "region": "BONO",
      "code": "J1201",
      "name": "WENCHI",
      "num": 190,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "KOJO FREMPONG"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "HARUNA SEIDU"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "DAVID KUSI"
        }
      ]
    },
    {
      "region": "WESTERN NORTH",
      "code": "G0101",
      "name": "AOWIN",
      "num": 191,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ABANGA YAKUBU FUSANI"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "OSCAR OFORI LARBI"
        }
      ]
    },
    {
      "region": "WESTERN NORTH",
      "code": "G0201",
      "name": "SUAMAN",
      "num": 192,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ADDY FREDERICK"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "JOSEPH BETINO"
        },
        {
          "pid": 3,
          "party": "LPG",
          "name": "JOHN ASARE"
        }
      ]
    },
    {
      "region": "WESTERN NORTH",
      "code": "G0301",
      "name": "BIBIANI-ANHWIASO-BEKWAI",
      "num": 193,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ALFRED OBENG-BOATENG"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "BRIGHT ASAMOAH BREFO"
        }
      ]
    },
    {
      "region": "WESTERN NORTH",
      "code": "G0401",
      "name": "SEFWI WIAWSO",
      "num": 194,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "FESTU BUMAKAMA AGYAPONG"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "AFFUL KOFI BENTEH"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "ABABIO VERONICA"
        }
      ]
    },
    {
      "region": "WESTERN NORTH",
      "code": "G0501",
      "name": "SEFWI AKONTOMBRA",
      "num": 195,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "DJORNOBUAH, ALEX TETTEH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "PIOUS KWAME NKUAH"
        }
      ]
    },
    {
      "region": "WESTERN NORTH",
      "code": "G0601",
      "name": "JUABOSO",
      "num": 196,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ALEXANDER AMPAABENG"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "KWABENA MINTAH AKANDOH"
        },
        {
          "pid": 3,
          "party": "CPP",
          "name": "OPPONG ERNESTINA"
        }
      ]
    },
    {
      "region": "WESTERN NORTH",
      "code": "G0701",
      "name": "BODI",
      "num": 197,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ANDOH NICHOLAS"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "AHI SAMPSON"
        }
      ]
    },
    {
      "region": "WESTERN NORTH",
      "code": "G0801",
      "name": "BIA WEST",
      "num": 198,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "BERNARD GYEBI BLAY"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "TANKO MUSTAPHA AMADU"
        }
      ]
    },
    {
      "region": "WESTERN NORTH",
      "code": "G0901",
      "name": "BIA EAST",
      "num": 199,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "NICHOLAS KUPOG YAYIN"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "RICHARD ACHEAMPONG"
        }
      ]
    },
    {
      "region": "BONO EAST",
      "code": "K0101",
      "name": "TECHIMAN SOUTH",
      "num": 200,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "MARTIN KWAKU ADJEI-MENSAH KORSAH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "CHRISTOPHPER BEYERE BAASONGTI"
        },
        {
          "pid": 3,
          "party": "PPP",
          "name": "DONKOR MOSES"
        },
        {
          "pid": 4,
          "party": "IND",
          "name": "BOYOUNG IDDRIS"
        }
      ]
    },
    {
      "region": "BONO EAST",
      "code": "K0201",
      "name": "KINTAMPO NORTH",
      "num": 201,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ISAAC  BAFFOE AMEYAW"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "JOSEPH KWAME KUMAH"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "BUARE KOJO"
        },
        {
          "pid": 4,
          "party": "IND",
          "name": "ISSIFU NKWANTA"
        }
      ]
    },
    {
      "region": "BONO EAST",
      "code": "K0301",
      "name": "KINTAMPO SOUTH",
      "num": 202,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ALEXANDER GYAN"
        },
        {
          "pid": 2,
          "party": "NPP",
          "name": "FELICIA ADJEI"
        }
      ]
    },
    {
      "region": "BONO EAST",
      "code": "K0401",
      "name": "NKORANZA NORTH",
      "num": 203,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "JACQUELINE BOATEMAA BONSU"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "MENSAH KWASI JOSEPH"
        },
        {
          "pid": 3,
          "party": "LPG",
          "name": "BOAHENE CLEMENT"
        },
        {
          "pid": 4,
          "party": "IND",
          "name": "CHARLES OWUSU"
        }
      ]
    },
    {
      "region": "BONO EAST",
      "code": "K0501",
      "name": "NKORANZA SOUTH",
      "num": 204,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "HARRIET KYEREMANTENG OPPONG"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "EMMANUEL KWADWO AGYEKUM"
        },
        {
          "pid": 3,
          "party": "PNC",
          "name": "FLORENCE AMPOUR"
        },
        {
          "pid": 4,
          "party": "LPG",
          "name": "SABASTINE GAATA"
        }
      ]
    },
    {
      "region": "BONO EAST",
      "code": "K0601",
      "name": "ATEBUBU/AMANTIN",
      "num": 205,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "BADUON DIMMIE ISSAH ABU"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "SANJA NANJA"
        },
        {
          "pid": 3,
          "party": "PPP",
          "name": "HENNAA KWAKU ABRAHAM"
        }
      ]
    },
    {
      "region": "BONO EAST",
      "code": "K0701",
      "name": "PRU WEST",
      "num": 206,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "STEPHEN PAMBIIN JALULAH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "EMMANUEL KOFI NTEKUNI"
        }
      ]
    },
    {
      "region": "BONO EAST",
      "code": "K0801",
      "name": "PRU EAST",
      "num": 207,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "APETORGBOR YUSSIF APPEH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "EMMANUEL KWAKU BOAM"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "FELIX NIMAKO"
        }
      ]
    },
    {
      "region": "BONO EAST",
      "code": "K0901",
      "name": "SENE WEST",
      "num": 208,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "JOSEPH KUMAH MACKAY"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "KWAME TWUMASI AMPOFO"
        }
      ]
    },
    {
      "region": "BONO EAST",
      "code": "K1001",
      "name": "SENE EAST",
      "num": 209,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "WUDONYIM KOFI IBRAHIM"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "NAPARE DOMINIC"
        }
      ]
    },
    {
      "region": "BONO EAST",
      "code": "K1101",
      "name": "TECHIMAN NORTH",
      "num": 210,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "MARTIN OTI GYARKO"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ELIZABETH OFOSU- ADJARE"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "FRANK ASARE"
        }
      ]
    },
    {
      "region": "OTI",
      "code": "L0101",
      "name": "BUEM",
      "num": 211,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "RICHARD KWADWO ADJEI"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ADAMS IDDIE KOFI"
        }
      ]
    },
    {
      "region": "OTI",
      "code": "L0201",
      "name": "BIAKOYE",
      "num": 212,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "FRANK YIRENKYI "
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "JEAN-MARIE FORMADI"
        },
        {
          "pid": 3,
          "party": "LPG",
          "name": "PAUL KWASI DEMENDS"
        }
      ]
    },
    {
      "region": "OTI",
      "code": "L0301",
      "name": "AKAN",
      "num": 213,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "TASSAH MUSTAPHA TASSAH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "GOMADO, YAO"
        },
        {
          "pid": 3,
          "party": "LPG",
          "name": "AFORLA YAWA MARY"
        }
      ]
    },
    {
      "region": "OTI",
      "code": "L0401",
      "name": "KRACHI EAST",
      "num": 214,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "MICHAEL YAW GYATO"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "NELSON KOFI DJABAB"
        }
      ]
    },
    {
      "region": "OTI",
      "code": "L0501",
      "name": "KRACHI WEST",
      "num": 215,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "JUSTICE MENSAH AMANKWA"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "HELEN ADJOA NTOSO"
        },
        {
          "pid": 3,
          "party": "LPG",
          "name": "CHARLES BISI GYAMGBUJA"
        }
      ]
    },
    {
      "region": "OTI",
      "code": "L0601",
      "name": "KRACHI-NCHUMURU",
      "num": 216,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "JAMES MAMUDU "
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "SOLOMON KUYON"
        },
        {
          "pid": 3,
          "party": "LPG",
          "name": "NTANA RICHMOND UWUMBORBIN"
        }
      ]
    },
    {
      "region": "OTI",
      "code": "L0701",
      "name": "NKWANTA SOUTH",
      "num": 217,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "SHERIFA SELINA SEKYERE-TIJANI"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "GEOFFREY KINI"
        }
      ]
    },
    {
      "region": "OTI",
      "code": "L0801",
      "name": "NKWANTA NORTH",
      "num": 218,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "BENJAMIN MUNYUM NADOR"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "JOHN KWABENA BLESS OTI"
        },
        {
          "pid": 3,
          "party": "LPG",
          "name": "GABRIEL KWABENA DONKOR"
        },
        {
          "pid": 4,
          "party": "IND",
          "name": "ODURO AIKENS KOFI YEBOAH"
        }
      ]
    },
    {
      "region": "OTI",
      "code": "L0901",
      "name": "GUAN",
      "num": 219,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "MICHAEL OSIBO"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "AGBENYO FRED KWESI"
        },
        {
          "pid": 3,
          "party": "NDP",
          "name": "SEKOR SUSSIE"
        }
      ]
    },
    {
      "region": "NORTHERN",
      "code": "M0101",
      "name": "KPANDAI",
      "num": 220,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "MATTHEW NYINDAM"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "DANIEL NSALA WAKPAL"
        },
        {
          "pid": 3,
          "party": "APC",
          "name": "DONKOR ERIC NIPANIL"
        }
      ]
    },
    {
      "region": "NORTHERN",
      "code": "M0201",
      "name": "BIMBILLA",
      "num": 221,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "NITIWUL BINGAB ADUNA DOMINIC"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "DR JOSEPH KWABENA MANBOAH-ROCKSON"
        },
        {
          "pid": 3,
          "party": "PPP",
          "name": "ATTAH SUALE"
        },
        {
          "pid": 4,
          "party": "IND",
          "name": "KAMIL BAMANPONG"
        }
      ]
    },
    {
      "region": "NORTHERN",
      "code": "M0301",
      "name": "WULENSI",
      "num": 222,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "HARUNA ABDULAI"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ABUKARI DAWUNI"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "NANDAYA YAW STANLEY"
        }
      ]
    },
    {
      "region": "NORTHERN",
      "code": "M0401",
      "name": "ZABZUGU",
      "num": 223,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "FAWAZ ALIU"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ALHASSAN UMAR"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "OBORI BUNAKPABINE SOLOMON"
        }
      ]
    },
    {
      "region": "NORTHERN",
      "code": "M0501",
      "name": "TATALE/SANGULI",
      "num": 224,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "MBOMBA THOMAS"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "NTEBE AYO WILLIAM"
        }
      ]
    },
    {
      "region": "NORTHERN",
      "code": "M0601",
      "name": "YENDI",
      "num": 225,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "UMAR FAROUK ALIU MAHAMA"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ABDUL-FATAWU ALHASSAN"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "HUSSEIN ABDUL-KARIM"
        }
      ]
    },
    {
      "region": "NORTHERN",
      "code": "M0701",
      "name": "MION",
      "num": 226,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "MUSAH ABDUL-AZIZ AYABA"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "MISBAHU MAHAMA ADAMS"
        }
      ]
    },
    {
      "region": "NORTHERN",
      "code": "M0801",
      "name": "SABOBA",
      "num": 227,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "BINTIN CHARLES BINIPOM"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "BUKARI NIKPE JOSEPH"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "EMMANUEL MAWANYE KOTIN"
        },
        {
          "pid": 4,
          "party": "IND",
          "name": "YAJABUN JANWOL SAMSON"
        }
      ]
    },
    {
      "region": "NORTHERN",
      "code": "M0901",
      "name": "GUSHEGU",
      "num": 228,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ALHASSAN TAMPULI SULEMANA"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "MOHAMMED YUSSIF MALIMALI"
        }
      ]
    },
    {
      "region": "NORTHERN",
      "code": "M1001",
      "name": "KARAGA",
      "num": 229,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "MOHAMMED AMIN ADAM"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ALHASSAN SUALIHU DANDAAWA"
        }
      ]
    },
    {
      "region": "NORTHERN",
      "code": "M1101",
      "name": "SAVELUGU",
      "num": 230,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ABDUL AZIZ FATAHIYA"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ABDULAI JACOB IDDRISS"
        },
        {
          "pid": 3,
          "party": "PNC",
          "name": "HARUNA ZAKARIA"
        }
      ]
    },
    {
      "region": "NORTHERN",
      "code": "M1201",
      "name": "NANTON",
      "num": 231,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "MOHAMMED HARDI TUFEIRU"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "MOHAMMED SHERIF ABDUL-KHALIQ"
        }
      ]
    },
    {
      "region": "NORTHERN",
      "code": "M1301",
      "name": "TAMALE SOUTH",
      "num": 232,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "FUSEINI MUSAH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "IDDRISU HARUNA"
        },
        {
          "pid": 3,
          "party": "CPP",
          "name": "KASSIM ABDUL JALILU"
        },
        {
          "pid": 4,
          "party": "PNC",
          "name": "SUALISU IMORO NABILA"
        },
        {
          "pid": 5,
          "party": "IND",
          "name": "HAASHIMIYU YAHAYA"
        }
      ]
    },
    {
      "region": "NORTHERN",
      "code": "M1302",
      "name": "TAMALE CENTRAL",
      "num": 233,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "SULEMANA SALIFU"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "IBRAHIM MURTALA MUHAMMED"
        }
      ]
    },
    {
      "region": "NORTHERN",
      "code": "M1401",
      "name": "SAGNARIGU",
      "num": 234,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "FELICIA TETTEY"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ATTA ISSAH "
        }
      ]
    },
    {
      "region": "NORTHERN",
      "code": "M1413",
      "name": "TAMALE NORTH",
      "num": 235,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ABDUL-RAHAMAN ALIDU"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ALHASSAN SAYIBU SUHUYINI"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "HUDU FAUZIYA"
        }
      ]
    },
    {
      "region": "NORTHERN",
      "code": "M1501",
      "name": "TOLON",
      "num": 236,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "IDDRISU HABIB"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "OSMAN TAHIDU DAMBA"
        },
        {
          "pid": 3,
          "party": "APC",
          "name": "SUMANI DAWUDA WUMBEI"
        }
      ]
    },
    {
      "region": "NORTHERN",
      "code": "M1601",
      "name": "KUMBUNGU",
      "num": 237,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ABDUL-SALAM HAMZA FATAW"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "HAMZA ADAM"
        }
      ]
    },
    {
      "region": "SAVANNAH",
      "code": "N0101",
      "name": "BOLE/BAMBOI",
      "num": 238,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "RAPHAEL KUMAH ABOLASOM"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "YUSIF SULEMANA"
        }
      ]
    },
    {
      "region": "SAVANNAH",
      "code": "N0201",
      "name": "SAWLA TUNA KALBA",
      "num": 239,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "SANKARA KUUBENEEM ANKAARA"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "CHIWITEY ANDREW DARI"
        }
      ]
    },
    {
      "region": "SAVANNAH",
      "code": "N0301",
      "name": "DAMONGO",
      "num": 240,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "JINAPOR SAMUEL ABDULAI"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ADAM MUTAWAKILU"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "SAAKA BABA MOSES"
        }
      ]
    },
    {
      "region": "SAVANNAH",
      "code": "N0401",
      "name": "DABOYA/MANKARIGU",
      "num": 241,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": " SAMUEL YEYU TIKA"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": " SHAIBU MAHAMA"
        }
      ]
    },
    {
      "region": "SAVANNAH",
      "code": "N0501",
      "name": "YAPEI-KUSAWGU",
      "num": 242,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "AMADU ZAKRIA"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "JOHN ABDULAI JINAPOR"
        },
        {
          "pid": 3,
          "party": "GUM",
          "name": "AWUDU ADAM"
        }
      ]
    },
    {
      "region": "SAVANNAH",
      "code": "N0601",
      "name": "SALAGA SOUTH",
      "num": 243,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "SALIFU ADAM BRAIMAH"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ZUWERA MOHAMMED IBRAHIMAH"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "ABRAHAM MBIDO BAGYIM"
        },
        {
          "pid": 4,
          "party": "IND",
          "name": "GEOFFREY AYIDZOE RAZAK"
        }
      ]
    },
    {
      "region": "SAVANNAH",
      "code": "N0701",
      "name": "SALAGA NORTH",
      "num": 244,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ALHASSAN ABDALLAH IDDI"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ALHASSAN MUMUNI"
        }
      ]
    },
    {
      "region": "UPPER WEST",
      "code": "P0101",
      "name": "WA CENTRAL",
      "num": 245,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "HUMU AWUDU"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ABDUL-RASHID HASSAN PELPUO"
        },
        {
          "pid": 3,
          "party": "PNC",
          "name": "YAKUBU ZAKARIA"
        },
        {
          "pid": 4,
          "party": "IND",
          "name": "RASHEED SAEED"
        }
      ]
    },
    {
      "region": "UPPER WEST",
      "code": "P0201",
      "name": "WA WEST",
      "num": 246,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "SAATIRI JAMES KPIR-FAATEY"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "PETER LANCHENE TOOBU"
        },
        {
          "pid": 3,
          "party": "PNC",
          "name": "BAYONG FRANCIS MWINA"
        }
      ]
    },
    {
      "region": "UPPER WEST",
      "code": "P0301",
      "name": "WA EAST",
      "num": 247,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "DR. EWURAH SULEMAN KANDIA MAHAMA"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "DR. GODFRED SEIDU JASAW"
        }
      ]
    },
    {
      "region": "UPPER WEST",
      "code": "P0401",
      "name": "NADOWLI/KALEO",
      "num": 248,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "KAMBOTUU FRANCIS XAVIER"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "SUMAH ANTHONY MWINKAARA"
        }
      ]
    },
    {
      "region": "UPPER WEST",
      "code": "P0501",
      "name": "DAFFIAMA/BUSSIE/ISSA",
      "num": 249,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "NADI IMORO   SANDA"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "DR. SEBASTIAN NGMENENSO SANDAARE"
        }
      ]
    },
    {
      "region": "UPPER WEST",
      "code": "P0601",
      "name": "JIRAPA",
      "num": 250,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "AISHA SALIFU"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "CLETUS SEIDU DAPILAH"
        }
      ]
    },
    {
      "region": "UPPER WEST",
      "code": "P0701",
      "name": "LAMBUSSIE",
      "num": 251,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "BRIGHT BAKYE YELVIEL BALIGI"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "TITUS KOFI BEYUO"
        },
        {
          "pid": 3,
          "party": "LPG",
          "name": "MOHAMMED IMURAN"
        }
      ]
    },
    {
      "region": "UPPER WEST",
      "code": "P0801",
      "name": "LAWRA",
      "num": 252,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "JACOB DOMEKAKPIER DERY"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "BEDE ANWATAAZUMO ZIEDENG"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "DABUO BAANAAH JOSEPH"
        }
      ]
    },
    {
      "region": "UPPER WEST",
      "code": "P0901",
      "name": "NANDOM",
      "num": 253,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "AMBROSE DERY"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "RICHARD KUUIRE"
        },
        {
          "pid": 3,
          "party": "GUM",
          "name": "NYINE-KAKONE DELLE STELLA SAABEDAAR"
        }
      ]
    },
    {
      "region": "UPPER WEST",
      "code": "P1001",
      "name": "SISSALA WEST",
      "num": 254,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "SALIFU NALIWIE BALUWIE"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "MOHAMMED ADAMS SUKPARU"
        },
        {
          "pid": 3,
          "party": "PNC",
          "name": "YUSSIF SHAIBU"
        }
      ]
    },
    {
      "region": "UPPER WEST",
      "code": "P1101",
      "name": "SISSALA EAST",
      "num": 255,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ISSAHAKU AMIDU CHINNIA"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "MOHAMMED ISSAH BATAGLIA "
        }
      ]
    },
    {
      "region": "NORTH EAST",
      "code": "Q0101",
      "name": "WALEWALE",
      "num": 256,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "MAHAMA TIAH ABDUL-KABIRU"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ABUBAKARI ABDALLAH"
        }
      ]
    },
    {
      "region": "NORTH EAST",
      "code": "Q0201",
      "name": "YAGABA /KUBORI",
      "num": 257,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "MUSTAPHA USSIF"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "MUSAH SIBIRI AMIDU"
        }
      ]
    },
    {
      "region": "NORTH EAST",
      "code": "Q0301",
      "name": "NALERIGU / GAMBAGA",
      "num": 258,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "MUMUNI MUHAMMED"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ISSIFU SEIDU"
        },
        {
          "pid": 3,
          "party": "PNC",
          "name": "SEIDU TIA KARIM"
        }
      ]
    },
    {
      "region": "NORTH EAST",
      "code": "Q0401",
      "name": "BUNKPURUGU",
      "num": 259,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "SOLOMON NAMLIIT BOAR"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "BANDIM ABED-NEGO AZUMAH"
        }
      ]
    },
    {
      "region": "NORTH EAST",
      "code": "Q0501",
      "name": "YUNYOO",
      "num": 260,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "LIWAAL OSCAR"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ALHASSAN SULEMANA"
        }
      ]
    },
    {
      "region": "NORTH EAST",
      "code": "Q0601",
      "name": "CHEREPONI",
      "num": 261,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "TAHIDU ABDUL-RAZAK"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "SEIDU ALHASSAN ALAJOR"
        },
        {
          "pid": 3,
          "party": "PNC",
          "name": "EUGENE TAMADO KOFFIKAN BASHIRU"
        }
      ]
    },
    {
      "region": "UPPER EAST",
      "code": "R0101",
      "name": "BUILSA SOUTH",
      "num": 262,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "DANIEL KWAME GARIBA"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "DR CLEMENT ABAS APAAK"
        },
        {
          "pid": 3,
          "party": "PNC",
          "name": "SEIDU CHRISTOPHER AKANZEBOKA"
        }
      ]
    },
    {
      "region": "UPPER EAST",
      "code": "R0201",
      "name": "BUILSA NORTH",
      "num": 263,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ALONSI THOMAS KOFI"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "AGALGA JAMES "
        },
        {
          "pid": 3,
          "party": "PNC",
          "name": "ASUOK JOSEPH RANSFORD "
        }
      ]
    },
    {
      "region": "UPPER EAST",
      "code": "R0301",
      "name": "KASSENA NANKANA MUNICIPAL(NAVRONGO CENTRAL)",
      "num": 264,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ABDALLAH OTITO WERSEH ACHULIWOR"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "SIMON AKIBANGE AWORIGO"
        }
      ]
    },
    {
      "region": "UPPER EAST",
      "code": "R0401",
      "name": "KASSENA NANKANA WEST(CHIANA-PAGA)",
      "num": 265,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ROBERT APECHIRA ALOO"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "NIKYEMA BILLA ALAMZY"
        }
      ]
    },
    {
      "region": "UPPER EAST",
      "code": "R0501",
      "name": "BOLGATANGA CENTRAL(MUNICIPAL)",
      "num": 266,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ELVIS ATIA AWONEKAI"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ADONGO ISAAC"
        },
        {
          "pid": 3,
          "party": "PNC",
          "name": "AKUNLIBE PETER A."
        }
      ]
    },
    {
      "region": "UPPER EAST",
      "code": "R0601",
      "name": "BOLGATANGA EAST",
      "num": 267,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": " MATHEW SILAS  AMOAH "
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "DOMINIC AKURITINGA AYINE"
        },
        {
          "pid": 3,
          "party": "PAG",
          "name": "ATIAH EDWIN"
        }
      ]
    },
    {
      "region": "UPPER EAST",
      "code": "R0701",
      "name": "BONGO",
      "num": 268,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "DIANA  ADUKO ABURIYA"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "CHARLES BAWADUAH"
        }
      ]
    },
    {
      "region": "UPPER EAST",
      "code": "R0801",
      "name": "TALENSI",
      "num": 269,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ALIBO ROBERT AYINENABA"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "DANIEL DUNG MAHAMA"
        },
        {
          "pid": 3,
          "party": "PNC",
          "name": "DR. MICHAEL WOMBEOGO"
        }
      ]
    },
    {
      "region": "UPPER EAST",
      "code": "R0901",
      "name": "NABDAM",
      "num": 270,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "NDANBON CHARLES TALEOG"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "DR. MARK KURT NAWAANE"
        }
      ]
    },
    {
      "region": "UPPER EAST",
      "code": "R1001",
      "name": "ZEBILLA (BAWKU WEST)",
      "num": 271,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "DR. JOHN KINGSLEY KRUGU"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "EBENEZER ALUMIRE NDEBILLA"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "PATIENCE AKPARIPOKA NDEBUGRE"
        }
      ]
    },
    {
      "region": "UPPER EAST",
      "code": "R1101",
      "name": "BAWKU CENTRAL(MUNICIPAL)",
      "num": 272,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "AGOBIRI PAUL ALALE"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "MAHAMA AYARIGA"
        },
        {
          "pid": 3,
          "party": "IND",
          "name": "WUNI ABUBAKARI"
        }
      ]
    },
    {
      "region": "UPPER EAST",
      "code": "R1201",
      "name": "PUSIGA",
      "num": 273,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ABDUL-WAHAB HANAN"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "LAADI AYII AYAMBA"
        },
        {
          "pid": 3,
          "party": "APC",
          "name": "ANABA SIMON PAUL"
        }
      ]
    },
    {
      "region": "UPPER EAST",
      "code": "R1301",
      "name": "GARU",
      "num": 274,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "AZUMAH GEORGINA LARDI"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "ANABAH THOMAS WINSUM"
        }
      ]
    },
    {
      "region": "UPPER EAST",
      "code": "R1401",
      "name": "TEMPANE",
      "num": 275,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "JOSEPH DINDIOK KPEMKA"
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "AKANVARIBA LYDIA LAMISI"
        }
      ]
    },
    {
      "region": "UPPER EAST",
      "code": "R1501",
      "name": "BINDURI",
      "num": 276,
      "candidates": [
        {
          "pid": 1,
          "party": "NPP",
          "name": "ABANGA ABDULAI "
        },
        {
          "pid": 2,
          "party": "NDC",
          "name": "MAHMOUD  ISSIFU"
        },
        {
          "pid": 3,
          "party": "APC",
          "name": "AMADU  ALALE"
        }
      ]
    }
  ]
  
  
  
  
 