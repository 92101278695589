import React from 'react';
import { Modal, Box, Typography, Button, Grid } from '@mui/material';
import QRCode from "react-qr-code";
import moment from 'moment';
import { GOOGLE_MAPS_API_KEY } from './Constants.js';

const IncidentsModal = ({ open, handleClose, post }) => {
    if (!post) return null;

    const { address, date_posted, description, files, posted_name, visible } = post;
    const file = files[0];
    const itemLink = `${window.location.origin}/post/${post._id}`;
    const approvalStatus = visible ? 'Approved' : 'Not approved';

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="post-modal-title" aria-describedby="post-modal-description">
            <Box
                sx={{
                    position: 'absolute',
                    top: '5%',
                    bottom: '5%',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '80%',
                    maxWidth: '900px',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    overflowY: 'auto',
                    maxHeight: '90vh',
                }}
            >
                <Grid container spacing={2}>
                    {/* Main Content: File Display */}
                    <Grid item xs={9}>
                        <Box sx={{ position: 'relative', paddingTop: '56.25%', backgroundColor: '#000', marginBottom: 2 }}>
                            {file && file.fileType.startsWith('video') ? (
                                <video
                                    src={file.url}
                                    controls
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                />
                            ) : (
                                <img
                                    src={file.url}
                                    alt="Post file"
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                />
                            )}
                            {/* Watermark */}
                            <Box
                                sx={{
                                    position: 'absolute',
                                    bottom: 35,
                                    right: 8,
                                    backgroundColor: 'rgba(0, 0, 0, 0.05)',
                                    color: 'white',
                                    padding: '4px 8px',
                                    borderRadius: 1,
                                    fontSize: '0.8rem',
                                }}
                            >
                                <Typography>{address}</Typography>

                                <Typography>
                                    {moment(date_posted).format('DD/MM/YYYY, h:mm:ss a')}
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>

                    {/* Side Content: Map and QR Code */}
                    <Grid item xs={3} container direction="column" spacing={2}>
                        <Grid item>
                            <iframe
                                title="Google Street View"
                                width="100%"
                                height="125"
                                style={{ border: 0 }}
                                loading="lazy"
                                allowFullScreen
                                src={`https://www.google.com/maps/embed/v1/streetview?key=${GOOGLE_MAPS_API_KEY}&location=${file.latitude},${file.longitude}&heading=210&pitch=10&fov=35`}
                            ></iframe>
                        </Grid>
                        <Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
                            <QRCode size={100} value={itemLink} />
                        </Grid>
                    </Grid>
                </Grid>

                {/* Details Section */}
                <Typography id="post-modal-title" variant="body2" sx={{ mb: 2 }}>
                    {posted_name}'s Post
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                    {address} at {moment(date_posted).format('DD/MM/YYYY, h:mm:ss a')}
                </Typography>

                {description && (
                    <Typography sx={{ mt: 1 }}>
                        <strong>{description}</strong>
                    </Typography>
                )}

                <Button onClick={handleClose} variant="contained" color="primary" fullWidth sx={{ mt: 3 }}>
                    Close
                </Button>
            </Box>
        </Modal>
    );
};

export default IncidentsModal;
