// Loader.js
import React from 'react';

const Loader = () => {
  const loaderContainerStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  const loaderStyle = {
    width: '40px',
    height: '40px',
    border: '4px solid #ccc',
    borderTop: '4px solid #3498db', 
    borderRadius: '50%',
    animation: 'spin 1s linear infinite',
  };

  const textStyle = {
    marginTop: '10px',
    fontSize: '1rem',
    color: '#555',
  };

  return (
    <div style={loaderContainerStyle}>
      <div style={loaderStyle}></div>
      <p style={textStyle}>Loading...</p>
      <style>{`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}</style>
    </div>
  );
};

export default Loader;
