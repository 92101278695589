import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { COLOR_PRIMARY, MAIN_URL } from './Constants';
import { Nav, NavItem, NavLink, Modal, ModalBody, Table, Dropdown, FormControl } from 'react-bootstrap';
import ElectionHeaderAdmin from './ElectionHeaderAdmin';
import useResponsive from './useResponsive';
import PostListAdmin from './PostListAdmin';
import Loader from './Loader';
import { useAuth } from './contexts/AuthContext';
import ConstituencyResult from './pages/ConstituencyResult';
import POLLING_STATION from './PollingStations.json';


const Constituency_Admin = () => {
    const { code, name } = useParams();
    const [data, setData] = useState({ presidential: [], parliamentary: [] });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeTab, setActiveTab] = useState('presidential');
    const [modalOpen, setModalOpen] = useState(false);
    const [modalImage, setModalImage] = useState(null);
    const isMobile = useResponsive();
    const [pollingStations, setPollingStations] = useState([]);
    const [selectedPollingStation, setSelectedPollingStation] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [pollingStationCode, setPollingStationCode] = useState("");
    const { user } = useAuth();

    // window.location.reload()
    const navigate = useNavigate();
    const [votercount, setVoterCount] = useState(0)



    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                setSelectedPollingStation(null)

                setData({ presidential: [], parliamentary: [] }); // Clear previous data
                const ps = await axios.get(`${MAIN_URL}get/polling-stations/${code}`);
                setPollingStations(ps.data);
                console.log(JSON.stringify(ps.data));
                const response = await axios.get(`${MAIN_URL}constituency/result/admin/${code}`);
                setData(response.data);

            } catch (err) {
                setError('Error loading election results');
            } finally {
                setLoading(false);
            }
        };

        if (code) fetchData();


        const getTotalVoteCount = (codePrefix, pollingStations) => {
            if (!codePrefix || codePrefix.length < 3) {
                console.error("Invalid code prefix. It must be at least 3 characters long.");
                return { totalVoteCount: 0, totalRows: 0 };
            }

            let matchingStations = [];

            if (codePrefix.startsWith('B0230')) {
                matchingStations = pollingStations.filter(station => {
                    if (station.pollingcode.startsWith('B02')) {
                        const numericValue = parseInt(station.pollingcode.slice(2, 5), 10);
                        return numericValue <= 230;
                    }
                    return false;
                });
            } else if (codePrefix.startsWith('B0231')) {
                matchingStations = pollingStations.filter(station => {
                    if (station.pollingcode.startsWith('B02')) {
                        const numericValue = parseInt(station.pollingcode.slice(2, 5), 10);
                        return numericValue > 230;
                    }
                    return false;
                });
            } else if (codePrefix.startsWith('M1301')) {
                matchingStations = pollingStations.filter(station => {
                    if (station.pollingcode.startsWith('M13')) {
                        const numericValue = parseInt(station.pollingcode.slice(3, 5), 10);
                        return numericValue < 24;
                    }
                    return false;
                });
            } else if (codePrefix.startsWith('M1302')) {
                matchingStations = pollingStations.filter(station => {
                    if (station.pollingcode.startsWith('M13')) {
                        const numericValue = parseInt(station.pollingcode.slice(3, 5), 10);
                        return numericValue > 23;
                    }
                    return false;
                });
            } else if (codePrefix.startsWith('M1413')) {
                matchingStations = pollingStations.filter(station => {
                    if (station.pollingcode.startsWith('M14')) {
                        const numericValue = parseInt(station.pollingcode.slice(3, 5), 10);
                        return numericValue > 12;
                    }
                    return false;
                });
            } else if (codePrefix.startsWith('M1401')) {
                matchingStations = pollingStations.filter(station => {
                    if (station.pollingcode.startsWith('M14')) {
                        const numericValue = parseInt(station.pollingcode.slice(3, 5), 10);
                        return numericValue <= 12;
                    }
                    return false;
                });
            } else if (codePrefix.startsWith('A0801')) {
                matchingStations = pollingStations.filter(station => {
                    if (station.pollingcode.startsWith('A080')) {
                        const numericValue = parseInt(station.pollingcode.slice(4, 6), 10);
                        return numericValue <= 61;
                    }
                    return false;
                });
            }
            else if (codePrefix.startsWith('A0802')) {
                matchingStations = pollingStations.filter(station => {
                    if (station.pollingcode.startsWith('A08')) {
                        const firstCharAfterA08 = station.pollingcode.charAt(3);
                        const numericValue = parseInt(station.pollingcode.slice(4, 6), 10);

                        return numericValue > 61 || firstCharAfterA08 === '1';
                    }
                    return false;
                });
            }
            else {
                const prefix = codePrefix.slice(0, 3);
                matchingStations = pollingStations.filter(station =>
                    station.pollingcode.startsWith(prefix)
                );
            }

            const totalVoteCount = matchingStations.reduce((total, station) => total + station.votercount, 0);

            return {
                totalVoteCount,
                totalRows: matchingStations.length,
            };
        };

        const count = getTotalVoteCount(code, POLLING_STATION)
        setVoterCount(count.totalVoteCount);

        console.log(count.totalVoteCount + " voters")

    }, [code]);

    const handlePollingStationSelect = (pollingStation) => {
        setSelectedPollingStation(pollingStation);
        const { pollingname, pollingcode } = pollingStation;
        setPollingStationCode(pollingcode)

        navigate(`/ps/results/admin/${code}/${pollingcode}`, { state: { cs_name: name, ps_name: pollingname } })

    };

    const filteredPollingStations = pollingStations.filter(station =>
        station.pollingname.toLowerCase().includes(searchQuery.toLowerCase())
    ); // Filter stations based on search query

    const getUniqueParties = (data) => {
        const parties = new Set();
        data.forEach(({ results }) => results.forEach(({ party }) => parties.add(party)));
        return Array.from(parties);
    };



    const getInstitutionsData = (data) => {
        const institutions = {};
        data.forEach((entry) => {
            if (!institutions[entry.sender_institution]) {
                institutions[entry.sender_institution] = { results: {}, totalVotes: 0, image: entry.files[0] };
            }
            entry.results.forEach(({ party, votes }) => {
                institutions[entry.sender_institution].results[party] = (institutions[entry.sender_institution].results[party] || 0) + votes;
                institutions[entry.sender_institution].totalVotes += votes;
            });
        });
        return institutions;
    };

    const toggleModal = (image) => {
        setModalImage(image);
        setModalOpen(!modalOpen);
    };

    const renderTable = (title, data) => {
        if (data.length === 0) {
            return <div className="my-4"><h5 style={{ textTransform: 'capitalize', marginLeft: "10%", color: "salmon" }}>No results yet</h5></div>;
        }

        const uniqueParties = getUniqueParties(data);
        const institutionsData = getInstitutionsData(data);
        const institutions = Object.keys(institutionsData);

        return (
            <div className="my-4" style={{}}>
                <Table borderedless responsive>
                    <thead>
                        {/* Header row for "INSTITUTIONS" label spanning institution columns only */}
                        <tr>
                            <th style={{ border: 'none', borderWidth: 0 }}></th>
                            <td colSpan={institutions.length} style={{
                                color: '#666', backgroundColor: "#eee",
                                fontSize: '1.2em', textAlign: "center", borderTopRightRadius: 5, borderTopLeftRadius: 5
                            }}>
                                INSTITUTIONS
                            </td>
                        </tr>
                        {/* Column headers for Candidates and Institutions */}
                        <tr >
                            <th style={{ color: "#ddd", backgroundColor: '#555', borderTopLeftRadius: 5, paddingLeft: 15 }}>PARTY</th>

                            {institutions.map((institution, index) => (
                                <th onClick={() => navigate("/institution/results", { state: { id: institution } })} key={index} style={{
                                    backgroundColor: "#777", color: '#fff', fontFamily: 'verdana'
                                    , textDecorationLine: 'underline', cursor: 'pointer', borderLeftWidth: 1, borderColor: "#ddd"
                                }}>{institution}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {uniqueParties.map((party, index) => (
                            <tr key={index} style={{ paddingLeft: 10 }}>
                                <td style={{ backgroundColor: "#f5f5f5" }}>{party}</td>
                                {institutions.map((institution, instIndex) => (
                                    <td style={{ backgroundColor: "#f5f5f5", borderLeftWidth: 1 }} key={instIndex}>{institutionsData[institution].results[party] || 0}</td>
                                ))}
                            </tr>
                        ))}
                        <tr style={{ fontWeight: 'bold' }}>
                            <td>Total Votes</td>
                            {institutions.map((institution, index) => (
                                <td sty key={index}>{institutionsData[institution].totalVotes}</td>
                            ))}
                        </tr>
                        <tr>
                            <td>Supporting Documents</td>
                            {institutions.map((institution, index) => (
                                <td key={index}>
                                    {institutionsData[institution].image ? (
                                        <img
                                            src={institutionsData[institution].image}
                                            alt="pink sheet"
                                            style={{ width: '100px', cursor: 'pointer' }}
                                            onClick={() => toggleModal(institutionsData[institution].image)}
                                        />
                                    ) : 'No Image'}
                                </td>
                            ))}
                        </tr>
                    </tbody>
                </Table>
            </div>
        );
    };


    // const renderTable = (title, data) => {
    //     if (data.length === 0) {
    //         return <div className="my-4"><h5 style={{ textTransform: 'capitalize', marginLeft: "10%", color: "salmon" }}>No results yet</h5></div>;
    //     }

    //     const uniqueParties = getUniqueParties(data);
    //     const institutionsData = getInstitutionsData(data);
    //     const institutions = Object.keys(institutionsData);

    //     return (
    //         <div className="my-4">
    //             <Table bordered responsive>
    //                 <thead style={{ backgroundColor: '#007bff', color: '#fff' }}>
    //                     <tr style={{ backgroundColor: COLOR_PRIMARY }}>
    //                         <th style={{ backgroundColor: COLOR_PRIMARY, color: "#fff" }}>Candidates</th>
    //                         {institutions.map((institution, index) => (
    //                             <th key={index} style={{ backgroundColor: COLOR_PRIMARY, color: "#fff" }}>{institution}</th>
    //                         ))}
    //                     </tr>
    //                 </thead>
    //                 <tbody>
    //                     {uniqueParties.map((party, index) => (
    //                         <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#f8f9fa' : '#ffffff' }}>
    //                             <td>{party}</td>
    //                             {institutions.map((institution, instIndex) => (
    //                                 <td key={instIndex}>{institutionsData[institution].results[party] || 0}</td>
    //                             ))}
    //                         </tr>
    //                     ))}
    //                     <tr style={{ fontWeight: 'bold' }}>
    //                         <td>Total Votes</td>
    //                         {institutions.map((institution, index) => (
    //                             <td key={index}>{institutionsData[institution].totalVotes}</td>
    //                         ))}
    //                     </tr>
    //                     <tr>
    //                         <td>Supporting Documents</td>
    //                         {institutions.map((institution, index) => (
    //                             <td key={index}>
    //                                 {institutionsData[institution].image ? (
    //                                     <img
    //                                         src={institutionsData[institution].image}
    //                                         alt="pink sheet"
    //                                         style={{ width: '100px', cursor: 'pointer' }}
    //                                         onClick={() => toggleModal(institutionsData[institution].image)}
    //                                     />
    //                                 ) : 'No Image'}
    //                             </td>
    //                         ))}
    //                     </tr>
    //                 </tbody>
    //             </Table>
    //         </div>
    //     );
    // };

    if (loading) return <Loader />;

    if (!user) {
        return <Navigate to="/login" />;
    }


    return (
        <div className="container" style={{ marginTop: isMobile ? "12%" : "5%" }}>
            <ElectionHeaderAdmin resultType={"constituency"} />
            <Nav variant="tabs" className="custom-tabs" style={{ borderBottom: '0.5px solid #ccc', marginTop: isMobile ? "36%" : "7%", marginLeft: "2%" }}>

                <NavItem>
                    <Dropdown>
                        <Dropdown.Toggle id="polling-station-dropdown" style={{ backgroundColor: '#eee', borderColor: "#eee", color: "#666" }}>
                            {selectedPollingStation ? selectedPollingStation.pollingname : 'Select Polling Station'}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            style={{
                                maxHeight: '500px',
                                overflowY: 'auto',
                                position: 'absolute',
                                backgroundColor: "#eee",
                                width: isMobile ? "100%" : '300px', // Reduced size for the dropdown
                                zIndex: 1000,
                                padding: 5,
                                marginTop: '5px' // Ensures the dropdown appears below the toggle
                            }}>
                            <FormControl
                                type="text"
                                placeholder="Search Polling Station..."
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                style={{ marginBottom: '10px', padding: '5px' }}
                            />
                            {filteredPollingStations.map((station, index) => (
                                <Dropdown.Item
                                    key={index}
                                    onClick={() => handlePollingStationSelect(station)}
                                    style={{ padding: '10px', fontSize: 13 }} // Alternating row colors
                                >
                                    {station.pollingname}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </NavItem>

                <NavItem>
                    {/* <NavLink className='tab-item'> */}
                    <span style={{
                        color: "#555", padding: 15,
                        fontFamily: 'verdana', fontSize: 22, fontWeight: "bold", marginRight: 20
                    }}>{data && (data.constituency_name ?? name)} CONSTITUENCY</span>
                    {/* </NavLink> */}
                </NavItem>
                <NavItem>
                    <NavLink
                        className={`tab-item ${activeTab === 'collated' ? 'active-tab' : ''}`}
                        onClick={() => setActiveTab('collated')}
                        style={{
                            fontWeight: activeTab === 'collated' ? 'bold' : 'normal',
                            backgroundColor: activeTab === 'collated' ? '#007bff' : '#f8f9fa',
                            color: activeTab === 'collated' ? '#ffffff' : '#007bff',
                            borderRadius: '5px 5px 0 0',
                            padding: '10px 20px'
                        }}
                    >
                        COLLATED PS RESULTS
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={`tab-item ${activeTab === 'presidential' ? 'active-tab' : ''}`}
                        onClick={() => setActiveTab('presidential')}
                        style={{
                            fontWeight: activeTab === 'presidential' ? 'bold' : 'normal',
                            backgroundColor: activeTab === 'presidential' ? '#007bff' : '#f8f9fa',
                            color: activeTab === 'presidential' ? '#ffffff' : '#007bff',
                            borderRadius: '5px 5px 0 0',
                            padding: '10px 20px'
                        }}
                    >
                        PRESIDENTIAL RESULTS
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={`tab-item ${activeTab === 'parliamentary' ? 'active-tab' : ''}`}
                        onClick={() => setActiveTab('parliamentary')}
                        style={{
                            fontWeight: activeTab === 'parliamentary' ? 'bold' : 'normal',
                            backgroundColor: activeTab === 'parliamentary' ? '#007bff' : '#f8f9fa',
                            color: activeTab === 'parliamentary' ? '#ffffff' : '#007bff',
                            borderRadius: '5px 5px 0 0',
                            padding: '10px 20px'
                        }}
                    >
                        PARLIAMENTARY RESULTS
                    </NavLink>
                </NavItem>
                {/* <NavItem>
                    <NavLink
                        className={`tab-item ${activeTab === 'incidents' ? 'active-tab' : ''}`}
                        onClick={() => setActiveTab('incidents')}
                        style={{
                            fontWeight: activeTab === 'incidents' ? 'bold' : 'normal',
                            backgroundColor: activeTab === 'incidents' ? '#007bff' : '#f8f9fa',
                            color: activeTab === 'incidents' ? '#ffffff' : '#007bff',
                            borderRadius: '5px 5px 0 0',
                            padding: '10px 20px'
                        }}
                    >
                        INCIDENT REPORTS
                    </NavLink>
                </NavItem> */}
            </Nav>

            <div className="tab-content">
                {activeTab === "collated" && <ConstituencyResult code_={code} collated="yes" />}
                {activeTab === 'presidential' && renderTable('presidential', data.presidential)}
                {activeTab === 'parliamentary' && renderTable('parliamentary', data.parliamentary)}
                {/* {activeTab === 'incidents' && <PostListAdmin code={code} />} */}
            </div>

            {modalOpen && (
                <Modal show={modalOpen} onHide={() => setModalOpen(false)}>
                    <ModalBody>
                        <img
                            src={modalImage}
                            alt="Supporting document"
                            className="img-fluid"
                            style={{ maxHeight: '80vh', objectFit: 'contain' }}
                        />
                    </ModalBody>
                </Modal>
            )}
        </div>
    );
};

export default Constituency_Admin;
