import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    Navbar, Nav, NavItem, NavLink, Button, Dropdown, DropdownButton,
    Alert, Container
} from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { MAIN_URL } from '../Constants';
import { useAuth } from '../contexts/AuthContext';
import Home from './Home';
import ElectionHeader from '../ElectionHeader';
import { useNavigate } from 'react-router-dom';
import PostListMain from '../PostListAdmin';
import PostListAdmin from '../PostListAdmin';
import UserList from './UserList';
import ElectionHeaderAdmin from '../ElectionHeaderAdmin';
import HeaderMain from '../HeaderMain';
import AudioFiles from '../AudioFiles';
import UserManagement from '../UserManagement';

const AdminDashboard = () => {
    const [users_, setUsers_] = useState([]);
    const [selectedUserType, setSelectedUserType] = useState({});
    const [message, setMessage] = useState(null);
    const { user, signOut } = useAuth();
    const token = user?.accessToken;

    const [filteredUsers, setFilteredUsers] = useState([]);


    const [activeTab, setActiveTab] = useState("incidentReports");
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const { navigate } = useNavigate()
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get(`${MAIN_URL}media/watch/users`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setUsers_(response.data);
                console.log(JSON.stringify(users_))
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        if (token) {
            fetchUsers();
        }
    }, [token]);

    const handleSearch = async (event) => {
        const query = event.target.value;
        setSearchQuery(query);
        console.log("Entered: " + query)

        if (query.length >= 3) {
            try {
                const response = await axios.get(`${MAIN_URL}election-results/search/${query}`);
                // console.log("Response: "+JSON.stringify(response.da)) 
                setSearchResults(response.data);
            } catch (error) {
                console.error('Error fetching search results', error);
            }
        } else {
            setSearchResults([]);
        }
    };
    const handleUserTypeChange = (userId, newUserType) => {
        setSelectedUserType(prevState => ({
            ...prevState,
            [userId]: newUserType,
        }));
    };

    const saveUserTypeChange = async (userId) => {
        const newUserType = selectedUserType[userId];
        try {
            await axios.put(
                `${MAIN_URL}media/watch/user-type/${userId}`,
                { user_type: newUserType },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            setUsers_(users_.map(user =>
                user._id === userId ? { ...user, user_type: newUserType } : user
            ));
            setSelectedUserType(prevState => {
                const updatedState = { ...prevState };
                delete updatedState[userId];
                return updatedState;
            });
            setMessage({ type: 'success', text: 'User type updated successfully!' });
        } catch (error) {
            console.error('Error updating user type:', error);
            setMessage({ type: 'error', text: 'Failed to update user type.' });
        }
    };

    const getUserTypeColor = (type) => {
        switch (type) {
            case 'admin': return 'danger';
            case 'agent': return 'warning';
            case 'normal': return 'info';
            default: return 'secondary';
        }
    };

    const columns = [
        { name: 'Email', selector: row => row.email, sortable: true },
        { name: 'Username', selector: row => row.username, sortable: true },
        { name: 'Phone Number', selector: row => row.phone_number },
        { name: 'Institution', selector: row => row.institution || 'N/A' },
        { name: 'Emp ID', selector: row => row.emp_id || 'N/A' },
        {
            name: 'User Type',
            cell: row => (
                <DropdownButton
                    variant={getUserTypeColor(selectedUserType[row._id] || row.user_type)}
                    id={`dropdown-${row._id}`}
                    title={selectedUserType[row._id] || row.user_type}
                    onSelect={(value) => handleUserTypeChange(row._id, value)}
                >
                    <Dropdown.Item eventKey="normal">Normal</Dropdown.Item>
                    <Dropdown.Item eventKey="agent">Agent</Dropdown.Item>
                    <Dropdown.Item eventKey="admin">Admin</Dropdown.Item>
                </DropdownButton>
            ),
        },
        {
            name: 'Actions',
            cell: row => (
                selectedUserType[row._id] && selectedUserType[row._id] !== row.user_type ? (
                    <Button variant="primary" size="sm" onClick={() => saveUserTypeChange(row._id)}>
                        Save
                    </Button>
                ) : null
            ),
        }
    ];

    return (
        <div className="container mt-5" style={{ paddingTop: '3%' }}>
            <HeaderMain
                searchQuery={searchQuery}
                searchResults={searchResults}
                type="national"
                // navigate={navigate}
                setSearchQuery={setSearchQuery}
                handleSearch={handleSearch}
                userType={"admin"}
            />
            <div bg="light" expand="lg" className="mb-0" style={{
                backgroundColor: 'white',
                display: 'flex', flexDirection: 'column',
                height: '13%',
                // paddingTop: 10,
                top: "1%",
                paddingLeft: "6%",
                zIndex: 30
            }}>
                {/* <Navbar bg="light" expand="lg" className="mb-0 fixed-top" style={{ backgroundColor: 'white' }}>
                   
                    
                </Navbar> */}


                <Nav className="mt-3">  {/* Add the `mt-3` class for top margin */}
                    <NavItem>
                        <NavLink active={activeTab === "incidentReports"} onClick={() => setActiveTab("incidentReports")}>
                            Incident Reports
                        </NavLink>
                    </NavItem>
                    {/* <NavItem>
                        <NavLink active={activeTab === "audioFiles"} onClick={() => setActiveTab("audioFiles")}>
                            Audios
                        </NavLink>
                    </NavItem> */}

                    {/* <NavItem>
                        <NavLink active={activeTab === "electionResults"} onClick={() => setActiveTab("electionResults")}>
                            Results
                        </NavLink>
                    </NavItem> */}

                    <NavItem>
                        <NavLink active={activeTab === "userManagement"} onClick={() => setActiveTab("userManagement")}>
                            User Management
                        </NavLink>
                    </NavItem>


                </Nav>

            </div>

            <div className="mt-4">
                {activeTab === "electionResults" && <Home type={"admin"} />}
                {activeTab === "userManagement" && (
                    // <UserList />
                    <UserManagement />
                )}
                {activeTab === "incidentReports" && <PostListAdmin />}
                {activeTab === "audioFiles" && <AudioFiles />}

                {activeTab === "settings" && <p>Admin settings options can be managed here.</p>}
            </div>

            <style jsx>{`
                .nav-link {
                    text-transform: uppercase;
                    font-weight: bold;
                    color: #555;
                }
                .nav-link.active {
                    background-color: rgb(0, 123, 255) !important; 
                    border-top-right-radius: 5px; 
                    border-top-left-radius: 5px;    
                    color: white !important;
                }
 `}</style>
        </div>
    );
};

export default AdminDashboard;
