import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { COLOR_PRIMARY, MAIN_URL } from './Constants';
import { Nav, NavItem, NavLink, Modal, ModalBody, Table, Dropdown, FormControl } from 'react-bootstrap'; // Import Dropdown and FormControl
import ElectionHeaderAdmin from './ElectionHeaderAdmin';
import useResponsive from './useResponsive';
import PostListAdmin from './PostListAdmin';
import { CONSTITUENCIES } from './ConstituencyList';
import Table_RegionalAdmin from './Table_RegionalAdmin';
import { useNavigate } from 'react-router-dom';
import Loader from './Loader';
import { useAuth } from './contexts/AuthContext';
import RegionDetails from './pages/RegionDetails';


const Regional_Admin = () => {
    const { code, region } = useParams();
    const { user } = useAuth();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeTab, setActiveTab] = useState('presidential');
    const [modalOpen, setModalOpen] = useState(false);
    const [modalImage, setModalImage] = useState(null);
    const isMobile = useResponsive();
    const [constituencies, setConstituencies] = useState([]);
    const [selectedConstituency, setSelectedConstituency] = useState(null);
    const [searchQuery, setSearchQuery] = useState(""); // State for search query
    const navigate = useNavigate()

    // window.location.reload()


    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                setSelectedConstituency(null)
                setData(null)

                const cs = CONSTITUENCIES.filter(c => c.region === region);

                setConstituencies(cs);
                console.log(JSON.stringify(cs));

                const response = await axios.get(`${MAIN_URL}region/result/admin/${region}`);
                console.log(JSON.stringify(response.data))
                setData(response.data);
            } catch (err) {
                setError('Error loading election results');
            } finally {
                setLoading(false);
            }
        };

        if (region) fetchData();
    }, [region]);

    const handleSelectedConstituency = (cs) => {
        setSelectedConstituency(cs);
    };

    const filteredConstituencies = constituencies.filter(cs =>
        cs.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        cs.code.toLowerCase().includes(searchQuery.toLowerCase())
    ); // Filter constituencies by name or code



    if (loading) return <Loader />;

    if (!user) {
        return <Navigate to="/login" />;
    }


    return (
        <div className="container">
            <ElectionHeaderAdmin resultType={"regional"} />
            <Nav variant="tabs" className="custom-tabs" style={{ borderBottom: '0.5px solid #ccc', marginTop: isMobile ? "36%" : "7%", marginLeft: "2%" }}>

                <NavItem>
                    <Dropdown>
                        <Dropdown.Toggle id="cs-station-dropdown" style={{ backgroundColor: '#eee', borderColor: "#eee", color: "#666" }}>
                            {selectedConstituency ? selectedConstituency.name : 'Select Constituency'}
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                            style={{
                                maxHeight: '500px',
                                overflowY: 'auto',
                                position: 'absolute',
                                backgroundColor: "#eee",
                                width: isMobile ? "100%" : '300px', // Reduced size for the dropdown
                                zIndex: 1000,
                                padding: 5,
                                marginTop: '5px' // Ensures the dropdown appears below the toggle
                            }}>
                            <FormControl
                                type="text"
                                placeholder="Search Constituency..."
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                style={{ marginBottom: '10px', padding: '5px' }}
                            />
                            {filteredConstituencies.map((cs, index) => (
                                <Dropdown.Item
                                    key={index}
                                    onClick={() => {
                                        handleSelectedConstituency(cs)
                                        navigate(`/constituency/results/admin/${cs.code}/${cs.name}?reload=${new Date().getTime()}`, { state: { name: cs.name } });

                                    }}
                                    style={{ padding: '10px', fontSize: 13 }}

                                >
                                    {cs.name}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                </NavItem>

                <NavItem>
                    {/* <NavLink className='tab-item'> */}
                    <span style={{
                        color: "#555", padding: 15,
                        fontFamily: 'verdana', fontSize: 22, fontWeight: "bold", marginRight: 20
                    }}>{region} REGION</span>
                    {/* </NavLink> */}
                </NavItem>
                <NavItem>
                    <NavLink
                        className={`tab-item ${activeTab === 'collated' ? 'active-tab' : ''}`}
                        onClick={() => setActiveTab('collated')}
                        style={{
                            fontWeight: activeTab === 'collated' ? 'bold' : 'normal',
                            backgroundColor: activeTab === 'collated' ? '#007bff' : '#f8f9fa',
                            color: activeTab === 'collated' ? '#ffffff' : '#007bff',
                            borderRadius: '5px 5px 0 0',
                            padding: '10px 20px'
                        }}
                    >
                        COLLATED RESULTS
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={`tab-item ${activeTab === 'presidential' ? 'active-tab' : ''}`}
                        onClick={() => setActiveTab('presidential')}
                        style={{
                            fontWeight: activeTab === 'presidential' ? 'bold' : 'normal',
                            backgroundColor: activeTab === 'presidential' ? '#007bff' : '#f8f9fa',
                            color: activeTab === 'presidential' ? '#ffffff' : '#007bff',
                            borderRadius: '5px 5px 0 0',
                            padding: '10px 20px'
                        }}
                    >
                        PRESIDENTIAL RESULTS
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={`tab-item ${activeTab === 'parliamentary' ? 'active-tab' : ''}`}
                        onClick={() => setActiveTab('parliamentary')}
                        style={{
                            fontWeight: activeTab === 'parliamentary' ? 'bold' : 'normal',
                            backgroundColor: activeTab === 'parliamentary' ? '#007bff' : '#f8f9fa',
                            color: activeTab === 'parliamentary' ? '#ffffff' : '#007bff',
                            borderRadius: '5px 5px 0 0',
                            padding: '10px 20px'
                        }}
                    >
                        PARLIAMENTARY RESULTS
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={`tab-item ${activeTab === 'incidents' ? 'active-tab' : ''}`}
                        onClick={() => setActiveTab('incidents')}
                        style={{
                            fontWeight: activeTab === 'incidents' ? 'bold' : 'normal',
                            backgroundColor: activeTab === 'incidents' ? '#007bff' : '#f8f9fa',
                            color: activeTab === 'incidents' ? '#ffffff' : '#007bff',
                            borderRadius: '5px 5px 0 0',
                            padding: '10px 20px'
                        }}
                    >
                        INCIDENT REPORTS
                    </NavLink>
                </NavItem>
            </Nav>

            <div className="tab-content">
                {activeTab === 'presidential' && (data && <Table_RegionalAdmin data={data.data} />)}
                {/* {activeTab === 'parliamentary' && renderTable('parliamentary', data.parliamentary)} */}
                {activeTab === 'incidents' && <PostListAdmin code={code} _name={region} type={'region'} />}
                {activeTab === "collated" && <RegionDetails name_={region} />}

            </div>

            {modalOpen && (
                <Modal show={modalOpen} onHide={() => setModalOpen(false)}>
                    <ModalBody>
                        <img
                            src={modalImage}
                            alt="Supporting document"
                            className="img-fluid"
                            style={{ maxHeight: '80vh', objectFit: 'contain' }}
                        />
                    </ModalBody>
                </Modal>
            )}
        </div>
    );
};

export default Regional_Admin;
