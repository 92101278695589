import { useAuth } from '../contexts/AuthContext';
import { Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Card } from '@mui/material';
import '../App.css';
import { COLOR_PRIMARY, COLOR_SECONDARY, MAIN_URL, PRESIDENTIAL_CANDIDATES, REGISTERED_VOTERS, fetchSeatsData } from '../Constants';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Assuming React Router for navigation
import 'bootstrap/dist/css/bootstrap.min.css';
import HeaderVoteCounts from '../HeaderVoteCounts';
import ElectionHeader from '../ElectionHeader';
import PostList from '../PostList';
import { io } from 'socket.io-client';
import ElectionCharts from '../ElectionCharts';
import { Nav, NavItem, NavLink } from 'react-bootstrap';
import GhanaMapSvg from '../GhanaMapSvg';
import SeatsWon from '../SeatsWon';
import useResponsive from '../useResponsive';
import AdminDashboard from './AdminDashboard';
import Admin_National from './Admin_National';
import Admin_Incidents from './Admin_Incidents';

const AdminPage = () => {
    const { user } = useAuth();
    // const { user_type } = user && user?.user;
    const [presidentialData, setPresidentialData] = useState(null);
    const [pollingStations, setPollingStations] = useState(0);
    const [validVotes, setValidVotes] = useState(0);
    const [rejectedVotes, setRejectedVotes] = useState(0);
    const [regionalData, setRegionalData] = useState([])

    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [activeTab, setActiveTab] = useState('map');
    const [activeTabMain, setActiveTabMain] = useState('presidential');

    const isMobile = useResponsive();

    const [seatsData, setSeatsData] = useState({})


    useEffect(() => {
        // Create an asynchronous function to fetch data
        const fetchData = async () => {
            try {
                // Fetch initial election data
                const response = await axios.get(`${MAIN_URL}election-results-m/national`);
                const allData = response.data;
                const { number_of_polling_stations, total_rejected_ballots, total_valid_votes } = allData;

                setValidVotes(total_valid_votes);
                setRejectedVotes(total_rejected_ballots);
                setPollingStations(number_of_polling_stations);

                const sortedResults = allData.results.sort((a, b) => b.votes - a.votes);
                setPresidentialData(sortedResults);

                // Fetch regional data
                const regionalResponse = await axios.get(`${MAIN_URL}election-results-m/regional`);
                setRegionalData(regionalResponse.data);

                // Fetch seats data
                const seats = await fetchSeatsData("");
                setSeatsData(seats);

            } catch (err) {
                console.error("Error fetching data: ", err.message);
            }

            // WebSocket connection
            const socket = io(MAIN_URL);
            socket.on('connect', () => console.log('WebSocket connected'));
            socket.on('disconnect', () => console.log('WebSocket disconnected'));

            socket.on('update-data', async (response) => {
                const { number_of_polling_stations, total_rejected_ballots, total_valid_votes } = response;
                setValidVotes(total_valid_votes);
                setRejectedVotes(total_rejected_ballots);
                setPollingStations(number_of_polling_stations);

                const sortedResults = response.results.sort((a, b) => b.votes - a.votes);
                setPresidentialData(sortedResults);

                // Fetch regional data on update
                const regionalResponse = await axios.get(`${MAIN_URL}election-results-m/regional`);
                setRegionalData(regionalResponse.data);
            });

            console.log("User: " + JSON.stringify(user))
            return () => {
                socket.disconnect();
            };
        };

        fetchData(); // Call the async function
    }, []);


    if (!user) {
        return <Navigate to="/login" />;
    }




    return (
        <div style={{ marginTop: "1%" }}>

            {
                // <Admin_National />
                // <Admin_Incidents />
            }
            {
                <AdminDashboard />
            }





        </div>
    );
};

export default AdminPage;
