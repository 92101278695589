import { useAuth } from '../contexts/AuthContext';
import { Navigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Card } from '@mui/material';
import '../App.css';
import { COLOR_PRIMARY, COLOR_SECONDARY, fetchSeatsData, MAIN_URL, PRESIDENTIAL_CANDIDATES } from '../Constants';
import axios from 'axios';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import ImageIcon from '@mui/icons-material/Image';
import { useNavigate, useLocation } from 'react-router-dom'; // Assuming React Router for navigation
import moment from 'moment';
import GhanaMapSvg from '../GhanaMapSvg';
import { ArrowRight, CodeOutlined, Icecream, More } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TablePollingStationResult from '../TablePollingStationResults';
import ElectionHeader from '../ElectionHeader';
import HeaderVoteCounts from '../HeaderVoteCounts';
import { Nav, NavItem, NavLink } from 'react-bootstrap';
import ElectionCharts from '../ElectionCharts';
import useResponsive from '../useResponsive';
import PostList from '../PostList';
import PostListAdmin from '../PostListAdmin';
// import Constituency_bounds from '../Constituency_bounds';




const ConstituencyResult = ({ userType }) => {
    const { user } = useAuth();
    // const { user_type } = user?.user;
    const [presidentialData, setPresidentialData] = useState(null);
    const [parliamentaryData, setParliamentaryData] = useState(null)
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [constituencyName, setConstituencyName] = useState("");



    const location = useLocation();
    const { state } = location;
    const { name } = state ?? "";

    console.log("Location " + JSON.stringify(location))


    const [pollingStations, setPollingStations] = useState(0);
    const [validVotes, setValidVotes] = useState(0);
    const [rejectedVotes, setRejectedVotes] = useState(0);
    const [posts, setPosts] = useState([]);
    const [selectedPosts, setSelectedPosts] = useState([]);
    const [regionalData, setRegionalData] = useState([])

    const [activeTab, setActiveTab] = useState('map');
    const [activeTabMain, setActiveTabMain] = useState('presidential');
    const isMobile = useResponsive()

    const [all, setAll] = useState([])

    const navigate = useNavigate();
    const [seatsData, setSeatsData] = useState({})


    const { code } = useParams();
    const [results, setResults] = useState(null);


    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(false);


    // Fetching data from the API
    useEffect(() => {


        axios.get(`${MAIN_URL}load/posts/main`)
            .then(response => {
                // console.log(JSON.stringify(response.data))
                setPosts(response.data)
            })

        const fetchElectionResults = async (code) => {
            try {
                const response = await axios.get(`${MAIN_URL}election-results/constituency/${code}`);

                // Process the response here (e.g., save to state, console log, etc.)
                const allData = response.data;
                const { number_of_polling_stations, total_rejected_ballots,
                    total_valid_votes } = allData.presidential;
                const { constituency_code, constituency_name } = response.data;
                setValidVotes(total_valid_votes);
                setRejectedVotes(total_rejected_ballots);
                setPollingStations(number_of_polling_stations)
                let res = response.data.presidential.results.sort((a, b) => b.votes - a.votes)

                setPresidentialData(res);
                // setPresidentialData(response.data.presidential.results);
                setConstituencyName(constituency_name)

                let parl_res = response.data.parliamentary.results.sort((a, b) => b.votes - a.votes)

                setParliamentaryData(parl_res)
                console.log('Parl Results:', JSON.stringify(parl_res));

                setAll(response.data)

                const seats_ = await fetchSeatsData("");
                setSeatsData(seats_);
                // return response.data; // Return data to use elsewhere
            } catch (error) {
                // Handle error case
                console.error('Error fetching election results:', error);
                return null;
            }
        };

        fetchElectionResults(code)
    }, []);



    const handleTabClick = (tab) => {
        setActiveTabMain(tab);
    };
    const tabClick = (tab) => setActiveTab(tab);

    const handleFileClick = (incidentId) => {
        navigate(`/post/incident/${incidentId}`);
    };
    const handleSearch = async (event) => {
        const query = event.target.value;
        setSearchQuery(query);

        // Perform search only when query length is at least 3 characters
        if (query.length >= 3) {
            try {
                const response = await axios.get(`${MAIN_URL}election-results/search/${query}`);
                setSearchResults(response.data);
            } catch (error) {
                console.error('Error fetching search results', error);
            }
        } else {
            // Clear search results if query length is less than 3
            setSearchResults([]);
        }
    };


    // Predefined colors for each party
    const partyColors = {
        NPP: '#0047ab',
        NDC: 'green',
        GCPP: '#228b22',
        GFP: '#32CD32',
        GUM: '#ff4500',
        LPG: '#800080',
        NDP: '#FFD700',
        CPP: '#ff6455',
        APC: '#ff6347',
        IND1: '#808080',
        IND2: '#808080',
        IND3: '#000080',
        IND4: '#8b0000',
    };

    // Function to get candidate image by party name
    const getCandidateImage = (partyName) => {
        const candidate = PRESIDENTIAL_CANDIDATES.find(c => c.partyName === partyName);
        return candidate ? candidate.imageOfCandidate : null;
    };

    const getPartyFlag = (partyName) => {
        const candidate = PRESIDENTIAL_CANDIDATES.find(c => c.partyName === partyName);
        return candidate ? candidate.partyImage : null;
    };

    // Function to get party color
    const getPartyColor = (partyName) => {
        return partyColors[partyName] || '#000000'; // Default to black if no color is found
    };

    // Calculate total votes for percentage calculation
    const totalVotes = presidentialData ? presidentialData.reduce((sum, result) => sum + result.votes, 0) : 0;

    const barHeight = 40;
    const barSpacing = 30;
    const svgWidth = 600;
    const svgHeight = presidentialData ? (barHeight + barSpacing) * presidentialData.length : 0;

    // alert(svgHeight)
    const renderParliamentary = () => {
        return (
            <div style={{ padding: 10, flexDirection: "column", display: 'flex' }}>

                <Card style={{ padding: 10, display: 'flex', justifyContent: 'center' }}>
                    {parliamentaryData ? (
                        <svg width={svgWidth} height={svgHeight / 2}>
                            {parliamentaryData.map((result, index) => {
                                const percentage = parseFloat(result.percentage.replace("%", ""));

                                const barWidth = (percentage / 100) * svgWidth;
                                const barHeight = 30; // Adjust bar height if needed
                                const barSpacing = 60; // Adjust bar spacing to control vertical distance between bars
                                const yPosition = index * (barHeight + barSpacing);
                                const partyColor = getPartyColor(result.party);
                                const candidateImage = "/mp.png"
                                const partyFlag = getPartyFlag(result.party);

                                return (
                                    <g key={result.party}>

                                        <image
                                            href={"/mp.png"}
                                            x="5"
                                            y={yPosition + 1}
                                            width={55}
                                            height="55"
                                        />

                                        <text
                                            x="65"
                                            y={yPosition + (barHeight / 2)}
                                            alignmentBaseline="middle"
                                            fontSize="16"
                                            fill="#aaa"
                                        >
                                            {result.party}
                                        </text>
                                        <text
                                            x="10"
                                            y={yPosition + (barHeight / 0.42)}
                                            alignmentBaseline="middle"
                                            fontSize="15"
                                            fill="#333"
                                        >
                                            {result.candidate}
                                        </text>

                                        {/* Party Flag */}
                                        <image
                                            x="65"
                                            y={yPosition + (barHeight / 1.4)}
                                            href={partyFlag}
                                            width="35"
                                            height="30"
                                        />

                                        {/* Bar */}
                                        <rect
                                            x="150"
                                            y={yPosition}
                                            width={barWidth}
                                            height={barHeight}
                                            fill={partyColor}
                                        />

                                        {/* Percentage Text */}
                                        <text
                                            x={150 + barWidth + 10}
                                            y={yPosition + (barHeight / 2)}
                                            alignmentBaseline="middle"
                                            fontSize="15"
                                            fill="#555"
                                            fontWeight={'bold'}
                                        >
                                            {result.percentage}
                                        </text>

                                        {/* Votes Text */}
                                        <text
                                            x="150"
                                            y={yPosition + barHeight + 15}
                                            fontSize="15"
                                            fill="#777"
                                        >
                                            {result.votes.toLocaleString()}
                                        </text>
                                    </g>
                                );
                            })}
                        </svg>
                    ) : (
                        <p style={{ marginLeft: 10 }}>Loading results...</p>
                    )}
                </Card>


            </div>
        )
    }

    return (
        <div className="admin-page">
            <div className="left-column">

                <ElectionHeader
                    searchQuery={searchQuery} searchResults={searchResults}
                    type="parliamentary"
                    navigate={navigate}
                    setSearchQuery={setSearchQuery}
                    handleSearch={handleSearch}
                    userType={userType}
                />

                <Nav variant="tabs" justify className="mb-3 text-center" style={{ marginTop: isMobile ? 80 : 25, marginBottom: 25, marginRight: 10 }}>
                    <span style={{
                        padding: 10, marginLeft: '20%', color: "#555",
                        fontFamily: 'verdana', fontSize: 22, fontWeight: "bold", marginRight: 20
                    }}>{name} CONSTITUENCY</span>

                    <NavItem>

                        <NavLink
                            eventKey="presidential"
                            active={activeTabMain === 'presidential'}
                            onClick={() => handleTabClick('presidential')}
                            style={{
                                backgroundColor: activeTabMain === 'presidential' ? '#007bff' : '#fff',
                                color: activeTabMain === 'presidential' ? 'white' : '#000',
                                // borderColor: activeTabMain === 'presidential' ? '#0056b3' : '#dee2e6',
                                borderTopLeftRadius: '15px',
                                borderTopRightRadius: '0px',
                                padding: '10px 15px',
                                transition: 'background-color 0.3s ease',
                                cursor: 'pointer',
                                fontWeight: activeTabMain === 'presidential' ? 'bold' : 'normal',
                                boxShadow: activeTabMain === 'presidential' ? '0 4px 8px rgba(0, 123, 255, 0.2)' : 'none',
                                margin: '0 5px'
                            }}
                        >
                            PRESIDENTIAL
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            eventKey="parliamentary"
                            active={activeTabMain === 'parliamentary'}
                            onClick={() => handleTabClick('parliamentary')}
                            style={{
                                backgroundColor: activeTabMain === 'parliamentary' ? '#007bff' : '#fff',
                                color: activeTabMain === 'parliamentary' ? 'white' : '#000',
                                // borderColor: activeTabMain === 'parliamentary' ? '#007bff' : '#dee2e6',
                                borderTopRightRadius: '15px',
                                borderTopLeftRadius: '0px',
                                padding: '10px 15px',
                                transition: 'background-color 0.3s ease',
                                cursor: 'pointer',
                                fontWeight: activeTabMain === 'parliamentary' ? 'bold' : 'normal',
                                boxShadow: activeTabMain === 'parliamentary' ? '0 4px 8px rgba(0, 123, 255, 0.2)' : 'none',
                                margin: '0 5px' // Add margin to space out the tabs
                            }}
                        >
                            PARLIAMENTARY
                        </NavLink>
                    </NavItem>
                </Nav>

                <div style={{ padding: 10, flexDirection: "column", display: 'flex' }}>
                    <HeaderVoteCounts counted={(validVotes + rejectedVotes).toLocaleString()}
                        registered={"N/A"} ps={pollingStations.toLocaleString()}
                        valid={validVotes.toLocaleString()}
                        rejected={rejectedVotes.toLocaleString()}
                        seats={seatsData} />

                    {


                        <div style={{ padding: 10, flexDirection: "column", display: 'flex' }}>

                            {presidentialData && (
                                <div style={{ marginLeft: "0%", display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ margin: 15, width: isMobile && "100%", flex: 2, display: 'flex' }}>
                                        {activeTabMain === "presidential" ? <ElectionCharts data={presidentialData} candidates={PRESIDENTIAL_CANDIDATES} />
                                            : renderParliamentary()}
                                    </div>

                                    <div style={{ display: 'flex', flex: 1, marginRight: "5%" }}>
                                        {<PostList />}</div>
                                </div>
                            )}
                        </div>
                    }

                    {/* {

                        presidentialData && <ElectionCharts data={presidentialData} candidates={PRESIDENTIAL_CANDIDATES} />

                    } */}


                    {/* {regionalData && <GhanaMapSvg data={regionalData} />} */}
                </div>

                {all && (
                    <TablePollingStationResult resultsData={all.polling_stations} />
                )}

            </div>



        </div>
    );
};

export default ConstituencyResult;
