import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { MAIN_URL } from './Constants';

const AudioFiles = () => {
    const [audios, setAudios] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchAudios = async () => {
            try {
                const response = await axios.get(MAIN_URL + 'incidents/audios/list');
                setAudios(response.data);
                setLoading(false);
            } catch (err) {
                console.error(err);
                setError('Failed to fetch audios');
                setLoading(false);
            }
        };

        fetchAudios();
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p style={{ color: 'red' }}>{error}</p>;

    return (
        <div>
            {audios.map((audio) => (
                <div key={audio._id} style={{ marginBottom: '20px', padding: '10px', border: '1px solid #ccc' }}>
                    <audio controls preload="auto">
                        <source src={audio.file} type="audio/mpeg" />
                        Your browser does not support the audio element.
                    </audio>
                    <p><strong>Location:</strong> {audio.location}</p>
                    <p><strong>Date Recorded:</strong> {new Date(audio.date_recorded).toLocaleString()}</p>
                    <p><strong>Sender:</strong> {audio.sender_name} ({audio.sender_email})</p>
                </div>
            ))}
        </div>
    );
};

export default AudioFiles;
