import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import { IconButton } from "@mui/material";
import { LocationOn, Share } from "@mui/icons-material";
import { Facebook, Twitter, WhatsApp, LinkedIn } from "@mui/icons-material"; // Import social media icons
import QRCode from "react-qr-code";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import { MAIN_URL } from "../Constants";
import { ShareIcon } from "lucide-react";
import { FaShare } from "react-icons/fa";

function IncidentPage({ post1, id }) {
    let { incident_id } = useParams();
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [itemLink, setItemLink] = useState(MAIN_URL + "post/incident/" + incident_id);
    const [qrExpanded, setQrExpanded] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);

    useEffect(() => {
        if (post1) {
            setPost(post1);
        }



        if (incident_id) {

            console.log(itemLink)
            axios
                .get(`${MAIN_URL}incident/${incident_id}`)
                .then((response) => {
                    setPost(response.data);
                    setItemLink(`${MAIN_URL}incident/${incident_id}`);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error("Failed to load post", error);
                    setError("Failed to load post");
                    setLoading(false);
                });
        } else {
            console.warn("Invalid incident ID");
        }
    }, [id, post1]);

    function openLocation() {
        const { latitude, longitude } = post?.files[0];
        const url = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
        window.open(url, "_blank");
    }

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>{error}</div>;
    }

    return (
        <Container
            fluid
            style={{
                padding: "10px",
                // backgroundColor: "black",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                color: "white",
            }}
        >
            {post && (
                <Row
                    style={{
                        width: "100%",
                        maxWidth: "600px",
                        margin: "0 auto",
                        position: "relative",
                        backgroundColor: "white",
                        borderRadius: "8px",
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                        color: "black",
                    }}
                >
                    {post.files.map((file) => (
                        <Col key={file._id} style={{ padding: "10px", position: "relative" }}>
                            {file.fileType.startsWith("image") && (
                                <img
                                    src={file.url}
                                    alt="file"
                                    style={{
                                        width: "100%",
                                        height: "auto",
                                        borderRadius: "8px",
                                    }}
                                />
                            )}
                            {file.fileType.startsWith("video") && (
                                <ReactPlayer
                                    url={file.url}
                                    controls
                                    width="100%"
                                    height="auto"
                                    style={{
                                        borderRadius: "8px",
                                    }}
                                />
                            )}

                            {/* Share and Locate Icons */}
                            <div
                                style={{
                                    position: "absolute",
                                    bottom: "160px",
                                    right: "30px",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    gap: "10px",
                                }}
                            >
                                <IconButton
                                    onClick={openLocation}
                                    style={{
                                        fontSize: "24px",
                                        color: "white",
                                        textShadow: "1px 1px 3px black",
                                    }}
                                >
                                    <LocationOn />
                                </IconButton>
                                <IconButton
                                    onClick={() => setShowShareModal(true)}
                                    style={{
                                        fontSize: "24px",
                                        marginTop: 20,
                                        color: "white",
                                        textShadow: "1px 1px 3px black",
                                    }}
                                >
                                    <FaShare />
                                </IconButton>
                            </div>

                            {/* QR Code */}
                            <div
                                onClick={() => setQrExpanded(!qrExpanded)}
                                style={{
                                    position: qrExpanded ? "fixed" : "absolute",
                                    top: qrExpanded ? "50%" : "10px",
                                    right: qrExpanded ? "50%" : "10px",
                                    transform: qrExpanded ? "translate(50%, -50%)" : "none",
                                    cursor: "pointer",
                                    backgroundColor: "white",
                                    padding: "5px",
                                    borderRadius: "8px",
                                    zIndex: 1000,
                                    boxShadow: qrExpanded
                                        ? "0 10px 20px rgba(0,0,0,0.5)"
                                        : "0 4px 6px rgba(0,0,0,0.3)",
                                }}
                            >
                                <QRCode size={qrExpanded ? 200 : 50} value={itemLink} />
                            </div>

                            {/* Bottom-Right Address and Date */}
                            <div
                                style={{
                                    position: "absolute",
                                    bottom: "85px",
                                    right: "20px",
                                    color: "white",
                                    textShadow: "2px 2px 5px black",
                                    zIndex: 10,
                                    fontSize: 16,
                                    padding: "5px",
                                    borderRadius: "5px",
                                }}
                            >
                                <p style={{ margin: 0 }}>{post.address}</p>
                                <p style={{ margin: 0 }}>{post.files[0].dateTime}</p>
                            </div>

                            {/* Bottom-Center Description */}
                            <div
                                style={{
                                    position: "absolute",
                                    bottom: 10,
                                    left: "53%",
                                    transform: "translateX(-50%)",
                                    width: "100%",
                                    color: "white",
                                    textShadow: "2px 2px 5px black",
                                    padding: "10px",
                                    textAlign: "left",
                                    borderRadius: "0 0 8px 8px",
                                }}
                            >
                                {post.description}
                            </div>
                        </Col>
                    ))}
                </Row>
            )}

            {/* Share Modal */}
            <Modal show={showShareModal} onHide={() => setShowShareModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Share Incident</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                        <a
                            href={`https://api.whatsapp.com/send?text=${itemLink}`}
                            target="_blank"
                            rel="noopener noreferrer">
                            <IconButton style={{ color: "#25D366" }}>
                                <WhatsApp />
                            </IconButton>
                        </a>
                        <a
                            href={`https://www.facebook.com/sharer/sharer.php?u=${itemLink}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <IconButton style={{ color: "#3b5998" }}>
                                <Facebook />
                            </IconButton>
                        </a>
                        <a
                            href={`https://twitter.com/intent/tweet?url=${itemLink}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <IconButton style={{ color: "#1DA1F2" }}>
                                <Twitter />
                            </IconButton>
                        </a>

                        <a
                            href={`https://www.linkedin.com/shareArticle?url=${itemLink}`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <IconButton style={{ color: "#0077B5" }}>
                                <LinkedIn />
                            </IconButton>
                        </a>
                    </div>
                </Modal.Body>
            </Modal>
        </Container>
    );
}

export default IncidentPage;
