import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom'; // Make sure to import navigate if you are using it

const Header = ({ searchQuery, handleSearch, searchResults }) => {
    const navigate = useNavigate(); // Initialize the navigate function

    return (
        <div className="bg-info text-white rounded-top" style={{ padding: 10 }}>
            <Container fluid>
                <Row className="align-items-center">
                    <Col xs={12} md={6}>
                        <div className="d-flex align-items-center">
                            <img src='/truekie_imprint.png' className="mr-3" style={{ width: 45, height: 45 }} alt="Logo" />
                            <span className="font-weight-bold" style={{ fontSize: 30 }}>ELECTION 2024 MONITORING</span>
                        </div>
                        <div className="d-flex flex-wrap mt-2">
                            <a href='/' className="btn btn-warning mr-2" style={{ flex: 1 }}>NATIONAL RESULTS</a>
                            <a href='/election-results/regional' className="text-white mr-2">REGIONAL</a>
                            <a href='/election-results/parliamentary' className="text-white">PARLIAMENTARY</a>
                        </div>
                    </Col>
                    <Col xs={12} md={6} className="d-flex flex-column align-items-end">
                        <input
                            type="text"
                            placeholder="Search..."
                            value={searchQuery}
                            onChange={handleSearch}
                            className="form-control mt-2"
                            style={{ width: "80%" }}
                        />
                        {searchResults.length > 0 && (
                            <ul className="list-unstyled mt-2 position-relative" style={{ cursor: "pointer", zIndex: 10 }}>
                                <li
                                    className="bg-light p-2 rounded border-bottom border-gray"
                                    onClick={() => navigate(`/constituency/result/${searchResults[0].constituency_code}`)}
                                >
                                    {searchResults[0].constituency_name}
                                </li>
                                {searchResults.map((result, index) => (
                                    <li
                                        key={index}
                                        className="bg-light p-2 rounded border-bottom border-gray"
                                        onClick={() => navigate(`/polling-station/${result.polling_station_code}`)}
                                    >
                                        <span className="text-primary">{result.polling_station_name} ({result.polling_station_code})</span><br />
                                        <span className="text-secondary">{result.constituency_name} ({result.constituency_code})</span>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Header;
