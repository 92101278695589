import { useAuth } from '../contexts/AuthContext';
import { Navigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Card } from '@mui/material';
import '../App.css';
import { COLOR_PRIMARY, COLOR_SECONDARY, fetchSeatsData, MAIN_URL, PRESIDENTIAL_CANDIDATES, REGISTERED_VOTERS } from '../Constants';
import axios from 'axios';

import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import ImageIcon from '@mui/icons-material/Image';
import { useNavigate } from 'react-router-dom'; // Assuming React Router for navigation
import moment from 'moment';
import GhanaMapSvg from '../GhanaMapSvg';
import { ArrowRight, Icecream, More } from '@mui/icons-material';
import 'bootstrap/dist/css/bootstrap.min.css';
import RegConstituencyTable from '../RegConstituencyTable';
import ElectionHeader from '../ElectionHeader';
import HeaderVoteCounts from '../HeaderVoteCounts';
import ElectionCharts from '../ElectionCharts';
import { Nav, NavItem, NavLink } from 'react-bootstrap';
import PostList from '../PostList';
import SeatsWon from '../SeatsWon';
import useResponsive from '../useResponsive';
import RegionalMaps from '../RegionalMap';


// import Constituency_bounds from '../Constituency_bounds';




const RegionDetails = ({ type, userType }) => {
    const { user } = useAuth();
    const [presidentialData, setPresidentialData] = useState(null);

    const { name } = useParams();
    const [posts, setPosts] = useState([]);
    const [selectedPosts, setSelectedPosts] = useState([]);

    const navigate = useNavigate();


    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    const [activeTab, setActiveTab] = useState('map');
    const [activeTabMain, setActiveTabMain] = useState('presidential');


    const [region, setRegion] = useState('');
    const [seats, setSeats] = useState([]);
    const [presidentialResults, setPresidentialResults] = useState({
        numberOfPollingStations: 0,
        totalVotes: 0,
        totalRejectedBallots: 0,
        results: []
    });
    const [parliamentaryResults, setParliamentaryResults] = useState({
        numberOfPollingStations: 0,
        totalVotes: 0,
        totalRejectedBallots: 0,
        results: []
    });
    const isMobile = useResponsive();
    console.log("Is mobile? " + isMobile)

    const [allData, setAllData] = useState(null)
    const [constituencies, setConstituencies] = useState();
    const [geoJson_data, setGeoJson_data] = useState(null);
    const [seatsData, setSeatsData] = useState({})

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${MAIN_URL}election-results-m/regional/${name}`);

                // Destructure the response data
                const { region, seats, results } = response.data;
                const { presidential, parliamentary } = results;

                const constituencies = await axios.get(`${MAIN_URL}election-results/constituencies/presidential/${name}`);
                setConstituencies(constituencies.data)

                const seats_ = await fetchSeatsData(name);
                setSeatsData(seats_);


                setRegion(region);
                setSeats(seats);




                setPresidentialResults({
                    numberOfPollingStations: presidential.number_of_polling_stations,
                    totalVotes: presidential.total_votes,
                    totalRejectedBallots: presidential.total_rejected_ballots,
                    results: presidential.results.sort((a, b) => b.votes - a.votes)
                });
                setParliamentaryResults({
                    numberOfPollingStations: parliamentary.number_of_polling_stations,
                    totalVotes: parliamentary.total_votes,
                    totalRejectedBallots: parliamentary.total_rejected_ballots,
                    results: parliamentary.results.sort((a, b) => b.votes - a.votes)
                });
                setAllData(response.data)

            } catch (error) {
                console.error('Error fetching election results:', error);
            }
        };

        fetchData();

        axios.get(`${MAIN_URL}load/posts/main`)
            .then(response => {
                // console.log(JSON.stringify(response.data))
                setPosts(response.data)
            })
    }, [name]);

    // Fetching data from the API


    // if (!user) {
    //     return <Navigate to="/login" />;
    // }

    const renderMapIncidentTab = () => {
        return (
            <div>

                <Nav variant="tabs" justify className="mb-3 text-center">
                    <NavItem>
                        <NavLink
                            eventKey="map"
                            active={activeTab === 'map'}
                            onClick={() => tabClick('map')}
                            className={activeTab === 'map' ? 'active' : ''}
                            style={{
                                color: activeTab === 'map' ? 'white' : '#000',
                                backgroundColor: activeTab === 'map' ? '#007bff' : '#fff',
                                color: activeTab === 'map' ? 'white' : '#000',
                                borderColor: activeTab === 'map' ? '#007bff' : '#dee2e6',
                                fontWeight: activeTab === "map" ? 'bold' : 'normal'

                            }}
                        >
                            MAP
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            eventKey="incidents"
                            active={activeTab === 'incidents'}
                            onClick={() => tabClick('incidents')}
                            className={activeTab === 'incidents' ? 'active' : ''}
                            style={{
                                color: activeTab === 'incidents' ? 'white' : '#000',
                                backgroundColor: activeTab === 'incidents' ? '#007bff' : '#fff',
                                color: activeTab === 'incidents' ? 'white' : '#000',
                                borderColor: activeTab === 'incidents' ? '#007bff' : '#dee2e6',
                                fontWeight: activeTab === "incidents" ? 'bold' : 'normal'

                            }}
                        >
                            INCIDENTS
                        </NavLink>
                    </NavItem>
                </Nav>

                {activeTab === 'map' && (
                    <div style={{
                        maxWidth: '100%',
                        height: '1000px', overflow: 'hidden',
                        width: '100%', display: 'flex',
                        flex: 1,
                        backgroundColor: '#fff'
                    }}>
                        {<RegionalMaps userType={userType} regionName={name} regionResults={constituencies} />}
                    </div>
                )}

                {activeTab === 'incidents' && (
                    <div style={{ maxHeight: '1000px', overflowY: 'hidden', width: '100%' }}>
                        <PostList />
                    </div>
                )}
            </div>
        )
    }

    const handleFileClick = (incidentId) => {
        navigate(`/post/incident/${incidentId}`);
    };
    const handleSearch = async (event) => {
        const query = event.target.value;
        setSearchQuery(query);

        // Perform search only when query length is at least 3 characters
        if (query.length >= 3) {
            try {
                const response = await axios.get(`${MAIN_URL}election-results/search/${query}`);
                setSearchResults(response.data);
            } catch (error) {
                console.error('Error fetching search results', error);
            }
        } else {
            // Clear search results if query length is less than 3
            setSearchResults([]);
        }
    };

    const handleTabClick = (tab) => {
        setActiveTabMain(tab);
    };
    const tabClick = (tab) => setActiveTab(tab);


    return (
        <div className="admin-page">
            <div className="left-column">
                <ElectionHeader
                    ionHeader searchQuery={searchQuery}
                    searchResults={searchResults} type="regional"
                    navigate={navigate}
                    setSearchQuery={setSearchQuery}
                    handleSearch={handleSearch}
                    userType={userType}
                />



                <Nav variant="tabs" justify className="mb-3 text-center" style={{ marginTop: isMobile ? 80 : 25, marginBottom: 25, marginRight: 10 }}>

                    <span style={{
                        padding: 10, marginLeft: '15%', color: "#555",
                        fontFamily: 'verdana', fontSize: 23, fontWeight: "bold", marginRight: "10%"
                    }}>{name} REGION</span>

                    <NavItem>

                        <NavLink
                            eventKey="presidential"
                            active={activeTabMain === 'presidential'}
                            onClick={() => handleTabClick('presidential')}
                            style={{
                                backgroundColor: activeTabMain === 'presidential' ? '#007bff' : '#fff',
                                color: activeTabMain === 'presidential' ? 'white' : '#000',
                                // borderColor: activeTabMain === 'presidential' ? '#0056b3' : '#dee2e6',
                                borderTopLeftRadius: '15px',
                                borderTopRightRadius: '0px',
                                padding: '10px 15px',
                                transition: 'background-color 0.3s ease',
                                cursor: 'pointer',
                                fontWeight: activeTabMain === 'presidential' ? 'bold' : 'normal',
                                boxShadow: activeTabMain === 'presidential' ? '0 4px 8px rgba(0, 123, 255, 0.2)' : 'none',
                                margin: '0 5px'
                            }}
                        >
                            PRESIDENTIAL
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            eventKey="parliamentary"
                            active={activeTabMain === 'parliamentary'}
                            onClick={() => handleTabClick('parliamentary')}
                            style={{
                                backgroundColor: activeTabMain === 'parliamentary' ? '#007bff' : '#fff',
                                color: activeTabMain === 'parliamentary' ? 'white' : '#000',
                                // borderColor: activeTabMain === 'parliamentary' ? '#007bff' : '#dee2e6',
                                borderTopRightRadius: '15px',
                                borderTopLeftRadius: '0px',
                                padding: '10px 15px',
                                transition: 'background-color 0.3s ease',
                                cursor: 'pointer',
                                fontWeight: activeTabMain === 'parliamentary' ? 'bold' : 'normal',
                                boxShadow: activeTabMain === 'parliamentary' ? '0 4px 8px rgba(0, 123, 255, 0.2)' : 'none',
                                margin: '0 5px' // Add margin to space out the tabs
                            }}
                        >
                            PARLIAMENTARY
                        </NavLink>
                    </NavItem>
                </Nav>

                <HeaderVoteCounts counted={(presidentialResults.totalVotes + presidentialResults.totalRejectedBallots).toLocaleString()}
                    registered={REGISTERED_VOTERS.regional[name]} ps={presidentialResults.numberOfPollingStations.toLocaleString()}
                    valid={presidentialResults.totalVotes.toLocaleString()}
                    rejected={presidentialResults.totalRejectedBallots.toLocaleString()}
                    seats={seatsData}
                />

                {/* Display the chart if data is available */}


                {
                    activeTabMain === "presidential" &&

                    <div style={{ padding: 10, flexDirection: "column", display: 'flex' }}>

                        {presidentialResults && (
                            <div style={{ marginLeft: "0%", display: 'flex', flexDirection: 'row' }}>
                                <div style={{ margin: 15, width: isMobile && "100%", }}>
                                    <ElectionCharts data={presidentialResults.results} candidates={PRESIDENTIAL_CANDIDATES} />
                                </div>

                                {!isMobile && renderMapIncidentTab()}
                            </div>
                        )}
                    </div>
                }


                {
                    activeTabMain === "parliamentary" && (
                        <SeatsWon region={name} />
                    )
                }
                {
                    activeTabMain == "presidential" && isMobile && renderMapIncidentTab()
                }
                <div style={{ padding: 10, flexDirection: "column", display: 'flex' }}>

                    {allData && <RegConstituencyTable data={allData} userType={userType} />}

                </div>

            </div>


        </div>
    );
};

export default RegionDetails;
