import { useAuth } from '../contexts/AuthContext';
import { Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Card } from '@mui/material';
import '../App.css';
import { COLOR_PRIMARY, COLOR_SECONDARY, MAIN_URL, PRESIDENTIAL_CANDIDATES, REGISTERED_VOTERS, fetchSeatsData } from '../Constants';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Assuming React Router for navigation
import 'bootstrap/dist/css/bootstrap.min.css';
import HeaderVoteCounts from '../HeaderVoteCounts';
import ElectionHeader from '../ElectionHeader';
import PostList from '../PostList';
import { io } from 'socket.io-client';
import ElectionCharts from '../ElectionCharts';
import { Nav, NavItem, NavLink } from 'react-bootstrap';
import GhanaMapSvg from '../GhanaMapSvg';
import SeatsWon from '../SeatsWon';
import useResponsive from '../useResponsive';
import Loader from '../Loader';
import PostListAdmin from '../PostListAdmin';
import ElectionHeaderAdmin from '../ElectionHeaderAdmin';

const Admin_National = ({ type }) => {
    const { user } = useAuth;
    // const  user_type  = user && user?.user;
    const [presidentialData, setPresidentialData] = useState(null);
    const [pollingStations, setPollingStations] = useState(0);
    const [validVotes, setValidVotes] = useState(0);
    const [rejectedVotes, setRejectedVotes] = useState(0);
    const [regionalData, setRegionalData] = useState([])

    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [activeTab, setActiveTab] = useState('map');
    const [activeTabMain, setActiveTabMain] = useState('incidentReports');

    const isMobile = useResponsive();
    const [loading, setLoading] = useState(false);

    const [seatsData, setSeatsData] = useState({})


    useEffect(() => {
        // Create an asynchronous function to fetch data
        const fetchData = async () => {
            setLoading(true);
            try {
                // Fetch initial election data
                const response = await axios.get(`${MAIN_URL}election-results-m/national`);
                const allData = response.data;
                const { number_of_polling_stations, total_rejected_ballots, total_valid_votes } = allData;

                setValidVotes(total_valid_votes);
                setRejectedVotes(total_rejected_ballots);
                setPollingStations(number_of_polling_stations);

                const sortedResults = allData.results.sort((a, b) => b.votes - a.votes);
                setPresidentialData(sortedResults);

                // Fetch regional data
                const regionalResponse = await axios.get(`${MAIN_URL}election-results-m/regional`);
                setRegionalData(regionalResponse.data);

                // Fetch seats data
                const seats = await fetchSeatsData("");
                setSeatsData(seats);

                setLoading(false)

            } catch (err) {
                setLoading(false)

                console.error("Error fetching data: ", err.message);
            }

            // WebSocket connection
            const socket = io(MAIN_URL);
            socket.on('connect', () => console.log('WebSocket connected'));
            socket.on('disconnect', () => console.log('WebSocket disconnected'));

            socket.on('update-data', async (response) => {
                const { number_of_polling_stations, total_rejected_ballots, total_valid_votes } = response;
                setValidVotes(total_valid_votes);
                setRejectedVotes(total_rejected_ballots);
                setPollingStations(number_of_polling_stations);

                const sortedResults = response.results.sort((a, b) => b.votes - a.votes);
                setPresidentialData(sortedResults);

                // Fetch regional data on update
                const regionalResponse = await axios.get(`${MAIN_URL}election-results-m/regional`);
                setRegionalData(regionalResponse.data);
            });

            return () => {
                socket.disconnect();
            };
        };

        fetchData(); // Call the async function
    }, []);



    const tabClick = (tab) => setActiveTab(tab);

    const handleSearch = async (event) => {
        const query = event.target.value;
        setSearchQuery(query);
        console.log("Entered: " + query)

        if (query.length >= 3) {
            try {
                const response = await axios.get(`${MAIN_URL}election-results/search/${query}`);
                // console.log("Response: "+JSON.stringify(response.da)) 
                setSearchResults(response.data);
            } catch (error) {
                console.error('Error fetching search results', error);
            }
        } else {
            setSearchResults([]);
        }
    };

    const handleTabClick = (tab) => {
        setActiveTabMain(tab);
    };

    if (loading) return <Loader />

    // if (!user) {
    //     return <Navigate to="/login" />;
    // }


    const renderMapIncidentTab = () => {
        return (
            <div>
 

                {activeTab === 'map' && (
                    <div style={{
                        maxWidth: '100%',
                        height: '1000px', overflow: 'hidden',
                        width: '100%', display: 'flex',
                        flex: 1,
                        backgroundColor: '#fff'
                    }}>
                        <GhanaMapSvg data={regionalData} type={type} />
                    </div>
                )}

               
            </div>
        )
    }
    return (
        <div className="admin-page">
            <div className="left-column">

               
                <ElectionHeaderAdmin resultType={"national"} />


                <Nav variant="tabs" justify className="mb-3 text-center" 
                style={{ marginTop: 25, marginBottom: 25, marginRight: 10 }}>
                    <span style={{
                        padding: 10, marginLeft: '15%', color: "#555",
                        fontFamily: 'verdana', fontSize: 23,
                         fontWeight: "bold", marginRight: "10%",
                         marginTop:isMobile ? "15%" :0
                    }}>{"NATIONAL"}</span>
                    <NavItem>
                        <NavLink active={activeTabMain === "incidentReports"}
                            onClick={() => setActiveTabMain("incidentReports")}
                            style={{
                                backgroundColor: activeTabMain === 'incidentReports' ? '#007bff' : '#fff',
                                color: activeTabMain === 'incidentReports' ? 'white' : '#000',
                                // borderColor: activeTabMain === 'presidential' ? '#0056b3' : '#dee2e6',
                                borderTopLeftRadius: '8px',
                                borderTopRightRadius: '8px',
                                padding: '10px 15px',
                                transition: 'background-color 0.3s ease',
                                cursor: 'pointer',
                                fontWeight: activeTabMain === 'incidentReports' ? 'bold' : 'normal',
                                boxShadow: activeTabMain === 'incidentReports' ? '0 4px 8px rgba(0, 123, 255, 0.2)' : 'none',
                                margin: '0 5px'
                            }}
                        >
                            INCIDENT REPORTS
                        </NavLink>
                    </NavItem>
                    <NavItem>

                        <NavLink
                            eventKey="presidential"
                            active={activeTabMain === 'presidential'}
                            onClick={() => handleTabClick('presidential')}
                            style={{
                                backgroundColor: activeTabMain === 'presidential' ? '#007bff' : '#fff',
                                color: activeTabMain === 'presidential' ? 'white' : '#000',
                                // borderColor: activeTabMain === 'presidential' ? '#0056b3' : '#dee2e6',
                                borderTopLeftRadius: '8px',
                                borderTopRightRadius: '8px',
                                padding: '10px 15px',
                                transition: 'background-color 0.3s ease',
                                cursor: 'pointer',
                                fontWeight: activeTabMain === 'presidential' ? 'bold' : 'normal',
                                boxShadow: activeTabMain === 'presidential' ? '0 4px 8px rgba(0, 123, 255, 0.2)' : 'none',
                                margin: '0 5px'
                            }}
                        >
                            PRESIDENTIAL
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            eventKey="parliamentary"
                            active={activeTabMain === 'parliamentary'}
                            onClick={() => handleTabClick('parliamentary')}
                            style={{
                                backgroundColor: activeTabMain === 'parliamentary' ? '#007bff' : '#fff',
                                color: activeTabMain === 'parliamentary' ? 'white' : '#000',
                                // borderColor: activeTabMain === 'parliamentary' ? '#007bff' : '#dee2e6',
                                borderTopRightRadius: '8px',
                                borderTopLeftRadius: '8px',
                                padding: '10px 15px',
                                transition: 'background-color 0.3s ease',
                                cursor: 'pointer',
                                fontWeight: activeTabMain === 'parliamentary' ? 'bold' : 'normal',
                                boxShadow: activeTabMain === 'parliamentary' ? '0 4px 8px rgba(0, 123, 255, 0.2)' : 'none',
                                margin: '0 5px'
                            }}
                        >
                            PARLIAMENTARY
                        </NavLink>
                    </NavItem>
                </Nav>

                {
                    activeTabMain != "incidentReports" && (
                        <HeaderVoteCounts
                            counted={(validVotes + rejectedVotes).toLocaleString()}
                            registered={(REGISTERED_VOTERS.TOTAL)}
                            ps={pollingStations.toLocaleString()}
                            valid={validVotes.toLocaleString()}
                            rejected={rejectedVotes.toLocaleString()}
                            seats={seatsData}
                        />
                    )
                }
                {
                    activeTabMain === "presidential" &&

                    <div style={{ padding: 10, flexDirection: "column", display: 'flex' }}>



                        {presidentialData && (
                            <div style={{ marginLeft: "0%", display: 'flex', flexDirection: 'row' }}>
                                <div style={{ margin: 15 }}>
                                    <ElectionCharts data={presidentialData} candidates={PRESIDENTIAL_CANDIDATES} />
                                </div>

                                {!isMobile && renderMapIncidentTab()}
                            </div>
                        )}
                    </div>
                }


                {
                    activeTabMain === "parliamentary" && (
                        <SeatsWon />
                    )
                }
                {
                    activeTabMain == "presidential" && isMobile && renderMapIncidentTab()
                }
                {
                    activeTabMain === "incidentReports" && (<div style={{ paddingLeft: '3%', paddingRight: "3%" }}><PostListAdmin /></div>)
                }
            </div>

        </div>
    );
};

export default Admin_National;
