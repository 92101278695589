import React from "react";
import { Container, Row, Col, Navbar, Nav } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import useResponsive from "../useResponsive";
import Partners from "../Partners";
import { FaShieldAlt, FaBullhorn, FaVoteYea } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { COLOR_PRIMARY } from "../Constants";


const LandingPage = () => {
    const navigate = useNavigate()

    const isMobile = useResponsive();
    return (
        <div style={{ fontFamily: "Arial, sans-serif" }}>
            {/* Header */}
            <Navbar bg="light" expand="lg" sticky="top">
                <Container>
                    <Navbar.Brand href="/" className="d-flex align-items-center">
                        <img
                            src="/truekie_imprint.png"
                            alt="Election Watch Logo"
                            style={{ height: "40px", marginRight: "10px" }}
                        />
                        <h1 style={{ fontSize: "1.5rem", margin: 0,padding:20, 
                        fontWeight: "bold", fontFamily: 'trebuchet', color:"#777"}}>
                             MEDIA WATCH APP
                        </h1>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navbarNav" />
                    <Navbar.Collapse id="navbarNav" className="justify-content-end">
                        <Nav>

                            <Nav.Link href="/results" style={{ margin: "0 10px" }}>
                                View Results
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            {/* Hero Section */}
            <div
                style={{
                    textAlign: "center",

                    marginTop: isMobile ? "-20%" : "-12%",
                    color: "#fff",
                    padding: "40px 20px",
                    backgroundImage: 'url("path/to/flag-image.jpg")',
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                }}
            >
                <Container>
                    <Row className="justify-content-center">
                        <Col xs={12} md={8}>
                            <h2>Help Ghana monitor the 2024 elections</h2>
                            <p>Download Election Watch Now</p>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Download Section */}
            <div
                style={{
                    position: "relative",
                    paddingTop: 60,
                    paddingBottom: "0%",
                     backgroundImage: 'url("gh_flag.jpg")',
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    color: "#fff",
                }}
            >
                {/* Overlay */}
                <div
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.8)",
                        zIndex: 1,
                    }}
                ></div>

                <Container style={{ position: "relative", zIndex: 2 }}>
                    <Row className="align-items-center">
                        <Col xs={12} md={6}>
                            <h4 style={{
                                fontSize: "1.1rem",
                                lineHeight: "1.8", fontWeight: 'bold', marginTop: "-3%", color: "#ccc"
                            }}>
                                The National Media Commission of Ghana, proudly presents the Media Watch App, an innovative technology to empower the public:
                            </h4>
                            <ul style={{ margin: "20px 0", fontSize: "1.1rem", lineHeight: "1.8" }}>
                                <li>Combat disinformation and fake news.</li>
                                <li>Monitor media performance.</li>
                                <li>Ensure transparent elections.</li>
                                <li>Safeguard journalists and journalism.</li>
                            </ul>
                            <strong style={{ fontSize: "1.1rem", lineHeight: "1.8", fontWeight: 'bold', color: "#ccc" }}>
                                Capture and submit videos, images, and audio that are time-stamped and geo-tagged to combat fake news and disinformation.
                            </strong>

                            <p>
                                <p style={{ fontWeight: "bold", fontSize: "1.2rem", marginTop: "40px", padding: 10, color: "#ccc" }}>
                                    Download the Media Watch App from
                                </p>
                                <p style={{ textAlign: "left" }}>
                                    <span
                                        style={{
                                            display: "inline-block",
                                            width: "130px",
                                            backgroundColor: '#ddd',
                                            borderRadius: 10,
                                            marginRight: 50,
                                            padding: 0,
                                        }}
                                    >
                                        <img
                                            src="/play_store_.png"
                                            alt="Google Play Store"
                                            onClick={() => window.open("https://play.google.com/store/apps/details?id=com.softmastersgroup.ghanamediawatch&hl=en", "_blank")}
                                            style={{
                                                width: "100%",
                                                height: "auto",
                                                borderRadius: 10,
                                            }}
                                        />
                                    </span>
                                    <span
                                        style={{
                                            display: "inline-block",
                                            width: "130px",
                                            backgroundColor: '#ddd',
                                            borderRadius: 10,
                                            padding: 8,
                                        }}
                                    >
                                        <img
                                            src="/app_store.png"
                                            alt="App Store"
                                            onClick={() => window.open("https://apps.apple.com/us/app/ghana-media-watch/id1586013520", "_blank")}
                                            style={{
                                                width: "100%",
                                                height: "auto",
                                                borderRadius: 10,
                                            }}
                                        />
                                    </span>
                                </p>



                            </p>

                        </Col>
                        <Col xs={12} md={6} className="text-center">
                            <img
                                src="/phone_screen_tp.png"
                                alt="Phone App Screen"
                                style={{ maxWidth: "40%", height: "auto" }}
                            />
                        </Col>
                    </Row>

                </Container>
            </div>
            {/* <div>
            </div> */}
            {/* <div> <div style={{ marginTop: "10px", padding: "20px" }}>
                <h3 style={{ fontWeight: "bold", fontSize: "1.5rem", color: "#333", marginBottom: "20px" }}>
                    Our Mission
                </h3>
                <ul style={{ listStyleType: "none", paddingLeft: "0" }}>
                    <li style={{ display: "flex", alignItems: "center", fontSize: "1.2rem", marginBottom: "15px" }}>
                        <FaShieldAlt style={{ color: "teal", marginRight: "10px" }} />
                        Let's protect our journalists.
                    </li>
                    <li style={{ display: "flex", alignItems: "center", fontSize: "1.2rem", marginBottom: "15px" }}>
                        <FaBullhorn style={{ color: "orange", marginRight: "10px" }} />
                        Let's fight disinformation.
                    </li>
                    <li style={{ display: "flex", alignItems: "center", fontSize: "1.2rem", marginBottom: "15px" }}>
                        <FaVoteYea style={{ color: "green", marginRight: "10px" }} />
                        Let's uphold the integrity of our elections.
                    </li>
                </ul>
            </div></div> */}
            <Partners />
            {/* Footer */}
            <footer
                style={{
                    backgroundColor: "#f8f8f8",
                    padding: "20px 0",
                    marginTop: "40px",
                }}
            >
                <Container>

                    <Row className="text-center mt-4">
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <p style={{ fontWeight: 'bold', fontSize: 20, color: "#555", marginRight: "20px" }}>
                                Developed by Softmasters Company Limited
                            </p>

                            <img
                                src="/sms_logo.png"
                                alt="Softmasters Logo"
                                style={{ width: 200, height: 'auto', marginLeft: "20px" }}
                            />

                            <p style={{ fontSize: "14px", color: "#555", marginTop: "10px", textAlign: "right", marginLeft: "20px" }}>
                                &copy; {new Date().getFullYear()} All rights reserved.
                            </p>
                        </div>

                    </Row>
                </Container>
            </footer>
        </div>
    );
};

export default LandingPage;
