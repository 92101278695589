import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { COLOR_SELECTED, REGIONS } from './Constants';
import { CONSTITUENCIES } from './ConstituencyList';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import ElectionHeaderAdmin from './ElectionHeaderAdmin';

const ElectionHeader = ({ searchQuery, handleSearch, searchResults, setSearchQuery, type, userType }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isRegionDropdownOpen, setIsRegionDropdownOpen] = useState(false);
    const [constituencySearch, setConstituencySearch] = useState("");
    const [regionSearch, setRegionSearch] = useState("");
    const navigate = useNavigate();
    const {user} =useAuth();

    const handleRegionClick = (region) => {
        userType === "admin"
            ? navigate(`/election-results/admin/region/${region}`)
            : navigate(`/election-results/region/${region}`);
        setIsRegionDropdownOpen(false);
    };

    const handleConstituencySearch = (e) => {
        setConstituencySearch(e.target.value);
    };

    const handleRegionSearch = (e) => {
        setRegionSearch(e.target.value);
    };

    const filteredConstituencies = CONSTITUENCIES.filter(constituency =>
        constituency.name.toLowerCase().includes(constituencySearch.toLowerCase()) ||
        constituency.code.toLowerCase().includes(constituencySearch.toLowerCase())
    );

    const filteredRegions = REGIONS.filter(region =>
        region.toLowerCase().includes(regionSearch.toLowerCase())
    );

    const menuItems = [
        { name: 'NATIONAL', href: '/', selected: type === 'national', bgColor: COLOR_SELECTED },
        { name: 'REGIONAL', href: '#', selected: type === 'regional', bgColor: COLOR_SELECTED, isDropdown: true },
        { name: 'CONSTITUENCY', href: '#header', selected: type === 'parliamentary', bgColor: COLOR_SELECTED, isDropdown: true }
    ];

    if(user!=null)
    return <ElectionHeaderAdmin />
    return (
        <header
            className="shadow-sm"
            id='header'
            style={{
                width: '100%',
                position: 'fixed',
                top: 0,
                left: 0,
                zIndex: 1000,
                padding: '10px 20px',
                backgroundColor: '#f9f9f9',
                borderBottom: '1px solid #ddd',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            }}
        >
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                    <img
                        src='/truekie_imprint.png'
                        alt="Logo"
                        style={{ width: 40, height: 40, marginRight: 15 }}
                        onClick={() => {
                            userType === "admin" ? navigate("/admin") : navigate("/")
                        }}
                    />
                    <h4 style={{ color: "#666", marginBottom: 0, marginLeft: 20, fontWeight: 'bold', textTransform: 'uppercase' }}>
                        Elections 2024
                    </h4>
                    <div className="d-flex align-items-center" style={{ fontSize: '14px', fontWeight: 'bold', color: 'salmon', marginRight: '20px', marginLeft: 20 }}>
                        <span style={{
                            display: 'inline-block',
                            width: '12px',
                            height: '12px',
                            borderRadius: '50%',
                            backgroundColor: '#ff0000',
                            marginRight: '5px',
                            animation: 'pulse 1.5s infinite',
                        }}></span>
                        LIVE
                    </div>
                    {menuItems.map((item, index) => (
                        <div key={index} className="position-relative" style={{ zIndex: 1200 }}>
                            <a
                                href={item.name === "NATIONAL" ? item.href : undefined}
                                className="text-decoration-none px-3 py-2 mx-2"
                                style={{
                                    backgroundColor: item.selected ? "#fff" : 'transparent',
                                    borderRadius: '4px',
                                    fontSize: '16px',
                                    color: item.selected ? "#000" : '#666',
                                    border: item.selected ? "1.3px solid teal" : null,
                                    fontWeight: item.selected ? 'bold' : 'bold',
                                    cursor: item.isDropdown ? 'pointer' : 'auto',
                                }}
                                onClick={item.isDropdown ? () => {
                                    setIsDropdownOpen(item.name === 'CONSTITUENCY' ? !isDropdownOpen : false);
                                    setIsRegionDropdownOpen(item.name === 'REGIONAL' ? !isRegionDropdownOpen : false);
                                    item.name === "NATIONAL" && navigate("/")
                                } : null}
                            >
                                {item.name}
                            </a>

                            {item.isDropdown && (item.name === 'CONSTITUENCY' ? isDropdownOpen : isRegionDropdownOpen) && (
                                <ul
                                    className="list-unstyled position-absolute mt-2"
                                    style={{
                                        background: '#fff',
                                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                        borderRadius: '8px',
                                        width: '200px',
                                        zIndex: 20,
                                        maxHeight: '300px', // Set a max height for scrolling
                                        overflowY: 'auto', // Enable vertical scrolling
                                        fontSize: '12px', // Reduced font size
                                    }}
                                >
                                    {item.name === 'CONSTITUENCY' && (
                                        <>
                                            <li className="p-2">
                                                <input
                                                    type="text"
                                                    placeholder="Search Constituency..."
                                                    value={constituencySearch}
                                                    onChange={handleConstituencySearch}
                                                    style={{
                                                        borderRadius: '4px',
                                                        padding: '5px',
                                                        width: '100%',
                                                        border: '1px solid #ddd',
                                                        marginBottom: '5px',
                                                    }}
                                                />
                                            </li>
                                            {filteredConstituencies.map((constituency, idx) => (
                                                <li
                                                    key={idx}
                                                    className="p-2"
                                                    style={{ cursor: 'pointer', color: '#000', background: '#f5f5f5', padding: '10px' }} // Increased padding for list items
                                                    onClick={() => {
                                                        // setConstituencySearch("");

                                                        navigate(`/temp-path`, { replace: true });
                                                        setTimeout(() => {
                                                            navigate(`/constituency/result/${constituency.code}?reload=${new Date().getTime()}`, {
                                                                state: { name: constituency.name },
                                                            });
                                                        }, 50);
                                                        // navigate(`/constituency/result/${constituency.code}`, { state: { name: constituency.name } });
                                                    }}
                                                >
                                                    {constituency.name} ({constituency.code})
                                                </li>
                                            ))}
                                        </>
                                    )}

                                    {item.name === 'REGIONAL' && (
                                        <>
                                            <li className="p-2">
                                                <input
                                                    type="text"
                                                    placeholder="Search Region..."
                                                    value={regionSearch}
                                                    onChange={handleRegionSearch}
                                                    style={{
                                                        borderRadius: '4px',
                                                        padding: '5px',
                                                        width: '100%',
                                                        border: '1px solid #ddd',
                                                        marginBottom: '5px',
                                                    }}
                                                />
                                            </li>
                                            {filteredRegions.map((region, idx) => (
                                                <li
                                                    key={idx}
                                                    className="p-2"
                                                    style={{ cursor: 'pointer', color: '#000', background: '#f5f5f5', padding: '10px' }} // Increased padding for list items
                                                    onClick={() => handleRegionClick(region)}
                                                >
                                                    {region}
                                                </li>
                                            ))}
                                        </>
                                    )}
                                </ul>
                            )}
                        </div>
                    ))}
                </div>

                <div className="position-relative" style={{ width: '30%' }}>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={handleSearch}
                        style={{
                            borderRadius: '12px',
                            padding: '10px 20px',
                            border: '1px solid #ddd',
                            fontSize: '16px',
                            backgroundColor: '#fff',
                        }}
                    />
                    {searchResults.length > 0 && (
                        <ul className="list-unstyled position-absolute mt-2"
                            style={{
                                zIndex: 10,
                                background: '#fff',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                borderRadius: '8px',
                                width: '100%',
                                zIndex: 20,
                                maxHeight: '200px', // Set a max height for scrolling
                                overflowY: 'auto', // Enable vertical scrolling
                                fontSize: '12px', // Reduced font size
                            }}>
                            {searchResults.map((result, index) => (
                                <li
                                    key={index}
                                    className="p-3 border-bottom"
                                    onClick={() => {
                                        setSearchQuery("");
                                        navigate(`/polling-station/${result.polling_station_code}?reload=${new Date().getTime()}`);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <span style={{ color: '#007bff', fontWeight: 'bold' }}>
                                        {result.polling_station_name} ({result.polling_station_code})
                                    </span>
                                    <br />
                                    <span className="text-muted" style={{ fontSize: '12px' }}>
                                        {result.constituency_name}
                                    </span>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>
        </header>
    );
};

export default ElectionHeader;
