import React from 'react';
import { useNavigate } from 'react-router-dom';
import { COLOR_PRIMARY, PARTIES } from './Constants';
import { CONSTITUENCIES } from './ConstituencyList';

const PARTY_COLORS = {
    NPP: 'blue',
    NDC: 'green',
    default: 'gold',
    zeroVotes: '#F5F5F5'
};

const RegConstituencyTable = ({ data, userType }) => {
    const { region, seats = [], constituencies = [] } = data;
    const navigate = useNavigate()

    const determineRowStyle = (results) => {
        let winningParty = '';
        let maxVotes = 0;

        results.forEach(({ party, votes }) => {
            if (votes > maxVotes) {
                maxVotes = votes;
                winningParty = party;
            }
        });

        const allVotesZero = results.every(result => result.votes === 0);
        if (allVotesZero) return { backgroundColor: PARTY_COLORS.zeroVotes };

        // Set styles for winning parties
        if (winningParty === 'NPP') return { backgroundColor: PARTY_COLORS.NPP, color: 'white' };
        if (winningParty === 'NDC') return { backgroundColor: PARTY_COLORS.NDC, color: 'white' };
        return { backgroundColor: PARTY_COLORS.default, color: 'white' };
    };


    // Render table for the constituency results
    const renderConstituencyResultsTable = () => {
        const filteredConstituencies = CONSTITUENCIES.filter(c => c.region.toLowerCase() === region.toLowerCase());

        return (
            <div style={{ marginTop: '20px', border: '1px solid #ddd' }}>
                <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                    <thead>
                        <tr style={{ backgroundColor: COLOR_PRIMARY }}>
                            <th style={{ borderBottom: '1px solid #ddd', padding: '8px', color: '#ddd', borderLeft: 'none', borderRight: 'none' }}>CODE</th>
                            <th style={{ borderBottom: '1px solid #ddd', padding: '8px', color: '#ddd', borderLeft: 'none', borderRight: 'none' }}>NAME</th>
                            {PARTIES.map((party) => (
                                <th key={party} style={{
                                    borderBottom: '1px solid #ddd',
                                    padding: '8px', color: '#ddd', borderLeft: 'none', borderRight: 'none'
                                }}>{party}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {filteredConstituencies.map((constituency) => {
                            const constituencyData = constituencies.find(c => c.code === constituency.code);
                            const results = constituencyData ? constituencyData.results[0].results : [];

                            // Calculate total votes for percentage calculation
                            const totalVotes = results.reduce((total, { votes }) => total + votes, 0);

                            return (
                                <tr key={constituency.code} style={constituencyData ? determineRowStyle(results) : { backgroundColor: '#f5f5f5' }}
                                    onClick={() => {
                                        let cName = constituency.name;
                                        userType === "admin" ? navigate("/constituency/admin/result/" + constituency.code, { state: { name: cName } }) : navigate("/constituency/result/" + constituency.code,
                                            { state: { name: cName } })
                                    }}>
                                    <td style={{ borderBottom: '1px solid #ddd', padding: '8px', fontSize: 13, borderLeft: 'none', borderRight: 'none' }}>{constituency.code}</td>
                                    <td style={{ borderBottom: '1px solid #ddd', padding: '8px', fontSize: 14, borderLeft: 'none', borderRight: 'none' }}>{constituency.name}</td>
                                    {PARTIES.map((party) => {
                                        const result = results.find(res => res.party === party);
                                        const votes = result ? result.votes : 0;
                                        const percentage = totalVotes > 0 ? ((votes / totalVotes) * 100).toFixed(2) : 0;
                                        return (
                                            <td key={party} style={{ fontSize: 13, borderBottom: '1px solid #ddd', padding: '8px', borderLeft: 'none', borderRight: 'none', fontWeight: party === 'NPP' || party === 'NDC' ? 'bold' : 'normal' }}>
                                                {totalVotes > 0 ? percentage + '%' : 'N/A'}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    };

    return (
        <div style={{ padding: '20px' }}>

            <div style={{ marginBottom: '5%', marginTop: "-5%" }} />

            <h4 style={{
                textAlign: 'center', margin: 0, padding: '10px',
                color: COLOR_PRIMARY,

            }}>
                CONSTITUENCY RESULTS in {region} REGION
            </h4>
            <div style={{ marginBottom: '5%' }} />

            {renderConstituencyResultsTable()}
        </div>
    );
};

export default RegConstituencyTable;
