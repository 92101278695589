import React, { useState } from 'react';
import { Nav, NavItem, NavLink } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import useResponsive from './useResponsive';

const HeaderVoteCounts = ({ registered, ps, valid, counted, rejected, seats, type }) => {
    const [activeTab, setActiveTab] = useState('presidential'); // State to track active tab

    const isMobile = useResponsive();
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const cardData = [
        { title: 'REGISTERED VOTERS', value: registered.toLocaleString(), bgColor: '#EC407A', footerColor: "#E91E63" },
        { title: 'COUNTED VOTES', value: counted.toLocaleString(), bgColor: "#5C6BC0", footerColor: '#3F51B5' },
        {
            title: 'PERCENTAGE',
            value: registered > 0 ? ((parseInt(counted.replace(",", "")) / parseInt(registered) * 100).toFixed(2) + "%") : "N/A",
            bgColor: '#00897B', footerColor: '#00796B'
        },
        // Additional cards can be added here
    ];

    const renderSeats = () => {
        return (
            <div className="col-12 col-sm-6 col-md-3 mb-4">
                <div style={{
                    border: 'none',
                    borderRadius: 8,
                    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                    backgroundImage: 'linear-gradient(90deg, #78909C,#607D8B,#607D8B,#78909C)',
                    padding: 0,
                    width: '100%',
                }}>
                    <div className="text-center" style={{
                        padding: 10,
                        color: '#fff',
                        fontWeight: 'normal',
                        fontSize: '1em',
                    }}>
                        {"SEATS"}
                    </div>

                    {/* Main container with overall gradient background */}
                    <div className="d-flex justify-content-between" style={{
                        padding: 3,
                        color: '#fff',
                        fontSize: '1em',
                        width: '100%',
                        borderRadius: '0 0 8px 8px',
                        textAlign: 'center',
                        backgroundImage: 'linear-gradient(90deg, #4CAF50, #2196F3, #DAA520)',
                    }}>
                        {/* NDC Column */}
                        <div style={{
                            flex: 1,
                            padding: 4,
                            backgroundImage: 'linear-gradient(135deg, #388E3C, #4CAF50, #66BB6A)', // Darker to lighter shades of green
                            borderRadius: 5,
                            marginRight: 2,
                            color: '#fff',
                        }}>
                            <div style={{ fontWeight: 'bold', color: "#E8F5E9" }}>NDC</div>
                            <div style={{ fontSize: '2.2em', fontWeight: 'bold' }}>{seats.NDC}</div>
                        </div>

                        {/* NPP Column */}
                        <div style={{
                            flex: 1,
                            padding: 4,
                            backgroundImage: 'linear-gradient(135deg, #1565C0, #2196F3, #42A5F5)', // Darker to lighter shades of blue
                            borderRadius: 5,
                            marginRight: 2,
                            color: '#fff',
                        }}>
                            <div style={{ fontWeight: 'bold', color: "#E3F2FD" }}>NPP</div>
                            <div style={{ fontSize: '2.2em', fontWeight: 'bold' }}>{seats.NPP}</div>
                        </div>

                        {/* IND Column */}
                        <div style={{
                            flex: 1,
                            padding: 4,
                            backgroundImage: 'linear-gradient(135deg, #B8860B, #DAA520, #FFD700)', // Darker to lighter shades of gold
                            borderRadius: 5,
                            color: '#fff',
                        }}>
                            <div style={{ fontWeight: 'bold', color: "#FFF8E1" }}>IND</div>
                            <div style={{ fontSize: '2.2em', fontWeight: 'bold' }}>{seats.IND}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };



    return (
        <div className="container-fluid">
            <div className="row">
                {cardData.map((card, index) => (
                    <div className="col-12 col-sm-6 col-md-3 mb-4" key={index}>
                        <div style={{
                            border: 'none',
                            borderRadius: 8,
                            boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                            backgroundColor: card.bgColor,
                            padding: 0,
                            width: '100%',
                        }}>
                            <div className="text-center" style={{
                                padding: 10,
                                color: '#ddd', // Gray color for titles
                                fontWeight: 'normal',
                                fontSize: '1em',
                            }}>
                                {card.title}
                            </div>

                            <div className="text-center" style={{
                                backgroundColor: card.footerColor,
                                padding: 20,
                                color: '#fff',
                                fontSize: '2em', // Larger font size for values
                                width: '100%',
                                borderRadius: '0 0 8px 8px' // Rounded bottom corners
                            }}>
                                <b>{card.value}</b>
                            </div>
                        </div>
                    </div>
                ))}

                {type != "parliamentary" && renderSeats()}
            </div>
        </div>
    );
};

export default HeaderVoteCounts;
