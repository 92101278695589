// src/App.js
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import HomePage from './pages/HomePage';
import AdminPage from './pages/AdminPage';
import LoginPage from './pages/LoginPage';
import { AuthProvider } from './contexts/AuthContext';
import IncidentPage from './pages/IncidentPage';
import RegionalResults from './pages/RegionalResults';
import ParliamentaryResults from './pages/ParliamentaryResults';
import ConstituencyResult from './pages/ConstituencyResult';
import PollingStationResult from './pages/PollingStationResult';
import 'bootstrap/dist/css/bootstrap.min.css';
import RegionDetails from './pages/RegionDetails';
import ManageIncidents from './pages/ManageIncidents';
import UserDetails from './pages/UserDetails';
import ParliamentaryResultsAdmin from './pages/ParliamentaryResultsAdmin';
import RegionalDetailsAdmin from './pages/RegionalDetailsAdmin';
import ConstituencyResultAdmin from './pages/ConstituencyResultAdmin';
import IncidentView from './pages/IncidentView';
import Constituency_Admin from './Constituency_Admin';
import Regional_Admin from './Regional_Admin';
import PollingStation_Admin from './PollingStation_Admin';
import InstitutionResults from './pages/InstitutionResults';
import ElectionResults from './pages/ElectionResults';


function App() {
    return (
        <AuthProvider>
            <Router>
                <Navbar />
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/admin" element={<AdminPage />} />
                    <Route path="/election-results" element={<ElectionResults />} />

                    <Route path="/login" element={<LoginPage />} />
                    <Route path='/election-results/region/:name' element={<RegionDetails />} />
                    <Route path='/election-results/admin/region/:name' element={<RegionalDetailsAdmin />} />

                    <Route path="/constituency/result/:code" element={<ConstituencyResult />} />
                    <Route path="/constituency/admin/result/:code" element={<ConstituencyResultAdmin />} />

                    <Route path="/polling-station/:polling_station" element={<PollingStationResult />} />
                    <Route path='/election-results/parliamentary' element={<ParliamentaryResults />} />
                    <Route path='/election-results/parliamentary/admin' element={<ParliamentaryResultsAdmin />} />

                    <Route path='/election-results/regional' element={<RegionalResults />} />
                    <Route path="/post/incident/:incident_id" element={<IncidentPage />} />
                    <Route path="/post/:id" element={<IncidentView />} />

                    <Route path='/incidents/elections/manage/' element={<ManageIncidents />} />
                    <Route path='/user/details/:id' element={<UserDetails />} />
                    <Route path='/institution/results/' element={<InstitutionResults />} />


                    <Route path='/ps/results/admin/:cs_code/:ps_code' element={<PollingStation_Admin />} />

                    <Route path='/constituency/results/admin/:code/:name' element={<Constituency_Admin />} />
                    <Route path='/region/results/admin/:region' element={<Regional_Admin />} />



                </Routes>
            </Router>
        </AuthProvider>
    );
}

export default App;
