import React, { useState, useEffect } from 'react';
import { MAIN_URL } from '../Constants';

const IncidentView = () => {
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const id = window.location.pathname.split("/post/")[1];
        if (id) {
            // alert(id)
            fetchPostData(id);
        } else {
            setError("Invalid incident ID");
            setLoading(false);
        }
    }, []);

    const fetchPostData = async (id) => {
        try {
            const response = await fetch(`${MAIN_URL}incident/load/${id}`);
            if (!response.ok) {
                throw new Error("Post not found");
            }
            const data = await response.json();
            setPost(data);
            setLoading(false);
        } catch (error) {
            console.log(error)
            setError("Failed to load post"+error);
            setLoading(false);
        }
    };

    if (loading) {
        return <div id="post-container">Loading...</div>;
    }

    if (error) {
        return <div id="post-container">{error}</div>;
    }

    return (
        <div>
            <header style={styles.header}>
                <h1>Ghana Media Watch</h1>
            </header>
            <div id="post-container" style={styles.postContainer}>
                {post && (
                    <>
                        <h2 style={styles.heading}>{post.posted_name}</h2>
                        <p>{post.address}</p>
                        <p>{post.description}</p>
                        <p>Posted on: {new Date(post.date_posted).toLocaleString()}</p>
                        {post.files.map((file, index) => (
                            <div key={index} style={styles.fileContainer}>
                                {file.fileType.startsWith("image") && (
                                    <img src={file.url} alt="Image file" style={styles.media} />
                                )}
                                {file.fileType.startsWith("video") && (
                                    <video controls style={styles.media}>
                                        <source src={file.url} type={file.fileType} />
                                        Your browser does not support the video tag.
                                    </video>
                                )}
                                {file.fileType.startsWith("audio") && (
                                    <audio controls style={styles.media}>
                                        <source src={file.url} type={file.fileType} />
                                        Your browser does not support the audio tag.
                                    </audio>
                                )}
                                <div style={styles.fileInfo}>
                                    <p>Location: {file.latitude}, {file.longitude}</p>
                                    <p>Captured: {file.dateTime}</p>
                                </div>
                            </div>
                        ))}
                    </>
                )}
            </div>
        </div>
    );
};

const styles = {
    header: {
        backgroundColor: '#333',
        color: 'white',
        padding: '10px 0',
        textAlign: 'center',
    },
    postContainer: {
        maxWidth: '800px',
        margin: '20px auto',
        backgroundColor: 'white',
        padding: '20px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    heading: {
        color: '#333',
    },
    fileContainer: {
        position: 'relative',
        margin: '20px 0',
    },
    media: {
        width: '100%',
        height: 'auto',
    },
    fileInfo: {
        position: 'absolute',
        bottom: '10px',
        right: '10px',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: 'white',
        padding: '5px 10px',
        borderRadius: '5px',
        fontSize: '14px',
    },
};

export default IncidentView;
