import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import proj4 from 'proj4';
import { MAIN_URL } from './Constants';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners'; // Import the loader
import { CONSTITUENCIES } from './ConstituencyList';



// Define the correct UTM zone (Ghana is generally in UTM zone 30 or 31)
proj4.defs("EPSG:UTM", "+proj=utm +zone=30 +datum=WGS84 +units=m +no_defs"); // Adjust based on your region

// Colors based on the winning party
const COLORS = {
    NPP: '#0047ab', // Blue
    NDC: 'green', // Green
    IND: '#FFD700', // Dark gold (for independent)
    DEFAULT: 'grey', // Grey (for no votes)
};

// Function to determine the color of each constituency based on results
const getConstituencyColor = (results) => {
    if (!results || results.length === 0) return COLORS.DEFAULT;
    const winner = results.reduce((max, partyResult) => {
        return (partyResult.votes > max.votes) ? partyResult : max;
    }, { votes: 0, party: '' });

    if (winner.votes === 0) {
        return COLORS.DEFAULT;
    } else if (winner.party.startsWith('IND')) {
        return COLORS.IND;
    } else {
        return COLORS[winner.party] || COLORS.DEFAULT;
    }
};

// Function to convert GeoJSON UTM coordinates to Lat/Lng
const convertGeoJsonToLatLng = (geoJson) => {


    const convertedFeatures = geoJson.features.map((feature) => {
        const { coordinates } = feature.geometry;
        const convertedCoordinates = coordinates.map((polygon) =>
            polygon.map((ring) =>
                ring.map(([x, y]) => proj4('EPSG:UTM', 'EPSG:4326', [x, y]))
            )
        );

        return {
            ...feature,
            geometry: {
                ...feature.geometry,
                coordinates: convertedCoordinates,
            },
        };
    });

    return { ...geoJson, features: convertedFeatures };
};

const ConstituencyMap = ({ constituencyData }) => {
    const [geoJsonData, setGeoJsonData] = useState(null);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();
    // Fetch GeoJSON data from backend and convert UTM to Lat/Lng
    useEffect(() => {
        fetch(MAIN_URL + 'election-data/constituencies/geojson')
            .then((response) => response.json())
            .then((data) => {
                const convertedData = convertGeoJsonToLatLng(data);
                setGeoJsonData(convertedData);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching GeoJSON:', error);
                setLoading(false);
            });
    }, []);

    // Map results to constituencies
    const resultsMap = {};
    if (constituencyData?.list) {
        constituencyData.list.forEach((item) => {
            resultsMap[item.constituency_name] = item.results;
        });
    }

    if (loading) {
        return (
            <div style={{
                display: 'flex', justifyContent: 'center', alignItems: 'center',
                height: '100vh'
            }}>
                <ClipLoader color="#0047ab" loading={loading} size={100} />
                <h5 style={{ textAlign: 'center' }}>Loading map...</h5>
            </div>
        );
    }

    if (!geoJsonData) {
        return <p>Failed to load constituency data.</p>;
    }

    // Define actions for each constituency feature on the map
    const onEachFeature = (feature, layer) => {
        const constituencyName = feature.properties.CONSTITUEN;
        const results = resultsMap[constituencyName] || [];
        const fillColor = getConstituencyColor(results);

        const all_consts = CONSTITUENCIES;

        const constituency = all_consts.filter(list => (constituencyName === list.name));

        console.log("Constituency: " + JSON.stringify(constituency))

        const code = constituency[0]?.code;
        layer.setStyle({
            color: 'white', // Border color
            weight: 1,
            fillColor: fillColor,
            fillOpacity: 0.7,
        });

        // Add tooltips for constituency names
        layer.bindTooltip(constituencyName);

        // Add click event for further actions on the constituency
        layer.on({
            click: () => {
                console.log(`Clicked on ${constituencyName} :  ${code}`);
                console.log("Clicked: " + JSON.stringify(results))
                navigate("/constituency/result/" + code, { state: { name: constituencyName } })
            },
        });
    };

    // Define bounds to restrict the map to Ghana's region
    const bounds = [
        [4.5, -3.5], // Southwest corner of Ghana
        [11.0, 1.5], // Northeast corner of Ghana
    ];

    return (
        <MapContainer
            style={{ height: '100vh', width: '90%' }}
            center={[7.9465, -1.0232]} // Center map on Ghana
            zoom={7} // Set initial zoom level
            maxBounds={bounds} // Restrict panning outside Ghana
            maxBoundsViscosity={1.0} // Make the map sticky within Ghana's bounds
        >
            <TileLayer
                opacity={0}
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {/* Render GeoJSON data and set styles for each constituency */}
            <GeoJSON data={geoJsonData} onEachFeature={onEachFeature} />
        </MapContainer>
    );
};

export default ConstituencyMap;
