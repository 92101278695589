import { useAuth } from '../contexts/AuthContext';
import { Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Card } from '@mui/material';
import '../App.css';
import { COLOR_PRIMARY, COLOR_SECONDARY, MAIN_URL, PRESIDENTIAL_CANDIDATES } from '../Constants';
import axios from 'axios';
import Swal from 'sweetalert2'; // For confirmation popups

import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import ImageIcon from '@mui/icons-material/Image';
import { useNavigate } from 'react-router-dom'; // Assuming React Router for navigation
import moment from 'moment';
import GhanaMapSvg from '../GhanaMapSvg';
import { ArrowRight, Icecream, More } from '@mui/icons-material';
import TableRegional from '../TableRegional';
import ElectionHeader from '../ElectionHeader';
import HeaderVoteCounts from '../HeaderVoteCounts';
import PostList from '../PostList';
import { io } from 'socket.io-client';





const RegionalResults = () => {
    const { user } = useAuth();
    const { user_type } = user?.user;
    const [presidentialData, setPresidentialData] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);


    const [pollingStations, setPollingStations] = useState(0);
    const [validVotes, setValidVotes] = useState(0);
    const [rejectedVotes, setRejectedVotes] = useState(0);
    const [posts, setPosts] = useState([]);
    const [selectedPosts, setSelectedPosts] = useState([]);
    const [regionalData, setRegionalData] = useState([])

    const navigate = useNavigate();


    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);


    // Fetching data from the API
    useEffect(() => {

        const socket = io(MAIN_URL);
        socket.on('connect', () => console.log('Regional socket connected 1'));
        socket.on('disconnect', () => console.log('Regional socket disconnected'));

        // Listen for "regional-results" event
        socket.on("regional-results", (data) => {
            console.log("Received regional results from socket:", data);
            setRegionalData(data);
        });
        axios.get(`${MAIN_URL}election-results-m/national`)
            .then(response => {
                const allData = response.data;
                const { number_of_polling_stations, total_rejected_ballots,
                    total_valid_votes } = allData;
                setValidVotes(total_valid_votes);
                setRejectedVotes(total_rejected_ballots);
                setPollingStations(number_of_polling_stations)

                let res = response.data.results.sort((a, b) => b.votes - a.votes)

                setPresidentialData(res);

                // setPresidentialData(response.data.results);
            })
            .catch(err => {
                console.error("Error fetching data: ", err.message);
            });

        axios.get(`${MAIN_URL}load/posts/main`)
            .then(response => {
                console.log(JSON.stringify(response.data))
                setPosts(response.data)
            })


        axios.get(`${MAIN_URL}election-results-m/regional`).then(response => {
            setRegionalData(response.data)
            console.log(JSON.stringify(response.data))
        })
    }, []);



    if (!user || user_type !== "admin") {
        return <Navigate to="/login" />;
    }

    const handleFileClick = (incidentId) => {
        navigate(`/post/incident/${incidentId}`);
    };
    const handleSearch = async (event) => {
        const query = event.target.value;
        setSearchQuery(query);

        // Perform search only when query length is at least 3 characters
        if (query.length >= 3) {
            try {
                const response = await axios.get(`${MAIN_URL}election-results/search/${query}`);
                setSearchResults(response.data);
            } catch (error) {
                console.error('Error fetching search results', error);
            }
        } else {
            // Clear search results if query length is less than 3
            setSearchResults([]);
        }
    };

    return (
        <div className="admin-page">
            <div className="left-column">
                <ElectionHeader
                    ionHeader searchQuery={searchQuery} searchResults={searchResults} type="regional"
                    navigate={navigate} setSearchQuery={setSearchQuery} handleSearch={handleSearch} />

                <HeaderVoteCounts counted={(validVotes + rejectedVotes).toLocaleString()}
                    registered={"N/A"} ps={pollingStations.toLocaleString()}
                    valid={validVotes.toLocaleString()} rejected={rejectedVotes.toLocaleString()} />


                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '8%'
                }}>
                    {regionalData && <GhanaMapSvg data={regionalData} />}
                    <div style={{
                        display: 'flex', flexDirection: 'column',
                        marginLeft: "-10%", marginTop: 10
                    }}>


                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                            <div style={{
                                height: 20,
                                width: 20,
                                backgroundColor: 'green', borderRadius: 10,
                                marginRight: 10
                            }} />
                            <span style={{
                                color: "#666",
                                height: 20, fontSize: 13
                            }}> {' NDC'}</span>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                            <div style={{
                                height: 20,
                                width: 20,
                                borderRadius: 10,
                                backgroundColor: 'blue',
                                marginRight: 10
                            }} />
                            <span style={{
                                color: "#666",
                                height: 20, fontSize: 13
                            }}> {' NPP'}</span>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                            <div style={{
                                height: 20,
                                width: 20,
                                borderRadius: 10,
                                backgroundColor: 'goldenrod',
                                marginRight: 10
                            }} />
                            <span style={{ color: "#666", height: 20, fontSize: 13 }}> {' Independent'}</span>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                            <div style={{
                                height: 20,
                                width: 20,
                                borderRadius: 10,
                                backgroundColor: '#ddd',
                                marginRight: 10
                            }} />
                            <span style={{ color: "#666", height: 20, fontSize: 13 }}> {' No Results Yet'}</span>
                        </div>
                    </div>

                </div>
                <h4 style={{
                    color: COLOR_PRIMARY, textAlign: 'center',
                    padding: 10,
                    margin: "4%"
                }}>DETAILED REGIONAL RESULTS </h4>
                <div className='right-column' style={{
                    display: 'flex',
                    flexDirection: 'row'
                }}>

                    {regionalData && <TableRegional resultsData={regionalData} />}
                </div>
            </div>

            <div className="right-column">
                <PostList />


            </div>


        </div>
    );
};

export default RegionalResults;
