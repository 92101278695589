import { useAuth } from '../contexts/AuthContext';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Card } from '@mui/material';
import '../App.css';
import { COLOR_PRIMARY, COLOR_SECONDARY, MAIN_URL, PRESIDENTIAL_CANDIDATES } from '../Constants';
import axios from 'axios';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import ImageIcon from '@mui/icons-material/Image';
import moment from 'moment';
import PollingStationTables from '../PollingStationTables';
import Header from '../Header';
import ElectionHeader from '../ElectionHeader';
import { Search } from '@mui/icons-material';
import PostList from '../PostList';
import ElectionCharts from '../ElectionCharts';
import { Nav, NavItem, NavLink } from 'react-bootstrap';
import useResponsive from '../useResponsive';
import ChartsParliamentary from '../ChartsParliamentary';


const PollingStationResult = () => {
    const { user } = useAuth();
    const { polling_station } = useParams();
    const navigate = useNavigate();

    const [presidentialData, setPresidentialData] = useState(null);
    const [parliamentaryData, setParliamentaryData] = useState(null);
    const [posts, setPosts] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [data, setData] = useState(null);
    const [activeTabMain, setActiveTabMain] = useState('presidential');
    const isMobile = useResponsive()
    const [presidentialResults, setPresidentialResults] = useState([]);
    const [parliamentaryResults, setParliamentaryResults] = useState([]);

    const removeDuplicates = (data) => {
        const uniquePosts = new Map();

        data.forEach(post => {
            if (!uniquePosts.has(post.id)) {
                uniquePosts.set(post.id, post);
            }
        });

        return Array.from(uniquePosts.values());
    }
    // Fetching data from the API
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${MAIN_URL}election-results-main/${polling_station}`);
                const stationData = response.data[0];

                if (stationData) {
                    setLatitude(stationData.latitude);
                    setLongitude(stationData.longitude);
                    setData(stationData);


                    // Set presidential and parliamentary data
                    setPresidentialResults(removeDuplicates(stationData.results.presidential.data));

                    setParliamentaryResults(stationData.results.parliamentary.data);
                    // alert(JSON.stringify(stationData.results.parliamentary.data))

                    // presidentialData && console.log(JSON.stringify(presidentialData.data))
                }

                console.log("Polling station data:", JSON.stringify(stationData));
            } catch (err) {
                console.error("Error fetching polling station data:", err.message);
            }
        };

        fetchData();
    }, [polling_station]);

    // Handle search input change
    const handleSearch = async (event) => {
        const query = event.target.value;
        setSearchQuery(query);

        if (query.length >= 3) {
            try {
                const response = await axios.get(`${MAIN_URL}election-results/search/${query}`);
                setSearchResults(response.data);
            } catch (error) {
                console.error('Error fetching search results', error);
            }
        } else {
            setSearchResults([]);
        }
    };

    const handleTabClick = (tab) => {
        setActiveTabMain(tab);
    };
    // Calculate total votes for presidential data
    const totalPresidentialVotes = presidentialData
        ? presidentialData.data.reduce((sum, candidate) => sum + candidate.votes, 0)
        : 0;

    return (
        <div className="admin-page">
            <div className="left-column">
                <Nav variant="tabs" justify className="mb-3 text-center" style={{ marginTop: isMobile ? 80 : 25, marginBottom: 25, marginRight: 10 }}>
                    <span style={{
                        padding: 10, marginLeft: '20%', color: "#555",
                        fontFamily: 'verdana', fontSize: 22, fontWeight: "bold", marginRight: 20
                    }}>{data && data.polling_station_name} </span>

                    <NavItem>

                        <NavLink
                            eventKey="presidential"
                            active={activeTabMain === 'presidential'}
                            onClick={() => handleTabClick('presidential')}
                            style={{
                                backgroundColor: activeTabMain === 'presidential' ? '#007bff' : '#fff',
                                color: activeTabMain === 'presidential' ? 'white' : '#000',
                                // borderColor: activeTabMain === 'presidential' ? '#0056b3' : '#dee2e6',
                                borderTopLeftRadius: '15px',
                                borderTopRightRadius: '0px',
                                padding: '10px 15px',
                                transition: 'background-color 0.3s ease',
                                cursor: 'pointer',
                                fontWeight: activeTabMain === 'presidential' ? 'bold' : 'normal',
                                boxShadow: activeTabMain === 'presidential' ? '0 4px 8px rgba(0, 123, 255, 0.2)' : 'none',
                                margin: '0 5px'
                            }}
                        >
                            PRESIDENTIAL
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            eventKey="parliamentary"
                            active={activeTabMain === 'parliamentary'}
                            onClick={() => handleTabClick('parliamentary')}
                            style={{
                                backgroundColor: activeTabMain === 'parliamentary' ? '#007bff' : '#fff',
                                color: activeTabMain === 'parliamentary' ? 'white' : '#000',
                                // borderColor: activeTabMain === 'parliamentary' ? '#007bff' : '#dee2e6',
                                borderTopRightRadius: '15px',
                                borderTopLeftRadius: '0px',
                                padding: '10px 15px',
                                transition: 'background-color 0.3s ease',
                                cursor: 'pointer',
                                fontWeight: activeTabMain === 'parliamentary' ? 'bold' : 'normal',
                                boxShadow: activeTabMain === 'parliamentary' ? '0 4px 8px rgba(0, 123, 255, 0.2)' : 'none',
                                margin: '0 5px' // Add margin to space out the tabs
                            }}
                        >
                            PARLIAMENTARY
                        </NavLink>
                    </NavItem>
                </Nav>

                <ElectionHeader
                    searchQuery={searchQuery}
                    searchResults={searchResults}
                    type="none"
                    navigate={navigate}
                    setSearchQuery={setSearchQuery}
                    handleSearch={handleSearch}
                />


                {
                    <div style={{ padding: 10, flexDirection: "column", display: 'flex' }}>
                        {presidentialResults && (
                            <div style={{ marginLeft: "0%", display: 'flex', flexDirection: 'row' }}>
                                <div style={{ margin: 15, width: isMobile ? "100%" : undefined, flex: 2, display: 'flex' }}>
                                    {activeTabMain === "presidential" ? (
                                        <ElectionCharts data={presidentialResults} candidates={PRESIDENTIAL_CANDIDATES} />
                                    ) : (
                                        <ElectionCharts data={parliamentaryResults} candidates={PRESIDENTIAL_CANDIDATES} />
                                    )}

                                </div>

                                <div style={{ display: 'flex', flex: 1, marginRight: "5%" }}>
                                    {latitude && longitude && <PostList lat={latitude} lng={longitude} />}
                                </div>
                            </div>
                        )}

                        {/* <div>
                            {JSON.stringify(parliamentaryResults)}
                        </div> */}



                    </div>
                }



                {/* Display PollingStationTables with polling station code */}
                {/* <PollingStationTables code={polling_station} /> */}
            </div>

            {/* <div className="right-column">
                
            </div> */}
        </div>
    );
};

export default PollingStationResult;
