import React, { useEffect, useState } from 'react';
import { polygonCentroid } from 'd3-polygon';
import { useNavigate } from 'react-router-dom';
import REGIONS_WITH_CONSTITUENCIES from './ConstituencyData';

const ALL_REGIONS = [
    "AHAFO", "ASHANTI", "BONO", "BONO EAST", "CENTRAL", "EASTERN", "GREATER ACCRA", "NORTHERN", "NORTH EAST", "OTI", "SAVANNAH",
    "UPPER EAST", "UPPER WEST", "VOLTA", "WESTERN", "WESTERN NORTH"
];

const getColor = (results) => {
    if (!results || results.length === 0) return '#ddd';
    const ndcVotes = results.find(r => r.party === 'NDC')?.votes || 0;
    const nppVotes = results.find(r => r.party === 'NPP')?.votes || 0;
    if (ndcVotes > nppVotes) return 'green';  
    if (nppVotes > ndcVotes) return 'blue';   
    return 'goldenrod'; 
};

const calculateBoundingBox = (constituencies) => {
    let minX = Infinity, maxX = -Infinity, minY = Infinity, maxY = -Infinity;

    constituencies.forEach(constituency => {
        const pathCoords = constituency.path
            .split(/[MLZ ]/)
            .filter(Boolean)
            .map(coord => coord.split(',').map(Number));

        pathCoords.forEach(([x, y]) => {
            if (x < minX) minX = x;
            if (x > maxX) maxX = x;
            if (y < minY) minY = y;
            if (y > maxY) maxY = y;
        });
    });

    return { minX, maxX, minY, maxY };
};

const ConstituencyMapSvg = ({ constituencyResults, selectedRegion }) => {
    const [transform, setTransform] = useState(null);
    const [activeRegion, setActiveRegion] = useState(selectedRegion || "ALL");

    const navigate = useNavigate();

    const getConstituencies = () => {
        if (activeRegion === "ALL") {
            return Object.values(REGIONS_WITH_CONSTITUENCIES).flat();
        }
        if (ALL_REGIONS.includes(activeRegion)) {
            return REGIONS_WITH_CONSTITUENCIES[activeRegion] || [];
        }
        return [];
    };

    useEffect(() => {
        const constituenciesToRender = getConstituencies();
        const { minX, maxX, minY, maxY } = calculateBoundingBox(constituenciesToRender);

        const width = 500; 
        const height = 500; 

        const scaleX = width / (maxX - minX);
        const scaleY = height / (maxY - minY);
        const scale = Math.min(scaleX, scaleY) * 0.9;  // Apply uniform scaling

        const translateX = -minX * scale;
        const translateY = -minY * scale;

        setTransform({
            translate: [translateX, translateY],
            scale: scale,
        });
    }, [activeRegion]);

    const goToConstituency = (cName, code) => {
        navigate("/constituency/result/" + code, { state: { name: cName } });
    };

    const constituenciesToRender = getConstituencies();

    return (
        <>
            <div>
                <label htmlFor="region-select">Select Region:</label>
                <select
                    id="region-select"
                    value={activeRegion}
                    onChange={(e) => setActiveRegion(e.target.value)}
                >
                    <option value="ALL">All Regions</option>
                    {ALL_REGIONS.map((region) => (
                        <option key={region} value={region}>
                            {region}
                        </option>
                    ))}
                </select>
            </div>

            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 500 500"
                width="100%"
                height="100%"
                style={{ border: '0px solid #999', marginTop: '0%' }}
            >
                <g transform={transform ? `translate(${transform.translate}) scale(${transform.scale})` : null}>
                    {constituenciesToRender.map((constituency, idx) => {
                        if (!constituency.path || constituency.path.trim() === "") {
                            return null;
                        }

                        const results = constituencyResults?.list?.find(item => item.constituency_code === constituency.code)?.results || [];
                        const regionColor = getColor(results);

                        return (
                            <g key={idx}>
                                <path
                                    d={constituency.path}
                                    fill={regionColor}
                                    stroke="white"
                                    strokeWidth="0.5"
                                    onClick={() => goToConstituency(constituency.name, constituency.code)}
                                />
                                <title>{constituency.name}</title>
                                <text
                                    x={polygonCentroid(constituency.path.split(/[MLZ ]/).filter(Boolean).map(coord => coord.split(',').map(Number)))[0]}
                                    y={polygonCentroid(constituency.path.split(/[MLZ ]/).filter(Boolean).map(coord => coord.split(',').map(Number)))[1]}
                                    fontSize="6"
                                    fill="white"
                                    fontWeight="bold"
                                    textAnchor="middle"
                                >
                                    {constituency.name}
                                </text>
                            </g>
                        );
                    })}
                </g>
            </svg>
        </>
    );
};

export default ConstituencyMapSvg;
