import React, { useState } from 'react';
import { Container, Row, Col, Navbar, Nav } from "react-bootstrap";


const Partners = () => {
    const [hovered, setHovered] = useState(null);

    const handleMouseEnter = (index) => {
        setHovered(index);
    };

    const handleMouseLeave = () => {
        setHovered(null);
    };

    const logos = [
        { src: "gja.jpg", alt: "GJA" },
        { src: "giba.webp", alt: "GIBA" },
        { src: "prinpag.png", alt: "PRINPAG GHANA" },
        { src: "gps.jpg", alt: "Police Service" },
    ];

    return (
        <Row className="text-center">
            <Col xs={12}>
                <h4 style={{ fontWeight: 'bold', fontSize: '24px', color: '#333',
                 marginBottom: '20px' ,marginTop:30}}>
                    Partners
                </h4>
                <div className="d-flex justify-content-center flex-wrap">
                    {logos.map((logo, index) => (
                        <img
                            key={index}
                            src={logo.src}
                            alt={logo.alt}
                            style={{
                                width: '120px',
                                height: 'auto',
                                margin: '10px',
                                borderRadius: '10px',
                                transition: 'transform 0.3s ease',
                                transform: hovered === index ? 'scale(1.1)' : 'scale(1)',
                                boxShadow: hovered === index ? '0 4px 8px rgba(0, 0, 0, 0.1)' : 'none',
                            }}
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={handleMouseLeave}
                        />
                    ))}
                </div>
            </Col>
        </Row>
    );
};

export default Partners;
