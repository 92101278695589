const REGIONS_WITH_CONSTITUENCIES =
{
    WESTERN: [
        { "code": "A0101", "name": "JOMORO", path: "M109.7,162.1l-2,6l2,4v8l-10,7.9L95,187l-33.4-6.5l-47.2-10.4l-1.3-2.6l11.7-2.9l11,6.8l13.4-5.9l4.8,2.6l15.6-1.6l-0.6-9.7l-5.2-10.8l1-14.6l8-0.6l11.3-2.4l0.8-1.7l2.2-4.9l5.7,3.1l2,4h13l0.9,4.6l1.1,5.4c0,0-1,10,0,10S109.7,162.1,109.7,162.1z" },
        { "code": "A0201", "name": "ELLEMBELE", path: "M150.7,186.1l-1,7l-8,3v5.6l-1.4-0.5l-30-11.2l-10.7-2.1l10-7.9v-8l-2-4l2-6c0,0,1-13,0-13s0-10,0-10l-1.1-5.4l-0.9-4.6h-1.3l3.3-4l6,4h13l3.7,3.7l1.3,1.3l-2,6h-3l3,4l-5,6l3,3l-3,6l3,2l-1,7l4,2l3-2v3l7,4l3,5v4L150.7,186.1z" },
        { "code": "A0301", "name": "EVALUE AJOMORO GWIRA", path: "M171.7,194.1l-1,6h-2l-1,4h-5v3l-2,3l-4,2l-0.8,2.9l-2.7-0.9L142.4,202l-0.7-0.3v-5.6l8-3l1-7l-5-2v-4l-3-5l-7-4v-3l-3,2l-4-2l1-7l-3-2l3-6l-3-3l5-6l-3-4h3l2-6l-1.3-1.3l4.3,1.3c0,0,4-3,5-2c1.1,1.3,2.1,2.6,3,4h6l6,3v7l4.9,1.6l1.1,0.4l4,8l-4,3v6l-1.9,3.3l-2.1,3.7l4,5l0.7-0.5l3.3-2.5l4,4l-5,4l3,3v4L171.7,194.1z" },
        { "code": "A0401", "name": "AHANTA WEST", path: "M 95.4,114.7 91.2,118.1 87.2,121.1 84.2,123.1 80.2,121.1 75.2,125.1 75.2,121.1 70.2,121.1 70.2,125.1 68.2,128.1 67.2,123.1 63.2,130.1 61.2,132.1 61.2,129.1 58.2,125.1 52.2,125.1 47.2,123.1 52.2,121.1 52.2,117.1 55.2,117.1 57.2,114.1 61.2,114.1 61.2,109.1 59.2,103.1 61.2,98.1 65.2,94.1 67.2,99.1 71.2,100.1 74.2,96.1 75.2,93.1 70.2,91.1 75.2,87.1 77.2,82.1 77.2,80.1 80.2,76.1 82.3,72.9 82.9,73.5 82.9,73.5 78.4,86.5 83.3,89.7 88.4,98.3 94.6,101.5" },
        { "code": "A0501", "name": "TAKORADI", path: "M219.8,191.1l-2.1,1.1l-4.1,1.5l-2.9,1.3l-6-4v-3l3-1l4.6-4.6l2.3,2.7c0,0,2.3,2.5,3.1,3c0.4,0.2,0.4,0.6,0.3,0.9c-0.1,0.4-0.3,0.6-0.3,0.6l0.4,0.2l1.4,0.9L219.8,191.1z" },
        { "code": "A0502", "name": "SEKONDI", path: "M 224.3,197.5 218.7,204.1 218,203.7 213.7,201.1 211.7,201.1 210.7,195.1 213.6,193.7 217.7,192.2 219.8,191.1 220,191.4 221,195.8" },
        { "code": "A0503", "name": "ESSIKADU-KETAN", path: "M 237.7,184 230.6,188.1 226.6,184 225.7,183.1 224.7,178.1 224.1,178.3 223.7,178.4 223.7,170.1 226.2,168.3 233.7,172.1 235.9,176 235.9,178.1" },
        { "code": "A0601", "name": "EFFIA", path: "M 246.3,164 246.3,179 239.1,183.2 237.7,184 235.9,178.1 235.9,176 233.7,172.1 236.7,167.1 241.7,162.1" },
        { "code": "A0602", "name": "KWESIMINTIM", path: "M226.6,184l-2.2,1.5l-0.7,1.4l-2.2,3.6l-1.5,0.6l-0.5-0.3l-1.4-0.9l-0.4-0.2c0,0,0.2-0.3,0.3-0.6c0.1-0.3,0.1-0.7-0.3-0.9c-0.8-0.4-3.1-3-3.1-3l-2.3-2.7l3.4-3.4l3,1l2,3l1-4l2-0.7l0.4-0.1l0.6-0.2l1,5L226.6,184z" },
        { "code": "A0701", "name": "SHAMA", path: "M 231.6,189.1 224.8,197 224.3,197.5 221,195.8 220,191.4 219.9,191.1 221.5,190.5 223.7,186.9 224.4,185.5 226.6,184 230.6,188.1" },
        { "code": "A0801", "name": "WASSA EAST", path: "M 268.2,143.5 261.9,152 254.1,152.6 247.2,161.9 246.3,163 246.3,164 241.7,162.1 236.7,167.1 233.7,172.1 219.7,165.1 213.7,166.1 211.7,161.1 204.7,157.6 203.9,157.2 203.7,157.1 203.7,152.1 208.7,148.1 217.7,139.1 216.7,132.1 211.7,127.1 209.7,118.1 207.7,112.1 211.7,110.1 214.7,104.1 209.7,104.1 207.7,94.1 207.7,89.1 209,88.7 212.5,89.8 219.7,87.4 231.4,90.3 244.8,97.1 241.5,105.8 241.5,114.3 250.6,121.8 254.5,132.2" },
        { "code": "A0901", "name": "MPOHOR", path: "M 226.2,168.3 223.7,170.1 223.7,178.4 221.7,179.1 220.7,183.1 218.7,180.1 215.7,179.1 207.7,187.1 204.7,188.1 204.7,191.1 198.7,191.1 201.7,194.1 200.7,196.1 197.7,197.1 193.7,194.1 191.7,192.1 183.7,192.1 183.7,190.1 185.7,187.1 185.7,182.1 188.7,182.1 189.7,178.1 188.7,170.1 191.7,165.1 191.7,163.1 193.7,161.1 199.7,163.1 203.9,157.2 204.7,157.6 211.7,161.1 213.7,166.1 219.7,165.1" },
        { "code": "A1001", "name": "TARKWA NSUAEM", path: "M 203.9,157.2 199.7,163.1 193.7,161.1 191.7,163.1 191.7,165.1 188.7,170.1 189.7,178.1 188.7,182.1 185.7,182.1 185.7,187.1 183.7,190.1 183.7,192.1 185.5,192.1 181.8,195.3 183.7,200.1 181.7,200.1 178.7,198.1 174.7,198.1 170.7,200.1 171.7,194.1 168.7,189.1 168.7,185.1 165.7,182.1 170.7,178.1 166.7,174.1 163.3,176.6 162.7,177.1 158.7,172.1 160.8,168.4 162.7,165.1 162.7,159.1 166.7,156.1 162.7,148.1 161.6,147.7 164.7,143.1 167.7,139.1 170.7,136.1 171.7,132.1 176.7,129.1 179.7,130.1 181.7,132.1 186.7,135.1 187.7,132.1 191.7,134.1 193.7,136.1 192.7,141.1 189.7,144.1 186.7,146.1 189.7,150.1 189.7,156.1 193.7,154.1 195.7,152.1 198.7,152.1 201.7,150.1 203.7,152.1 203.7,157.1" },
        { "code": "A1101", "name": "PRESTEA HUNI-VALLEY", path: "M 217.7,139.1 208.7,148.1 203.7,152.1 201.7,150.1 198.7,152.1 195.7,152.1 193.7,154.1 189.7,156.1 189.7,150.1 186.7,146.1 189.7,144.1 192.7,141.1 193.7,136.1 191.7,134.1 187.7,132.1 186.7,135.1 181.7,132.1 179.7,130.1 176.7,129.1 171.7,132.1 170.7,136.1 167.7,139.1 164.7,143.1 161.6,147.7 156.7,146.1 156.7,139.1 150.7,136.1 148.7,131.1 142.7,125.1 142.7,120.1 145.7,118.1 142.7,114.1 144.7,108.1 145.7,103.1 150.7,100.1 157.7,103.1 155.7,96.1 159,92.8 163,92.3 168.7,96.1 171.7,92.1 177.7,91.1 177.7,90.4 180.9,90 184.7,91.1 189.7,91.1 189.7,89.1 190.1,88.8 194.7,86.1 198.7,86.1 203.7,82.1 206.7,82.1 208.8,83.7 208.3,86.5 208,88 209,88.7 207.7,89.1 207.7,94.1 209.7,104.1 214.7,104.1 211.7,110.1 207.7,112.1 209.7,118.1 211.7,127.1 216.7,132.1" },
        { "code": "A1201", "name": "WASSA AMENFI EAST", path: "M 212.3,64 208.8,83.7 206.7,82.1 203.7,82.1 198.7,86.1 194.7,86.1 190.1,88.8 189.7,89.1 189.7,91.1 184.7,91.1 180.9,90 177.7,90.4 177.7,91.1 171.7,92.1 168.7,96.1 163,92.3 162.7,92.1 159.1,92.7 159.7,92.1 157.7,89.1 160.7,85.1 159.7,79.1 162.7,75.1 159.7,72.1 156.7,67.1 159.7,65.1 157.7,59.1 159.7,53.1 157.7,46.1 157.7,41.1 155.7,36.1 151.7,31.1 153.7,27.1 150.7,23.1 150.7,18.1 150,12.1 159.7,12.1 169,16.2 174.9,24.7 185,29.9 197.6,44.2 204.8,47.8 209.3,53.9 208,64" },
        { "code": "A1301", "name": "AMENFI CENTRAL", path: "M160.7,85.1l-3,4l2,3l-0.6,0.6l-0.1,0.1l-3.3,3.3l2,7l-7-3l-5,3l-1,5l-2,6l3,4l-3,2v5l6,6l2,5h-6c-0.9-1.4-1.9-2.7-3-4c-1-1-5,2-5,2l-4.3-1.3l-3.7-3.7h-9.4l6.4-1l-2-8l3-4l-6-3L116,110l-1.3-0.9v-6l3-6v-7l9-4l-2-7l1-5l3-5l6-3l-1-7l3-3l-8-9l-7.9-5.7l-3.1-2.3l3-6l0.8-0.8l1.2-1.2l3-2v-5l-1.9-5.6l6.5-3.6l6.8,2.8l11.2-5.6h1.7l0.7,6v5l3,4l-2,4l4,5l2,5v5l2,7l-2,6l2,6l-3,2l3,5l3,3l-3,4L160.7,85.1z" },
        { "code": "A1401", "name": "AMENFI WEST", path: "M 133.7,59.1 134.7,66.1 128.7,69.1 125.7,74.1 124.7,79.1 126.7,86.1 117.7,90.1 117.7,97.1 114.7,103.1 114.7,109.1 116,109.9 120.7,113.1 126.7,116.1 123.7,120.1 125.7,128.1 119.2,129.1 115.7,129.1 109.7,125.1 106.4,129.1 94.7,129.1 92.7,125.1 87,121.9 89.1,117.4 100.3,101.8 99.6,94.4 99,88.7 100.3,73.4 92.2,71.4 98.4,62.5 111.5,62.5 109,49.5 106.4,40 102.8,33.1 106.4,20.1 112.1,12.1 120.8,20.1 122.4,19.2 123.8,18.4 125.7,24.1 125.7,29.1 122.7,31.1 121.5,32.3 120.7,33.1 117.7,39.1 120.8,41.4 128.7,47.1 136.7,56.1" }
    ],
    "CENTRAL": [
        { "code": "B0101", "name": "KOMENDA EDINA EGUAFO ABREM", path: "M 112.5,180.5 110.5,182.5 103.5,182.5 96.5,191.5 96.5,208.5 129.5,200.5 132.5,193.5 135.4,192.9 129.5,187.5 124.5,180.5 120.5,181.5" },
        { "code": "B0201", "name": "CAPE COAST SOUTH", path: "M 129.5,200.5 146.5,197.5 143.5,190.5 139.5,188.9 137.5,183.5 129.5,187.5 135.5,192.5 132.5,193.5" },
        { "code": "B0202", "name": "CAPE COAST NORTH", path: "M 124.5,180.5 127.5,176.5 135.5,175.5 135.5,177.5 133.5,181.5 137.5,183.5 129.5,187.5" },
        { "code": "B0301", "name": "ABURA/ASEBU/KWAMANKESE", path: "M 135.5,175.5 135.5,178.5 133.5,181.5 137.5,183.5 138.5,188.5 143.5,190.5 146.5,197.5 150.5,195.5 150.5,190.5 148.5,186.5 152.5,177.5 158.5,177.5 164.5,180.5 167,177.3 166.5,168.5 157.5,155.5 151.5,160.5 135.5,162.5" },
        { "code": "B0401", "name": "MFANTSEMAN", path: "M 157.5,155.5 166.5,168.5 166.5,177.5 164.5,180.5 158.5,177.5 152.5,177.5 148.5,186.5 150.5,190.5 150.5,195.5 160.5,189.5 168.5,183.5 183.5,183.5 175.5,167.5 170.5,158.5 167.2,157.7 160.5,152.5" },
        { "code": "B0501", "name": "EKUMFI", path: "M 183.5,183.5 206.5,182.5 208.5,180.5 206.5,175.5 200.5,170.5 200.5,166.5 194.5,160.5 188.5,162.5 184.5,167.5 175.5,167.5" },
        { "code": "B0601", "name": "AJUMAKO ENYAN ESIAM", path: "M 164.5,136.5 164.5,140.5 167.5,145.5 160.5,152.5 166.5,157.5 170.5,158.5 175.5,167.5 184.5,167.5 188.5,162.5 194.5,160.5 196.5,155.5 196.5,145.2 194.5,136.5 189.5,138.5 181.5,136.5" },
        { "code": "B0701", "name": "GOMOA WEST", path: "M 212.5,136.5 208.5,136.5 205.5,137.5 194.5,136.5 196.5,144.5 196.5,154.5 194.5,160.5 200.5,166.5 200.5,170.5 206.5,175.5 208.5,180.5 213.5,178.5 216.5,143.5 210.5,142.5" },
        { "code": "B0801", "name": "GOMOA CENTRAL", path: "M 238.5,162.5 223.5,170.5 213.5,178.5 216.5,143.5 236.5,155.5" },
        { "code": "B0901", "name": "GOMOA EAST", path: "M 216.5,143.5 210.5,142.5 212.5,136.5 221.5,137.5 226.3,138.1 230.5,136.5 237.5,129.5 237.5,128.5 242.5,128.5 248.5,136.5 244.5,143.5 244.2,146.8 248.5,151.5 243.5,151.5 241.5,152.5 236.5,155.5" },
        { "code": "B1001", "name": "EFFUTU", path: "M 238.5,162.5 236.5,155.5 243.5,151.1 248.5,151.5 248.5,158.5" },
        { "code": "B1101", "name": "AWUTU SENYA WEST", path: "M 244.5,146.5 248.5,151.5 258.5,154.5 260.5,153.5 260.5,150.5 270.5,141.5 274.5,140.5 270.5,123.5 260.5,117.5 248.5,136.5 244.5,143.5" },
        { "code": "B1201", "name": "AWUTU SENYA EAST", path: "M 233.5,104.5 252.5,104.5 260.5,117.5 248.5,136.5 242.5,128.5 237.5,128.5 235.5,122.5 235.5,117.5 237.5,113.5" },
        { "code": "B1301", "name": "AGONA WEST", path: "M 194.5,136.5 194.5,129.5 199.5,123.5 199.5,119.5 196.5,117.5 198.5,113.5 198.5,101.5 206.5,101.5 219.5,104.5 215.5,111.5 215.5,123.5 222.5,125.5 224.5,131.5 220.5,137.5 208.5,136.5 205.5,137.5" },
        { "code": "B1401", "name": "AGONA EAST", path: "M 219.5,104.5 215.5,111.5 215.5,123.5 222.5,125.5 224.5,131.5 220.5,137.5 226.5,137.5 230.5,136.5 237.5,129.5 235.5,122.5 235.5,117.5 237.5,113.5 233.5,104.5" },
        { "code": "B1501", "name": "ASIKUMA/ODOBEN/BRAKWA", path: "M 161.5,92.5 157.5,97.5 157.5,105.5 154.5,108.5 158.5,117.5 158.7,131.7 161.5,136.5 181.5,136.5 189.5,138.5 194.5,136.5 194.5,129.5 199.5,123.5 199.5,119.5 196.5,117.5 198.5,113.5 198.5,101.5 184.5,101.5 176.5,98.5 163.5,96.5" },
        { "code": "B1601", "name": "ASSIN CENTRAL", path: "M 115.5,116.5 116.5,132.5 158.7,132.5 158.5,117.5 154.5,108.5 150.5,111.5 150.5,116.5 143.5,116.5 140.5,114.5 135.5,116.5" },
        { "code": "B1701", "name": "ASSIN NORTH", path: "M 90.5,90.5 94.5,90.5 95.5,94.5 95.5,99.5 94.5,103.5 100.5,103.5 104.5,106.5 110.5,106.5 115.5,116.5 135.5,116.5 140.5,114.5 143.5,116.5 150.5,116.5 150.5,111.5 157.5,105.5 157.5,97.5 161.5,92.5 154.5,81.5 143.5,77.5 134.5,80.5 125.5,74.5 110.5,85.5 99.5,74.5 94.5,75.5 94.5,79.5 90.5,82.5" },
        { "code": "B1801", "name": "ASSIN SOUTH", path: "M 116.5,132.5 115.5,134.5 115.5,140.5 120.5,148.5 120.5,150.5 130.5,160.5 133.5,160.5 135.5,162.5 151.5,160.5 157.5,155.5 160.5,152.5 167.5,145.5 164.5,140.5 164.5,136.5 161.5,136.5 159.5,132.5" },
        { "code": "B1901", "name": "TWIFO ATTI MORKWA", path: "M 100.5,152.5 104.5,162.5 117.5,173.5 112.5,180.5 120.5,181.5 124.5,180.5 127.5,176.5 135.5,175.5 135.5,162.5 133.5,160.5 131.5,160.5 120.5,150.5 120.5,148.5 115.5,140.5" },
        { "code": "B2001", "name": "HEMANG LOWER DENKYIRA", path: "M 80.5,89.5 80.5,95.5 73.5,101.5 73.5,107.5 69.5,108.5 60.5,119.5 61.5,121.5 69.5,118.5 80.5,120.5 92.5,128.5 91.5,145.5 100.5,152.5 115.5,140.5 115.5,134.5 116.5,132.5 115.5,116.5 110.5,106.5 104.5,106.5 100.5,103.5 94.5,103.5 95.5,99.5 95.5,94.5 94.5,90.5 90.5,90.5" },
        { "code": "B2101", "name": "UPPER DENKYIRA EAST", path: "M 59.5,59.5 35.5,62.5 49.5,76.5 55.5,79.5 59.5,85.5 58.5,95.5 63.5,95.5 58.5,119.5 60.5,119.5 69.5,108.5 73.5,107.5 73.5,101.5 80.5,95.5 80.5,89.5 90.5,90.5 90.5,82.5 94.5,79.5 94.5,75.5 81.5,78.5" },
        { "code": "B2201", "name": "UPPER DENKYIRA WEST", path: "M 30.5,19.5 21.5,18.5 15.5,26.5 6.5,30.5 11.5,44.5 20.5,48.5 26.5,57.5 35.5,62.5 45.6,61.2 35.5,49.5 27.5,33.5" }
    ],
    "GREATER ACCRA": [
        { "code": "C0101", "name": "BORTIANOR-NGLESHIE AMANFRO", path: "M 4.5,118.5 4.5,127.5 15.5,145.5 21.5,149.5 33.5,147.5 20.5,114.5" },
        { "code": "C0102", "name": "DOMEABRA/OBOM", path: "M 20.5,114.5 33.5,147.5 51.5,146.5 51.5,137.5 43.5,124.5 33.5,123.5 22.5,114.5" },
        { "code": "C0201", "name": "WEIJA / GBAWE", path: "M 21.5,149.5 29.5,154.5 34.5,179.5 44.5,176.5 33.5,147.5" },
        { "code": "C0301", "name": "ANYAA/SOWUTUOM", path: "M 34.5,148.5 44.5,176.5 51.5,173.5 51.5,169.5 50.5,163.5 48.5,157.5" },
        { "code": "C0401", "name": "TROBU", path: "M 43.5,124.5 51.5,137.5 51.5,147.5 59.5,138.5 65.5,137.5 61.5,113.5 48.5,124.5" },
        { "code": "C0501", "name": "AMASAMAN", path: "M 61.5,113.5 65.5,137.5 77.5,137.5 78.9,117.9 76.5,116.5 73.5,120.5" },
        { "code": "C0601", "name": "DOME/KWABENYA", path: "M 20.5,114.5 33.5,147.5 51.5,146.5 51.5,137.5 43.5,124.5 33.5,123.5 22.5,114.5" },
        { "code": "C0701", "name": "MADINA", path: "M 89.5,94.5 76.5,116.5 102.5,131.5 102.5,111.5 100.5,103.5" },
        { "code": "C0801", "name": "AYAWASO EAST", path: "M 93.5,150.5 109.2,141.6 102.5,131.5 87.5,144.5" },
        { "code": "C0901", "name": "AYAWASO NORTH", path: "M 87.5,144.5 102.5,131.5 77.5,137.5" },
        { "code": "C1001", "name": "AYAWASO CENTRAL", path: "M 69.5,157.5 94.1,150.5 87.5,144.5 85.5,143.5 69.5,151.5" },
        { "code": "C1101", "name": "AYAWASO WEST WUOGON", path: "M 69.5,151.5 85.4,143 77.5,137.5 69.5,137.5" },
        { "code": "C1201", "name": "OKAIKWEI SOUTH", path: "M 60.5,153.5 69.5,151.5 69.5,157.5 61.5,159.5" },
        { "code": "C1202", "name": "ABLEKUMA SOUTH", path: "M 48.5,157.5 60.5,150.5 60.5,146.5 59.5,138.5 48.5,151.5" },
        { "code": "C1203", "name": "ODODODIODIOO", path: "M 62.5,172.5 61.5,159.5 69.5,157.5 71.5,169.5" },
        { "code": "C1301", "name": "OKAIKWEI CENTRAL", path: "M 69.5,144.5 69.5,151.5 60.5,153.5 60.5,146.5" },
        { "code": "C1302", "name": "OKAIKWEI NORTH", path: "M 59.5,138.5 60.5,146.5 69.5,144.5 69.5,137.5 65.5,137.5" },
        { "code": "C1401", "name": "ABLEKUMA NORTH", path: "M 48.5,157.5 60.5,150.5 60.5,146.5 59.5,138.5 48.5,151.5" },
        { "code": "C1501", "name": "ABLEKUMA CENTRAL", path: "M 50.5,163.5 61.5,159.5 60.5,153.5 60.5,150.5 48.5,157.5" },
        { "code": "C1601", "name": "ABLEKUMA WEST", path: "M 52.5,146.5 33.5,147.5 48.5,157.5 48.5,151.5" },
        { "code": "C1701", "name": "KORLE KLOTTEY", path: "M 62.5,172.5 51.5,173.5 51.5,169.5 61.5,166.3" },
        { "code": "C1801", "name": "DADEKOTOPON", path: "M 71.5,169.5 86.5,162.5 81.5,153.5 69.5,157.5" },
        { "code": "C1901", "name": "LEDZOKUKU", path: "M 86.5,162.5 98.5,156.5 93.5,150.5 81.8,154" },
        { "code": "C2001", "name": "KROWOR", path: "M 98.5,156.5 114.5,149.5 109.5,141.5 93.5,150.5" },
        { "code": "C2101", "name": "TEMA EAST", path: "M 123.5,127.5 129.5,138.5 138.5,132.5 133.5,125.5" },
        { "code": "C2102", "name": "TEMA CENTRAL", path: "M 122.5,143.5 129.5,138.5 123.5,127.5 114.5,129.5" },
        { "code": "C2201", "name": "TEMA-WEST", path: "M 114.5,149.5 102.5,131.5 114.5,129.5 122.5,143.5" },
        { "code": "C2301", "name": "KPONE KATAMANSO", path: "M 90.5,91.5 89.5,94.5 100.5,103.5 102.5,111.5 110.5,116.5 120.5,116.5 133.5,125.5 138.5,132.5 145.5,128.5 138.5,115.5 126.5,105.5 125.5,101.5 125.5,97.5 118.5,93.5 102.5,90.5" },
        { "code": "C2401", "name": "ASHAIMAN", path: "M 114.5,129.5 120.5,116.5 132.9,125.1" },
        { "code": "C2501", "name": "ADENTAN", path: "M 102.5,111.5 102.5,131.5 114.5,129.5 120.5,116.5 110.5,116.5" },
        { "code": "C2601", "name": "SHAI-OSUDOKU", path: "M 171.5,43.5 154.5,47.5 130.5,33.5 102.5,90.5 118.5,93.5 125.5,97.5 146.5,97.5 175.5,90.5 185.5,80.5 182.5,55.5" },
        { "code": "C2701", "name": "NINGO PRAMPRAM", path: "M 125.5,97.5 146.5,97.5 175.5,90.5 185.5,80.5 191.5,79.5 191.5,83.5 182.5,89.5 188.5,97.5 188.5,115.5 157.5,123.5 153.5,124.5 145.5,128.5 138.5,115.5 126.5,105.5 125.2,101.8 " },
        { "code": "C2801", "name": "SEGE", path: "M 197.5,70.5 185.5,80.5 191.5,79.5 191.5,83.5 182.5,89.5 188.5,97.5 188.5,115.5 220.5,112.5 218.5,70.5" },
        { "code": "C2901", "name": "ADA", path: "M 218.5,70.5 220.5,112.5 273.5,117.5 262.5,107.5 251.5,99.5 241.5,75.5" }
    ],
    "VOLTA": [
        { "code": "D0101", "name": "KETA", path: "M 130.81 195.23 128.23 197.36 126.45 208.18 125.59 210.01 125.48 210.24 120.81 208.94 118.62 207.73 115.75 206.15 102.33 206.15 97.52 200.83 93.65 200.83 93.11 198.1 95.59 196 99.45 196 103.31 189.24 107.17 189.24 107.17 185.38 104.28 184.41 109.19 180.26 115.86 182.48 118.76 181.52 122.62 182.48 127.13 182.48 127.45 183.45 125.52 188.27 130.81 195.23" },
        { "code": "D0201", "name": "ANLO", path: "M 125.48 210.24 120.54 220.73 114.4 222.58 102.82 225.04 90.41 224.17 84.5 217.65 83.94 217.28 85.93 216.27 85.93 210.48 89.79 206.62 93.65 208.55 97.52 206.62 97.52 200.83 102.33 206.15 115.75 206.15 118.62 207.73 120.81 208.94 125.48 210.24" },
        { "code": "D0301", "name": "KETU SOUTH", path: "M 153.99 166.35 153.99 176.19 131.69 194.52 130.81 195.24 125.52 188.27 127.45 183.45 127.13 182.48 127.45 182.48 127.22 180.55 126.89 177.65 122.62 177.65 126.48 173.79 131.31 169.93 130.34 167.03 134.21 164.14 140 164.14 139.39 166.35 153.99 166.35" },
        { "code": "D0401", "name": "KETU NORTH", path: "M 134.21 164.14 130.34 167.03 131.31 169.93 126.48 173.79 122.62 177.65 126.89 177.65 127.22 180.55 127.45 182.48 122.62 182.48 118.76 181.51 115.86 182.48 109.19 180.26 107.17 179.58 109.1 175.72 111.03 170.89 111.03 163.17 112.96 159.31 112.96 149.65 122.62 148.69 122.62 144.21 126.22 144.21 131.77 157.99 137.09 162.04 137.66 164.14 134.21 164.14" },
        { "code": "D0501", "name": "AKATSI SOUTH", path: "M 109.1 175.72 107.17 179.58 109.19 180.26 104.28 184.41 105.18 184.71 107.17 185.38 107.17 189.24 103.31 189.24 99.45 196 95.58 196 93.11 198.1 92.69 196 90.76 196 89.79 193.1 86.89 192.14 85.93 188.27 89.79 187.31 90.76 183.45 92.69 179.58 89.79 176.69 87.86 173.79 87.86 168 86.89 164.14 86.89 157.38 84.96 155.45 84.96 152.55 83.03 150.62 84 146.76 81.29 145.85 81.27 144.97 81.31 144.73 85.44 142.9 85.93 142.9 89.79 140.96 95.58 140.96 95.71 140.38 97.52 145.79 97.52 151.58 94.62 156.41 95.58 162.21 98.48 164.14 101.38 163.17 101.38 158.34 106.21 157.38 111.03 164.14 111.03 170.89 109.1 175.72" },
        { "code": "D0601", "name": "AKATSI NORTH", path: "M 122.62 144.21 122.62 148.69 112.96 149.65 112.96 159.31 111.03 163.17 111.03 164.14 106.21 157.38 101.38 158.34 101.38 163.17 98.48 164.14 95.58 162.21 94.62 156.41 97.52 151.58 97.52 145.79 95.71 140.38 96.22 138.1 97.03 135.65 99.22 130.96 99.22 130.95 108.73 137.07 114.4 144.21 122.62 144.21" },
        { "code": "D0701", "name": "SOUTH TONGU", path: "M 97.52 200.82 97.52 206.62 93.65 208.55 89.79 206.62 85.93 210.48 85.93 216.27 83.94 217.28 83 216.66 76.23 212.12 70.32 196.49 56.81 192.92 56.8 192.92 55.61 192.61 56.97 189.24 61.79 189.24 63.72 185.38 63.72 178.62 68.55 178.62 71.45 174.76 73.38 174.76 75.31 176.69 78.21 174.76 81.94 172.88 82.07 172.82 87.86 172.82 87.86 173.79 89.79 176.69 92.69 179.58 90.76 183.45 89.79 187.31 85.93 188.27 86.89 192.14 89.79 193.1 90.76 196 92.69 196 93.11 198.1 93.65 200.82 97.52 200.82" },
        { "code": "D0801", "name": "CENTRAL TONGU", path: "M 87.86 168 87.86 172.82 82.07 172.82 81.94 172.88 78.21 174.76 75.31 176.69 73.38 174.76 71.45 174.76 68.55 178.62 63.72 178.62 63.72 185.38 61.79 189.24 56.97 189.24 51.17 186.34 51.17 183.45 46.8 177.65 50.21 177.65 49.24 174.76 50.21 170.89 53.1 172.82 54.07 169.93 56.97 167.03 56 162.21 51.17 162.21 46.34 160.27 51.17 157.38 53.1 153.51 46.11 152.64 48.06 148.69 50.21 148.69 50.53 148.47 53.1 146.76 56.97 147.72 59.04 148.02 63.72 148.69 63.72 150.62 69.52 148.69 72.24 147.33 73.86 147.24 75.31 148.69 78.09 147.02 80.14 145.79 81.3 146.18 81.29 145.85 84 146.76 83.03 150.62 84.96 152.55 84.96 155.45 86.89 157.38 86.89 164.14 87.86 168" },
        { "code": "D0901", "name": "NORTH TONGU", path: "M 51.17 186.34 56.97 189.24 55.61 192.61 54.96 192.44 42.31 192.44 34.27 198.95 32.27 183.82 32.15 183.67 32.14 183.66 28 182.48 25.49 175.62 14.54 178.53 0 167.82 2.72 154.3 13.83 148.51 14.58 145.79 15.38 142.9 28.96 142.9 31.86 144.83 37.66 147.72 40.55 145.79 43.45 149.65 45.38 152.55 46.09 152.64 45.83 153.21 46.11 152.64 53.1 153.51 51.17 157.38 46.34 160.27 51.17 162.21 56 162.21 56.97 167.03 54.07 169.93 53.1 172.82 50.21 170.89 49.24 174.76 50.21 177.65 46.8 177.65 51.17 183.45 51.17 186.34" },
        { "code": "D1001", "name": "ADAKLU", path: "M 86.89 130.34 84 134.21 84 138.07 82.07 140.96 81.34 144.58 81.1 144.83 81.31 144.73 81.27 144.97 81.1 145.79 81.29 145.85 81.3 146.18 80.14 145.79 78.09 147.02 75.31 148.69 73.38 146.76 72.24 147.33 69.52 148.69 63.72 150.62 63.72 148.69 59.04 148.02 56.97 147.72 53.1 146.76 50.53 148.47 48.11 148.59 47.95 148.6 49.24 145.79 44.41 140.96 46.34 137.1 44.41 133.24 46.34 130.34 44.41 127.45 44.41 122.62 48.27 117.79 46.34 115.86 48.27 113.93 52.14 107.17 53.1 112 55.03 109.1 55.03 105.24 59.86 105.24 59.86 109.1 64.69 105.24 68.55 107.17 75.31 112 75.31 116.83 74.34 121.65 77.24 124.55 80.14 128.41 83.03 128.41 84.96 126.48 86.89 130.34" },
        { "code": "D1101", "name": "AGOTIME-ZIOPE", path: "M 99.22 130.95 99.22 130.96 97.03 135.65 96.22 138.1 95.58 140 95.71 140.38 95.58 140.96 89.79 140.96 85.44 142.9 83.03 142.9 81.34 144.58 82.07 140.96 84 138.07 84 134.21 86.89 130.34 84.96 126.48 83.03 128.41 80.14 128.41 77.24 124.55 74.34 121.65 75.31 116.83 75.31 112 68.55 107.17 71.45 105.24 75.31 102.34 79.37 99.09 80.14 98.48 80.23 102.3 86.63 107.3 90.41 107.3 91 108.73 90.76 107.17 99.22 130.94 99.22 130.95" },
        { "code": "D1201", "name": "HO CENTRAL", path: "M 79.37 99.09 75.31 102.34 71.45 105.24 68.55 107.17 64.69 105.24 59.86 109.1 59.86 105.24 55.03 105.24 55.03 109.1 53.1 112 52.14 107.17 48.27 113.93 46.34 115.86 46.34 112.96 43.45 109.1 37.66 109.1 32.83 107.17 37.66 105.24 37.66 101.38 40.55 101.38 42.48 98.48 46.34 98.48 46.34 93.65 44.41 87.86 46.34 83.03 50.21 79.17 52.14 84 56 84.96 58.9 81.1 59.86 78.21 55.03 76.28 59.86 72.41 61.79 67.58 61.79 65.65 64.69 61.79 66.74 58.73 67.33 59.28 67.36 59.31 62.99 71.86 67.72 74.94 72.68 83.3 78.59 86.38 79.37 99.09" },
        { "code": "D1301", "name": "HO WEST", path: "M 66.74 58.73 64.69 61.79 61.79 65.65 61.79 67.58 59.86 72.41 55.03 76.28 59.86 78.21 58.9 81.1 56 84.96 52.14 84 50.21 79.17 46.34 83.03 44.41 87.86 46.34 93.65 46.34 98.48 42.48 98.48 40.55 101.38 37.66 101.38 37.66 105.24 32.83 107.17 37.66 109.1 43.45 109.1 46.34 112.96 46.34 115.86 48.27 117.79 44.41 122.62 44.41 127.45 46.34 130.34 44.41 133.24 46.34 137.1 44.41 140.96 49.24 145.79 47.95 148.6 46.34 148.69 47.91 148.69 46.09 152.64 45.38 152.55 43.45 149.65 40.55 145.79 37.66 147.72 31.86 144.83 28.96 142.9 15.38 142.9 19.26 128.83 15.36 125.38 14.88 122.48 16.83 119.52 20.28 117.79 25.1 112.96 26.07 108.14 25.18 106.81 24.14 105.24 26.44 104.91 30.9 104.28 32.83 100.41 34.76 97.52 34.76 92.25 34.97 91.94 35.72 91.72 34.76 91.72 34.76 87.86 37.66 85.93 40.55 81.1 42.48 75.31 42.48 72.41 44.41 66.62 42.48 63.72 40.55 58.9 41.52 56.97 44.41 59.86 46.34 62.76 49.24 66.62 52.09 65.91 53.1 65.65 56.97 62.76 58.9 57.93 58.9 55.55 61.7 51.3 62.04 54.4 66.74 58.73" },
        { "code": "D1401", "name": "SOUTH DAYI", path: "M 34.76 91.72 34.76 97.52 32.83 100.41 30.9 104.28 26.44 104.91 24.14 105.24 25.18 106.81 26.07 108.14 25.1 112.96 20.28 117.79 16.83 119.52 16.41 119.72 14.85 122.32 13.83 116.15 3.9 114.92 9.26 100.98 10.62 102.34 15.45 101.38 19.31 99.45 25.1 95.1 26.05 94.39 27.03 93.65 31.86 91.72 34.76 91.72" },
        { "code": "D1501", "name": "KPANDO", path: "M 44.41 45.38 44.41 49.24 40.55 49.24 37.66 51.17 37.66 54.07 35.61 55.43 34.76 56 35.31 57.66 34.76 61.79 28.96 61.79 24.14 59.86 24.14 56 21.24 53.1 16.96 51.67 18.08 37.05 26.52 31.89 27.59 30.84 32.83 34.76 35.72 41.52 37.61 40.58 39.59 43.45 42.48 45.38 44.41 45.38" },
        { "code": "D1601", "name": "NORTH DAYI", path: "M 32.83 67.58 34.76 68.55 34.76 70.48 30.9 70.48 28.96 73.38 29.93 75.31 28 81.1 27.03 87.86 25.1 91.72 25.1 94.65 25.01 94.68 25.1 95.1 19.31 99.45 15.45 101.38 10.62 102.34 9.26 100.98 7.72 99.45 10.1 98.79 10.99 96.47 18.08 90.69 15.36 79.62 8.63 69.4 16.55 57.1 16.6 56.41 16.6 56.41 16.96 51.67 21.24 53.1 24.14 56 24.14 59.86 28.96 61.79 34.76 61.79 31.86 64.69 32.83 67.58" },
        { "code": "D1701", "name": "HOHOE", path: "M 81.31 3.58 78 17.12 74.22 25.48 72.89 40.46 72.79 41.52 58.9 41.52 55.03 38.62 50.21 40.55 46.34 40.55 46.34 37.66 41.52 35.72 41.52 38.62 37.61 40.57 37.61 40.58 35.72 41.52 32.83 34.76 27.59 30.84 28.63 29.82 34.27 24.25 37.3 9.61 46.94 0 57.15 2.37 57.15 9.61 62.83 5.31 72.47 4.72 80.72 0 81.31 3.58" },
        { "code": "D1801", "name": "AFADJATO SOUTH", path: "M 72.79 41.52 72.44 45.41 67.95 49.11 61.45 49.11 61.7 51.3 58.9 55.03 58.9 57.93 56.97 62.76 53.1 65.65 52.09 65.91 49.24 66.62 46.34 62.76 44.41 59.86 41.52 56.97 40.55 58.9 42.48 63.72 44.41 66.62 42.48 72.41 42.48 75.31 40.55 81.1 37.66 85.93 34.76 87.86 34.76 91.72 31.86 91.72 27.03 93.65 26.05 94.39 25.1 94.65 25.1 91.72 27.03 87.86 28 81.1 29.93 75.31 28.96 73.38 30.9 70.48 34.76 70.48 34.76 68.55 32.83 67.58 31.86 64.69 34.76 61.79 35.72 58.9 35.31 57.66 35.61 55.43 37.66 54.07 37.66 51.17 40.55 49.24 44.41 49.24 44.41 45.38 42.48 45.38 39.59 43.45 37.61 40.58 37.61 40.57 41.52 38.62 41.52 35.72 46.34 37.66 46.34 40.55 50.21 40.55 55.03 38.62 58.9 41.52 72.79 41.52" }
    ],
    "EASTERN": [
        { "code": "E0101", "name": "ASUOGYAMAN", path: "M 219.5,162.5 222.5,155.5 224.5,144.5 237.5,137.5 241.5,118.5 238.5,113.5 237.5,104.5 226.5,103.5 219.5,103.5 207.5,101.5 204.5,127.5 213.5,140.5" },
        { "code": "E0201", "name": "LOWER MANYA KROBO", path: "M 190.5,144.5 201.5,152.5 214.5,172.5 219.5,162.5 213.5,140.5 204.5,127.5" },
        { "code": "E0301", "name": "UPPER MANYA KROBO", path: "M 207.5,101.5 204.5,127.5 190.5,144.5 179.5,136.5 167.5,138.5 171.5,131.5 174.5,124.5 176.5,121.5 181.5,117.5 182.5,110.5 186.5,103.5 186.5,92.5" },
        { "code": "E0401", "name": "YILO KROBO", path: "M 161.5,142.5 172.5,164.5 179.5,172.5 186.5,165.5 195.5,164.5 199.5,177.5 204.5,177.5 207.5,185.5 214.5,172.5 201.5,152.5 190.5,144.5 179.5,136.5 167.5,138.5" },
        { "code": "E0501", "name": "NEW JUABEN SOUTH", path: "M 161.5,172.5 172.5,164.5 166.5,152.5 156.5,162.5" },
        { "code": "E0601", "name": "NEW JUABEN NORTH", path: "M 171.5,179.5 179.5,172.5 172.5,164.5 161.5,172.5" },
        { "code": "E0701", "name": "AKROPONG", path: "M 190.5,203.5 194.5,196.5 202.5,196.5 193.5,184.5 184.5,184.5 176.5,174.5 171.5,179.5 171.5,185.5 167.5,189.5 166.5,194.5 172.5,199.5" },
        { "code": "E0801", "name": "OKERE", path: "M 202.5,196.5 207.5,185.5 204.5,177.5 199.5,177.5 195.5,164.5 186.5,165.5 176.5,175.1 184.5,184.5 193.5,184.5" },
        { "code": "E0901", "name": "AKUAPEM SOUTH", path: "M 182.5,217.5 161.5,202.5 166.5,194.5 172.5,199.5 190.2,203.2" },
        { "code": "E1001", "name": "NSAWAM/ADOAGYIRI", path: "M 156.5,219.5 172.5,219.5 182.5,217.5 161.5,202.5 148.8,211.9" },
        { "code": "E1101", "name": "SUHUM", path: "M 166.5,194.5 158.5,185.5 140.5,185.5 128.5,179.5 126.5,172.5 134,171.3 146.5,169.5 156.5,162.5 161.5,172.5 171.5,179.5 171.5,185.5 167.5,189.5" },
        { "code": "E1201", "name": "AYENSUANO", path: "M 158.5,185.5 166.5,194.5 161.5,202.5 148.5,212.5 136.5,209.5 126.5,203.5 123.5,194.5 113.5,177.5 126.5,172.5 128.5,179.5 139.5,185.5" },
        { "code": "E1301", "name": "LOWER WEST AKIM", path: "M 136.5,221.5 107.5,221.5 107.5,208.5 105.5,205.5 123.5,194.5 126.5,203.5 136.5,209.5 148.5,212.5 136.5,215.5" },
        { "code": "E1401", "name": "UPPER WEST AKIM", path: "M 113.5,177.5 123.5,194.5 106,205.5 103.5,200.5 91.5,189.5 91.5,185.5 103.5,179.5" },
        { "code": "E1501", "name": "AKIM ODA", path: "M 67.5,179.5 71.5,185.5 77.5,186.5 97.5,219.5 77.5,217.5 74.5,211.5 74.5,200.5 67.5,198.5 56.5,189.5 " },
        { "code": "E1601", "name": "ASENE/AKROSO/MANSO", path: "M 91.5,185.5 91.5,189.5 103.5,200.5 107.5,208.5 107.5,221.5 97.2,219.4 77.5,186.5 82.5,183.5" },
        { "code": "E1701", "name": "AKIM SWEDRU", path: "M 77.5,217.5 74.5,211.5 74.5,200.5 67.5,198.5 56.5,189.5 48.9,189.5 61.5,215.5 70.5,217.5" },
        { "code": "E1801", "name": "ACHIASE", path: "M 61.5,215.5 47.5,186.5 28.5,186.5 28.5,195.5 40.5,199.5 48.5,213.5" },
        { "code": "E1901", "name": "OFOASE/AYIREBI", path: "M 28.5,186.5 47.5,186.5 50.5,189.5 56.5,189.5 67.5,179.5 69.5,166.5 67.5,158.5 64.5,154.5 64.5,144.5 71.5,135.5 47.5,135.5 38.5,149.5 28.5,181.5" },
        { "code": "E2001", "name": "KADE", path: "M 69.5,166.5 83.5,162.5 89.5,162.5 95.5,165.5 123.5,162.5 121.5,154.5 114.5,154.5 86.5,128.5 86.5,124.5 71.5,135.5 64.5,144.5 64.5,154.5 67.5,158.5" },
        { "code": "E2101", "name": "AKWATIA", path: "M 126.5,172.5 113.5,177.5 102.5,179.5 91.5,185.5 82.5,183.5 77.5,186.5 71.5,185.5 67.5,179.5 70.5,166.5 83.5,162.5 89.5,162.5 95.5,165.5 124.5,162.5" },
        { "code": "E2201", "name": "ABIREM", path: "M 47.5,135.5 71.5,135.5 86.5,124.5 86.5,118.5 93.5,113.5 92,106.5 79.5,103.5 67.5,103.5" },
        { "code": "E2301", "name": "ABUAKWA SOUTH", path: "M 137.5,143.5 132.5,144.5 123.5,144.5 121.5,152.5 121.5,154.5 123.5,162.5 124.5,162.5 126.5,172.5 146.5,169.5" },
        { "code": "E2401", "name": "ABUAKWA NORTH", path: "M 149.5,138.5 137.5,143.5 146.5,169.5 156.5,162.5 166.5,152.5 161.5,142.5" },
        { "code": "E2501", "name": "ATIWA WEST", path: "M 114.5,154.5 121.5,154.5 121.5,152.5 108.5,127.5 103.5,118.5 93.5,113.5 86.5,118.5 86.5,128" },
        { "code": "E2601", "name": "ATIWA EAST", path: "M 121.5,152.5 108.5,127.5 113.5,111.5 116.5,110.5 139.5,121.5 136.5,130.5 129.5,137.5 137.5,143.5 132.5,144.5 123.5,144.5" },
        { "code": "E2701", "name": "FANTEAKWA NORTH", path: "M 176.5,121.5 181.5,117.5 182.5,110.5 186.5,104.5 186.5,92.5 164.5,86.5 161.5,92.5 146.5,92.5 142.5,95.5 142.5,103.5 146.5,108.5 146.5,113.5 156.5,116.5 166.5,116.5" },
        { "code": "E2801", "name": "FANTEAKWA SOUTH", path: "M 129.5,137.5 136.5,130.5 139.5,121.5 146.5,113.5 156.5,116.5 167.5,116.5 176.5,121.5 174.5,125.5 168.5,137.5 161.5,142.5 149.5,138.5 137.5,143.5" },
        { "code": "E2901", "name": "NKAWKAW", path: "M 67.5,103.5 79.5,103.5 91.5,106.5 93.5,113.5 103.5,118.5 108.5,127.5 113.5,110.5 116.5,110.5 112.5,101.5 106.5,94.5 86.5,92.5 76.5,88.5" },
        { "code": "E3001", "name": "MPRAESO", path: "M 139.5,121.5 146.5,113.5 146.5,108.5 142.5,103.5 142.5,95.5 146.5,92.5 161.5,92.5 164.5,86.5 128.5,70.5 128.5,76.5 121.5,82.5 111.5,82.5 107.5,83.5 101.5,83.5 86.5,92.5 106.5,94.5 112.5,100.5 116.5,110.5" },
        { "code": "E3101", "name": "ABETIFI", path: "M 70.5,69.5 91.5,67.5 101.5,53.5 111.5,61.5 119.5,70.5 128.5,70.5 128.5,76.5 121.5,82.5 111.5,82.5 107.5,83.5 101.5,83.5 86.5,92.5 76.5,88.5" },
        { "code": "E3201", "name": "AFRAM PLAINS NORTH", path: "M 217.5,5.5 176.5,5.5 174.5,14.5 176.5,28.5 185.5,32.5 199.5,32.5 203.5,37.5 205.5,43.5 211.5,50.5 213.5,55.5 222.5,55.5 231.5,57.5 239.5,44.5 240.5,28.5 243.5,11.5 226.5,11.5" },
        { "code": "E3301", "name": "AFRAM PLAINS SOUTH", path: "M 121.5,24.5 148.5,24.5 175.8,8.6 174.5,14.5 176.5,28.5 185.5,32.5 198.3,32.5 203.5,37.5 205.5,43.5 211.5,50.5 213.5,55.5 222.5,55.5 231.5,57.5 238.5,66.5 241.5,77.5 233.5,84.5 226.5,103.5 218.5,103.5 207.5,101.5 185.5,92.5 164.5,86.5 128.5,70.5 119.5,70.5 111.5,61.5 101.5,53.5" }
    ],
    "ASHANTI": [
        { code: "F0101", name: "NEW EDUBIASE", path: "M 153.5,189.5 137.5,190.5 117.5,195.5 117.5,200.5 114.5,205.5 113.5,211.5 122.5,221.5 135.5,211.5 141.5,216.5 148.5,214.5 149.5,202.5" },
        { code: "F0201", name: "AKROFUOM", path: "M 79.5,199.5 90.5,208.5 97.5,214.5 113.5,211.5 113.5,203.5 90.5,190.5 86.5,190.5" },
        { code: "F0301", name: "FOMENA", path: "M 126.5,159.5 113.5,159.5 101.5,167.5 97.5,167.5 100.5,175.5 106.5,175.5 121.5,170.5 126.5,163.5" },
        { code: "F0401", name: "ADANSI ASOKWA", path: "M 127.5,163.5 124.5,166.5 121.5,170.5 106.5,175.5 104.5,181.5 110.5,189.5 117.5,195.5 137.5,190.5 145.5,189.5 143.5,182.5 143.5,175.5 137.5,170.5" },
        { code: "F0501", name: "OBUASI WEST", path: "M 97.5,167.5 93.5,170.5 93.5,176.5 91.5,190.5 96.9,194 104.5,181.5 106.5,175.5 100.5,175.5" },
        { code: "F0601", name: "OBUASI EAST", path: "M 96.5,193.9 104.5,181.5 110.5,189.5 117.5,195.5 117.5,200.5 114.5,204.5" },
        { code: "F0701", name: "BEKWAI", path: "M 101.5,137.5 98.5,139.5 99.5,148.5 88.5,156.5 91.5,161.5 97.5,161.5 97.5,167.5 101.5,167.5 113.5,159.5 114.5,150.5 109.5,141.5 104.5,141.5" },
        { code: "F0801", name: "BOSOME FREHO", path: "M 156.5,180.5 153.5,189.5 145.5,189.5 143.5,181.5 143.5,175.5 137.5,170.5 126.5,163.5 126.5,159.5 113.5,159.5 114.5,150.5 109.5,141.5 114.5,139.5 137.5,146.5 142.5,150.5 147.5,167.5 150.5,170.5" },
        { code: "F0901", name: "ODOTOBIRI", path: "M 69.5,200.5 59.5,188.5 69.5,179.5 69.5,173.5 67.5,172.5 69.5,167.5 74.5,163.5 79.5,163.5 83.5,158.5 88.5,156.5 91.5,161.5 97.5,161.5 97.5,167.5 93.5,170.5 93.5,176.5 91.5,190.5 86.5,190.5 79.5,199.5" },
        { code: "F1001", name: "MANSO NKWANTA", path: "M 62.5,141.5 59.5,145.5 51.5,152.5 41.5,159.5 43.5,165.5 56.5,167.5 54.5,178.5 59.5,189.5 69.5,179.5 69.5,173.5 67.5,172.5 69.5,167.5 74.5,163.5" },
        { code: "F1101", name: "MANSO ADUBIA", path: "M 79.5,130.5 60.5,130.5 62.5,141.5 74.5,163.5 79.5,163.5 83.5,159.5 88.5,156.5 99.5,148.5 98.5,139.5 94.5,134.5 83.5,139.5 82.5,134.5" },
        { code: "F1201", name: "ATWIMA NWABIAGYA SOUTH", path: "M 97.5,108.5 94.5,111.5 94.5,117.5 94.5,121.5 86.5,121.5 81.5,124.5 79.5,130.5 60.5,130.5 66.5,121.5 68.5,111.5 74.5,108.5 79.5,108.5 84.5,107.5" },
        { code: "F1301", name: "ATWIMA NWABIAGYA NORTH", path: "M 82.5,82.5 77.5,86.5 77.5,102.5 82.5,102.5 84.5,107.5 98.5,108.5 98.5,103.5 96.5,102.5 96.5,97.5 98.5,93.5 93.5,90.5 91.5,87.5 87.5,87.5" },
        { code: "F1401", name: "ATWIMA MPONUA", path: "M 8.5,119.5 13.5,132.5 5.5,148.5 5.5,156.5 9.5,159.5 15.5,152.5 17.5,139.5 24.5,143.5 31.5,143.5 30.5,156.5 41.5,159.5 51.5,152.5 59.5,145.5 62.5,141.5 60.5,130.5 66.5,121.5 68.5,112.5 65.5,108.5 57.5,108.5 52.5,108.5 47.5,113.5 41.5,113.5 34.5,109.5 31.5,113.5 22.5,113.5 21.5,119.5" },
        { code: "F1501", name: "BOSOMTWE", path: "M 118.5,125.5 134.5,131.5 138.5,136.5 140,148.5 137.5,146.5 114.5,139.5 109.5,141.5 104.5,133.5 106.5,129.5" },
        { code: "F1601", name: "ATWIMA KWANWOMA", path: "M 106.5,128.5 93.5,121.5 86.5,121.5 81.5,124.5 79.5,130.5 82.5,133.5 83.5,139.5 94.5,134.5 98.5,139.5 101.5,137.5 104.5,141.5 109.5,141.5 104.5,133.5" },
        { code: "F1701", name: "BANTAMA", path: "M 103.5,111.5 103.5,117.5 98.5,117.5 98.5,108.5" },
        { code: "F1702", name: "NHYIAESO", path: "M 98.5,117.5 98.5,120.5 96.6,123.2 93.5,121.5 94.5,120.3 94.5,117.5" },
        { code: "F1703", name: "MANHYIA SOUTH", path: "M 119.5,121.5 110.5,120.5 110.5,117.5 116.5,116.5" },
        { code: "F1704", name: "MANHYIA NORTH", path: "M 116.5,116.5 110.5,117.5 110.5,112.5 116.5,112.5" },
        { code: "F1705", name: "SUBIN", path: "M 103.5,117.5 103.5,122.5 102.3,126.2 95.5,123.5 98.5,120.5 98.5,117.5 " },
        { code: "F1801", name: "KWADASO", path: "M 98.5,108.5 98.5,117.5 94.5,117.5 94.5,111.5" },
        { code: "F1901", name: "OLD TAFO", path: "M 106.5,118.5 103.5,119 103.5,111.5 106.5,111.5" },
        { code: "F2001", name: "SUAME", path: "M 110.5,120.5 110.5,112.5 106.5,111.5 106.5,118.5" },
        { code: "F2101", name: "ASOKWA", path: "M 113.8,127.1 106.5,129.5 102.5,126.5 103.5,123.5 112.5,124.5" },
        { code: "F2201", name: "OFORIKROM", path: "M 118.5,125.5 118.5,121.5 110.5,120.5 113.5,126.5" },
        { code: "F2301", name: "ASAWASE", path: "M 112.5,124.5 103.5,123.2 103.5,118.5 106.5,118.5 110.5,120.5" },
        { code: "F2401", name: "KWABRE EAST", path: "M 123.5,112.5 123.5,106.5 128.5,98.5 118.5,98.5 106.5,111.5 110.5,112.5 116.5,112.5" },
        { code: "F2501", name: "AFIGYA KWABRE SOUTH", path: "M 107.5,97.5 98.5,104.5 98.5,108.5 103.5,111.5 106.5,111.5 118.5,98.5 118.5,97.5" },
        { code: "F2601", name: "AFIGYA KWABRE NORTH", path: "M 98.5,94.5 102.5,90.5 99.5,72.5 101.5,67.5 101.5,61.5 106.5,61.5 110.5,64.5 108.5,68.5 112.5,74.5 113.5,97.5 108.5,97.5 98.5,104.5 96.5,102.5 96.5,97.5" },
        { code: "F2701", name: "JUABEN", path: "M 126.5,101.5 123.5,106.5 123.5,112.5 116.5,112.5 116.5,116.5 119.5,121.5 118.5,125.5 145.5,115.5 141.5,113.5 137.5,106.5" },
        { code: "F2801", name: "EJISU", path: "M 145.5,115.5 118.5,125.5 134.5,131.5 138.5,136.5 139.5,148.5 142.5,150.5 145.5,143.5 144.5,133.5 146.5,130.5" },
        { code: "F2901", name: "ASANTE AKIM SOUTH", path: 'M 184.5,111.5 188.5,126.5 156.5,180.5 147.5,167.5 142.5,150.5 145.5,143.5 150.5,143.5 154.5,140.5 158.5,140.5 158.5,130.5 162.5,129.5 163.5,121.5 175.5,121.5' },
        { code: "F3001", name: "ASANTE AKIM CENTRAL", path: "M 161.5,99.5 156.5,106.5 154.5,113.5 145.8,120.2 146.5,130.5 144.5,133.5 145.5,143.5 150.5,143.5 154.5,140.5 158.5,140.5 158.5,130.5 161.5,129.5 163.5,121.5 175.5,121.5" },
        { code: "F3101", name: "ASANTE AKIM NORTH", path: "M 180.5,78.5 172.5,92.5 161.5,99.5 175.5,121.5 184.5,110.5 201.5,109.5 209.5,98.5 204.5,98.5 201.5,94.5 197.5,86.5 187.5,78.5" },
        { code: "F3201", name: "EFFIDUASE / ASOKORE", path: "M 146.5,95.5 146.5,98.5 156.5,105.5 154.5,113.5 146.5,119.7 145.5,115.5 141.5,113.5 137.5,106.5 126.5,101.7 130.5,94.5 137.5,94.5" },
        { code: "F3301", name: "KUMAWU", path: "M 132.5,88.5 139.5,88.5 139.5,90.5 147.5,90.5 156.5,83.5 158.5,77.5 168.5,71.5 171.5,66.5 177.5,66.5 183.5,59.5 183.5,57.5 200.5,24.5 206.5,20.5 225.5,74.5 209.5,98.5 204.5,98.5 200.8,93.1 197.5,86.5 187.5,78.5 180.5,78.5 172.5,92.5 161.5,99.5 157.2,105.5 146.5,98.5 146.5,95.5 132.5,94.5" },
        { code: "F3401", name: "SEKYERE AFRAM PLAINS", path: "M 216.5,17.5 230.5,36.5 263.5,46.5 273.5,60.5 248.5,74.5 225.5,74.5 206.5,20.5" },
        { code: "F3501", name: "NSUTA/KWAMANG/BEPOSO", path: "M 188.5,20.5 178.5,16.5 168.5,23.5 167.5,27.5 161.5,33.5 158.5,36.5 152.5,38.5 144.5,38.5 144.5,40.5 150.5,44.5 153.5,49.5 153.5,55.5 150.5,57.5 145.5,57.5 137.5,66.5 136.5,75.5 133.5,83.5 133.5,88.5 139.5,88.5 139.5,90.5 147.5,90.5 156.5,83.5 158.5,77.5 168.5,71.5 171.5,66.5 177.5,66.5 183.5,59.5 183.5,57.5 200.5,24.5 206.5,20.5" },
        { code: "F3601", name: "MAMPONG", path: "M 115.5,66.5 119.5,68.5 125.5,79.5 125.5,87.5 129.7,96 130.5,94.5 132.5,94.5 132.5,88.5 136.6,74.3 137.5,66.5 145.5,57.5 150.5,57.5 153.5,55.5 153.5,49.5 150.5,44.5 144.5,40.5 144.5,38.5 143.1,38.5 141.5,40.5 139.5,43.5 129.5,46.5 122.5,51.5" },
        { code: "F3701", name: "EJURA SEKYEDUMASE", path: "M 106.5,61.5 101.5,61.5 103.5,54.5 104.5,47.5 104.5,41.5 102.5,39.5 115.5,33.5 115.5,23.5 130.5,7.5 138.5,13.5 144.5,25.5 165.5,24.5 168.5,23.5 167.5,27.5 158.5,36.5 152.5,38.5 143.5,38.5 141.5,40.5 139.5,43.5 129.5,46.5 122.5,51.5 115.5,66.5 110.5,64.5" },
        { code: "F3801", name: "AFIGYA SEKYERE EAST", path: "M 108.5,68.5 112.5,74.5 113.5,97.5 118.5,97.5 121,98.5 128.5,98.5 129.5,95.5 125.5,87.5 125.5,79.5 119.5,68.5 115.5,66.5 110.5,64.5" },
        { code: "F3901", name: "OFFINSO SOUTH", path: "M 78.5,62.5 82.5,68.5 82.5,79.5 82.5,82.5 87.5,87.5 91.5,87.5 93.5,90.5 98.5,93.5 102.5,90.5 99.5,72.5 101.5,67.5 101.5,61.5 91.5,62.5" },
        { code: "F4001", name: "OFFINSO NORTH", path: "M 76.5,24.5 66.5,28.5 58.5,24.5 49.5,27.5 46.5,25.5 40.5,39.5 50.5,43.5 59.5,44.5 64.5,49.5 75.5,55.5 73.5,62.5 78.5,62.5 91.5,62.5 101.5,61.5 103.5,54.5 104.5,46.7 104.5,41.5 102.5,39.5 98.5,39.5 94.5,38.5 89.5,41.5 80.5,34.5" },
        { code: "F4101", name: "AHAFO ANO SOUTH WEST", path: "M 54.5,76.5 54.5,86.5 42.5,92.5 42.5,101.5 34.5,109.5 41.5,113.5 47.5,113.5 52.5,108.5 65.5,108.5 65.5,92.5 68.5,85.5 68.5,64.5 65.5,65.5" },
        { code: "F4201", name: "AHAFO ANO SOUTH EAST", path: "M 68.5,64.5 68.5,85.5 65.5,92.5 65.5,108.5 68.5,112.5 74.5,108.5 80.3,108.5 84.5,107.5 82.5,102.5 77.5,102.5 77.5,86.5 82.5,82.5 82.5,68.5 78.5,62.5 73.5,62.5" },
        { code: "F4301", name: "AHAFO ANO NORTH", path: "M 31.5,72.5 18.5,82.5 18.5,90.5 12.5,98.5 24.5,98.5 29.5,101.5 24.5,104.5 22.5,113.5 31.5,113.5 34.5,109.5 42.5,101.5 42.5,93.5 54.5,86.5 54.5,76.5 35.5,77.5" }
    ],
    "AHAFO": [
        { code: "H0101", name: "ASUNAFO SOUTH", path: "M 142.28 118.77 131.86 123.29 127.14 152.2 103.9 153.33 101.4 145 104.4 138 99.4 133 96.4 127 84.08 124.16 81.4 117 77.4 111 80.4 109 79.4 105 75.4 98 81.4 91 79.65 87.5 88.4 88 94.4 92 101.4 92 109.4 89 114.4 83 120.65 83.12 120.65 95.76 108.26 114.05 129.89 112.08 142.28 118.77" },
        { code: "H0201", name: "ASUNAFO NORTH", path: "M 84.4 151 81.4 156 74.4 158 67.4 155 60.4 148 67.4 148 67.4 146 58.4 142 54.4 136 47.4 138 41.4 147 34.4 150 26.4 150 21.4 152 14.4 154 6.6 159.53 6.6 126.34 0 103.69 6.6 87.64 19.55 76.47 19.97 76.1 20.36 75.32 29.4 70 34.4 73 39.4 73 42.4 76 48.4 72 52.4 73 55.4 70 57.4 76 70.4 80 78.4 85 81.4 91 75.4 98 79.4 105 80.4 109 77.4 111 81.4 117 84.08 124.16 83.4 124 84.24 125.24 80.4 131 82.4 138 81.4 145 84.4 151" },
        { code: "H0301", name: "ASUTIFI SOUTH", path: "M 142.28 118.77 131.86 123.29 127.14 152.2 103.9 153.33 101.4 145 104.4 138 99.4 133 96.4 127 84.08 124.16 81.4 117 77.4 111 80.4 109 79.4 105 75.4 98 81.4 91 79.65 87.5 88.4 88 94.4 92 101.4 92 109.4 89 114.4 83 120.65 83.12 120.65 95.76 108.26 114.05 129.89 112.08 142.28 118.77" },
        { code: "H0401", name: "ASUTIFI NORTH", path: "M 128.4 58 126.4 61 126.4 67 121.4 70 120.75 81.34 120.65 81.41 120.65 83.12 114.4 83 109.4 89 101.4 92 94.4 92 88.4 88 79.65 87.5 78.4 85 70.4 80 57.4 76 55.4 70 52.4 73 48.4 72 42.4 76 39.4 73 34.4 73 29.4 70 20.36 75.32 27.37 61.21 38.69 49.89 61.35 38.74 75.5 21.83 88.72 27.23 88.72 48 104.76 48 108.81 56.08 114.69 50.04 120.4 50 120.4 54 126.4 54 128.4 58" },
        { code: "H0501", name: "TANO SOUTH", path: "M 229.39 30.87 224.28 47.19 211.7 49.16 191.44 70.2 153.79 72.74 162.4 67 167.4 64 170.4 59 175.4 59 176.4 52 178.4 48 175.4 43 174.4 37 173.4 32 173.4 25 171.4 20 174.4 12 175.87 5.52 182.99 8.65 198.72 9.83 205.8 19.07 229.39 30.87" },
        { code: "H0601", name: "TANO NORTH", path: "M 178.4 48 176.4 52 175.4 59 170.4 59 167.4 64 162.4 67 153.79 72.74 153.49 72.76 153.48 72.75 146.22 63.71 120.75 81.34 121.4 70 126.4 67 126.4 61 128.4 58 126.4 54 120.4 54 120.4 50 114.69 50.04 124.13 40.33 119.96 33.84 124.59 21.83 134.02 21.83 141.57 7.41 163.32 0 175.87 5.52 174.4 12 171.4 20 173.4 25 173.4 32 174.4 37 175.4 43 178.4 48" }
    ],
    "BONO": [
        { code: "J0101", name: "SUNYANI EAST", path: "M 99.1,154.3 96,155.4 95.6,156.2 92.6,161.9 88.3,161.9 86.2,167.3 88.1,170.3 81.2,177.4 79.4,173.7 72.1,173.7 72.1,164.4 70.7,163.8 71.2,160.3 71.1,160.1 75,159.5 75,155.6 77.8,153.7 82.6,151.8 85.4,148 87.3,149.9 91.1,149 94.9,149.9 97.7,148 97.7,150.9" },
        { code: "J0201", name: "SUNYANI WEST", path: "M 109.9,140.8 105.9,152 99.1,154.3 97.7,150.9 97.7,148 94.9,149.9 91.1,149 87.3,149.9 85.4,148 82.6,151.8 77.8,153.7 75,155.6 75,159.5 71.1,160.1 68.9,155 68.4,153.7 68.4,148 70.3,145.2 65.5,140.5 70.3,137.6 68.4,133.8 68.4,130.1 73.1,128.2 71.2,126.3 72,125.1 73.1,123.4 75.9,127.2 78.8,128.2 84.5,127.2 88.2,127.2 92,125.3 93,123.4 94.8,124.2 94.9,124.2 97.7,125.3 97.7,125.3 95.8,127.2 96.8,128.2 93,131 93,135.7 96.7,137.6 96.8,137.6 101.5,135.7 107.6,135.7" },
        { code: "J0301", name: "DORMAA WEST", path: "M 42.7,183.1 41,186.4 35,191.7 32,198.9 35,209.2 35,225 32.5,209.3 25.4,193.6 13,193.6 7.1,186.4 8.3,182.9 15.1,181.4 15.1,175.5 16.6,173.5 24.8,178.3 24.8,181.2 27.7,185 32.4,185 38.1,183.1" },
        { code: "J0401", name: "DORMAA CENTRAL", path: "M 52.9,172.9 49.5,174.6 44.3,179.7 43,182.4 42.7,183.1 38.1,183.1 32.4,185 27.7,185 24.8,181.2 24.8,178.3 16.6,173.5 17,173 22.5,165.4 20.1,162.4 25.2,139.9 25.2,136.8 27.7,137.6 30.5,139.5 32.4,143.3 35.2,140.5 39.7,138.2 40.2,138 44.7,139.5 47.6,140.5 51.3,146.2 44.7,146.2 41.9,148 42.8,153.7 39,153.7 39,157.5 43.8,158.5 47.6,163.2 46.6,166 49.4,167.9 51.3,171.7" },
        { code: "J0501", name: "DORMAA EAST", path: "M 71.2,160.3 70.7,163.8 70.3,167 55.1,174.5 52.9,172.9 52.9,172.9 51.3,171.7 49.4,167.9 46.6,166 47.6,163.2 43.8,158.5 39,157.5 39,153.7 42.8,153.7 41.9,148 44.7,146.2 51.3,146.2 57,148 57,150.9 62.7,152.8 68.4,153.7 68.9,155 71.1,160.1" },
        { code: "J0601", name: "BEREKUM EAST", path: "M 70.3,137.6 65.5,140.5 70.3,145.2 68.4,148 68.4,153.7 62.7,152.8 61.5,152.4 61.5,151.4 61,150.2 61,145.4 59.2,142.2 58.8,141.2 58.8,139.9 57.5,138.7 58.8,136.8 57.3,135.5 56.7,134 57.3,133.6 56,132.5 55.1,131.5 55.1,126 53.2,122.6 53.1,122.4 56,117.6 61.7,116.8 61.7,116.4 66.4,120.4 65.5,121.5 68.4,123.4 72,125.1 71.2,126.3 73.1,128.2 68.4,130.1 68.4,133.8" },
        { code: "J0701", name: "BEREKUM WEST", path: "M 61.5,151.4 61.5,152.4 61.1,152.3 57,150.9 57,148 51.3,146.2 47.6,140.5 44.7,139.5 40.2,138 40.9,137.6 43.8,135.7 43.8,130.1 49.4,130.1 47.6,128.2 53.2,128.2 51.3,125.3 52.6,123.2 53.1,122.4 53.2,122.6 55.1,126 55.1,131.5 56,132.5 57.3,133.6 56.7,134 57.3,135.5 58.8,136.8 57.5,138.7 58.8,139.9 58.8,141.2 59.2,142.2 61,145.4 61,150.2" },
        { code: "J0801", name: "JAMAN SOUTH", path: "M 53.2,122.5 51.3,125.3 53.2,128.2 47.6,128.2 49.4,130.1 43.8,130.1 43.8,135.7 40.9,137.6 40.2,138 39.7,138.2 35.2,140.5 32.4,143.3 30.5,139.5 27.7,137.6 25.2,136.8 25.2,126.2 29.4,119.1 36.1,102.6 37.5,101.8 40.9,103.6 45.7,105.5 45.7,110.2 49.4,114 52.3,115.9 54.5,117.3 55.1,117.8 56,117.6" },
        { code: "J0901", name: "JAMAN NORTH", path: "M 70.3,78 67.4,81.8 66.9,84 66.8,84.2 64.6,85.6 63.6,91.3 59.9,92.2 61.7,95 63.6,98.8 63.6,100.7 62.7,103.6 63.6,109.2 63.6,111.1 61.7,114 61.7,116.8 56,117.6 55.1,117.8 54.5,117.3 52.3,115.9 49.4,114 45.7,110.2 45.7,105.5 40.9,103.6 37.5,101.8 41,99.9 41,91 42.7,91.5 51,82.6 60.1,80.3 58.1,70.5 59.8,65.9 60.6,66.2 62.1,66.8 62.2,66.8 63.2,67.2 66.5,68.5 66.5,72.3" },
        { code: "J1001", name: "BANDA", path: "M108.1,62.9c0,0.5-0.2,3.1-0.5,5.5c-0.2,2.5-0.5,4.9-0.5,4.9l-0.9,5.7l0.9,5.7l-2.6,0.7l-1.2,0.3l-3.8,4.7l-5.7-1.9L92,85.6h-3.8l-2.8,0.9l-1.9-1.9l-6.6-1.9l-0.9,2.8l-4.7,6.6l-4.7-6.6l0.4-1.4V84l0.6-2.3l2.8-3.8l-3.8-5.7v-3.8l-3.2-1.3l-2.6-1.1l-0.7-0.4l0,0.1L57,64.7l11-0.4l2.6-1.5L60.8,7.9l-4.2-5.3L63,3.9l5.2,4.4l2.4,4.6l4.3,4.4l2.7,2.7l4.4,7.4l0.4,5.3l6,10.9l4.7,10.7l6.2,3.6l4.2,5.1L108.1,62.9z" },
        { code: "J1101", name: "TAIN", path: "M 102.4,92.2 104.3,96.9 102.4,101.7 99.6,104.5 100.6,109.2 97.7,114 99.6,117.8 97.7,123.4 94.9,123.4 94.9,124.2 94.8,124.2 93,123.4 92,125.3 88.2,127.2 84.5,127.2 78.8,128.2 75.9,127.2 73.1,123.4 72,125.1 68.4,123.4 65.5,121.5 66.4,120.4 61.7,116.4 61.7,114 63.6,111.1 63.6,109.2 62.7,103.6 63.6,100.7 63.6,98.8 61.7,95 59.9,92.2 63.6,91.3 64.6,85.6 66.8,84.2 66.5,85.6 71.2,92.2 75.9,85.6 76.9,82.7 83.5,84.6 85.4,86.5 88.2,85.6 92,85.6 93.9,88.4 99.6,90.3 103.4,85.6 104.5,85.3 104.5,85.3 104.8,86.9 104.8,87.1 105.2,89.4 105.2,89.4" },
        { code: "J1201", name: "WENCHI", path: "M132.7,99l-4.3,6.8h-5.1l-3.8,2.6h-6.8l2.1,4.7l3,6l-5.1,5.4l-1.4,1.7l0,0l-1.3,1.7l-3.7,4.8l1.4,3h-6.1l-4.7,1.9l-3.8-1.9V131l3.8-2.8l-0.9-0.9l1.9-1.9l-2.8-1.1v-0.8h2.8l1.9-5.7l-1.9-3.8l2.8-4.7l-0.9-4.7l2.8-2.8l1.9-4.7l-1.9-4.7l2.8-2.8l-0.7-4.1l2.6-0.7l-0.9-5.7l0.9-5.7c0,0,0.2-2.4,0.5-4.9h2.5l3,7.1h3.4l3.8,6.9l-1.7,5.3h5.6L132.7,99z" }
    ],
    "WESTERN NORTH": [
        { code: "G0101", name: "AOWIN", path: "M 94.48 172.9 93.38 186.31 93.86 191.31 94.48 197.77 84.65 211.42 80.28 221.25 70.52 223.29 61.76 188.59 51.52 185.41 42.99 188.59 38.67 187.46 39.58 175.51 31.05 176.31 31.85 154.13 24.23 139.13 27.61 138 35.61 136 40.61 132 44.74 132 46.61 137 48.61 142 67.61 142 71.61 139 74.61 140 77.61 144 83.61 142 92.61 140 97.67 139.54 99.88 143.72 102.12 152.02 104.3 163.38 92.84 163.38 87.38 171.19 94.48 172.9" },
        { code: "G0201", name: "SUAMAN", path: "M 43.61 129 44.74 132 40.61 132 35.61 136 27.61 138 24.23 139.13 24.22 139.11 22.61 131.74 22.61 131.73 19.56 117.73 16.36 112.58 16.06 112.1 18.61 107 22.61 107 24.9 107.76 25.49 107.96 25.61 108 29.61 107 30.61 109 35.61 109 40.61 112 39.61 115 40.15 115.45 39.61 116 40.22 115.51 45.61 120 44.61 125 40.61 126 43.61 129" },
        { code: "G0301", name: "BIBIANI-ANHWIASO-BEKWAI", path: "M 149.92 102.71 142.18 106.7 146.5 119.21 136.52 119.21 126.69 124.06 120.74 121.63 120.51 121.76 121.61 119 121.61 116 118.61 112 116.61 107 118.61 103 116.61 99 114.61 96 114.61 89 113.15 86.09 116.93 82.24 120.8 66.77 128.3 71.66 135.92 70.86 133.99 84.97 146.5 87.93 148.78 94.41 156.17 95.78 149.92 102.71" },
        { code: "G0401", name: "SEFWI WIAWSO", path: "M 121.61 116 121.61 119 120.51 121.76 120.5 121.76 119.3 122.43 112.49 126.25 108.94 122.98 107.61 117 98.61 117 98.61 113 93.61 112 89.61 109 90.61 107 87.61 105 82.61 105 80.61 107 75.7 105.36 76.61 105 75.61 101 82.61 101 82.61 94 79.61 89 77.61 87 74.61 86 71.61 83 71.61 77 68.61 73 75.61 71 77.61 68 82.61 66 88.79 67.68 88.79 67.69 90.65 70.67 96.45 79.96 107.83 84.97 110.9 88.38 112.37 86.89 113.15 86.09 114.61 89 114.61 96 116.61 99 118.61 103 116.61 107 118.61 112 121.61 116" },
        { code: "G0501", name: "SEFWI AKONTOMBRA", path: "M 108.94 122.98 104.85 119.21 102.9 121.97 99.88 126.25 96.66 137.63 96.78 137.86 97.67 139.54 92.61 140 83.61 142 77.61 144 74.61 140 71.61 139 67.61 142 48.61 142 46.61 137 44.74 132 43.61 129 40.61 126 44.61 125 45.61 120 40.22 115.51 44.61 112 50.61 112 52.61 115 54.61 120 56.61 126 64.61 122 62.61 117 66.61 113 68.61 108 71.61 107 75.41 105.48 75.7 105.36 80.61 107 82.61 105 87.61 105 90.61 107 89.61 109 93.61 112 98.61 113 98.61 117 107.61 117 108.94 122.98" },
        { code: "G0601", name: "JUABOSO", path: "M 88.79 67.68 82.61 66 77.61 68 75.61 71 68.61 73 71.61 77 71.61 83 70.61 87 65.61 95 62.61 93 61.69 93.23 58.61 94 52.61 95 48.85 94.25 42.07 98.07 40.61 101 31.61 101 30.39 104.66 29.61 107 25.61 108 25.49 107.96 25.56 107.39 24.9 107.76 22.61 107 18.61 107 16.06 112.1 10.01 102.37 9.33 98.71 9.33 98.7 13.61 92 16.61 89 22.61 89 22.61 81 18.61 77 18.61 73 23.61 66 28.61 69 29.61 66 33.61 70 37.61 69 39.05 69.86 42.61 72 42.61 68 48.61 63 48.61 57 52.26 50.41 55.43 49.36 55.44 49.36 58.75 50.74 68.02 54.6 78.71 54.6 87.01 64.84 88.79 67.68" },
        { code: "G0701", name: "BODI", path: "M 82.61 101 75.61 101 76.61 105 75.7 105.36 75.41 105.48 71.61 107 68.61 108 66.61 113 62.61 117 64.61 122 56.61 126 54.61 120 52.61 115 50.61 112 44.61 112 40.22 115.51 40.15 115.45 39.61 115 40.61 112 35.61 109 30.61 109 29.61 107 30.39 104.66 31.61 101 40.61 101 42.07 98.07 48.85 94.25 52.61 95 58.61 94 61.69 93.23 62.61 93 65.61 95 70.61 87 71.61 83 74.61 86 77.61 87 79.61 89 82.61 94 82.61 101" },
        { code: "G0801", name: "BIA WEST", path: "M 52.26 50.41 48.61 57 48.61 63 42.61 68 42.61 72 39.05 69.86 37.61 69 33.61 70 29.61 66 28.61 69 23.61 66 18.61 73 18.61 77 22.61 81 22.61 89 16.61 89 13.61 92 9.33 98.7 2.04 59.15 0 56.65 2.84 50.62 7.96 40.72 2.84 34.12 2.84 27.64 3.12 27.17 3.33 26.83 8.61 28 14.61 31 18.61 28 22.61 31 24.61 34 33.61 36 42.61 36 42.61 46 49.61 52 52.26 50.41" },
        { code: "G0901", name: "BIA EAST", path: "M 55.43 49.36 52.26 50.41 49.61 52 42.61 46 42.61 36 33.61 36 24.61 34 22.61 31 18.61 28 14.61 31 8.61 28 3.33 26.83 19.56 0 27.07 9.1 42.99 9.1 52.09 29.23 54.4 44.26 55.08 48.72 55.16 49.25 55.43 49.36" }
    ],
    "BONO EAST": [
        { code: "K0101", name: "TECHIMAN", path: "M 265.8,171.8 256.7,169.9 251.8,166.6 250.3,169.2 246.3,169.2 243,171.8 240.4,175.5 237.5,177.5 242,181.1 240.7,182.6 237.5,184.4 233.3,191.7 229.6,193.5 226.2,198.7 218.2,186.8 208.3,183.1 206.9,182.7 206.6,182.6 202.9,172.2 204.6,166.8 206.2,165.5 209.9,162.4 213.5,162.4 217,159.8 219.8,157.4 223.5,158.4 228.8,155.3 233.3,149.2 244.2,141.9 252.8,131 252.8,147.3" },
        { code: "K0201", name: "KINTAMPO NORTH", path: "M 115.27 43.93 112.45 54.63 120.11 61.73 120.41 62.48 117.05 65 112.05 66 109.05 71 103.05 71 99.05 75 99.05 82 96.05 89 95.6 90.79 94.05 97 88.47 98.86 88.05 99 85.64 102 77.05 102 74.05 98 67.05 98 61.05 96 54.05 96 50.05 93 43.05 93 39.05 96 34.05 96 29.05 94 25.05 89 20.05 87 16.51 83.25 22.68 81.44 26.85 77.16 33.04 68.48 39.01 68.48 45.21 71.86 49.15 71.86 51.97 65.67 46 57.22 35.64 58.91 30 50.12 30 44.83 33.04 42.58 34.51 20.27 43.18 14.42 51.4 18.59 55.35 18.59 61.77 11.6 70.78 12.16 79.79 12.95 86.55 1.69 98.37 0 105.47 2.59 113.58 7.66 127.1 12.39 130.25 28.16 126.53 35.82 115.27 43.93" },
        { code: "K0301", name: "KINTAMPO SOUTH", path: "M 86.05 105 84.05 110 86.05 115 83.05 118 83.6 119.64 82.67 119.87 83.45 120.83 77.05 119 73.05 120 70.05 125 65.05 128 58.05 127 55.05 130 49.05 131 45.05 134 36.05 134 33.05 138 31.26 138.76 30.72 138.59 31.13 138.81 14.43 145.84 14.08 145.15 10.81 138.6 8.11 132.65 14.57 132.65 16 96.39 12.97 91 8.65 91 4.86 82 7.7 82 14.23 83.92 16.51 83.25 20.05 87 25.05 89 29.05 94 34.05 96 39.05 96 43.05 93 50.05 93 54.05 96 61.05 96 67.05 98 74.05 98 77.05 102 85.64 102 85.05 102.73 85.7 102 86.05 102 86.05 105" },
        { code: "K0401", name: "NKORANZA NORTH", path: "M 112.05 152 99.07 154.13 97.81 152.96 97.78 153 97.77 153.01 93.18 157.8 92.96 158.03 89.05 158 84.05 161 77.05 159 68.05 159 58.05 156 53.05 158 46.05 156 41.05 151 41.05 144 37.05 142 31.13 138.81 31.26 138.76 33.05 138 36.05 134 45.05 134 49.05 131 55.05 130 58.05 127 65.05 128 70.05 125 73.05 120 77.05 119 83.45 120.83 83.46 120.83 86.05 124 88.05 128 93.05 126 95.05 129 99.05 128 104.55 129.38 99.05 134 101.83 138.86 103.05 141 97.05 147 97.05 150 101.05 150 107.05 146 112.05 152" },
        { code: "K0501", name: "NKORANZA SOUTH", path: "M 80.58 170.98 80.92 181.57 64.81 189.46 58.05 187.2 52.42 190.13 43.41 183.37 38.45 172.56 26.85 175.94 17.84 172.56 10.74 174.81 4.66 173.69 0 163.47 4.69 157.38 8.11 152.96 14.59 146.17 14.43 145.84 31.13 138.81 37.05 142 41.05 144 41.05 151 46.05 156 53.05 158 58.05 156 68.05 159 77.05 159 84.05 161 89.05 158 92.96 158.03 80.58 170.98" },
        { code: "K0601", name: "ATEBUBU/AMANTIN", path: "M 180.78 166.78 180.41 166.86 177 167.6 160.1 167.27 149.29 163.55 134.64 172.11 112.45 173.69 106.82 161.3 99.07 154.13 112.05 152 107.05 146 101.05 150 97.05 150 97.05 147 103.05 141 101.83 138.86 99.05 134 104.55 129.38 107.05 130 113.05 130 118.05 128 119.05 123 123.05 123 126.05 118 126.05 115 138.05 115 135.05 110 137.74 106.41 137.8 106.56 140.95 108.13 142.69 106.29 142.98 105.99 145.05 107 150.05 106 156.05 109 161.05 112 167.19 112 166.05 113 167.75 112 169.05 112 176.05 109 174.05 113 171.05 118 173.05 123 175.05 129 174.05 133 176.05 139 175.05 145 178.05 150 176.05 154 180.05 158 180.78 166.78" },
        { code: "K0701", name: "PRU WEST", path: "M 138.05 115 126.05 115 126.05 118 123.05 123 119.05 123 118.05 128 113.05 130 107.05 130 104.55 129.38 99.05 128 95.05 129 93.05 126 88.05 128 86.05 124 83.46 120.83 84.05 121 83.6 119.64 83.05 118 86.05 115 84.05 110 86.05 105 86.05 102 85.7 102 88.47 98.86 94.05 97 95.6 90.79 96.05 89 99.05 82 99.05 75 103.05 71 109.05 71 112.05 66 117.05 65 120.41 62.48 120.47 62.65 137.62 106.11 137.74 106.41 135.05 110 138.05 115" },
        { code: "K0801", name: "PRU EAST", path: "M 216.01 83.6 183.06 102.99 180.05 106 180.05 110 175.88 107.22 167.75 112 161.05 112 156.05 109 150.05 106 145.05 107 142.98 105.99 147.37 101.37 147.37 94.62 144.56 86.17 155.82 79.41 160.66 70.17 168.77 65.67 172.49 59.13 188.26 60.04 215.88 83.48 216.01 83.6" },
        { code: "K0901", name: "SENE WEST", path: "M 242.05 166 238.05 169 234.05 169 229.81 172.53 228.05 174 226.05 180 230.3 191.87 205.16 184.95 190.51 164.68 180.78 166.78 180.05 158 176.05 154 178.05 150 175.05 145 176.05 139 174.05 133 175.05 129 173.05 123 171.05 118 174.05 113 176.05 109 169.05 112 167.75 112 175.88 107.22 180.05 110 180.05 106 183.06 102.99 216.01 83.6 218.11 85.38 225.67 85.38 221.05 90 223.05 99 226.9 104.77 226.91 105.1 223.05 108 223.05 116 226.05 121 224.05 127 224.05 131 228.05 133 224.05 137 228.61 144.6 230.05 147 239.05 147 238.05 158 235.05 162 237.05 165 242.05 166" },
        { code: "K1001", name: "SENE EAST", path: "M 312.61 190.13 311.03 213.79 295.72 213.79 287.83 210.07 252.46 210.07 243.45 196.55 232.19 192.39 230.65 191.97 230.3 191.87 226.05 180 228.05 174 229.81 172.53 234.05 169 238.05 169 242.05 166 237.05 165 235.05 162 238.05 158 239.05 147 230.05 147 228.61 144.6 224.05 137 228.05 133 224.05 131 224.05 127 226.05 121 223.05 116 223.05 108 226.91 105.1 227.05 105 226.9 104.77 223.05 99 221.05 90 225.67 85.38 229.37 85.38 244.24 90.34 255.5 100.02 262.6 100.02 263.05 108.13 269.02 130.1 282.54 133.48 282.54 151.84 297.18 179.66 312.61 190.13" },
        { code: "K1101", name: "TECHIMAN NORTH", path: "M 276.4,168.1 275,189 264.5,202 257.5,198.7 226.2,198.7 229.6,193.5 233.3,191.7 237.5,184.4 240.7,182.6 242,181.1 237.5,177.5 240.4,175.5 243,171.8 246.3,169.2 250.3,169.2 251.8,166.6 256.7,169.9 265.8,171.8" }
    ],
    "OTI": [
        { code: "L0101", name: "BUEM", path: "M 145.22 196.71 137.01 201.23 127.42 201.79 121.77 205.89 121.77 198.97 119.49 198.46 119.14 198.38 119.13 198.38 118 194.41 118 189.74 117.22 185.19 115 187.41 115 193.41 110 191.41 106 190.41 101 190.41 98 189.41 101 186.41 101 184.41 105 182.41 107 175.41 107 170.41 111.87 167.63 114.31 168.24 119 169.41 125.97 169.41 125.46 176.03 127.81 184.02 133.46 188.37 143.45 186.38 145.22 196.71" },
        { code: "L0201", name: "BIAKOYE", path: "M 119.13 198.38 111.61 196.71 102.02 205.89 99.01 219.89 91.29 227.19 82.9 232.12 85.25 219.89 86.82 196.38 88 192.41 89 184.41 93 177.41 98 169.41 98 160.41 100 157.41 100 152.41 103 148.41 106 145.41 111 147.41 111 167.41 111.87 167.63 107 170.41 107 175.41 105 182.41 101 184.41 101 186.41 98 189.41 101 190.41 106 190.41 110 191.41 115 193.41 115 187.41 117.22 185.19 118 189.74 118 194.41 119.13 198.38" },
        { code: "L0301", name: "AKAN", path: "M 133.69 146.63 134.05 156.39 128.22 159.81 126.64 160.74 125.97 169.41 119 169.41 114.31 168.24 111.87 167.63 111 167.41 111 147.41 114 146.41 114.08 146.18 115 143.41 115 140.41 114 135.41 116 130.41 113 127.41 119 127.41 120 122.41 123 119.41 120.96 116.69 124 114.41 128 117.41 130 114.41 136 117.41 140.08 117.41 138.99 131.7 140.75 144.28 133.69 146.63" },
        { code: "L0401", name: "KRACHI EAST", path: "M195.68,122.51l-3,3-1,5h-6l3,3-2,5,1,5v3l-.92,2.77-3.08,1.23-5-2-3,3-3,4v5l-2,3v9l-5,8-4,7-1,8-1.18,4,.08-1.19-16.11-10.93-15.29-29V141l2.5-2.5h5v-25l1-2h3l1-4v-5h-2l-3,2v-3l-4-1-.42-7.14,3.42,1.14h5l1-2,3-2,2,5,5-2.14,18,7.64v.5l5,3,4,3,5,4h4l4,2v4l2,3,.33-.24.63-.48Z" },
        { code: "L0501", name: "KRACHI WEST", path: "M140.68,102.51v5l-1,4h-3l-1,2v25h-5l-2.5,2.5v-1.86l-1.16-.29-13-3.24-6.23-22.93-.47-8.46H100l-.63-.54,4.35-2.18h5l6-2,1-4,6-3h7l2.58.86.42,7.14,4,1v3l3-2Z" },
        { code: "L0601", name: "KRACHI-NCHUMURU", path: "M 80 89.41 77.99 90.27 73 92.41 71 87.41 68 89.41 67 91.41 62 91.41 58.58 90.27 56 89.41 49 89.41 43 92.41 42 96.41 36 98.41 31 98.41 26.65 100.59 26.38 100.36 15.52 91.01 0 85.84 3.17 80.55 20.58 74.08 30.22 71.73 33.51 62.56 45.27 58.8 57.85 62.56 65.09 70.86 65.85 71.73 67.95 71.31 70 75.41 72 78.41 76 78.41 76 80.41 74 84.41 80 89.41" },
        { code: "L0701", name: "NKWANTA SOUTH", path: "M 146.98 69.03 135.81 74.9 138.75 84.31 134.87 85.49 137.57 109.59 140.16 116.41 140.14 116.62 140.08 117.41 136 117.41 130 114.41 128 117.41 124 114.41 120.96 116.69 120.33 117.17 120 117.41 118 114.41 118 110.41 114 108.41 110 108.41 105 104.41 101 101.41 96 98.41 96 97.91 77.99 90.27 80 89.41 74 84.41 76 80.41 76 78.41 72 78.41 70 75.41 67.95 71.31 80.55 68.79 80.76 59.63 84 62.41 88 59.41 88 58.41 92 59.41 94 56.41 92 51.41 94 46.41 92 45.41 94 42.41 94 38.41 98 38.41 98 34.41 103 32.41 107 27.41 109 24.41 113 23.41 113 21.41 116.61 21.41 118.1 20.09 118.81 21.41 119 21.76 133.46 32.93 141.34 35.28 144.63 46.45 150.51 49.39 154.62 62.56 146.98 69.03" },
        { code: "L0801", name: "NKWANTA NORTH", path: "M 118.1 20.09 116.61 21.41 113 21.41 113 23.41 109 24.41 107 27.41 103 32.41 98 34.41 98 37.85 97.37 38.41 94 38.41 94 42.41 92 45.41 94 46.41 92 51.41 94 56.41 92 59.41 88 58.41 88 59.41 84 62.41 80.76 59.63 80.78 58.8 88.42 54.92 87.01 48.8 85.66 48.76 75.84 48.45 70.2 51.15 66.44 45.27 66.08 39.63 71.37 37.87 66.08 30.58 71.37 19.99 78.19 21.76 74.31 13.53 79.37 1.77 89.01 0 103.12 1.77 107.24 0 118.1 20.09" }
    ],
    "NORTHERN": [
        { code: "M0101", name: "KPANDAI", path: "M 175.2,211 169.4,214 169.2,221.6 158,223.9 151.9,216.9 142.3,214 133.3,216.9 128.3,215.1 124.4,213.7 115,213.7 113.2,209.9 120.1,209.9 121,204.2 121,203.4 121.4,199.1 124.4,194.3 124.4,188.3 123.8,188.5 126.8,184.9 129.8,184.9 135.8,186.8 140.9,184.9 147.9,187.7 152.9,187.7 159.6,189.6 158.2,192.5 162.2,198 158.2,199.4 158.4,203.7 161.3,208.2 165.6,206.1 174.1,206.4" },
        { code: "M0201", name: "BIMBILLA", path: "M 179.1,134.6 178.1,139.3 174.1,139.3 168,144 168,147.8 164,147.8 163,151.6 152.9,154.5 154.9,147.8 149.9,149.7 143.9,147.8 144.9,154.5 136.8,158.3 136.8,162.1 141.9,162.1 141.9,164.9 131.8,171.6 119.7,178.2 110.7,182 103.5,182.8 102.9,181 95.6,176.2 96,170.9 101.4,171.7 105.5,172.4 110,169.1 113.2,169.1 114.5,162.9 110,160.7 110,156.8 116.7,153.8 124.4,146.5 122.3,140.5 124.4,134.9 121,131.4 118.3,131.4 127.8,129.8 133.8,127 142.9,127.9 142.9,120.3 145.9,116.5 144.2,112.7 147.9,114.6 152.9,114.6 152.9,119.4 154.2,119.4 152.9,122.2 150.8,123.5 149.9,124.1 149.9,127.9 156,129.8 158,131.7 162,128.9 165.8,126.2 166,126 173.1,131.7 173.1,132.7 167,132.7 169,135.5 175.1,137.4 " },
        { code: "M0301", name: "WULENSI", path: "M 190.2,167.6 189.6,169.1 186.4,170.5 175.7,169.1 168.3,170.5 164.4,179.4 167.4,185.7 162.2,184.4 159.6,189.6 152.9,187.7 147.9,187.7 140.9,184.9 135.8,186.8 129.8,184.9 126.8,184.9 123.8,188.5 118,190.9 110,190.9 104.6,186.6 103.5,182.8 110.7,182 119.7,178.2 131.8,171.6 141.9,164.9 141.9,162.1 136.8,162.1 136.8,158.3 144.9,154.5 143.9,147.8 149.9,149.7 154.9,147.8 152.9,154.5 163,151.6 164,147.8 168,147.8 168,144 174.1,139.3 176.6,139.3 181.1,141.2 178.9,143.8 177.1,145.9 177.1,150.7 179.1,156.4 181.1,158.3 182.1,153.5 187.7,161.5 187.7,161.5 187.1,160.2 188.1,159.6" },
        { code: "M0401", name: "ZABZUGU", path: "M 187.1,131.7 186.1,138.4 190.2,137.4 189.2,141.2 193.2,141.2 193.2,147.8 190.2,152.6 190.2,158.3 188.1,159.6 187.1,160.2 187.7,161.5 187.7,161.5 182.1,153.5 181.1,158.3 179.1,156.4 177.1,150.7 177.1,145.9 178.9,143.8 181.1,141.2 176.6,139.3 178.1,139.3 179.1,134.6 175.1,137.4 169,135.5 167,132.7 173.1,132.7 173.1,131.7 166,126 165.8,126.2 165.8,120.3 167,120.3 171,116.5 167,114.6 167,109.9 173.1,109.9 174.8,109 175.1,108.9 174.2,106.9 179.1,104.2 184.1,104.2 186.1,103.2 188.2,108.9 190.2,107 193.2,106.1 197.2,108.9 192.2,112.7 190.2,119.4 190.2,124.1 193.2,127.9" },
        { code: "M0501", name: "TATALE/SANGULI", path: "M 207.8,104 204.8,112.7 204.8,120.6 199.3,127.5 200.3,132.8 196.7,136.1 196.7,141.3 202.8,150.9 201.7,160.3 200.6,164.6 195.2,163.3 195.2,167.3 191.4,164.8 190.2,167.6 188.1,159.6 190.2,158.3 190.2,152.6 193.2,147.8 193.2,141.2 189.2,141.2 190.2,137.4 186.1,138.4 187.1,131.7 193.2,127.9 190.2,124.1 190.2,119.4 192.2,112.7 197.2,108.9 193.2,106.1 190.2,107 188.2,108.9 186.1,103.2 184.1,104.2 179.1,104.2 174.2,106.9 173.1,104.2 168,103.2 170,100.4 171,96.6 173.7,94.7 173.7,96.1 184.4,95.7 186.9,91.2 193.4,90.1 202.8,93.3 202.1,96.8 205.7,97.7" },
        { code: "M0601", name: "YENDI", path: "M 167,109.9 167,114.6 171,116.5 167,120.3 160,120.3 157,119.4 152.9,119.4 152.9,114.6 147.9,114.6 144.2,112.7 142.9,109.9 139.8,104.2 145.9,103.2 143.9,98.5 137.8,95.6 137.8,88 141.9,84.2 137.8,76.6 129.8,74.7 129.8,71.9 139.8,69 144.9,63.3 145.3,61.6 146.8,63.9 148.9,67.1 152,68.8 154.9,71.9 150.9,75.7 156,78.5 156,82.3 160,84.2 163,84.2 165,86.1 162,89.9 161,95.6 166,96.6 171,96.6 170,100.4 168,103.2 173.1,104.2 174.2,106.9 174.8,109 173.1,109.9" },
        { code: "M0701", name: "MION", path: "M 129.8,71.9 129.8,74.7 137.8,76.6 141.9,84.2 137.8,88 137.8,95.6 143.9,98.5 145.9,103.2 139.8,104.2 142.9,109.9 144.2,112.7 145.9,116.5 142.9,120.3 142.9,127.9 133.8,127 127.8,129.8 118.3,131.4 116.7,131.4 116.7,127.7 113.7,123.2 107.7,119.3 96,119.3 93.4,115 88.3,115 86.1,113.2 88.5,109.9 92.5,107 92.5,102.3 86.5,100.4 85.5,98.5 87.5,95.6 85.2,92.8 83.5,90.9 83.5,88.1 84.9,88.1 88.5,88 92.5,88 94.1,87.2 94.6,88 99.6,88 104.6,89.9 106.6,86.1 111.7,84.2 113.7,80.4 119.7,80.4 118.7,76.6 120.1,73.5 120.7,71.9 120.7,69 122.7,67.1 128.8,67.1 128.8,64.3 133.8,66.2 133.8,66.2 134,66.1 136.8,64.3 136.8,60.5 141.9,60.5 147.6,58.5 145.9,59.5 145.8,59.8 145.3,61.6 145.3,61.7 144.9,63.3 139.8,69" },
        { code: "M0801", name: "SABOBA", path: "M187.3,82.2l-0.6-5.8l-7,2.2l-3.8-4.9l1.3-4l7.4,4l2.1-4.5l-3.9-5.8l3.4-9.9l-1.4-7.4l4.2-2.3l-6.1-0.3l-5.2-3.5l-3.2-0.3l-2.6,1.6h-3.9l-2.6-2.3l-3.9-0.3l-2.3,2.6h-3.1l-6.4,3l-1,8.5v4.8l-3,1.9l-0.1,0.3l-0.5,1.8v0l0.4,0.5l1.1,1.7l2.1,3.2l3.1,1.7l2.9,3.2l-4,3.7l5,2.9v3.8l4,1.9h3l2,1.9l-3,3.8l-1,5.7h0l5,1h5l2.6-1.9l1.4-1l-1.3-1.4l7.1-4l-7.9-1.6v-5.1L187.3,82.2zM149.3,58l0-0.1l0.5-0.2L149.3,58z" },
        { code: "M0901", name: "GUSHEGU", path: "M 153.5,38.4 153.5,41.4 156.3,41.4 149.9,44.3 148.9,52.9 148.9,57.6 147.6,58.5 141.9,60.5 136.8,60.5 136.8,64.3 134,66.1 133.8,66.2 128.8,64.3 128.8,67.1 122.7,67.1 120.7,69 120.7,71.9 120.1,73.4 118.7,76.6 119.7,80.4 113.7,80.4 111.7,84.2 106.6,86.1 104.6,89.9 99.6,88 94.6,88 92.6,84.2 95.6,80.4 95.6,78.5 98.6,72.8 97.8,71.1 96.6,68.1 99.6,63.3 95.6,60.5 97.6,57.6 103.6,57.6 109.7,55.7 112.1,54.2 115.7,51.9 115.4,50.3 114.7,46.2 118.7,45.3 117.7,40.5 116.7,34.8 121.7,32 121.7,28.2 131.1,31.7 131.8,32 133.8,30.1 133.2,29.2 129.8,23.5 134.8,15.9 138.8,12.1 144.9,12.1 144.9,7.3 153.1,5.7 153.5,7 155.5,10 153.5,16.8 153.5,20.3 157.8,23.9 157.8,30 159.4,32.6 157.8,37.8" },
        { code: "M1001", name: "KARAGA", path: "M 153.1,5.7 144.9,7.3 144.9,12.1 138.8,12.1 134.8,15.9 129.8,23.5 133.2,29.2 133.8,30.1 131.8,32 131.1,31.7 121.7,28.2 121.7,32 116.7,34.8 117.7,40.5 118.7,45.3 114.7,46.2 115.4,50.3 115.7,51.9 112.1,54.2 109.7,55.7 103.6,57.6 97.6,57.6 95.6,60.5 99.6,63.3 96.6,68.1 97.8,71.1 98.6,72.8 95.6,78.5 95.6,80.4 92.6,84.2 94.1,87.2 92.6,88 88.5,88 84.9,88.1 83.5,88.1 83.5,86.1 84.6,83.1 84.8,82.5 84.5,82.1 80.5,77.6 80.5,71.9 74.4,68.1 73.4,63.3 75.4,59.5 73.4,57.6 68.4,55.7 68.4,53.8 62.4,51.9 66.4,48.1 68.4,43.4 71.4,40.5 75.4,37.7 73.4,34.8 76.4,32.9 77.5,29.2 80.5,27.3 80.5,16.5 80.5,16.5 80.9,16.4 83.1,20.7 87.8,16.4 90.5,10.9 109.5,9 110,8.9 118.7,8 119.7,8.4 126.2,8.4 130.7,7 136.2,8.4 141,7 142.6,5.5 145.9,2.6 152,2.6 152.6,4.5" },
        { code: "M1101", name: "SAVELUGU", path: "M 87.5,95.6 85.5,98.5 84.5,96.6 76.5,92.8 71.4,94.7 68,95.3 62.9,90.6 62.4,89 62.4,85.2 59.3,85.2 56.3,82.3 52.3,80.9 52.3,77.6 50.3,73.8 47.3,73.8 45.3,73.3 45.1,66.8 47.3,64.3 48.3,59.5 44.8,57.9 44.2,57.6 44.2,53.8 42.2,53.8 38.2,51.9 41.2,48.1 42.2,45.3 41.2,42.5 44.1,40.3 44.1,40.4 44.3,41.3 49.9,35.8 55.5,30.4 57.3,24.1 62.8,21.5 73.2,19.4 80.5,16.5 80.5,27.3 77.5,29.2 76.5,33 73.4,34.8 75.4,37.7 71.4,40.5 68.4,43.4 66.4,48.1 62.4,51.9 68.4,53.8 68.4,55.7 73.4,57.6 75.4,59.5 73.4,63.3 74.4,68.1 80.5,71.9 80.5,77.6 84.5,82.1 84.6,83.1 83.5,86.1 83.5,90.9 85.2,92.8" },
        { code: "M1201", name: "NANTON", path: "M 165.8,120.3 165.8,126.2 162,128.9 158,131.7 156,129.8 149.9,127.9 149.9,124.7 150.8,123.5 152.9,122.2 154.2,119.4 157,119.4 160,120.3" },
        { code: "M1301", name: "TAMALE SOUTH", path: "M 92.5,102.3 92.5,107 88.5,109.9 86.1,113.2 73.4,112.6 73.2,112.6 73.2,112.9 71.3,109.7 72.3,106.5 74.4,103.9 77.7,103.9 79.7,103.5 81.5,103.2 83.5,100.4 84.5,96.6 85.5,98.5 86.5,100.4" },
        { code: "M1302", name: "TAMALE CENTRAL", path: "M 84.5,96.6 83.5,100.4 81.5,103.2 79.7,103.5 77.7,103.9 74.4,103.9 72.3,106.5 71.3,109.7 73.2,112.9 69,115 66.7,112.3 57.2,111.8 58,109.5 59.9,108.2 62.4,105.1 62.9,100.4 61.3,99.4 66.4,95.6 68,95.3 71.4,94.7 76.5,92.8" },
        { code: "M1401", name: "SAGNARIGU", path: "M 68,95.3 66.4,95.6 61.3,99.4 58.3,98.5 47.3,100.4 47.3,97.6 55.3,90.9 53.3,88 55.3,84.2 52.3,82.3 52.3,80.9 56.3,82.3 59.3,85.2 62.4,85.2 62.4,89 62.9,90.6" },
        { code: "M1402", name: "TAMALE NORTH", path: "M 62.9,100.4 62.4,105.1 59.9,108.2 58,109.5 57.7,109.7 57.1,111.8 47.3,111.3 47.3,100.4 58.3,98.5 61.3,99.4" },
        { code: "M1501", name: "TOLON", path: "M 47.3,97.6 47.3,111.3 45.6,111.2 42.7,109.2 42.4,109 41,110 37,112.9 31,110.7 23.7,110.3 17.4,115 15,107.7 17.4,100.4 15,95.2 6,83.2 6,78.4 8.6,73.1 6,69.8 7.7,59 16.8,55.6 22.8,59 24,57.8 25.1,64.3 27.1,70 22.1,73.8 27.1,73.8 28.1,79.5 33.2,79.5 36.2,83.3 34.2,87.1 37.2,89 38.2,92.8 41.2,95.6 41.2,99.4 46.2,97.8 46.3,98.5" },
        { code: "M1601", name: "KUMBUNGU", path: "M 53.3,88 55.3,90.9 47.3,97.6 47.3,97.5 46.2,97.8 41.2,99.4 41.2,95.6 38.2,92.8 37.2,89 34.2,87.1 36.2,83.3 33.2,79.5 28.1,79.5 27.1,73.8 22.1,73.8 27.1,70 25.1,64.3 24,57.8 27.1,54.7 22.8,49.5 23.9,47.2 25.4,43.9 28.8,43.9 16.8,35.8 18.9,32.1 21.1,28.5 28,25.4 30.5,19.4 35.3,19.4 35.3,22.8 39.2,25.8 42.4,32.3 44.1,40.3 41.2,42.4 42.2,45.3 41.2,48.1 38.2,51.9 42.2,53.8 44.2,53.8 44.2,57.6 44.8,57.9 48.3,59.5 47.3,64.3 45.1,66.8 45.3,73.3 47.3,73.8 50.3,73.8 52.3,77.6 52.3,82.3 55.3,84.2" }
    ],
    "SAVANNAH": [
        { code: "N0101", name: "BOLE/BAMBOI", path: "M 110,190.6 107.7,195.5 104.6,195.5 99.8,192.9 95.1,192.9 90.2,199.7 86.9,203 80.2,205 75.1,203.5 68.9,203.5 66.2,198.2 62.1,193 55.9,189.5 51.2,178.8 45.2,168 44.8,162.7 40.4,155.3 37.7,152.7 33.4,148.3 31,143.6 25.9,139.2 19.5,137.9 22.4,132.3 20.4,128.3 20.4,124.1 16.4,119.3 15.7,113.7 5.1,110.6 4,101.3 6.4,98.6 7.9,96.4 19.2,97.5 30.7,95.6 39.4,93.7 39.4,98.4 45.1,102.3 53.2,102.3 60.4,107.1 66.2,110 71,114.7 76.4,116.1 78.7,116.7 74.8,119.5 62.4,119.5 55.7,124.3 65.2,130.1 69.1,136.8 78.7,136.8 80.6,143.5 92.1,145.4 96.1,156.3 96.1,156.3 95.1,172.5 92.7,174.2 92.7,178.4 97.1,185.3 105.3,184" },
        { code: "N0201", name: "SAWLA TUNA KALBA", path: "M 92.1,43.8 92.1,49.6 89.8,55.6 89.2,57.2 86.3,60.1 81.5,55.3 74.8,57.2 70,63 65.2,65.9 69.1,77.4 62.4,81.2 54.7,89.8 50.9,94.6 54.7,102.3 45.1,102.3 39.4,98.4 39.4,93.7 30.7,95.6 19.2,97.5 7.9,96.4 9.5,94 13.9,90.4 15.1,87.5 10.7,85.3 10.7,81.6 13.9,74.2 13.9,67.6 10.8,61.4 6.4,59 4,51.9 6.7,46.6 6.7,46.6 6.8,46.7 19.2,45.6 19.7,45.1 25.5,40.1 25.8,39.9 29.1,34.1 40.2,33.3 42.8,33 48,31 53,31 56.2,32.1 56.3,32.1 56.3,32.1 56.5,32 63.7,30.6 63.9,30.6 73.1,31 77.8,30.6 82.5,29.7 83.8,29.5 87.3,35.2 87.3,38.1" },
        { code: "N0301", name: "DAMONGO", path: "M 162,93.7 161.1,97.5 157.3,97.5 154.4,102.3 148.6,102.3 145.8,105.2 138.1,105.2 136.2,110 129.5,112.8 125.6,120.5 127.5,124.3 125.6,127.2 118,127.2 114.1,131 109.3,133.9 103.6,132 96.9,129.1 96.9,122.4 98.8,116.7 95,112.8 91.1,113.8 84.4,112.8 76.4,116.1 71,114.7 66.2,110 60.4,107.1 53.2,102.3 54.7,102.3 50.9,94.6 54.7,89.8 62.4,81.2 69.1,77.4 65.2,65.9 70,63 74.8,57.2 81.5,55.3 86.3,60.1 89.2,57.2 89.8,55.6 94,61.1 98.8,65.9 104.5,65.9 109.3,69.7 109.3,78.3 118.9,83.1 125.6,87.9 128.5,83.1 131.4,87.9 135.2,84.1 139,86 144.8,80.2 151.5,78.3 159.6,85.7 160.3,88 160.4,88" },
        { code: "N0401", name: "DABOYA/MANKARIGU", path: "M 165.6,23.3 169.9,28.5 165.6,32.7 159.7,29.3 150.8,32.7 149.1,43.3 151.6,46.7 149.1,51.9 149.1,56.5 158,68.4 160.3,73.6 158,80.8 159.6,85.7 151.5,78.3 144.8,80.2 139,86 135.2,84.1 131.4,87.9 128.5,83.1 125.6,87.9 118.9,83.1 109.3,78.3 109.3,69.7 104.5,65.9 98.8,65.9 94,61.1 89.8,55.6 92.1,49.6 92.1,43.8 87.3,38.1 87.3,35.2 83.8,29.5 90.2,28.7 95.7,28.6 105.7,29 109.1,28.3 109.1,28.2 109.1,28.2 109.1,28.1 109,26.6 113.7,23 114.3,19.4 113,17.7 113.5,15.3 113.5,15.1 113.4,9.9 115.5,7 120.2,7.5 119.3,3 126.4,2.6 131.6,6.7 137.1,2.6 152,4.7 159.7,9.7 171.6,17.7 168.2,17.7 166.7,21" },
        { code: "N0501", name: "YAPEI-KUSAWGU", path: "M 227.2,123.4 220.5,126.3 223.4,130.1 223.4,137.8 219.6,145.4 217.6,151.2 212.8,157.9 208,156 206.1,160.8 203.3,160.8 198.7,165.3 200.9,169 205.8,172.9 206.8,177.8 209.8,183.7 215.4,186.1 204.8,185.5 201.9,190.6 195.5,194.2 191.7,201.5 182.8,206.8 185,213.4 185,218.7 180,224.1 177.5,222.8 163.6,187.5 157.6,181.9 159.8,173.5 168.7,167.1 171.6,161.1 169.7,151.5 169.1,148.7 158.5,145 152.1,141 146.5,139 137.2,140.3 131.8,149.1 124.8,148.5 117.7,148.1 112.6,153.6 109.5,153.6 103,150.3 96.2,154.9 96.1,156.3 92.1,145.4 80.6,143.5 78.7,136.8 69.1,136.8 65.2,130.1 55.7,124.3 62.4,119.5 74.8,119.5 78.7,116.7 76.4,116.1 84.4,112.8 91.1,113.8 95,112.8 98.8,116.7 96.9,122.4 96.9,129.1 103.6,132 109.3,133.9 114.1,131 118,127.2 125.6,127.2 127.5,124.3 125.6,120.5 129.5,112.8 136.2,110 138.1,105.2 145.8,105.2 148.6,102.3 154.4,102.3 157.3,97.5 161.1,97.5 162,93.7 160.4,88 166.5,83.3 173.7,83.8 179.7,85.9 185,82.1 188.2,84.2 191,84.4 194.6,88.9 197.5,90.8 200.4,95.6 204.2,95.6 202.3,102.3 210,102.3 213.8,109 215.7,117.6 220.5,115.7 227.2,117.6" },
        { code: "N0601", name: "SALAGA SOUTH", path: "M274.9,188.6l-2.5,6.9l-7.3,1.8l-13.1,4.9l-2.4,4h-8.9l-23.5-20l-1.9-0.1l-5.6-2.4l-3-5.9l-1-4.9l-4.9-3.9l-2.2-3.7l4.5-4.5h2.9l1.9-4.8l4.8,1.9l4.8-6.7l3.1-0.1v-1.7l1.8-1.2c0,0,1.5-1.5,2.3-2.3c0.7-0.7,0.2-2,0-2.1c0.2-0.2,2.4-3.5,2.4-3.5l2.3,0.3l3.4-2.3l5,4.9l-0.4,5.3l2.6,1.7l4.7,3l1.7,5.5l5.3,4.3h7.9l6.4-2.5v6l-3,4.7l-0.4,4.3l-0.1,0.8l-0.8,5.5H255l1.7,3.8h9.4l3.9,1.4L274.9,188.6z" },
        { code: "N0701", name: "SALAGA NORTH", path: "M264,113.1l2.1,6l-7.7,7.2l-6.6,3v3.8l4.4,2.1l-1.3,6.2h-3.2l-4.5,3.2l-4.1-0.6l-5.3-0.8l-5-4.9l-3.4,2.3l-2.3-0.3c0,0-2.2,3.3-2.4,3.5c0.1,0.1,0.6,1.4,0,2.1c-0.8,0.8-2.3,2.3-2.3,2.3l-1.8,1.2v1.7l-3.1,0.1l1.9-5.8l3.8-7.7v-7.7l-2.9-3.8l6.7-2.9v-5.8l-6.7-1.9l-4.8,1.9l-1.9-8.6l-3.8-6.7h-7.7l1.9-6.7h-3.8l-2.9-4.8l-2.9-1.9l-3.6-4.4l7.4,0.6l7.3-1.4l1.6-0.3l4,4.7l4.1-2.1v-2.1l10.4,0.4l4.5,3.8h5.1l2.5,4.3h11.6l5.9,3.8l3,4.4v3.7h4.3l3.4,3.4L264,113.1z" }
    ],
    "UPPER WEST": [
        { code: "P0101", name: "WA CENTRAL", path: "M 110.5,131.5 110.5,136.5 100.5,139.5 95.5,139.5 89.5,148.5 82.5,159.5 82.5,189.5 88.5,193.5 94.5,200.5 103.5,200.5 110.5,197.5 117.5,197.5 123.5,199.5 128.5,197.5 148.5,197.5 155.5,197.5 162.5,195.5 159.5,189.5 159.5,176.5 151.5,158.5 151.5,141.5 143.5,139.5 137.5,139.5 132.5,135.5 127.5,127.5 119.5,125.5" },
        { code: "P0201", name: "WA WEST", path: "M 40.5,136.5 40.5,150.5 41.5,159.5 46.5,172.5 45.5,180.5 44.5,187.5 48.5,196.5 46.5,200.5 41.5,206.5 41.5,216.5 46.5,216.5 47.5,221.5 55.5,221.5 56.5,220.5 66.5,220.5 69.5,216.5 76.5,211.5 82.5,201.5 93.5,201.5 94.5,200.5 88.5,193.5 82.5,189.5 82.5,159.5 89.5,148.5 79.5,139.5 75.5,141.5 60.5,141.5 53.5,136.5" },
        { code: "P0301", name: "WA EAST", path: "M 166.5,114.5 164.5,122.5 167.5,126.5 165.5,130.5 156.5,130.5 151.5,133.5 151.5,158.5 159.5,176.5 159.5,189.5 162.5,195.5 173.5,194.5 202.5,193.5 202.5,191.5 209.5,185.5 210.5,180.5 208.5,177.5 209.5,165.5 212.5,161.5 219.5,161.5 218.5,154.5 228.5,154.5 228.5,148.5 219.5,140.5 219.5,137.5 203.5,138.5 195.5,129.5 191.5,129.5 187.5,126.5 185.5,122.5 172.5,116.5" },
        { code: "P0401", name: "NADOWLI/KALEO", path: "M 73.5,91.5 67.5,95.5 58.5,95.5 45.5,99.5 42.5,103.5 39.5,103.5 34.5,108.5 32.5,112.5 32.5,117.5 36.5,118.5 40.5,123.5 46.5,123.5 46.5,127.5 40.5,136.5 53.5,136.5 60.5,141.5 75.5,141.5 79.5,139.5 89.5,148.5 95.5,139.5 100.5,139.5 94.5,133.5 89.5,126.5 89.5,111.5 80.5,103.5 75.5,96.5" },
        { code: "P0501", name: "DAFFIAMA/BUSSIE/ISSA", path: "M 121.5,72.5 109.5,77.5 102.5,80.5 98.5,85.5 91.5,85.5 78.5,90.5 73.5,91.5 75.5,96.5 80.5,103.5 89.5,111.5 89.5,126.5 94.5,133.5 100.5,139.5 110.5,136.5 110.5,131.5 119.5,125.5 127.5,127.5 132.5,135.5 137.5,139.5 143.5,139.5 151.5,141.8 151.5,133.5 156.5,130.5 165.5,130.5 167.5,126.5 164.5,122.5 166.5,114.5 160.5,111.5 146.5,111.5 146.5,107.5 134.5,93.5 128.5,90.5 123.5,80.5" },
        { code: "P0601", name: "JIRAPA", path: "M 34.5,98.5 43.5,98.5 43.5,100.5 45.5,99.5 58.5,95.5 66.5,95.5 73.5,91.5 91.5,85.5 98.5,85.5 102.5,80.5 121.5,72.5 112.5,57.5 106.4,56 100.5,53.5 93.5,47.5 92.9,45.5 85.5,46.5 83.5,50.5 75.5,50.5 73.5,46.5 63.5,46.5 58.5,51.5 57.5,55.5 51.5,59.5 41.5,59.5 36.5,66.5 32.5,66.5 32.5,70.5 25.5,81.5 25.5,84.5 29.5,91.5" },
        { code: "P0701", name: "LAMBUSSIE", path: "M 61.5,10.5 53.5,10.5 53.5,17.5 55.5,20.5 56.4,29.2 61.5,41.5 63.5,46.5 73.5,46.5 75.5,50.5 83.5,50.5 85.5,45.5 92.5,45.5 88.5,30.5 83.5,26.5 76.5,24.5 76.5,22.5 69.5,10.5" },
        { code: "P0801", name: "LAWRA", path: "M 25.5,81.5 19.5,66.5 22.5,60.5 22.5,55.5 19.5,53.5 25.5,45.5 40.5,45.5 47.5,43.5 56.2,42.7 61.5,41.5 63.5,46.5 58.5,51.5 57.5,55.5 51.5,59.5 41.5,59.5 36.5,66.5 32.5,66.5 32.5,70.5" },
        { code: "P0901", name: "NANDOM", path: "M 33.5,7.5 33.5,12.5 37.5,16.5 33.5,27.5 29.5,27.5 25.5,41.5 25.5,45.5 40.5,45.5 47.5,43.5 58.5,42.5 61.5,41.5 56.5,30.5 55.5,20.5 53.5,17.5 53.5,10.5 49.5,10.5 49.5,7.5" },
        { code: "P1001", name: "SISSALA WEST", path: "M 69.5,7.5 69.5,10.5 76.5,22.5 76.5,24.5 83.5,26.5 88.5,31.5 90.5,37.5 93.5,47.5 100.5,53.5 107.5,56.5 112.5,57.5 121.5,72.5 123.5,80.5 128.5,90.5 134.5,93.5 146.5,107.5 146.5,111.5 160.5,111.5 166.5,114.5 172.5,100.5 170.5,93.5 174.5,83.5 170.5,75.5 166.5,60.5 166.5,46.5 168.5,39.5 169.5,32.5 170.5,28.5 166.5,24.5 170.5,14.5 170.5,7.5" },
        { code: "P1101", name: "SISSALA EAST", path: "M 219.5,137.5 222.5,131.5 222.5,124.5 244.5,101.5 252.5,101.5 259.5,105.5 262.5,99.5 263.5,96.5 257.5,90.5 253.5,92.5 251.5,92.5 247.5,88.5 249.5,85.5 249.5,81.5 244.5,81.5 237.5,74.5 233.5,72.5 232.5,68.5 231.5,65.5 232.5,48.5 237.5,47.5 244.5,41.5 244.5,35.5 249.5,32.5 249.5,18.5 250.5,11.5 254.5,2.5 232.5,2.5 232.5,8.5 170.5,8.3 170.5,13.7 166.5,24.5 170.5,28.5 169.5,32.5 168.5,39.5 166.5,46.5 166.5,60.5 170.5,75.5 174.5,84.3 170.5,93.5 172.5,100.5 166.5,114.5 185.5,122.5 187.5,126.5 191.5,129.5 195.5,129.5 203.5,138.5" }
    ],
    "NORTH EAST": [
        { code: "Q0101", name: "WALEWALE", path: "M 128.8 53.5 130.31 56.32 127.42 56.62 124.4 62.65 119.12 67.45 116.72 62.65 108.08 66.01 96.56 68.41 90.32 71.29 88.4 78.25 82.16 84.25 75.92 90.49 73.8 80.41 70.16 73.21 65.81 69.85 65.81 66.01 60.56 66.01 59.89 67.57 59.8 60.5 61.8 55.5 59.05 53.14 54.8 49.5 57.77 31.22 58.4 29.6 64.1 26.6 68.8 26.6 76.4 21.5 86.29 22.09 84 28.1 84.19 28.14 84.1 28.4 85.4 28.6 91.3 21.6 99.1 12 99.91 9.2 100.44 7.5 109.1 8.5 109.6 13.6 113.3 15.4 109.1 22 110.33 22.94 109.8 25.5 111.8 27.5 114.8 39.5 120.8 42.5 122.26 42.86 124.8 43.5 130.8 46.5 128.8 50.5 128.8 53.5" },
        { code: "Q0201", name: "YAGABA /KUBORI", path: "M100.68,134.51l.09,7.07-2.21,5.16-7.68,3.47-2.4,4-2.4,4-8.64-5.71-16.8-2.34L54.4,154.9l-5.92-4.69-.11-.34c-.06-1.14-.42-5.92-2.18-6.52v0l-5.2-2.8-.1-3.2,2-3.1.6-6.5,16.2-17.4,5.41-.49L69,112.16l2.5,1.55,5.3,3.1,2,3.6,5.14.69,2.15,3,1.61,2.3,2.8-2.8.7-11.7,5.6-1.9,1.87-4.78-3,18.28,4.25,3.64,2.75,2.36Z" },
        { code: "Q0301", name: "NALERIGU / GAMBAGA", path: "M 189.6 47.29 189.08 47.29 185.45 50.59 183.68 52.2 178.28 53.77 172.16 52.2 167.12 53.77 159.92 53.77 158.79 53.36 149.14 54.36 148.58 54.42 130.31 56.32 128.8 53.5 128.8 50.5 130.8 46.5 124.8 43.5 122.26 42.86 120.8 42.5 114.8 39.5 111.8 27.5 109.8 25.5 110.33 22.94 110.8 23.3 110.88 23.12 111 23.2 112.2 20.6 114.2 22.6 114.36 22.46 114.4 22.5 117.3 19.77 117.7 19.4 125.1 20 125.2 19.79 125.3 19.8 126.69 16.93 132.6 18.6 132.63 18.41 132.8 18.5 133.89 11.58 137.1 11.3 141.4 14.3 146.3 13.9 146.6 13.7 151.4 10.5 155.6 11.3 162.1 10.4 163.09 9.89 162.8 12.5 160.8 20.5 160.8 24.5 161.8 29.5 164.8 34.5 165.8 37.5 165.8 39.5 171.8 42.5 174.8 44.5 178.8 46.5 182.8 45.5 186.8 45.5 189.6 47.29" },
        { code: "Q0401", name: "BUNKPURUGU", path: "M 216.8 40.5 215.58 41.72 214.5 42.3 211.8 40.5 208.8 39.5 204.8 40.5 201.8 35.5 194.8 35.5 186.26 30.52 180.69 29.2 179.8 29.5 177.34 29.99 174.8 30.5 170.8 29.5 167.8 29.5 164.8 27.5 162.8 25.5 160.8 24.5 160.8 20.5 162.8 12.5 163.09 9.89 169.1 6.8 176.1 5 184.7 0 185.1 9.8 191.3 11.8 197.4 14.8 206.8 21.8 215.61 37.45 215.61 37.46 215.8 37.8 215.88 37.74 216.8 40.5" },
        { code: "Q0501", name: "YUNYOO", path: "M 215.58 41.72 212.8 45.5 208.8 47.5 205.8 50.5 200.8 50.5 197.37 51.52 196.65 49.43 195.92 47.29 189.6 47.29 186.8 45.5 182.8 45.5 178.8 46.5 174.8 44.5 171.8 42.5 165.8 39.5 165.8 37.5 164.8 34.5 161.8 29.5 160.8 24.5 162.8 25.5 164.8 27.5 167.8 29.5 170.8 29.5 174.8 30.5 177.34 29.99 179.8 29.5 180.69 29.2 186.26 30.52 194.8 35.5 201.8 35.5 204.8 40.5 208.8 39.5 211.8 40.5 214.5 42.3 215.58 41.72" },
        { code: "Q0601", name: "CHEREPONI", path: "M 234.6 81 237.3 93.3 230.48 93.01 224.72 89.05 221.12 88.69 218.24 90.49 213.92 90.49 211.04 87.97 206.72 87.61 204.2 90.49 197.6 90.49 197.6 87.25 202.4 86.53 204.2 80.77 202.4 77.89 202.4 71.05 197.6 67.09 197.6 63.13 199.88 55.57 197.6 52.2 197.37 51.52 200.8 50.5 205.8 50.5 208.8 47.5 212.8 45.5 215.58 41.72 216.8 40.5 215.88 37.74 218.3 36 221.1 37.8 225.6 36 231.6 45.8 232.3 49.5 238.3 49.3 234.8 56.5 233.3 70.3 238.1 75.8 239.6 81 234.6 81" }
    ],
    "UPPER EAST": [
        { code: "R0101", name: "BUILSA SOUTH", path: "M 60.5,188.5 64.5,184.5 64.5,168.5 73.5,165.5 76.5,157.5 83.5,152.5 89.5,152.5 89.5,148.5 88.5,148.5 88.5,142.5 75.5,132.5 41.5,132.5 23.5,138.5 23.5,142.5 25.5,145.5 27.5,147.5 27.5,149.5 26.5,149.5 25.5,153.5 28.5,156.5 32.5,156.5 36.5,155.5 41.5,161.5 37.5,170.5 44.5,175.5 48.5,180.5 55.5,181.5" },
        { code: "R0201", name: "BUILSA NORTH", path: "M8.5,110.5l-1,19l2,6l6,3l6,7h4l-2-3v-4l18-6h34l12.4,9.6c0.1,0.1,0.3-0.1,0.2-0.2l-4.6-7.4v-3l4-4v-7l-6-17v-11l-14,6h-3l-10,5h-4l-8,5h-15l-7-5l-8,6L8.5,110.5z" },
        { code: "R0301", name: "KASSENA/NANKANA EAST", path: "M117.5,66.5h-13v-2h-18l-1,3v6l-2,4v10l-2,3v2v11c0,0,4,10,6,17c0,2,0,7,0,7l-4,4v3l5,8v6h1v4h3l9-7h11v-10l-2-14l-1-5l-5-8l-6-4l4-9h6l5-6l4-8l4-10L117.5,66.5z" },
        { code: "R0401", name: "KASSENA/NANKANA WEST", path: "M85.5,67.5h-28h-8v-1l-9.3,0.7l-8-4c0,0-4.7,1.3-5.7,30.3l-6,4v6l7,5h15l8-5h4l10-5h3l14-6v-2l2-3v-10l2-4V67.5z" },
        { code: "R0501", name: "BOLGATANGA CENTRAL", path: "M 111.8,130.7 119.5,123.5 125.5,120.5 125.5,108.5 126.5,106.5 125.5,102.5 126.5,97.5 121.5,91.5 113.5,89.5 108.5,95.5 102.5,95.5 98.5,104.5 104.5,108.5 109.5,116.5 110.5,121.5" },
        { code: "R0601", name: "BOLGATANGA EAST", path: "M 125.5,120.5 125.5,108.5 126.5,105.5 125.5,102.5 126.5,97.5 136.5,97.5 140.5,91.5 145.5,95.5 148.5,93.5 148.5,106.5 145.5,115.5 138.5,115.5 134.5,117.5 130.5,117.5" },
        { code: "R0701", name: "BONGO", path: "M 158.5,69.5 151.5,68.5 144.5,68.5 144.5,67.5 135.5,67.5 121.5,71.5 113.5,89.5 121.5,91.5 126.5,97.5 136.5,97.5 140.5,91.5 145.5,95.5 148.5,93.5 148.5,90.5 160.5,90.5 168.5,87.5 165.5,81.5 157.5,75.5" },
        { code: "R0801", name: "TALENSI", path: "M 135.5,124.5 135.5,129.5 114.5,155.5 112.5,154.5 115.5,148.5 115.5,145.5 112.5,145.5 111.5,130.5 119.5,123.5 125.5,120.5 130.5,117.5 134.5,117.5 138.5,115.5 145.5,115.5 145.5,114.5 158.5,114.5 165.5,111.5 174.5,111.5 176.5,116.5 176.5,119.5 180.5,119.5 180.5,122.5 181.5,126.5 181.5,141.5 176.5,138.5 172.5,138.5 170.5,142.5 160.5,142.5 156.5,146.5 152.5,143.5 150.5,147.5 148.5,145.5 154.5,139.5 154.5,136.5 148.5,134.5 148.5,127.5 145.5,126.5 138.5,126.5" },
        { code: "R0901", name: "NABDAM", path: "M 174.5,111.5 174.5,102.5 168.5,87.5 160.5,90.5 148.5,90.5 148.5,106.5 145.5,114.5 158.5,114.5 165.5,111.5" },
        { code: "R1001", name: "BAWKU WEST", path: "" },
        { code: "R1101", name: "BAWKU", path: "M 237.5,87.5 230.5,87.5 223.5,90.5 213.5,99.5 207.5,91.5 205.5,83.5 209.5,74.5 205.5,64.5 213.5,57.5 217.5,70.5 221.5,70.5 221.5,69.5 225.5,69.5 226.5,73.5 229.5,78.5" },
        { code: "R1201", name: "PUSIGA", path: "M 238.5,57.5 246.5,68.5 237.5,87.5 229.5,78.5 226.5,73.5 225.5,69.5 221.5,69.5 221.5,70.5 217.5,70.5 213.5,57.5 214.5,53.5 214.5,49.5 219.5,46.5 219.5,39.5 222.5,39.5 228.5,40.5 229.5,45.5 234.5,57.5" },
        { code: "R1301", name: "GARU", path: "M 254.5,115.5 237.5,87.5 230.5,87.5 223.5,90.5 213.5,99.5 208.5,106.5 216.5,124.5 216.5,130.5 222.5,130.5 232.5,124.5 242.5,122.5" },
        { code: "R1401", name: "TEMPANE", path: "M 255.5,106.5 264.5,97.5 264.5,90.5 263.5,88.5 266.5,81.5 266.5,74.5 272.5,69.5 272.5,60.5 266.5,62.5 260.5,62.5 246.5,68.5 237.5,87.5 230.5,87.5 223.5,90.5 212.9,100.3 208.5,106.5 216.5,124.5 216.5,130.5 222.5,130.5 232.5,124.5 242.5,122.5 254.5,115.5 254.5,111.5 255.5,111.5" },
        { code: "R1501", name: "BINDURI", path: "M 272.5,60.5 272.5,53.5 260.5,49.5 251.5,52.5 242.5,46.5 242.5,42.5 228.5,40.5 230,46.7 234.5,57.5 238.5,57.5 246.5,68.5 260.5,62.5 266.5,62.5" }
    ]
}

export default REGIONS_WITH_CONSTITUENCIES;