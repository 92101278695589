import React from 'react';
import { Modal, Box, Typography, Button, Grid } from '@mui/material';
import QRCode from "react-qr-code";
import moment from 'moment';
import ReactPlayer from 'react-player';
import { GOOGLE_MAPS_API_KEY } from './Constants.js';

const IncidentsModal = ({ open, handleClose, post }) => {
    if (!post) return null;

    console.log("Post Details: " + JSON.stringify(post))

    const { address, date_posted, description, files, posted_name, visible, orientation = "none" } = post;
    const file = files[0];
    const itemLink = `${window.location.origin}/post/${post._id}`;
    const approvalStatus = visible ? 'Approved' : 'Not approved';

    // Calculate the rotation based on orientation
    const getRotation = (orientation) => {
        switch (orientation) {
            case 'landscape-right':
                return 'rotate(-90deg)';
            case 'landscape-left':
                return 'rotate(90deg)';
            case 'portrait-upside-down':
                return 'rotate(180deg)';
            case 'portrait':
            default:
                return 'rotate(0deg)';
        }
    };

    const rotationStyle = getRotation(orientation);

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="post-modal-title" aria-describedby="post-modal-description">
            <Box
                sx={{
                    position: 'absolute',
                    top: '5%',
                    bottom: '5%',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    width: '80%',
                    maxWidth: '900px',
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                    borderRadius: 2,
                    display: 'flex',
                    flexDirection: 'row',
                    overflowY: 'auto',
                    maxHeight: '100vh',
                }}
            >
                {/* Main Content: File Display */}
                <Box sx={{ flex: 1, position: 'relative', paddingTop: '56.25%', backgroundColor: '#000', marginRight: 2 }}>
                    {file && file.fileType.startsWith('video') ? (
                        <ReactPlayer
                            url={file.url}
                            controls
                            width="100%"
                            height="100%"
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                objectFit: 'contain',
                            }}
                        />
                    ) : (
                        <img
                            src={file.url}
                            alt="Post file"
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                            }}
                        />
                    )}
                    {/* Watermark */}
                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: 50,
                            right: 8,
                            backgroundColor: 'rgba(0, 0, 0, 0.05)',
                            color: 'white',
                            padding: '4px 8px',
                            borderRadius: 1,
                            fontSize: '0.8rem',
                        }}
                    >
                        <Typography>{address}</Typography>

                        <Typography>
                            {moment(date_posted).format('DD/MM/YYYY, h:mm:ss a')}
                        </Typography>
                    </Box>
                </Box>

                {/* Side Content: Map, QR Code, and Post Details */}
                <Box sx={{ flexBasis: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    {/* Map */}
                    <iframe
                        title="Google Street View"
                        width="100%"
                        height="125"
                        style={{ border: 0 }}
                        loading="lazy"
                        allowFullScreen
                        src={`https://www.google.com/maps/embed/v1/streetview?key=${GOOGLE_MAPS_API_KEY}&location=${file.latitude},${file.longitude}&heading=210&pitch=10&fov=35`}
                    ></iframe>
                    {/* QR Code */}
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <QRCode size={100} value={itemLink} />
                    </Box>

                    {/* Post Details */}
                    <Box>
                        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                            {address} at {moment(date_posted).format('DD/MM/YYYY, h:mm:ss a')}
                        </Typography>

                        {description && (
                            <Typography sx={{ mt: 1 }}>
                                <strong>{description}</strong>
                            </Typography>
                        )}

                        <Button onClick={handleClose} variant="contained" color="primary" fullWidth sx={{ mt: 3 }}>
                            Close
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
};

export default IncidentsModal;
