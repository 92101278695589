import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Container, Spinner, Alert, Modal, Button } from 'react-bootstrap';
import { COLOR_PRIMARY, MAIN_URL } from './Constants';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';

const PollingStationTables = ({ code }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [latitude, setLatitude] = useState(0)
    const [longitude, setLongitude] = useState(0)

    const navigate = useNavigate()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(MAIN_URL + 'election-results-main/' + code);
                console.log("Polling data: " + JSON.stringify(response.data))
                setData(response.data);

                setLatitude(response.data.latitude)

                setLongitude(response.data.longitude)

                const coords={latitude,longitude};
                localStorage.setItem("coords",JSON.stringify(coords))
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [code]);

    if (loading) return <Spinner animation="border" variant="primary" />;
    if (error) return <Alert variant="danger">Error: {error}</Alert>;

    return (
        <Container className="mt-4">
            {data.map((result) => (
                <div key={result.polling_station_code} className="mb-5">
                    <h2>{result.polling_station_name} ({result.constituency_name})</h2>
                    <h5 style={{ textAlign: 'center', padding: 10, color: COLOR_PRIMARY }}>PRESIDENTIAL RESULTS</h5>
                    <ResultsTable
                        data={result.results.presidential.data}
                        uploaderName={result.sender_name}
                        uploadedFile={result.files[0]}
                        institution={result.sender_institution}
                        uploaded_by={result.uploaded_by}
                        navigate={navigate}
                    />
                    <h5 style={{ textAlign: 'center', padding: 10, color: COLOR_PRIMARY }}>PARLIAMENTARY RESULTS</h5>
                    <ResultsTable
                        data={result.results.parliamentary.data}
                        uploaderName={result.sender_name}
                        uploadedFile={result.files[0]}
                        institution={result.sender_institution}
                        uploaded_by={result.uploaded_by}
                        navigate={navigate}

                    />
                </div>
            ))}
        </Container>
    );
};

const ResultsTable = ({ data, uploaderName, uploadedFile, institution, uploaded_by, navigate }) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    // Filter out duplicates based on name, party, and votes
    const uniqueData = data.filter(
        (item, index, self) =>
            index === self.findIndex((t) => (
                t.name === item.name && t.party === item.party && t.votes === item.votes
            ))
    );

    // Calculate the total votes across all candidates
    const totalVotes = uniqueData.reduce((acc, item) => acc + item.votes, 0);

    // Handle image click to show in modal
    const handleImageClick = (imageSrc) => {
        setSelectedImage(imageSrc);
        setShowModal(true);
    };

    // Handle modal close
    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedImage(null);
    };

    return (
        <>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th style={{ backgroundColor: COLOR_PRIMARY, color: '#fff' }}>PARTY</th>
                        <th colSpan={3} style={{ backgroundColor: COLOR_PRIMARY, color: '#fff' }}>CANDIDATE NAME</th>
                        <th colSpan={2} style={{ backgroundColor: COLOR_PRIMARY, color: '#fff' }} onClick={() => {
                            navigate('/user/details/' + uploaded_by)
                        }}>
                            {uploaderName} ({institution})
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {uniqueData.map((item, index) => (
                        <tr key={index}>
                            <td>{item.party}</td>
                            <td colSpan={3} style={{ textTransform: 'uppercase' }}>{item.name}</td>
                            <td>{item.votes}</td>
                        </tr>
                    ))}
                    <tr>
                        <td><strong>Total</strong></td>
                        <td colSpan={3}></td>
                        <td><strong>{totalVotes}</strong></td>
                    </tr>

                    {/* Render the uploaded file (image) under the uploader column */}
                    <tr>
                        <th>Pink Sheets</th>
                        <th colSpan={3}></th>
                        <td>
                            <div onClick={() => handleImageClick(uploadedFile)}>
                                <img
                                    src={uploadedFile}
                                    alt="Uploaded"
                                    style={{ maxWidth: '15%', height: '20%', cursor: 'pointer' }}
                                />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </Table>

            {/* Image Modal */}
            <Modal show={showModal} onHide={handleCloseModal} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Pink Sheet</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    {selectedImage && (
                        <img
                            src={selectedImage}
                            alt="Expanded"
                            style={{ maxWidth: '100%', height: 'auto' }}
                        />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default PollingStationTables;
