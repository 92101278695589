import React from 'react';
import { Card } from '@mui/material';
import './App.css';
import { PARTY_COLOURS } from './Constants';
// Function to get candidate image by party name
const getCandidateImage = (partyName, candidates) => {
    const candidate = candidates.find(c => c.partyName === partyName);
    return candidate ? candidate.imageOfCandidate : null;
};

// Function to get party flag by party name
const getPartyFlag = (partyName, candidates) => {
    const candidate = candidates.find(c => c.partyName === partyName);
    return candidate ? candidate.partyImage : null;
};

// Function to get party color by party name
const getPartyColor = (partyName) => {
    return PARTY_COLOURS[partyName] || '#000000'; // Default to black if no color is found
};

const ElectionCharts = ({ data, candidates }) => {
    // Calculate total votes for percentage calculation
    const totalVotes = data ? data.reduce((sum, result) => sum + result.votes, 0) : 0;

    const barHeight = 30;
    const barSpacing = 40;
    const svgWidth = 600;
    const svgHeight = data ? (barHeight + barSpacing) * data.length : 0;

    return (
        <Card style={{ padding: 10, display: 'flex', justifyContent: 'center' }}>
            {data ? (
                <svg width={svgWidth} height={svgHeight}>
                    {data.map((result, index) => {
                        const percentage = ((result.votes / totalVotes) * 100).toFixed(2);
                        const barWidth = (percentage / 100) * svgWidth;
                        const yPosition = index * (barHeight + barSpacing);
                        const partyColor = getPartyColor(result.party);
                        const candidateImage = getCandidateImage(result.party, candidates);
                        const partyFlag = getPartyFlag(result.party, candidates);

                        return (
                            <g key={result.party}>
                                {/* Candidate Image with Circle Background */}
                                {candidateImage && (
                                    <>
                                        <defs>
                                            <clipPath id={`clip-candidate-${result.party}`}>
                                                <circle cx="35" cy={yPosition + 30} r="25" />
                                            </clipPath>
                                        </defs>
                                        <circle cx="35" cy={yPosition + 30} r="25" fill="#ddd" />
                                        <image
                                            href={candidateImage}
                                            x="10"
                                            y={yPosition + 10}
                                            width={result.party === "NPP" ? 70 : 55}
                                            height="55"
                                            clipPath={`url(#clip-candidate-${result.party})`}
                                        />
                                    </>
                                )}

                                {/* Party Label */}
                                <text
                                    x="65"
                                    y={yPosition + barHeight / 2}
                                    alignmentBaseline="middle"
                                    fontSize="16"
                                    fill="#000"
                                >
                                    {result.party}
                                </text>

                                {/* Party Flag */}
                                {partyFlag && (
                                    <image
                                        x="65"
                                        y={yPosition + barHeight / 1.4}
                                        href={partyFlag}
                                        width="35"
                                        height="30"
                                    />
                                )}

                                {/* Bar */}
                                <rect
                                    x="150"
                                    y={yPosition}
                                    width={barWidth}
                                    height={barHeight}
                                    fill={partyColor}
                                />

                                {/* Percentage Text */}
                                <text
                                    x={150}
                                    y={yPosition + barHeight + 15}
                                    alignmentBaseline="middle"
                                    fontSize="20"
                                    fill="#777"
                                    fontWeight="bold"
                                >
                                    {percentage}%
                                </text>

                                {/* Votes Text */}
                                <text
                                    x="240"
                                    y={yPosition + barHeight + 15}
                                    fontSize="17"
                                    alignmentBaseline="middle"
                                    fill="#777"
                                >
                                    {result.votes.toLocaleString()} votes
                                </text>
                            </g>
                        );
                    })}
                </svg>
            ) : (
                <p>Loading results...</p>
            )}
        </Card>
    );
};

export default ElectionCharts