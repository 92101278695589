import React from 'react';
import { FaFacebook, FaTwitter, FaWhatsapp, FaLinkedin, FaPinterest, FaReddit, FaEnvelope } from 'react-icons/fa';

const handleShare = (platform, url, text = '') => {
    let shareUrl = '';

    switch (platform) {
        case 'facebook':
            shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
            break;
        case 'twitter':
            shareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}`;
            break;
        case 'whatsapp':
            shareUrl = `https://wa.me/?text=${encodeURIComponent(text)} ${encodeURIComponent(url)}`;
            break;
        case 'linkedin':
            shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(url)}&title=${encodeURIComponent(text)}`;
            break;
        case 'pinterest':
            shareUrl = `https://www.pinterest.com/pin/create/button/?url=${encodeURIComponent(url)}&description=${encodeURIComponent(text)}`;
            break;
        case 'reddit':
            shareUrl = `https://www.reddit.com/submit?url=${encodeURIComponent(url)}&title=${encodeURIComponent(text)}`;
            break;
        case 'email':
            shareUrl = `mailto:?subject=${encodeURIComponent(text)}&body=${encodeURIComponent(url)}`;
            break;
        default:
            return;
    }

    window.open(shareUrl, '_blank', 'width=600,height=400');
};

const SharePost = ({ url, text, onCancel }) => {
    return (
        <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
            <h5 className="text-center mb-4">Share post via <button className='btn btn-danger' onClick={onCancel}>Cancel</button> </h5>
            <div className="d-flex justify-content-center align-items-center gap-3 flex-wrap">
                <button onClick={() => handleShare('facebook', url, text)} className="btn btn-primary text-center">
                    <FaFacebook size={32} />
                    <div>Facebook</div>
                </button>
                <button onClick={() => handleShare('twitter', url, text)} className="btn btn-info text-center">
                    <FaTwitter size={32} />
                    <div>Twitter</div>
                </button>
                <button onClick={() => handleShare('whatsapp', url, text)} className="btn btn-success text-center">
                    <FaWhatsapp size={32} />
                    <div>WhatsApp</div>
                </button>
                <button onClick={() => handleShare('linkedin', url, text)} className="btn btn-primary text-center" style={{ backgroundColor: '#0077B5' }}>
                    <FaLinkedin size={32} />
                    <div>LinkedIn</div>
                </button>
                <button onClick={() => handleShare('pinterest', url, text)} className="btn btn-danger text-center" style={{ backgroundColor: '#BD081C' }}>
                    <FaPinterest size={32} />
                    <div>Pinterest</div>
                </button>
                <button onClick={() => handleShare('reddit', url, text)} className="btn text-center" style={{ backgroundColor: '#FF4500', color: 'white' }}>
                    <FaReddit size={32} />
                    <div>Reddit</div>
                </button>
                <button onClick={() => handleShare('email', url, text)} className="btn btn-dark text-center">
                    <FaEnvelope size={32} />
                    <div>Email</div>
                </button>
            </div>
        </div>
    );
};

export default SharePost;
