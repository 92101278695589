import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { Button, Form, Row, Col, Spinner } from 'react-bootstrap';
import { MAIN_URL } from './Constants';
import { FaUser } from 'react-icons/fa';

const UserManagement = () => {
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(false);

    const fetchUsers = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${MAIN_URL}users/grouped-by-institution`);
            const flatUsers = Object.entries(response.data.groupedByInstitution).flatMap(
                ([institution, users]) =>
                    users.map((user) => ({
                        ...user,
                        institution,
                    }))
            );
            flatUsers.sort((a, b) => {
                const dateA = new Date(a.createdAt || 0);
                const dateB = new Date(b.createdAt || 0);
                return dateB - dateA; // Descending order
            });
            setUsers(flatUsers);
        } catch (error) {
            console.error('Error fetching users:', error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const filteredUsers = users.filter(
        (user) =>
            user.username.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user.institution.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user.phone_number.includes(searchQuery)
    );

    const toggleUserSelection = (userId) => {
        setSelectedUsers((prev) =>
            prev.includes(userId)
                ? prev.filter((id) => id !== userId)
                : [...prev, userId]
        );
    };

    const selectAllUsers = () => setSelectedUsers(filteredUsers.map((user) => user._id));
    const clearSelectedUsers = () => setSelectedUsers([]);

    const updateUserType = async (newType) => {
        setUpdating(true);
        try {
            await axios.put(`${MAIN_URL}users/update-user-type`, {
                userIds: selectedUsers,
                newType,
            });
            alert('Updated successfully.');
            setSelectedUsers([]);
            await fetchUsers(); // Refresh the user list
        } catch (error) {
            console.error('Error updating user types:', error.message);
            alert('Failed to update user types.');
        } finally {
            setUpdating(false);
        }
    };

    const columns = [

        {
            name: 'SELECT ALL',
            selector: () => <FaUser color='#ccc' />,
            style: {
                backgroundColor: '#fff',
                cursor: 'pointer',
            },
        },
        {
            name: "Name",
            selector: (row) => row.username,
            style: {
                // fontWeight: 'bold',
                backgroundColor: '#fff',
            },
        },
        {
            name: 'Email',
            selector: (row) => row.email,
            style: {
                // fontWeight: 'bold',
                backgroundColor: '#fff',
            },
        },
        {
            name: 'Phone',
            selector: (row) => row.phone_number.replace(" ", "").substring(0, 10),
            style: {
                // fontWeight: 'bold',
                backgroundColor: '#fff',
            },
        },
        {
            name: 'Institution',
            selector: (row) => row.institution,
            style: {
                // fontWeight: 'bold',
                backgroundColor: '#fff',
            },
        },
        {
            name: 'Status',
            selector: (row) => (
                <span style={{ color: row.user_type === 'normal' ? 'red' : 'green' }}>
                    {row.user_type === 'normal' ? 'Not Approved' : 'Approved'}
                </span>
            ),
            style: {
                // fontWeight: 'bold',
                backgroundColor: '#ffffff',
            },
        },
        {
            name: 'Created At',
            selector: (row) =>
                row.createdAt
                    ? new Date(row.createdAt).toLocaleString('en-US', {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                    })
                    : 'N/A',
            style: {
                backgroundColor: '#fff',
                fontSize: 10
            },
        }
    ];

    return (
        <div style={{ padding: '20px', backgroundColor: '#f9f9f9' }}>
            <Row className="mb-4">
                <Col md={8}>
                    <Form.Control
                        type="text"
                        placeholder="Search by name, phone, or institution"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </Col>
            </Row>
            <Row className="mb-4">
                <Col>
                    <span><b>Total Users: </b>{filteredUsers.length}</span>
                </Col>
                {selectedUsers.length > 0 && (
                    <Col>
                        <div className="d-flex justify-content-between">
                            <Button
                                variant="danger"
                                onClick={() => updateUserType('normal')}
                                disabled={updating}
                            >
                                Revoke
                            </Button>
                            <Button
                                variant="success"
                                onClick={() => updateUserType('agent')}
                                disabled={updating}
                            >
                                Approve
                            </Button>
                        </div>
                    </Col>
                )}
            </Row>

            <DataTable
                columns={columns}
                data={filteredUsers}
                pagination
                selectableRows
                onSelectedRowsChange={({ selectedRows }) =>
                    setSelectedUsers(selectedRows.map((row) => row._id))
                }
                progressPending={loading}
                progressComponent={<Spinner animation="border" />}
            />

            {updating && (
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <Spinner animation="border" /> Updating...
                </div>
            )}
        </div>
    );
};

export default UserManagement;
