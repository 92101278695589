import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import ImageIcon from '@mui/icons-material/Image';
import Settings from '@mui/icons-material/Settings';
import moment from 'moment';
import { MAIN_URL, COLOR_PRIMARY } from './Constants';
import { useAuth } from './contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { DateRange, CalendarToday } from '@mui/icons-material';

const PostList = React.memo(({ lat, lng, type }) => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    console.log("Type: " + type)

    const { user } = useAuth();
    const navigate = useNavigate();

    const handleFileClick = (incident_id) => {
        navigate("/post/incident/" + incident_id);
    };

    const fetchPosts = async (start, end) => {
        setLoading(true);
        setError(null);

        try {
            const url = (lat && lng)
                ? `${MAIN_URL}load/posts/main/${lat}/${lng}`
                : `${MAIN_URL}load/posts/main`;

            const response = await axios.get(url);

            // Filter posts based on selected dates if provided
            const filteredPosts = response.data.filter(post => {
                const postDate = new Date(post.date_posted);

                if (start && end) {
                    return postDate >= new Date(start) && postDate <= new Date(end);
                } else if (start) {
                    return postDate >= new Date(start);
                } else if (end) {
                    return postDate <= new Date(end);
                }
                return true;
            });

            setPosts(filteredPosts);
        } catch (err) {
            setError('Failed to load posts');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPosts(startDate, endDate);
    }, [startDate, endDate, lat, lng]);

    const PostItem = ({ post }) => (
        <div
            key={post._id}
            className="post-item"
            style={{
                position: 'relative',
                marginBottom: '1px',
                width: '100%',
                padding: '5px 0',
                height: '100%',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
            }}
        >
            {post.files && post.files[0] && (
                <div
                    style={{
                        position: 'relative',
                        backgroundColor: 'black',
                        paddingTop: '56.25%',
                    }}
                    onClick={() => handleFileClick(post.incident_id)}
                >
                    {post.files[0].fileType.startsWith('video') ? (
                        <>
                            <video
                                src={post.files[0].url || 'path_to_default_video_thumbnail'}
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                            />
                            <PlayCircleFilledWhiteIcon
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    color: '#fff',
                                    fontSize: '25px',
                                    opacity: 0.7,
                                }}
                            />
                        </>
                    ) : (
                        <>
                            <img
                                src={post.files[0].url}
                                alt="Image thumbnail"
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                }}
                            />
                            <ImageIcon
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    color: '#fff',
                                    fontSize: '30px',
                                    opacity: 0.7,
                                }}
                            />
                        </>
                    )}
                </div>
            )}
            <div style={{
                display: "flex", flexDirection: 'column',
                position: 'absolute', zIndex: 10,
                backgroundColor: 'rgba(0,0,0,0.0)', width: '100%', height: '40%',
                top: '57%', padding: 10, paddingTop: 0
            }}>
                <p style={{ color: "#fff", fontSize: '10px', padding: '3px 0', margin: '2px 0' }}>
                    {post.address + "\n\n" + moment(post.date_posted).format('DD/MM/YYYY, h:mm:ss a')}
                </p>

            </div>

        </div>
    );

    return (
        <div className="post-list" style={{ width: '100%', overflow: 'hidden' }} >
            <h5 style={{ color: "#777" }}>INCIDENTS</h5>

            {/* {user && (
                <div style={{ marginBottom: '1px' }}>
                    <div
                        onClick={() => navigate("/incidents/elections/manage")}
                        style={{
                            backgroundColor: COLOR_PRIMARY,
                            borderRadius: 10,
                            padding: 10,
                            border: `1px solid ${COLOR_PRIMARY}`,
                            cursor: 'pointer',
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginBottom: 10,
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        }}
                    >
                        <span
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px',
                                fontWeight: 'bold',
                                color: "#ccc",
                            }}
                        >
                            <Settings color={COLOR_PRIMARY} style={{ fontSize: 20 }} />
                            <span style={{ fontSize: 16 }}>MANAGE</span>
                        </span>
                    </div>
                </div>
            )} */}

            {/* Date Filter */}
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', gap: '10px' }}>
                <div>
                    <label style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        <CalendarToday style={{ fontSize: '20px', color: '#777' }} />
                        From:
                        <input
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                            style={{ padding: '5px', borderRadius: '4px', border: '1px solid #ccc' }}
                        />
                    </label>
                </div>
                <div>
                    <label style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                        <CalendarToday style={{ fontSize: '20px', color: '#777' }} />
                        To:
                        <input
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                            style={{ padding: '5px', borderRadius: '4px', border: '1px solid #ccc' }}
                        />
                    </label>
                </div>
            </div>

            {/* Loading Spinner */}
            {loading && (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <CircularProgress />
                </div>
            )}

            {/* Error State */}
            {error && (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <Alert severity="error">{error}</Alert>
                </div>
            )}

            {/* Post List */}
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '2px' }}>
                {!loading && !error && posts.length > 0 && posts.map(post => <PostItem key={post._id} post={post} />)}

                {/* Empty State */}
                {!loading && posts.length === 0 && (
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <p>No posts available.</p>
                    </div>
                )}
            </div>
        </div>
    );
});

export default PostList;
