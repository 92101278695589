import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { MAIN_URL, COLOR_PRIMARY } from '../Constants';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';
import ElectionHeader from '../ElectionHeader';
import { Modal, Button } from 'react-bootstrap';


const UserDetails = () => {
    const [userData, setUserData] = useState(null);
    const [presidentialResults, setPresidentialResults] = useState([]);
    const [parliamentaryResults, setParliamentaryResults] = useState([]);
    const [uniquePollingStationsCount, setUniquePollingStationsCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const { id } = useParams();
    const { user } = useAuth();
    const navigate = useNavigate();

    const [presidentialData, setPresidentialData] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);


    const [pollingStations, setPollingStations] = useState(0);
    const [validVotes, setValidVotes] = useState(0);
    const [rejectedVotes, setRejectedVotes] = useState(0);
    const [posts, setPosts] = useState([]);
    const [selectedPosts, setSelectedPosts] = useState([]);
    const [regionalData, setRegionalData] = useState([])



    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);





    useEffect(() => {
        if (!user || user.user.user_type !== "admin") {
            navigate('/login');
            return;
        }

        const fetchUserDetails = async () => {
            try {
                const response = await axios.get(`${MAIN_URL}user/details/${id}`, {
                    headers: {
                        Authorization: `Bearer ${user.accessToken}`,
                    },
                });

                setUserData(response.data.userdata);

                const presidential = response.data.captured.filter(result => result.type === 'presidential');
                const parliamentary = response.data.captured.filter(result => result.type === 'parliamentary');

                setPresidentialResults(presidential);
                setParliamentaryResults(parliamentary);

                // Calculate unique polling stations based on polling_station_code
                const allPollingStations = response.data.captured.map(result => result.polling_station_code);
                const uniquePollingStations = new Set(allPollingStations);
                setUniquePollingStationsCount(uniquePollingStations.size);

                console.log(JSON.stringify(response.data))

                setLoading(false);
            } catch (err) {
                console.error('Error fetching user data:', err);
                setError('Error fetching user details.');
                setLoading(false);
            }
        };

        fetchUserDetails();
    }, [id, user]);



    const handleCloseModal = () => setShowModal(false);

    const handleImageClick = (image) => {
        setSelectedImage(image);
        setShowModal(true);
    };
    const handleSearch = async (event) => {
        const query = event.target.value;
        setSearchQuery(query);

        // Perform search only when query length is at least 3 characters
        if (query.length >= 3) {
            try {
                const response = await axios.get(`${MAIN_URL}election-results/search/${query}`);
                setSearchResults(response.data);
            } catch (error) {
                console.error('Error fetching search results', error);
            }
        } else {
            // Clear search results if query length is less than 3
            setSearchResults([]);
        }
    };

    if (loading) return <div className="text-center">Loading...</div>;
    if (error) return <div className="alert alert-danger">{error}</div>;

    return (
        <body>
            <ElectionHeader
                searchQuery={searchQuery} searchResults={searchResults} type="none"
                navigate={navigate} setSearchQuery={setSearchQuery} handleSearch={handleSearch} />



            <div className="container mt-5">
                {/* User Details Section */}
                <div className="card mb-4 shadow-lg"
                    style={{ backgroundColor: 'white' }}
                >
                    <div className="card-header text-white" style={{ backgroundColor: COLOR_PRIMARY }}>
                        <h3 className="card-title" style={{ textAlign: 'center' }}>USER DETAILS</h3>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-4 text-center">
                                <img src={userData.id_card_image} alt="ID Card" className="img-fluid rounded mb-3" style={styles.idCardImage} />
                            </div>
                            <div className="col-md-8">
                                <p><strong>Name:</strong> {userData.username}</p>
                                <p><strong>Email:</strong> {userData.email}</p>
                                <p><strong>Phone:</strong> {userData.phone_number}</p>
                                <p><strong>Institution:</strong> {userData.institution}</p>
                                <p><strong>ID Card Type:</strong> {userData.id_card_type}</p>
                                <p><strong>ID Card Number:</strong> {userData.id_card_number}</p>
                                <p><strong>Accreditation ID:</strong> {userData.accreditation_id}</p>
                                <p><strong>Employee ID:</strong> {userData.emp_id}</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Display Unique Polling Stations Count */}
                <div className="card mb-4 shadow-lg text-center" style={{ backgroundColor: 'white' }}
                >
                    <div className="card-body" style={styles.uniquePollingStationsCard}>
                        <h3 className="card-title text-white">POLLING STATIONS RECORDED</h3>
                        <p className="display-4" style={{ color: 'yellowgreen', fontWeight: 'bold' }}>{uniquePollingStationsCount}</p>
                    </div>
                </div>

                {/* Presidential Results Table */}
                <div className="card mb-4 shadow-lg" style={{ backgroundColor: 'white' }}
                >
                    <div className="card-header text-white" style={{ backgroundColor: COLOR_PRIMARY }}>
                        <h3 style={{ textAlign: 'center' }}>PRESIDENTIAL RESULTS
                        </h3>
                    </div>
                    <div className="card-body table-responsive">
                        {presidentialResults.map((result, index) => {
                            const totalVotes = result.total_valid_votes + result.total_rejected_ballots;
                            return (
                                <div key={index} className="mb-3 p-3" style={styles.resultSection}>
                                    <p><strong>Constituency:</strong> {result.constituency_name}</p>
                                    <p><strong>Polling Station:</strong> {result.polling_station_name}</p>
                                    <table className="table table-striped table-bordered">
                                        <thead className="bg-dark text-white">
                                            <tr>
                                                <th>Party</th>
                                                <th>Candidate</th>
                                                <th>Votes</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {result.results.map(candidate => (
                                                <tr key={candidate.id}>
                                                    <td>{candidate.party}</td>
                                                    <td>{candidate.name}</td>
                                                    <td>{candidate.votes}</td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td colSpan="2"><strong>Total Valid Votes</strong></td>
                                                <td>{result.total_valid_votes}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2"><strong>Total Rejected Ballots</strong></td>
                                                <td>{result.total_rejected_ballots}</td>
                                            </tr>
                                            {/* Sum Total Row */}
                                            <tr style={{ fontWeight: 'bold' }}>
                                                <td colSpan="2"><strong>Total Votes </strong></td>
                                                <td>{totalVotes}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="image-gallery">
                                        {result?.files?.map((item, index) => (
                                            result.files.length > 0 && (
                                                <img
                                                    key={index}
                                                    src={result.files[0]}
                                                    alt="Captured"
                                                    style={{ maxWidth: '100px', cursor: 'pointer', margin: '10px' }}
                                                    onClick={() => handleImageClick(result.files[0])}
                                                />
                                            )
                                        ))}
                                    </div>
                                    <span style={{ display: "flex", justifyContent: 'flex-end', fontSize: 13 }}>
                                        Submited on : {moment(result.date_captured).format("DD/MM/YYYY hh:mm A")}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>

                {/* Parliamentary Results Table */}
                <div className="card mb-4 shadow-lg" style={{ backgroundColor: 'white' }}
                >
                    <div className="card-header text-white" style={{ backgroundColor: COLOR_PRIMARY }}>
                        <h3 style={{ textAlign: 'center' }}>PARLIAMENTARY RESULTS</h3>
                    </div>
                    <div className="card-body table-responsive">
                        {parliamentaryResults.map((result, index) => {
                            const totalVotes = result.total_valid_votes + result.total_rejected_ballots;
                            return (
                                <div key={index} className="mb-3 p-3" style={styles.resultSection}>
                                    <p><strong>Constituency:</strong> {result.constituency_name}</p>
                                    <p><strong>Polling Station:</strong> {result.polling_station_name}</p>
                                    <table className="table table-striped table-bordered">
                                        <thead className="bg-dark text-white">
                                            <tr>
                                                <th>Party</th>
                                                <th>Candidate</th>
                                                <th>Votes</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {result.results.map(candidate => (
                                                <tr key={candidate.id}>
                                                    <td>{candidate.party}</td>
                                                    <td>{candidate.name}</td>
                                                    <td>{candidate.votes}</td>
                                                </tr>
                                            ))}
                                            <tr>
                                                <td colSpan="2"><strong>Total Valid Votes</strong></td>
                                                <td>{result.total_valid_votes}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan="2"><strong>Total Rejected Ballots</strong></td>
                                                <td>{result.total_rejected_ballots}</td>
                                            </tr>
                                            {/* Sum Total Row */}
                                            <tr style={{ fontWeight: 'bold' }}>
                                                <td colSpan="2"><strong>Total Votes</strong></td>
                                                <td>{totalVotes}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="image-gallery">
                                        {result?.files?.map((item, index) => (
                                            result.files.length > 0 && (
                                                <img
                                                    key={index}
                                                    src={result.files[0]}
                                                    alt="Captured"
                                                    style={{ maxWidth: '100px', cursor: 'pointer', margin: '10px' }}
                                                    onClick={() => handleImageClick(result.files[0])}
                                                />
                                            )
                                        ))}
                                    </div>
                                    <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
                                        <Modal.Header closeButton>
                                            <Modal.Title>PINK SHEET</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            {selectedImage && (
                                                <img
                                                    src={selectedImage}
                                                    alt="Expanded"
                                                    style={{ width: '100%' }}
                                                />
                                            )}
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleCloseModal}>
                                                Close
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                    <span style={{ display: "flex", justifyContent: 'flex-end', fontSize: 13 }}>
                                        Submited on : {moment(result.date_captured).format("DD/MM/YYYY hh:mm A")}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </body>
    );
};

const styles = {
    idCardImage: {
        width: '100%',
        height: 'auto',
        maxWidth: '200px',
        borderRadius: '8px',
    },
    resultSection: {
        backgroundColor: '#f0f8ff',
        border: '1px solid #ddd',
        borderRadius: '8px',
    },
    uniquePollingStationsCard: {
        backgroundColor: 'black',
        padding: '20px',
        borderRadius: '8px',
    },
};

export default UserDetails;
