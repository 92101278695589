import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Container, Spinner, Alert } from 'react-bootstrap';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { MAIN_URL } from './Constants';

ChartJS.register(ArcElement, Tooltip, Legend);

export const PARTIES = ["NDC", "NPP", "IND1", "IND2", "GCPP", "IND3", "IND4", "GFP", "GUM", "CPP", "APC", "NDP", "LPG"];

const SeatsWon = ({ region }) => {
    const [seatsData, setSeatsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [ndcSeats, setNdcSeats] = useState(0);
    const [nppSeats, setNppSeats] = useState(0);
    const [indSeats, setIndSeats] = useState(0);

    useEffect(() => {
        const fetchSeatsData = async () => {
            try {
                let fetchedSeats;

                if (!region) {
                    fetchedSeats = (await axios.get(MAIN_URL + 'election-results/seats')).data;
                } else {
                    fetchedSeats = (await axios.get(MAIN_URL + 'election-results/seats/' + region)).data;
                }

                // Initialize seats for all parties, starting with 0
                const seatsMap = PARTIES.reduce((acc, party) => {
                    acc[party] = 0;
                    return acc;
                }, {});

                // Merge all independents as "IND"
                let independentSeats = 0;
                fetchedSeats.forEach(({ party, seats }) => {
                    if (party.startsWith('IND')) {
                        independentSeats += seats; // Sum up all independent seats
                    } else {
                        seatsMap[party] = seats;
                    }
                });
                seatsMap['IND'] = independentSeats; // Assign the sum of independent seats to 'IND'

                // Process the data for NDC, NPP, and IND
                setNdcSeats(seatsMap['NDC'] || 0);
                setNppSeats(seatsMap['NPP'] || 0);
                setIndSeats(seatsMap['IND'] || 0);

                // Convert the seatsMap into an array of {party, seats}
                const processedSeatsData = Object.keys(seatsMap).map(party => ({
                    party,
                    seats: seatsMap[party] || 0
                }));

                setSeatsData(processedSeatsData);
                setLoading(false);
            } catch (err) {
                setError('Failed to load seats data.');
                setLoading(false);
            }
        };

        fetchSeatsData();
    }, [region]);

    // Calculate total seats
    const totalSeats = (ndcSeats || 0) + (nppSeats || 0) + (indSeats || 0);

    if (loading) {
        return (
            <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </Container>
        );
    }

    if (error) {
        return (
            <Container className="my-5">
                <Alert variant="danger">{error}</Alert>
            </Container>
        );
    }

    const pieData = {
        labels: ['NDC (' + ndcSeats + ' seats)', 'NPP (' + nppSeats + ' seats)', 'Independent (' + indSeats + ' seats)'],
        datasets: [
            {
                label: '# of Seats Won',
                data: [ndcSeats, nppSeats, indSeats],
                backgroundColor: [
                    'rgba(0, 128, 0, 0.8)',   // Green for NDC
                    'rgba(0, 0, 255, 0.8)',   // Blue for NPP
                    'rgba(218, 165, 32, 0.8)' // Deep gold for IND
                ],
                borderColor: [
                    'rgba(0, 128, 0, 1)',
                    'rgba(0, 0, 255, 1)',
                    'rgba(218, 165, 32, 1)'
                ],
                borderWidth: 1,
            },
        ],
    };

    const pieOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    font: {
                        size: 16
                    }
                }
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        return `${tooltipItem.label}: ${tooltipItem.raw} seats`;
                    },
                },
            },
        },
    };

    return (
        <Container className="my-5">
            <h2 className="text-center mb-4 mt-5" style={{ fontSize: '28px' }}>SEATS</h2>
            <div className="d-flex justify-content-center">
                <div style={{ width: '50%', maxWidth: '400px' }}>
                    <Pie data={pieData} options={pieOptions} />
                </div>
            </div>
            <h5 style={{ fontSize: '28px', margin: "40px", color: "#777", textAlign: "center" }}>SEATS WON BY PARTIES</h5>
            <Table striped bordered hover responsive>
                <thead className="table-dark">
                    <tr>
                        <th style={{ fontSize: '18px' }}>Party</th>
                        <th style={{ fontSize: '18px' }}>Seats Won</th>
                    </tr>
                </thead>
                <tbody>
                    {seatsData.map(({ party, seats }) => (
                        <tr key={party}>
                            <td style={{ fontSize: '16px' }}>{party === 'IND' ? 'Independent' : party}</td>
                            <td style={{ fontSize: '16px' }}>{seats}</td>
                        </tr>
                    ))}
                    <tr>
                        <td style={{ fontWeight: 'bold', fontSize: '18px' }}>Total</td>
                        <td style={{ fontWeight: 'bold', fontSize: '18px' }}>{totalSeats}</td>
                    </tr>
                </tbody>
            </Table>
        </Container>
    );
};

export default SeatsWon;
