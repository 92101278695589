import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import ImageIcon from '@mui/icons-material/Image';
import PublishIcon from '@mui/icons-material/Publish';
import ShareIcon from '@mui/icons-material/Share';
import ForwardIcon from '@mui/icons-material/Forward';
import DeleteIcon from '@mui/icons-material/Delete';
import FlagIcon from '@mui/icons-material/Flag';
import { Checkbox, Tooltip } from '@mui/material';
import moment from 'moment';
import { GOOGLE_MAPS_API_KEY, MAIN_URL } from './Constants';
import { useAuth } from './contexts/AuthContext';
import Modal from '@mui/material/Modal';
import Swal from 'sweetalert2';
import IncidentsModal from './IncidentsModal';
import { LocalActivity, LocationOn, Phone, Unpublished } from '@mui/icons-material';
import ReactTooltip from 'react-tooltip';
import SharePost from './SharePost';
import Loader from './Loader';
import { Forward } from 'lucide-react';
import { io } from 'socket.io-client';
import withReactContent from "sweetalert2-react-content";
import { useNavigate } from 'react-router-dom';
import Loader_tp from './Loader_tp';


const PostListAdmin = React.memo(({ lat, lng, type, columns = 4, _name }) => {

    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedPosts, setSelectedPosts] = useState([]);
    const [selectionMode, setSelectionMode] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const { user } = useAuth();
    const [loading_, setLoading_] = useState(false);

    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [showShare, setShowShare] = useState(false);
    const [link, setLink] = useState("");
    const [title, setTitle] = useState("")

    const ContactPoster = withReactContent(Swal);


    const performSharing = (id) => {
        setShowShare(!showShare);
        setLink(MAIN_URL + "post/" + id)

        // console.log("/post/" + id)

    };


    // State for filtering
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [filteredPosts, setFilteredPosts] = useState([]);
    const [selectedPost, setSelectedPost] = useState(null);

    const [posterData, setPosterData] = useState(null)


    const navigate = useNavigate()
    const Open = (post) => {
        setSelectedPost(post);
        // navigate(`/post/incident/view/${post._id}`, { state: { post } });

        if (!selectionMode) {
            setOpenModal(true);
            setSelectedPost(post);
        }
    };

    const Close = () => {
        setOpenModal(false);
        setSelectedPost(null);
    };


    const getUser = async (id) => {

        try {
            setLoading_(true)

            const response = await axios.get(MAIN_URL + "media-watch/user/get/" + id)
            if (response.data) {
                setPosterData(response.data?.user)
                // alert(JSON.stringify(response.data.user))
                const { phone_number, email } = response.data.user;
                showContactOptions(phone_number, email)

            }
            setLoading_(false)

        } catch (e) {
            console.log("Error occured" + e)
            setLoading_(false)

        }

    }

    const fetchPosts = async () => {
        // setLoading(true);
        setError(null);
        try {
            let url = lat && lng
                ? `${MAIN_URL}load/posts/main/${lat}/${lng}`
                : `${MAIN_URL}load/posts/main/t`;

            if (type === "region")
                url = `${MAIN_URL}load/posts/${_name}`

            const response = await axios.get(url);
            const newPosts = response.data;

            console.log("Posts: url " + url + " " + JSON.stringify
                (newPosts))

            setLoading(false);
            // Check if the new posts are different from the current posts
            if (JSON.stringify(newPosts) !== JSON.stringify(posts)) {
                setPosts(newPosts); // Update posts only if there's new data
            }
        } catch (err) {
            setError('Failed to load posts');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };
    const showContactOptions = (phone_number, email) => {
        ContactPoster.fire({
            title: "Contact Options",
            text: "Choose your preferred method of contact:",
            icon: "info",
            showCancelButton: true,
            showConfirmButton: false,
            html: `
            <button id="call-button" style="padding: 10px; margin: 5px; background-color: #4CAF50; color: white; border: none; border-radius: 5px; cursor: pointer;">Call</button>
            <button id="whatsapp-button" style="padding: 10px; margin: 5px; background-color: #25D366; color: white; border: none; border-radius: 5px; cursor: pointer;">WhatsApp</button>
            <button id="email-button" style="padding: 10px; margin: 5px; background-color: #0072C6; color: white; border: none; border-radius: 5px; cursor: pointer;">Email</button>
          `,
            didOpen: () => {


                document.getElementById("call-button").addEventListener("click", () => {
                    window.location.href = `tel:${phone_number}`;
                });

                // Handle WhatsApp action
                document.getElementById("whatsapp-button").addEventListener("click", () => {
                    const whatsappURL = `https://wa.me/${phone_number?.replace(/\D/g, "")}`;
                    window.open(whatsappURL, "_blank");
                });

                // Handle Email action
                document.getElementById("email-button").addEventListener("click", () => {
                    window.location.href = `mailto:${email}`;
                });
            },
        });
    };

    useEffect(() => {

        fetchPosts();

        const socket = io(MAIN_URL);
        socket.on("connect", () => console.log("connected for posts..."))
        socket.on("disconnect", () => console.log("disconnected for posts..."))

        socket.on("load-posts", (res) => {
            console.log(res)
            fetchPosts()
        })

        return () => {
            socket.disconnect();
        };

    }, [lat, lng]);


    useEffect(() => {
        // Filter posts whenever the posts or dates change
        if (startDate && endDate) {
            const start = moment(startDate);
            const end = moment(endDate);
            const filtered = posts.filter(post => {
                const postDate = moment(post.date_posted);
                return postDate.isBetween(start, end, null, '[]'); // Inclusive filtering
            });
            setFilteredPosts(filtered);
        } else {
            setFilteredPosts(posts);
        }
    }, [posts, startDate, endDate]);

    const toggleSelectPost = (postId) => {
        setSelectedPosts(prev =>
            prev.includes(postId) ? prev.filter(id => id !== postId) : [...prev, postId]
        );
    };

    const SelectAll = () => {
        if (selectAll) {
            setSelectedPosts([]);
        } else {
            setSelectedPosts(filteredPosts.map(post => post._id));
        }
        setSelectAll(!selectAll);
    };

    const FileClick = (post) => {
        if (!selectionMode) {
            setModalContent(post);
            setOpenModal(true);
        }
    };

    const Publish = async (postId, visibility) => {
        try {
            const response = await axios.put(`${MAIN_URL}incident/update/${postId}/${visibility ? 'yes' : 'no'}`);
            console.log("ids", postId);

            // Update local state with the new visibility status
            setPosts(posts.map(post =>
                post._id === postId ? { ...post, visible: visibility } : post
            ));

            Swal.fire({
                title: 'Success!',
                text: 'Visibility has been updated.',
                icon: 'success',
                confirmButtonText: 'OK'
            }).then((result) => {

            });
        } catch (error) {
            console.error("Error updating post visibility:", error);
            Swal.fire('Error!', 'There was a problem updating the visibility.', 'error');
        }
    };


    const BulkPublish = async (_ids, visibility) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: `This will ${visibility ? 'publish' : 'hide'} the selected posts.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: `Yes, ${visibility ? 'publish' : 'hide'} it!`
        });

        if (result.isConfirmed) {
            try {
                // Ensure _ids is an array and visibility is a boolean before sending
                if (!Array.isArray(_ids) || typeof visibility !== 'boolean') {
                    throw new Error("Invalid data format");
                }

                // Send the array of IDs and visibility status in the request
                await axios.put(`${MAIN_URL}incident/update-visibility-bulk`, { ids: _ids, visible: visibility });

                Swal.fire('Success!', 'Selected posts have been updated.', 'success').then(() => {
                    // Reload the page after the user clicks OK
                    window.location.reload();
                });
            } catch (error) {
                console.error("Error updating post visibility:", error);
                Swal.fire('Error!', 'There was a problem updating the visibility of your posts.', 'error');
            }
        }
    };
    const performSearch = async (e) => {
        e.preventDefault()
        const query = e.target.value;
        setSearchQuery(query);
        if (query.length == 0)
            setSearchResults([])
        console.log("Search value: " + query)
        if (query) {
            try {
                const response = await axios.get(`${MAIN_URL}posts/search`, {
                    params: { query },
                });
                setSearchResults(response.data.data);
                console.log("Search: " + JSON.stringify(response.data))
            } catch (err) {
                console.error("Error fetching search results:", err);
            }
        } else {
            setSearchResults([]); // Clear results if query is empty
        }
    };

    const DeletePosts = async () => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "This will permanently delete the selected posts.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete them!',
            cancelButtonText: 'No, cancel!',
        });

        if (result.isConfirmed) {
            setLoading(true);
            try {
                const deletePromises = selectedPosts.map(postId =>
                    axios.delete(`${MAIN_URL}incident/delete/${postId}`)
                );
                await Promise.all(deletePromises);
                Swal.fire('Deleted!', 'Your posts have been deleted.', 'success');
                setSelectedPosts([]); // Clear the selection after deletion
                fetchPosts(); // Re-fetch the posts to update the list
            } catch (err) {
                console.error(err);
                Swal.fire('Error!', 'There was an error deleting your posts.', 'error');
            } finally {
                setLoading(false);
            }
        }
    };


    const singleDelete = async (postId) => {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
        });

        if (result.isConfirmed) {
            try {
                await axios.delete(`${MAIN_URL}incident/delete/${postId}`);
                setPosts(posts.filter(post => post.incident_id !== postId));
                Swal.fire('Deleted!', 'Your post has been deleted.', 'success').then(() => {
                    // Reload the page after the success message is closed
                    window.location.reload();
                });
            } catch (error) {
                console.error("Error deleting post:", error);
                Swal.fire('Error!', 'There was a problem deleting your post.', 'error');
            }
        }
    };





    const ForwardPosts = () => {
        // Implement forward functionality
    };

    const FlagPosts = () => {
        // Implement flag functionality
    };

    // if (loading) return <Loader />;

    const PostItem = ({ post }) => (
        <div
            key={post._id}
            className="post-item"
            style={{
                position: 'relative',
                marginBottom: '5px',
                padding: '10px',
                border: selectedPosts.includes(post._id) ? '2px solid #1976d2' : '1px solid #ccc',
                borderRadius: '5px',
                cursor: selectionMode ? 'default' : 'pointer',
            }}
        >
            <Checkbox
                checked={selectedPosts.includes(post._id)}
                onChange={() => toggleSelectPost(post._id)}
                style={{ position: 'absolute', top: '5px', left: '5px', zIndex: 1 }}
            />
            <div onClick={() => Open(post)} style={{ zIndex: 0 }}>
                {post.files && post.files[0] && (
                    <div
                        style={{
                            position: 'relative',
                            backgroundColor: 'black',
                            paddingTop: '56.25%',
                        }}
                    >
                        {post.files[0].fileType.startsWith('video') ? (
                            <>
                                <video
                                    src={post.files[0].url}
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                />
                                <PlayCircleFilledWhiteIcon
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        color: '#fff',
                                        fontSize: '25px',
                                        opacity: 0.7,
                                    }}
                                />
                            </>
                        ) : (
                            <>
                                <img
                                    src={post.files[0].url}
                                    alt="Image thumbnail"
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                />
                                <ImageIcon
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        color: '#fff',
                                        fontSize: '30px',
                                        opacity: 0.7,
                                    }}
                                />
                            </>
                        )}
                    </div>
                )}
                <div style={{
                    padding: '4px', color: '#333', fontWeight: 'bold',
                    fontSize: '12px', zIndex: 20, position: 'absolute', top: "48%"
                }}>
                    <p style={{
                        color: 'white', textShadow: '2px 2px 5px #444',
                    }}>{moment(post.date_posted).format('DD/MM/YYYY, h:mm:ss a')}</p>
                    <p style={{ color: 'white', textShadow: '2px 2px 5px #444' }}>{post.address}</p>
                    {/* <p>Status: {post.visible ? <b className='text-success'>Approved</b> : <span className='text-danger'>Not Approved</span>}</p> */}
                </div>
            </div>
            <div style={{
                padding: '10px', color: '#333',
                fontSize: '12px', flexDirection: 'row', display: 'flex', justifyContent: 'space-evenly'
            }}>
                {/* {
                JSON.stringify(post)
            } */}

                <Tooltip title={'Location'}>
                    <LocationOn color='info' onClick={() => {
                        const { latitude, longitude } = post.files[0];
                        const url = `https://www.google.com/maps?q=${latitude},${longitude}`;
                        window.open(url, '_blank');
                    }} />
                </Tooltip>
                <Tooltip title={post.visible ? 'Unpublished: Click to publish' : 'Published'} onClick={() => {
                    let isPublished = !post.visible ? true : false;
                    console.log(post._id, isPublished)
                    Publish(post._id, isPublished)
                }}>
                    {post.visible ? <Unpublished /> : <PublishIcon />}
                </Tooltip>
                <Tooltip title={'Delete'} onClick={() => singleDelete(post._id)}>

                    <DeleteIcon />
                </Tooltip>
                <Tooltip title={'Share'} onClick={() => performSharing(post._id)}>

                    <ShareIcon />
                </Tooltip>
                <Tooltip title={'Forward'} onClick={ForwardPosts}>

                    {/* <ForwardIcon /> */}
                    <Forward />
                </Tooltip>
                <Tooltip title="Call" onClick={() => {
                    // alert(post.posted_by)
                    getUser(post.posted_by)
                }}>
                    <Phone color='success' />
                </Tooltip>
            </div>
        </div >
    );
    // if (loading) return <Loader />

    return (
        <div className="post-list" style={{ width: '100%', overflow: 'hidden' }}>
            {/* <h5 style={{ color: "#777" }}>INCIDENTS</h5> */}
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '10px', marginTop: 30 }}>
                <label>
                    Start Date:
                    <input type="date" value={startDate} onChange={e => setStartDate(e.target.value)} />
                </label>
                <label>
                    End Date:
                    <input type="date" value={endDate} onChange={e => setEndDate(e.target.value)} />
                </label>
                <button className='btn btn-primary' style={{ marginTop: 15 }} onClick={() => { setStartDate(''); setEndDate(''); }}>Reset</button>
                {/* <li className="p-2"> */}
                {/* <div style={{ display: 'flex', flexDirection: 'column', width: "60%" }}>
                    <input type="text" placeholder="Search for incidents..." value={searchQuery}

                        onChange={performSearch}
                        style={{
                            borderRadius: '4px',
                            padding: '10px',
                            width: '50%',
                            marginLeft: 20,
                            marginTop: 10,
                            border: '1px solid #ddd',
                        }} />

                    {searchResults.length > 0 && (
                        <ul
                            className="list-unstyled position-absolute mt-2"
                            style={{
                                zIndex: 10,
                                background: '#fff',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                borderRadius: '8px',
                                top: "40%",
                                width: '100%',
                                maxHeight: '200px',
                                overflowY: 'auto',
                                fontSize: '12px',
                            }}
                        >
                            {searchResults.map((result, index) => {
                                const lastFile = result.files?.[result.files.length - 1];
                                const thumbnailUrl = lastFile?.url;
                                const isVideo = lastFile?.fileType.startsWith('video');

                                return (
                                    <li
                                        key={index}
                                        className="p-3 border-bottom d-flex align-items-center"
                                        onClick={() => {
                                            Open(result)
                                            setSearchResults([]);
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <div className="me-3" style={{ width: '50px', height: '50px', overflow: 'hidden', borderRadius: '4px' }}>
                                            {thumbnailUrl ? (
                                                isVideo ? (
                                                    <video
                                                        src={thumbnailUrl}
                                                        alt="Video thumbnail"
                                                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                        muted
                                                        playsInline
                                                        autoPlay
                                                        loop
                                                    />
                                                ) : (
                                                    <img
                                                        src={thumbnailUrl}
                                                        alt="Image thumbnail"
                                                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                    />
                                                )
                                            ) : (
                                                <div style={{ width: '100%', height: '100%', backgroundColor: '#f0f0f0' }}></div>
                                            )}
                                        </div>

                                        <div>
                                            <div style={{ fontWeight: 'bold', color: '#333' }}>{result.address || 'No address available'}</div>

                                            <div style={{ color: '#666', fontSize: '11px' }}>
                                                {result.description ? `${result.description.slice(0, 50)}...` : 'No description'}
                                            </div>

                                            <div style={{ color: '#999', fontSize: '10px' }}>{result.posted_name || 'Anonymous'}</div>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    )}

                </div> */}
            </div>
            {loading_ && <Loader_tp />}
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <label style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    <input type="checkbox" checked={selectionMode} onChange={() => setSelectionMode(!selectionMode)} />
                    SELECT
                </label>
                {selectionMode && (
                    <label style={{ display: 'flex', alignItems: 'center', gap: '5px', marginLeft: '20px' }}>
                        <input type="checkbox" checked={selectAll} onChange={SelectAll} />
                        ALL
                    </label>
                )}
            </div>

            {loading && <CircularProgress />}
            {error && <Alert severity="error">{error}</Alert>}

            <div style={{ display: 'grid', gridTemplateColumns: `repeat(${columns}, 1fr)`, gap: '10px' }}>
                {filteredPosts.map(post => (
                    <PostItem key={post._id} post={post} />
                ))}
            </div>

            {showShare && (
                <div style={{ position: 'fixed', top: '40%', left: '50%', transform: 'translateX(-50%)', zIndex: 1000 }}>
                    <SharePost url={link} text={title} onCancel={() => setShowShare(false)} />
                </div>
            )}


            {selectedPosts.length > 0 && (
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    padding: '10px',
                    paddingLeft: 3,
                    backgroundColor: '#f1f1f1',
                    marginTop: '-2%',
                    marginLeft: "0%",
                    position: 'fixed',
                    zIndex: 300,
                    // bottom: 0,
                    width: '70%'
                }}>
                    <button onClick={DeletePosts}>
                        <DeleteIcon /> Delete
                    </button>
                    <button onClick={() => {
                        console.log("SELECTED POSTS: " + JSON.stringify(selectedPosts))

                        BulkPublish(selectedPosts, true)
                    }}>
                        <PublishIcon /> Publish
                    </button>

                    <button onClick={() => { selectedPosts && performSharing(selectedPosts[0]) }}>
                        <ShareIcon /> Share
                    </button>
                    <button onClick={ForwardPosts}>
                        <Forward /> Forward
                    </button>
                    <button onClick={FlagPosts}>
                        <FlagIcon /> Flag
                    </button>
                </div>
            )}
            {openModal && <IncidentsModal open={openModal} handleClose={Close} post={selectedPost} />}


        </div>
    );
});

export default PostListAdmin;
