import React, { useState } from 'react';
import { Table, Modal, Button } from 'react-bootstrap';
import { COLOR_PRIMARY } from './Constants';
import './App.css';
import { useNavigate } from 'react-router-dom';

const Table_RegionalAdmin = ({ data }) => {
    const [isModalVisible, setModalVisible] = useState(false);
    const [modalImage, setModalImage] = useState(null);
    const navigate=useNavigate();

    // Extract unique parties and organize vote counts with candidate names
    const institutionsData = data.reduce((acc, item) => {
        const institution = item.sender_institution;
        if (!acc[institution]) {
            acc[institution] = { votes: {}, files: item.files[0] };
        }
        item.results.forEach(result => {
            const { party, name, votes } = result;
            if (!acc[institution].votes[party]) {
                acc[institution].votes[party] = { votes: 0, candidate: name };
            }
            acc[institution].votes[party].votes += votes;
        });
        return acc;
    }, {});

    const institutions = Object.keys(institutionsData);
    const parties = [...new Set(data.flatMap(item => item.results.map(result => result.party)))];

    // Calculate total votes for each institution
    const getTotalVotes = (institution) => {
        return Object.values(institutionsData[institution].votes).reduce((acc, { votes }) => acc + votes, 0);
    };

    // Open modal with selected image
    const openModal = (image) => {
        setModalImage(image);
        setModalVisible(true);
    };

    return (
        <div className="container mt-4">
            <Table borderedless hover responsive>
                <thead>
                    {/* Header row for "INSTITUTIONS" label, spanning institution columns only */}
                    <tr>
                        <th colSpan={2}></th>
                        <th colSpan={institutions.length} style={{
                            color: '#666', backgroundColor: "#eee",
                            fontSize: '1.2em', textAlign: "center", borderTopRightRadius: 5, borderTopLeftRadius: 5
                        }}>
                            INSTITUTIONS
                        </th>
                    </tr>
                    {/* Institutions header */}
                    <tr style={{ backgroundColor: '#fff', color: '#ffffff' }}>
                        <th style={{
                            color: "#ddd", backgroundColor: '#555', borderTopLeftRadius: 5, paddingLeft: 15,
                        }}>PARTY</th>
                        <th style={{
                            color: "#ddd", backgroundColor: '#555',borderLeftWidth:1,borderLeftColor:"white", paddingLeft: 15,
                        }}>CANDIDATE</th>
                        {institutions.map((institution, index) => (
                            <td onClick={()=> navigate("/institution/results", { state: { id: institution } })} key={index} style={{
                                backgroundColor: "#777", color: '#fff', fontFamily: 'verdana'
                                , textDecorationLine: 'underline', cursor: 'pointer', borderLeftWidth: 1, borderColor: "#ddd"
                            }} >{institution}</td>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {parties.map((party, partyIndex) => (
                        <tr key={partyIndex}>
                            <td >{party}</td>
                            <td style={{borderLeftWidth:1}}>{institutionsData[institutions[0]].votes[party]?.candidate || 'N/A'}</td>
                            {institutions.map((institution, instIndex) => (
                                <td key={instIndex} style={{borderLeftWidth:1}}>{institutionsData[institution].votes[party]?.votes || 0}</td>
                            ))}
                        </tr>
                    ))}
                    <tr>
                        <td><strong>Total</strong></td>
                        <td></td>
                        {institutions.map((institution, index) => (
                            <td key={index}><strong>{getTotalVotes(institution)}</strong></td>
                        ))}
                    </tr>
                    <tr>
                        <td>Supporting Documents</td>
                        <td></td>
                        {institutions.map((institution, index) => (
                            <td key={index}>
                                <img
                                    src={institutionsData[institution].files}
                                    alt="Supporting Document"
                                    onClick={() => openModal(institutionsData[institution].files)}
                                    style={{ width: '40px', height: '60px', cursor: 'pointer' }}
                                />
                            </td>
                        ))}
                    </tr>
                </tbody>
            </Table>

            {/* Modal to display the full-size document */}
            <Modal show={isModalVisible} onHide={() => setModalVisible(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Supporting Document</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalImage && <img src={modalImage} alt="Supporting Document" style={{ width: '100%' }} />}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setModalVisible(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default Table_RegionalAdmin;
