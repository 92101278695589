import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { MAIN_URL } from '../Constants';
import { useAuth } from '../contexts/AuthContext';
import { Alert, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import Loader from '../Loader';

const UserList = () => {
    const [users_, setUsers_] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [selectedUserType, setSelectedUserType] = useState({});
    const [message, setMessage] = useState(null);
    const { user } = useAuth();
    const token = user?.accessToken;
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const fetchUsers = async () => {
            setLoading(true)
            try {
                const response = await axios.get(`${MAIN_URL}media/watch/users`, {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setLoading(false)
                setUsers_(response.data);
                setFilteredUsers(response.data);
            } catch (error) {
                setLoading(false)
                console.error('Error fetching users:', error);
            }
        };

        if (token) fetchUsers();
    }, [token]);

    if (loading)
        return <Loader />
    // Handle search
    const handleSearch = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);

        if (query.trim() === '') {
            setFilteredUsers(users_); // Reset when search is cleared
            return;
        }

        // Filter users by search criteria
        const filtered = users_.filter(user =>
            (user.username && user.username.toLowerCase().includes(query)) ||
            (user.email && user.email.toLowerCase().includes(query)) ||
            (user.phone_number && user.phone_number.toLowerCase().includes(query)) ||
            (user.institution && user.institution.toLowerCase().includes(query))
        );
        setFilteredUsers(filtered);
    };

    const handleUserTypeChange = (userId, newUserType) => {
        setSelectedUserType(prevState => ({
            ...prevState,
            [userId]: newUserType,
        }));
    };

    const saveUserTypeChange = async (userId) => {
        const newUserType = selectedUserType[userId];
        try {
            await axios.put(
                `${MAIN_URL}media/watch/user-type/${userId}`,
                { user_type: newUserType },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            setUsers_(users_.map(user =>
                user._id === userId ? { ...user, user_type: newUserType } : user
            ));
            setFilteredUsers(filteredUsers.map(user =>
                user._id === userId ? { ...user, user_type: newUserType } : user
            ));
            setSelectedUserType(prevState => {
                const updatedState = { ...prevState };
                delete updatedState[userId];
                return updatedState;
            });
            setMessage({ type: 'success', text: 'User type updated successfully!' });
        } catch (error) {
            console.error('Error updating user type:', error);
            setMessage({ type: 'error', text: 'Failed to update user type.' });
        }
    };

    const getUserTypeColor = (type) => {
        switch (type) {
            case 'admin': return 'danger';
            case 'agent': return 'warning';
            case 'normal': return 'info';
            default: return 'secondary';
        }
    };

    const columns = [
        { name: 'Email', selector: row => row.email, sortable: true },
        { name: 'Username', selector: row => row.username, sortable: true },
        { name: 'Phone Number', selector: row => row.phone_number },
        { name: 'Institution', selector: row => row.institution || 'N/A' },
        { name: 'Emp ID', selector: row => row.emp_id || 'N/A' },
        {
            name: 'User Type',
            cell: row => (
                <DropdownButton
                    variant={getUserTypeColor(selectedUserType[row._id] || row.user_type)}
                    id={`dropdown-${row._id}`}
                    title={selectedUserType[row._id] || row.user_type}
                    onSelect={(value) => handleUserTypeChange(row._id, value)}
                >
                    <Dropdown.Item eventKey="normal">Normal</Dropdown.Item>
                    <Dropdown.Item eventKey="agent">Agent</Dropdown.Item>
                    <Dropdown.Item eventKey="admin">Admin</Dropdown.Item>
                </DropdownButton>
            ),
        },
        {
            name: 'Actions',
            cell: row => (
                selectedUserType[row._id] && selectedUserType[row._id] !== row.user_type ? (
                    <Button variant="primary" size="sm" onClick={() => saveUserTypeChange(row._id)}>
                        Save
                    </Button>
                ) : null
            ),
        },
    ];

    return (
        <div className="container mt-5">
            <div style={{ marginBottom: '15px' }}>
                <input
                    type="text"
                    placeholder="Search for users"
                    value={searchQuery}
                    onChange={handleSearch}
                    style={{
                        padding: '10px',
                        width: '100%',
                        border: '1px solid #ccc',
                        borderRadius: '5px',
                    }}
                />
            </div>

            {message && (
                <Alert variant={message.type === 'success' ? 'success' : 'danger'}>
                    {message.text}
                </Alert>
            )}

            {filteredUsers.length === 0 ? (
                <Alert variant="warning">No users match your search criteria.</Alert>
            ) : (
                <DataTable
                    columns={columns}
                    data={filteredUsers}
                    pagination
                    highlightOnHover
                    striped
                />
            )}
        </div>
    );
};

export default UserList;
