import axios from "axios";

// export const MAIN_URL = "http://localhost:3000/";
export const MAIN_URL = process.env.REACT_APP_MAIN_URL;
export const COLOR_PRIMARY = "#004372";
export const COLOR_SECONDARY = "#0061A0";
export const COLOR_ACCENT = "gold";
export const COLOR_HEADER_BG = "rgb(0,67,100)";
export const COLOR_SELECTED = "rgb(0,97,160)";
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
export const PRESIDENTIAL_CANDIDATES = [
    {
        id: 1,
        nameOfCandidate: "Mahamudu Bawumia",
        partyName: "NPP",
        partyImage: "/flags/npp.png",   
        imageOfCandidate: "/candidates/bawumia_n.png"
    },
    {
        id: 2,
        nameOfCandidate: "Daniel Augustus Lartey",
        partyName: "GCPP",
        partyImage: "/flags/gcp_flag.jpg",
        imageOfCandidate: "/candidates/lartey_gcpp_.png"
    },
    {
        id: 3,
        nameOfCandidate: "Akua Donkor",
        partyName: "GFP",
        partyImage: "/flags/gfp_flag.png",
        imageOfCandidate: "/candidates/akua_donkor_.png"
    },
    {
        id: 4,
        nameOfCandidate: "Christian Kwabena Andrews",
        partyName: "GUM",
        partyImage: "/flags/gum.png",
        imageOfCandidate: "/candidates/abosom_.png"
    },
    {
        id: 5,
        nameOfCandidate: "Kofi Akpaloo",
        partyName: "LPG",
        partyImage: "/flags/lpg_flag.jpg",
        imageOfCandidate: "/candidates/akpaloo_.png"
    },
    {
        id: 6,
        nameOfCandidate: "Mohammed Frimpong",
        partyName: "NDP",
        partyImage: "/flags/ndp.png",
        imageOfCandidate: "/candidates/mohammed_ndp.png"
    },
    {
        id: 7,
        nameOfCandidate: "Nana Akosua Frimpomaa",
        partyName: "CPP",
        partyImage: "/flags/cpp.png",
        imageOfCandidate: "/candidates/akosua_cpp.png"
    },
    {
        id: 8,
        nameOfCandidate: "John Dramani Mahama",
        partyName: "NDC",
        partyImage: "/flags/ndc.png",
        imageOfCandidate: "/candidates/mahama_main.png"
    },
    {
        id: 9,
        nameOfCandidate: "Hassan Ayariga",
        partyName: "APC",
        partyImage: "/flags/apc.png",
        imageOfCandidate: "/candidates/ayariga_.png"
    },
    {
        id: 10,
        nameOfCandidate: "Kofi Koranteng",
        partyName: "IND1",
        partyImage: "/flags/independent.jpg",
        imageOfCandidate: "/candidates/koranteng_.png"
    },
    {
        id: 11,
        nameOfCandidate: "George Twum Barima",
        partyName: "IND2",
        partyImage: "/flags/independent.jpg",
        imageOfCandidate: "/candidates/george_twum_.png"
    },
    {
        id: 12,
        nameOfCandidate: "Nana Kwame Bediako",
        partyName: "IND3",
        partyImage: "/flags/nf.png",
        imageOfCandidate: "/candidates/nkb.png"
    },
    {
        id: 13,
        nameOfCandidate: "Alan Kyerematen",
        partyName: "IND4",
        partyImage: "/flags/butterfly.png",
        imageOfCandidate: "/candidates/alan_k.png"
    }
];

export const REGISTERED_VOTERS = {
    regional: {
        "GREATER ACCRA": 3765303,
        "ASHANTI": 3295724,
        "EASTERN": 1810421,
        "CENTRAL": 1787305,
        "WESTERN": 1288777,
        "NORTHERN": 1209934,
        "WESTERN NORTH": 522506,
        "BONO": 719916,
        "BONO EAST": 672830,
        "AHAFO": 359284,
        "VOLTA": 1023830,
        "OTI": 410607,
        "SAVANNAH": 342431,
        "UPPER EAST": 741288,
        "UPPER WEST": 518365,
        "NORTH EAST": 339883,


    },
    "TOTAL": 18640811

}

export const PARTY_COLOURS = {
    NPP: '#0047ab',
    NDC: 'green',
    GCPP: '#228b22',
    GFP: '#32CD32',
    GUM: '#ff4500',
    LPG: '#800080',
    NDP: '#FFD700',
    CPP: '#ff6455',
    APC: '#ff6347',
    IND1: '#808080',
    IND2: '#808080',
    IND3: '#000080',
    IND4: '#8b0000',
};
export const REGIONS = [
    "AHAFO", "ASHANTI", "BONO", "BONO EAST", "CENTRAL", "EASTERN", "GREATER ACCRA", "NORTHERN", "NORTH EAST", "OTI", "SAVANNAH",
    "UPPER EAST", "UPPER WEST", "VOLTA", "WESTERN", "WESTERN NORTH"
]

export const PARTIES = ["NDC", "NPP", "IND1", "IND2", "GCPP", "IND3", "IND4", "GFP", "GUM", "CPP", "APC", "NDP", "LPG"];


export const fetchSeatsData = async (region) => {
    try {
        let fetchedSeats;

        if (!region || region === "") {
            fetchedSeats = (await axios.get(MAIN_URL + 'election-results/seats')).data;
        } else {
            fetchedSeats = (await axios.get(MAIN_URL + 'election-results/seats/' + region)).data;
        }

         const seatsMap = PARTIES.reduce((acc, party) => {
            acc[party] = 0;
            return acc;
        }, {});

         let independentSeats = 0;
        fetchedSeats.forEach(({ party, seats }) => {
            if (party.startsWith('IND')) {
                independentSeats += seats;  
            } else {
                seatsMap[party] = seats;
            }
        });

         seatsMap['IND'] = independentSeats;

        console.log("Seats Data: ", JSON.stringify(seatsMap));
        return seatsMap;
    } catch (err) {
        console.log('Failed to load seats data.');
    }
};

export  const getTotalVoteCount = (codePrefix, pollingStations) => {
    if (!codePrefix || codePrefix.length < 3) {
        console.error("Invalid code prefix. It must be at least 3 characters long.");
        return { totalVoteCount: 0, totalRows: 0 };
    }

    let matchingStations = [];

    if (codePrefix.startsWith('B0230')) {
        matchingStations = pollingStations.filter(station => {
            if (station.pollingcode.startsWith('B02')) {
                const numericValue = parseInt(station.pollingcode.slice(2, 5), 10);
                return numericValue <= 230;
            }
            return false;
        });
    } else if (codePrefix.startsWith('B0231')) {
        matchingStations = pollingStations.filter(station => {
            if (station.pollingcode.startsWith('B02')) {
                const numericValue = parseInt(station.pollingcode.slice(2, 5), 10);
                return numericValue > 230;
            }
            return false;
        });
    } else if (codePrefix.startsWith('M1301')) {
        matchingStations = pollingStations.filter(station => {
            if (station.pollingcode.startsWith('M13')) {
                const numericValue = parseInt(station.pollingcode.slice(3, 5), 10);
                return numericValue < 24;
            }
            return false;
        });
    } else if (codePrefix.startsWith('M1302')) {
        matchingStations = pollingStations.filter(station => {
            if (station.pollingcode.startsWith('M13')) {
                const numericValue = parseInt(station.pollingcode.slice(3, 5), 10);
                return numericValue > 23;
            }
            return false;
        });
    } else if (codePrefix.startsWith('M1413')) {
        matchingStations = pollingStations.filter(station => {
            if (station.pollingcode.startsWith('M14')) {
                const numericValue = parseInt(station.pollingcode.slice(3, 5), 10);
                return numericValue > 12;
            }
            return false;
        });
    } else if (codePrefix.startsWith('M1401')) {
        matchingStations = pollingStations.filter(station => {
            if (station.pollingcode.startsWith('M14')) {
                const numericValue = parseInt(station.pollingcode.slice(3, 5), 10);
                return numericValue <= 12;
            }
            return false;
        });
    } else if (codePrefix.startsWith('A0801')) {
        matchingStations = pollingStations.filter(station => {
            if (station.pollingcode.startsWith('A080')) {
                const numericValue = parseInt(station.pollingcode.slice(4, 6), 10);
                return numericValue <= 61;
            }
            return false;
        });
    }
    else if (codePrefix.startsWith('A0802')) {
        matchingStations = pollingStations.filter(station => {
            if (station.pollingcode.startsWith('A08')) {
                const firstCharAfterA08 = station.pollingcode.charAt(3);
                const numericValue = parseInt(station.pollingcode.slice(4, 6), 10);

                return numericValue > 61 || firstCharAfterA08 === '1';
            }
            return false;
        });
    }
    else {
        const prefix = codePrefix.slice(0, 3);
        matchingStations = pollingStations.filter(station =>
            station.pollingcode.startsWith(prefix)
        );
    }

    const totalVoteCount = matchingStations.reduce((total, station) => total + station.votercount, 0);

    return {
        totalVoteCount,
        totalRows: matchingStations.length,
    };
};
