export const CONSTITUENCIES = [

    //WESTERN REGION
    { "region": "WESTERN", "code": "A0101", "name": "JOMORO" },
    { "region": "WESTERN", "code": "A0201", "name": "ELLEMBELE" },
    { "region": "WESTERN", "code": "A0301", "name": "EVALUE AJOMORO GWIRA" },
    { "region": "WESTERN", "code": "A0401", "name": "AHANTA WEST" },
    { "region": "WESTERN", "code": "A0501", "name": "TAKORADI" },
    { "region": "WESTERN", "code": "A0502", "name": "SEKONDI" },
    { "region": "WESTERN", "code": "A0503", "name": "ESSIKADU-KETAN" },
    { "region": "WESTERN", "code": "A0601", "name": "EFFIA" },
    { "region": "WESTERN", "code": "A0602", "name": "KWESIMINTIM" },
    { "region": "WESTERN", "code": "A0701", "name": "SHAMA" },
    { "region": "WESTERN", "code": "A0801", "name": "WASSA EAST" },
    { "region": "WESTERN", "code": "A0901", "name": "MPOHOR" },
    { "region": "WESTERN", "code": "A1001", "name": "TARKWA NSUAEM" },
    { "region": "WESTERN", "code": "A1101", "name": "PRESTEA HUNI-VALLEY" },
    { "region": "WESTERN", "code": "A1201", "name": "WASSA AMENFI EAST" },
    { "region": "WESTERN", "code": "A1301", "name": "AMENFI CENTRAL" },
    { "region": "WESTERN", "code": "A1401", "name": "AMENFI WEST" },

    //CENTRAL REGION

    { "region": "CENTRAL", "code": "B0101", "name": "KOMENDA EDINA EGUAFO ABREM" },
    { "region": "CENTRAL", "code": "B0201", "name": "CAPE COAST SOUTH" },
    { "region": "CENTRAL", "code": "B0202", "name": "CAPE COAST NORTH" },
    { "region": "CENTRAL", "code": "B0301", "name": "ABURA/ASEBU/KWAMANKESE" },
    { "region": "CENTRAL", "code": "B0401", "name": "MFANTSEMAN" },
    { "region": "CENTRAL", "code": "B0501", "name": "EKUMFI" },
    { "region": "CENTRAL", "code": "B0601", "name": "AJUMAKO ENYAN ESIAM" },
    { "region": "CENTRAL", "code": "B0701", "name": "GOMOA WEST" },
    { "region": "CENTRAL", "code": "B0801", "name": "GOMOA CENTRAL" },
    { "region": "CENTRAL", "code": "B0901", "name": "GOMOA EAST" },
    { "region": "CENTRAL", "code": "B1001", "name": "EFFUTU" },
    { "region": "CENTRAL", "code": "B1101", "name": "AWUTU SENYA WEST" },
    { "region": "CENTRAL", "code": "B1201", "name": "AWUTU SENYA EAST" },
    { "region": "CENTRAL", "code": "B1301", "name": "AGONA WEST" },
    { "region": "CENTRAL", "code": "B1401", "name": "AGONA EAST" },
    { "region": "CENTRAL", "code": "B1501", "name": "ASIKUMA/ODOBEN/BRAKWA" },
    { "region": "CENTRAL", "code": "B1601", "name": "ASSIN CENTRAL" },
    { "region": "CENTRAL", "code": "B1701", "name": "ASSIN NORTH" },
    { "region": "CENTRAL", "code": "B1801", "name": "ASSIN SOUTH" },
    { "region": "CENTRAL", "code": "B1901", "name": "TWIFO ATTI MORKWA" },
    { "region": "CENTRAL", "code": "B2001", "name": "HEMANG LOWER DENKYIRA" },
    { "region": "CENTRAL", "code": "B2101", "name": "UPPER DENKYIRA EAST" },
    { "region": "CENTRAL", "code": "B2201", "name": "UPPER DENKYIRA WEST" },

    //GREATER ACCRA

    { "region": "GREATER ACCRA", "code": "C0101", "name": "BORTIANOR-NGLESHIE AMANFRO" },
    { "region": "GREATER ACCRA", "code": "C0102", "name": "DOMEABRA/OBOM" },
    { "region": "GREATER ACCRA", "code": "C0201", "name": "WEIJA / GBAWE" },
    { "region": "GREATER ACCRA", "code": "C0301", "name": "ANYAA/SOWUTUOM" },
    { "region": "GREATER ACCRA", "code": "C0401", "name": "TROBU" },
    { "region": "GREATER ACCRA", "code": "C0501", "name": "AMASAMAN" },
    { "region": "GREATER ACCRA", "code": "C0601", "name": "DOME/KWABENYA" },
    { "region": "GREATER ACCRA", "code": "C0701", "name": "MADINA" },
    { "region": "GREATER ACCRA", "code": "C0801", "name": "AYAWASO EAST" },
    { "region": "GREATER ACCRA", "code": "C0901", "name": "AYAWASO NORTH" },
    { "region": "GREATER ACCRA", "code": "C1001", "name": "AYAWASO CENTRAL" },
    { "region": "GREATER ACCRA", "code": "C1101", "name": "AYAWASO WEST WUOGON" },
    { "region": "GREATER ACCRA", "code": "C1201", "name": "OKAIKWEI SOUTH" },
    { "region": "GREATER ACCRA", "code": "C1202", "name": "ABLEKUMA SOUTH" },
    { "region": "GREATER ACCRA", "code": "C1203", "name": "ODODODIODIOO" },
    { "region": "GREATER ACCRA", "code": "C1301", "name": "OKAIKWEI CENTRAL" },
    { "region": "GREATER ACCRA", "code": "C1302", "name": "OKAIKWEI NORTH" },
    { "region": "GREATER ACCRA", "code": "C1401", "name": "ABLEKUMA NORTH" },
    { "region": "GREATER ACCRA", "code": "C1501", "name": "ABLEKUMA CENTRAL" },
    { "region": "GREATER ACCRA", "code": "C1601", "name": "ABLEKUMA WEST" },
    { "region": "GREATER ACCRA", "code": "C1701", "name": "KORLE KLOTTEY" },
    { "region": "GREATER ACCRA", "code": "C1801", "name": "DADEKOTOPON" },
    { "region": "GREATER ACCRA", "code": "C1901", "name": "LEDZOKUKU" },
    { "region": "GREATER ACCRA", "code": "C2001", "name": "KROWOR" },
    { "region": "GREATER ACCRA", "code": "C2101", "name": "TEMA EAST" },
    { "region": "GREATER ACCRA", "code": "C2102", "name": "TEMA CENTRAL" },
    { "region": "GREATER ACCRA", "code": "C2201", "name": "TEMA-WEST" },
    { "region": "GREATER ACCRA", "code": "C2301", "name": "KPONE KATAMANSO" },
    { "region": "GREATER ACCRA", "code": "C2401", "name": "ASHAIMAN" },
    { "region": "GREATER ACCRA", "code": "C2501", "name": "ADENTAN" },
    { "region": "GREATER ACCRA", "code": "C2601", "name": "SHAI-OSUDOKU" },
    { "region": "GREATER ACCRA", "code": "C2701", "name": "NINGO PRAMPRAM" },
    { "region": "GREATER ACCRA", "code": "C2801", "name": "SEGE" },
    { "region": "GREATER ACCRA", "code": "C2901", "name": "ADA" },

    //VOLTA REGION

    { "region": "VOLTA", "code": "D0101", "name": "KETA" },
    { "region": "VOLTA", "code": "D0201", "name": "ANLO" },
    { "region": "VOLTA", "code": "D0301", "name": "KETU SOUTH" },
    { "region": "VOLTA", "code": "D0401", "name": "KETU NORTH" },
    { "region": "VOLTA", "code": "D0501", "name": "AKATSI SOUTH" },
    { "region": "VOLTA", "code": "D0601", "name": "AKATSI NORTH" },
    { "region": "VOLTA", "code": "D0701", "name": "SOUTH TONGU" },
    { "region": "VOLTA", "code": "D0801", "name": "CENTRAL TONGU" },
    { "region": "VOLTA", "code": "D0901", "name": "NORTH TONGU" },
    { "region": "VOLTA", "code": "D1001", "name": "ADAKLU" },
    { "region": "VOLTA", "code": "D1101", "name": "AGOTIME-ZIOPE" },
    { "region": "VOLTA", "code": "D1201", "name": "HO CENTRAL" },
    { "region": "VOLTA", "code": "D1301", "name": "HO WEST" },
    { "region": "VOLTA", "code": "D1401", "name": "SOUTH DAYI" },
    { "region": "VOLTA", "code": "D1501", "name": "KPANDO" },
    { "region": "VOLTA", "code": "D1601", "name": "NORTH DAYI" },
    { "region": "VOLTA", "code": "D1701", "name": "HOHOE" },
    { "region": "VOLTA", "code": "D1801", "name": "AFADJATO SOUTH" },

    //EASTERN REGION

    { "region": "EASTERN", "code": "E0101", "name": "ASUOGYAMAN" },
    { "region": "EASTERN", "code": "E0201", "name": "LOWER MANYA KROBO" },
    { "region": "EASTERN", "code": "E0301", "name": "UPPER MANYA KROBO" },
    { "region": "EASTERN", "code": "E0401", "name": "YILO KROBO" },
    { "region": "EASTERN", "code": "E0501", "name": "NEW JUABEN SOUTH" },
    { "region": "EASTERN", "code": "E0601", "name": "NEW JUABEN NORTH" },
    { "region": "EASTERN", "code": "E0701", "name": "AKROPONG" },
    { "region": "EASTERN", "code": "E0801", "name": "OKERE" },
    { "region": "EASTERN", "code": "E0901", "name": "AKUAPEM SOUTH" },
    { "region": "EASTERN", "code": "E1001", "name": "NSAWAM/ADOAGYIRI" },
    { "region": "EASTERN", "code": "E1101", "name": "SUHUM" },
    { "region": "EASTERN", "code": "E1201", "name": "AYENSUANO" },
    { "region": "EASTERN", "code": "E1301", "name": "LOWER WEST AKIM" },
    { "region": "EASTERN", "code": "E1401", "name": "UPPER WEST AKIM" },
    { "region": "EASTERN", "code": "E1501", "name": "AKIM ODA" },
    { "region": "EASTERN", "code": "E1601", "name": "ASENE/AKROSO/MANSO" },
    { "region": "EASTERN", "code": "E1701", "name": "AKIM SWEDRU" },
    { "region": "EASTERN", "code": "E1801", "name": "ACHIASE" },
    { "region": "EASTERN", "code": "E1901", "name": "OFOASE/AYIREBI" },
    { "region": "EASTERN", "code": "E2001", "name": "KADE" },
    { "region": "EASTERN", "code": "E2101", "name": "AKWATIA" },
    { "region": "EASTERN", "code": "E2201", "name": "ABIREM" },
    { "region": "EASTERN", "code": "E2301", "name": "ABUAKWA SOUTH" },
    { "region": "EASTERN", "code": "E2401", "name": "ABUAKWA NORTH" },
    { "region": "EASTERN", "code": "E2501", "name": "ATIWA WEST" },
    { "region": "EASTERN", "code": "E2601", "name": "ATIWA EAST" },
    { "region": "EASTERN", "code": "E2701", "name": "FANTEAKWA NORTH" },
    { "region": "EASTERN", "code": "E2801", "name": "FANTEAKWA SOUTH" },
    { "region": "EASTERN", "code": "E2901", "name": "NKAWKAW" },
    { "region": "EASTERN", "code": "E3001", "name": "MPRAESO" },
    { "region": "EASTERN", "code": "E3101", "name": "ABETIFI" },
    { "region": "EASTERN", "code": "E3201", "name": "AFRAM PLAINS NORTH" },
    { "region": "EASTERN", "code": "E3301", "name": "AFRAM PLAINS SOUTH" },

    //ASHANTI REGION

    { "region": "ASHANTI", "code": "F0101", "name": "NEW EDUBIASE" },
    { "region": "ASHANTI", "code": "F0201", "name": "AKROFUOM" },
    { "region": "ASHANTI", "code": "F0301", "name": "FOMENA" },
    { "region": "ASHANTI", "code": "F0401", "name": "ADANSI ASOKWA" },
    { "region": "ASHANTI", "code": "F0501", "name": "OBUASI WEST" },
    { "region": "ASHANTI", "code": "F0601", "name": "OBUASI EAST" },
    { "region": "ASHANTI", "code": "F0701", "name": "BEKWAI" },
    { "region": "ASHANTI", "code": "F0801", "name": "BOSOME FREHO" },
    { "region": "ASHANTI", "code": "F0901", "name": "ODOTOBIRI" },
    { "region": "ASHANTI", "code": "F1001", "name": "MANSO NKWANTA" },
    { "region": "ASHANTI", "code": "F1101", "name": "MANSO ADUBIA" },
    { "region": "ASHANTI", "code": "F1201", "name": "ATWIMA NWABIAGYA SOUTH" },
    { "region": "ASHANTI", "code": "F1301", "name": "ATWIMA NWABIAGYA NORTH" },
    { "region": "ASHANTI", "code": "F1401", "name": "ATWIMA MPONUA" },
    { "region": "ASHANTI", "code": "F1501", "name": "BOSOMTWE" },
    { "region": "ASHANTI", "code": "F1601", "name": "ATWIMA KWANWOMA" },
    { "region": "ASHANTI", "code": "F1701", "name": "BANTAMA" },
    { "region": "ASHANTI", "code": "F1702", "name": "NHYIAESO" },
    { "region": "ASHANTI", "code": "F1703", "name": "MANHYIA SOUTH" },
    { "region": "ASHANTI", "code": "F1704", "name": "MANHYIA NORTH" },
    { "region": "ASHANTI", "code": "F1705", "name": "SUBIN" },
    { "region": "ASHANTI", "code": "F1801", "name": "KWADASO" },
    { "region": "ASHANTI", "code": "F1901", "name": "OLD TAFO" },
    { "region": "ASHANTI", "code": "F2001", "name": "SUAME" },
    { "region": "ASHANTI", "code": "F2101", "name": "ASOKWA" },
    { "region": "ASHANTI", "code": "F2201", "name": "OFORIKROM" },
    { "region": "ASHANTI", "code": "F2301", "name": "ASAWASE" },
    { "region": "ASHANTI", "code": "F2401", "name": "KWABRE EAST" },
    { "region": "ASHANTI", "code": "F2501", "name": "AFIGYA KWABRE SOUTH" },
    { "region": "ASHANTI", "code": "F2601", "name": "AFIGYA KWABRE NORTH" },
    { "region": "ASHANTI", "code": "F2701", "name": "JUABEN" },
    { "region": "ASHANTI", "code": "F2801", "name": "EJISU" },
    { "region": "ASHANTI", "code": "F2901", "name": "ASANTE AKIM SOUTH" },
    { "region": "ASHANTI", "code": "F3001", "name": "ASANTE AKIM CENTRAL" },
    { "region": "ASHANTI", "code": "F3101", "name": "ASANTE AKIM NORTH" },
    { "region": "ASHANTI", "code": "F3201", "name": "EFFIDUASE / ASOKORE" },
    { "region": "ASHANTI", "code": "F3301", "name": "KUMAWU" },
    { "region": "ASHANTI", "code": "F3401", "name": "SEKYERE AFRAM PLAINS" },
    { "region": "ASHANTI", "code": "F3501", "name": "NSUTA/KWAMANG/BEPOSO" },
    { "region": "ASHANTI", "code": "F3601", "name": "MAMPONG" },
    { "region": "ASHANTI", "code": "F3701", "name": "EJURA SEKYEDUMASE" },
    { "region": "ASHANTI", "code": "F3801", "name": "AFIGYA SEKYERE EAST" },
    { "region": "ASHANTI", "code": "F3901", "name": "OFFINSO SOUTH" },
    { "region": "ASHANTI", "code": "F4001", "name": "OFFINSO NORTH" },
    { "region": "ASHANTI", "code": "F4101", "name": "AHAFO ANO SOUTH WEST" },
    { "region": "ASHANTI", "code": "F4201", "name": "AHAFO ANO SOUTH EAST" },
    { "region": "ASHANTI", "code": "F4301", "name": "AHAFO ANO NORTH" },

    //AHAFO REGION

    { "region": "AHAFO", "code": "H0101", "name": "ASUNAFO SOUTH" },
    { "region": "AHAFO", "code": "H0201", "name": "ASUNAFO NORTH" },
    { "region": "AHAFO", "code": "H0301", "name": "ASUTIFI SOUTH" },
    { "region": "AHAFO", "code": "H0401", "name": "ASUTIFI NORTH" },
    { "region": "AHAFO", "code": "H0501", "name": "TANO SOUTH" },
    { "region": "AHAFO", "code": "H0601", "name": "TANO NORTH" },

    //BONO REGION

    { "region": "BONO", "code": "J0101", "name": "SUNYANI EAST" },
    { "region": "BONO", "code": "J0201", "name": "SUNYANI WEST" },
    { "region": "BONO", "code": "J0301", "name": "DORMAA WEST" },
    { "region": "BONO", "code": "J0401", "name": "DORMAA CENTRAL" },
    { "region": "BONO", "code": "J0501", "name": "DORMAA EAST" },
    { "region": "BONO", "code": "J0601", "name": "BEREKUM EAST" },
    { "region": "BONO", "code": "J0701", "name": "BEREKUM WEST" },
    { "region": "BONO", "code": "J0801", "name": "JAMAN SOUTH" },
    { "region": "BONO", "code": "J0901", "name": "JAMAN NORTH" },
    { "region": "BONO", "code": "J1001", "name": "BANDA" },
    { "region": "BONO", "code": "J1101", "name": "TAIN" },
    { "region": "BONO", "code": "J1201", "name": "WENCHI" },

    //WESTERN REGION

    { "region": "WESTERN NORTH", "code": "G0101", "name": "AOWIN" },
    { "region": "WESTERN NORTH", "code": "G0201", "name": "SUAMAN" },
    { "region": "WESTERN NORTH", "code": "G0301", "name": "BIBIANI-ANHWIASO-BEKWAI" },
    { "region": "WESTERN NORTH", "code": "G0401", "name": "SEFWI WIAWSO" },
    { "region": "WESTERN NORTH", "code": "G0501", "name": "SEFWI AKONTOMBRA" },
    { "region": "WESTERN NORTH", "code": "G0601", "name": "JUABOSO" },
    { "region": "WESTERN NORTH", "code": "G0701", "name": "BODI" },
    { "region": "WESTERN NORTH", "code": "G0801", "name": "BIA WEST" },
    { "region": "WESTERN NORTH", "code": "G0901", "name": "BIA EAST" },

    //BONO EAST

    { "region": "BONO EAST", "code": "K0101", "name": "TECHIMAN" },
    { "region": "BONO EAST", "code": "K0201", "name": "KINTAMPO NORTH" },
    { "region": "BONO EAST", "code": "K0301", "name": "KINTAMPO SOUTH" },
    { "region": "BONO EAST", "code": "K0401", "name": "NKORANZA NORTH" },
    { "region": "BONO EAST", "code": "K0501", "name": "NKORANZA SOUTH" },
    { "region": "BONO EAST", "code": "K0601", "name": "ATEBUBU/AMANTIN" },
    { "region": "BONO EAST", "code": "K0701", "name": "PRU WEST" },
    { "region": "BONO EAST", "code": "K0801", "name": "PRU EAST" },
    { "region": "BONO EAST", "code": "K0901", "name": "SENE WEST" },
    { "region": "BONO EAST", "code": "K1001", "name": "SENE EAST" },
    { "region": "BONO EAST", "code": "K1101", "name": "TECHIMAN NORTH" },

    //OTI REGION

    { "region": "OTI", "code": "L0101", "name": "BUEM" },
    { "region": "OTI", "code": "L0201", "name": "BIAKOYE" },
    { "region": "OTI", "code": "L0301", "name": "AKAN" },
    { "region": "OTI", "code": "L0401", "name": "KRACHI EAST" },
    { "region": "OTI", "code": "L0501", "name": "KRACHI WEST" },
    { "region": "OTI", "code": "L0601", "name": "KRACHI-NCHUMURU" },
    { "region": "OTI", "code": "L0701", "name": "NKWANTA SOUTH" },
    { "region": "OTI", "code": "L0801", "name": "NKWANTA NORTH" },
    { "region": "OTI", "code": "L0901", "name": "GUAN" },


    //NORTHERN REGION

    { "region": "NORTHERN", "code": "M0101", "name": "KPANDAI" },
    { "region": "NORTHERN", "code": "M0201", "name": "BIMBILLA" },
    { "region": "NORTHERN", "code": "M0301", "name": "WULENSI" },
    { "region": "NORTHERN", "code": "M0401", "name": "ZABZUGU" },
    { "region": "NORTHERN", "code": "M0501", "name": "TATALE/SANGULI" },
    { "region": "NORTHERN", "code": "M0601", "name": "YENDI" },
    { "region": "NORTHERN", "code": "M0701", "name": "MION" },
    { "region": "NORTHERN", "code": "M0801", "name": "SABOBA" },
    { "region": "NORTHERN", "code": "M0901", "name": "GUSHEGU" },
    { "region": "NORTHERN", "code": "M1001", "name": "KARAGA" },
    { "region": "NORTHERN", "code": "M1101", "name": "SAVELUGU" },
    { "region": "NORTHERN", "code": "M1201", "name": "NANTON" },
    { "region": "NORTHERN", "code": "M1301", "name": "TAMALE SOUTH" },
    { "region": "NORTHERN", "code": "M1302", "name": "TAMALE CENTRAL" },
    { "region": "NORTHERN", "code": "M1401", "name": "SAGNARIGU" },
    { "region": "NORTHERN", "code": "M1402", "name": "TAMALE NORTH" },
    { "region": "NORTHERN", "code": "M1501", "name": "TOLON" },
    { "region": "NORTHERN", "code": "M1601", "name": "KUMBUNGU" },

    //SAVANNAH REGION

    { "region": "SAVANNAH", "code": "N0101", "name": "BOLE/BAMBOI" },
    { "region": "SAVANNAH", "code": "N0201", "name": "SAWLA TUNA KALBA" },
    { "region": "SAVANNAH", "code": "N0301", "name": "DAMONGO" },
    { "region": "SAVANNAH", "code": "N0401", "name": "DABOYA/MANKARIGU" },
    { "region": "SAVANNAH", "code": "N0501", "name": "YAPEI-KUSAWGU" },
    { "region": "SAVANNAH", "code": "N0601", "name": "SALAGA SOUTH" },
    { "region": "SAVANNAH", "code": "N0701", "name": "SALAGA NORTH" },

    //UPPER WEST

    { "region": "UPPER WEST", "code": "P0101", "name": "WA CENTRAL" },
    { "region": "UPPER WEST", "code": "P0201", "name": "WA WEST" },
    { "region": "UPPER WEST", "code": "P0301", "name": "WA EAST" },
    { "region": "UPPER WEST", "code": "P0401", "name": "NADOWLI/KALEO" },
    { "region": "UPPER WEST", "code": "P0501", "name": "DAFFIAMA/BUSSIE/ISSA" },
    { "region": "UPPER WEST", "code": "P0601", "name": "JIRAPA" },
    { "region": "UPPER WEST", "code": "P0701", "name": "LAMBUSSIE" },
    { "region": "UPPER WEST", "code": "P0801", "name": "LAWRA" },
    { "region": "UPPER WEST", "code": "P0901", "name": "NANDOM" },
    { "region": "UPPER WEST", "code": "P1001", "name": "SISSALA WEST" },
    { "region": "UPPER WEST", "code": "P1101", "name": "SISSALA EAST" },

    //NORTH EAST

    { "region": "NORTH EAST", "code": "Q0101", "name": "WALEWALE" },
    { "region": "NORTH EAST", "code": "Q0201", "name": "YAGABA /KUBORI" },
    { "region": "NORTH EAST", "code": "Q0301", "name": "NALERIGU / GAMBAGA" },
    { "region": "NORTH EAST", "code": "Q0401", "name": "BUNKPURUGU" },
    { "region": "NORTH EAST", "code": "Q0501", "name": "YUNYOO" },
    { "region": "NORTH EAST", "code": "Q0601", "name": "CHEREPONI" },

    //UPPER EAST

    { "region": "UPPER EAST", "code": "R0101", "name": "BUILSA SOUTH" },
    { "region": "UPPER EAST", "code": "R0201", "name": "BUILSA NORTH" },
    { "region": "UPPER EAST", "code": "R0301", "name": "NAVRONGO CENTRAL (KASSENA/NANKANA EAST)" },
    { "region": "UPPER EAST", "code": "R0401", "name": "CHIANA-PAGA (KASSENA/NANKANA WEST)" },
    { "region": "UPPER EAST", "code": "R0501", "name": "BOLGATANGA CENTRAL" },
    { "region": "UPPER EAST", "code": "R0601", "name": "BOLGATANGA EAST" },
    { "region": "UPPER EAST", "code": "R0701", "name": "BONGO" },
    { "region": "UPPER EAST", "code": "R0801", "name": "TALENSI" },
    { "region": "UPPER EAST", "code": "R0901", "name": "NABDAM" },
    { "region": "UPPER EAST", "code": "R1001", "name": "ZEBILLA (BAWKU WEST) " },
    { "region": "UPPER EAST", "code": "R1101", "name": "BAWKU" },
    { "region": "UPPER EAST", "code": "R1201", "name": "PUSIGA" },
    { "region": "UPPER EAST", "code": "R1301", "name": "GARU" },
    { "region": "UPPER EAST", "code": "R1401", "name": "TEMPANE" },
    { "region": "UPPER EAST", "code": "R1501", "name": "BINDURI" }
]