import React from 'react';
import { CONSTITUENCIES } from './ConstituencyList'; // Ensure this imports the correct constituency data
import { COLOR_PRIMARY, PARTIES } from './Constants'; // Ensure this imports the fixed list of parties
import { Card, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const ConstituencyResultsTable = ({ constituencyData }) => {
    const navigate = useNavigate();
    const resultsMap = {};

    // Safely populate resultsMap with the data from constituencyData
    if (Array.isArray(constituencyData?.list)) {
        constituencyData.list.forEach(item => {
            if (item?.constituency_code && Array.isArray(item.results)) {
                resultsMap[item.constituency_code] = item.results;
            }
        });
    }

    // Styles for the table and its elements with only horizontal borders
    const tableStyle = {
        width: '100%',
        borderCollapse: 'collapse',
        margin: '20px 0',
    };

    const headerStyle = {
        backgroundColor: COLOR_PRIMARY,
        color: 'white',
        padding: '10px',
        textAlign: 'center',
        fontSize: "16px",
        borderBottom: '2px solid #ddd', // Horizontal bottom border
    };

    const cellStyle = {
        borderBottom: '1px solid #ddd', // Horizontal border only
        textAlign: 'center',
        padding: '8px',
        fontSize: '14px',
    };

    const leftAlignCellStyle = {
        ...cellStyle,
        textAlign: 'left',
    };

    const boldCellStyle = {
        ...cellStyle,
        fontWeight: 'bold',
        fontSize: '16px',
        color: 'white'
    };

    const hasNonZeroVotes = (votes) => {
        return Object.values(votes).some(vote => vote.votes > 0);
    };

    const determineRowStyle = (votes) => {
        const totalVotes = Object.values(votes).reduce((sum, partyVotes) => sum + partyVotes.votes, 0);
        if (totalVotes === 0) {
            return { backgroundColor: '#F5F5F5' };
        }

        let winningParty = null;
        let maxPercentage = 0;

        for (const party in votes) {
            if (votes[party].percentage > maxPercentage) {
                maxPercentage = votes[party].percentage;
                winningParty = party;
            }
        }

        if (winningParty === "NDC") {
            return { backgroundColor: 'green', color: 'white' };
        } else if (winningParty === "NPP") {
            return { backgroundColor: 'blue', color: 'white' };
        }

        return {};
    };

    const seatsData = constituencyData?.seats || {};
    const totalSeats = Object.values(seatsData).reduce((sum, seats) => sum + seats, 0);

    return (
        <div style={{ padding: '20px' }}>
           
            {/* <Table variant='dark' bordered hover responsive="sm" style={{ width: '50%', margin: '0 auto' }}>
                <thead>
                    <tr style={headerStyle}>
                        <th>PARTY</th>
                        <th className="text-center">SEATS WON</th>
                    </tr>
                </thead>
                <tbody>
                    {PARTIES.map(party => (
                        <tr key={party}>
                            <td style={cellStyle}>{party}</td>
                            <td style={cellStyle}>{seatsData[party] || 0}</td>
                        </tr>
                    ))}
                    <tr style={{ fontWeight: 'bold' }}>
                        <td style={cellStyle}>Total seats</td>
                        <td style={cellStyle}>{totalSeats}</td>
                    </tr>
                </tbody>
            </Table> */}

            <h5 style={{
                textAlign: 'center', marginTop: '50px', color: COLOR_PRIMARY,
                marginBottom: "3%"
            }}>CONSTITUENCY RESULTS</h5>

            <table style={tableStyle}>
                <thead>
                    <tr style={headerStyle}>
                        <th style={cellStyle}>CODE</th>
                        <th style={leftAlignCellStyle}>CONSTITUENCY NAME</th>
                        {PARTIES.map(party => (
                            <th key={party} style={cellStyle}>{party}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {CONSTITUENCIES.map(constituency => {
                        const results = resultsMap[constituency.code] || [];

                        const votes = {};
                        PARTIES.forEach(party => {
                            votes[party] = { votes: 0, percentage: 0 };
                        });

                        results.forEach(result => {
                            if (votes[result.party]) {
                                votes[result.party].votes = result.votes;
                                votes[result.party].percentage = parseFloat(result.percentage);
                            }
                        });

                        const rowStyle = determineRowStyle(votes);
                        const nonZeroVotes = hasNonZeroVotes(votes);

                        return (
                            <tr key={constituency.code} style={rowStyle} onClick={() => {
                                navigate("/constituency/result/" + constituency.code, { state: { name: constituency.name } })
                            }}>
                                <td style={cellStyle}>{constituency.code}</td>
                                <td style={leftAlignCellStyle}>{constituency.name}</td>
                                {PARTIES.map(party => (
                                    <td key={party} style={nonZeroVotes && (party === "NPP" || party === "NDC") ? boldCellStyle : cellStyle}>
                                        {votes[party].percentage.toFixed(2)}%
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default ConstituencyResultsTable;
