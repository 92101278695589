import React from 'react';
import { useNavigate } from 'react-router-dom';
import { COLOR_PRIMARY } from './Constants';

const TablePollingStationResults = ({ resultsData }) => {
    const navigate = useNavigate();

    // Check if resultsData is defined and has at least one element
    const presidentialResults = resultsData?.[0]?.results?.presidential?.results || [];
    const parliamentaryResults = resultsData?.[0]?.results?.parliamentary?.results || [];
    const presidentialParties = presidentialResults.map(r => r.party);
    const parliamentaryParties = parliamentaryResults.map(r => r.party);

    // If no resultsData, return null or some fallback UI
    if (!resultsData || resultsData.length === 0) return <div style={{padding:10,marginLeft:20}}>No results available</div>;

    // Function to determine which party got the highest votes
    const getHighestVoteParty = (results) => {
        const sortedResults = [...results].sort((a, b) => parseFloat(b.percentage) - parseFloat(a.percentage));
        return sortedResults[0]?.party || null; // Return null if no results are present
    };

    // Function to apply color based on party with highest votes
    const getCellStyle = (party, highestParty) => {
        if (party === highestParty) {
            if (party === 'NDC') {
                return { backgroundColor: '#28a745', color: 'white', fontWeight: "bold" }; // NDC green
            } else if (party === 'NPP') {
                return { backgroundColor: '#007bff', color: 'white', fontWeight: "bold" }; // NPP blue
            } else {
                return { backgroundColor: '#ffc107', color: 'white', fontWeight: "bold" }; // Others deep gold
            }
        }
        return {};
    };

    return (
        <div style={{ padding: '20px', maxWidth: '100%', margin: '0 auto', overflowX: 'auto' }}>
            <h5 style={{ textAlign: 'center', marginBottom: '20px', color: COLOR_PRIMARY }}>POLLING STATION RESULTS</h5>
            <table style={{
                width: '100%',
                minWidth: '800px',
                borderCollapse: 'collapse',
                marginBottom: '20px',
                fontSize: '12px',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                overflowX: 'auto'
            }}>
                <thead>
                    <tr style={{ backgroundColor: '#343a40', color: 'white', height: '50px' }}>
                        <th rowSpan="2" style={{ padding: '5px', border: '1px solid black', textAlign: 'center', verticalAlign: 'middle' }}>
                            POLLING STATION
                        </th>
                        <th rowSpan="2" style={{ padding: '5px', border: '1px solid black', textAlign: 'center', verticalAlign: 'middle' }}>
                            CODE
                        </th>
                        <th colSpan={presidentialParties.length} style={{
                            padding: '5px', border: '1px solid black',
                            textAlign: 'center', backgroundColor: '#B2EBF2', color: '#000', fontSize: 15
                        }}>
                            PRESIDENTIAL
                        </th>
                        <th colSpan={parliamentaryParties.length} style={{
                            padding: '5px', border: '1px solid black',
                            textAlign: 'center', backgroundColor: '#d4edda', color: '#333', fontSize: 15
                        }}>
                            PARLIAMENTARY
                        </th>
                    </tr>
                    <tr style={{ backgroundColor: '#495057', color: 'white' }}>
                        {presidentialParties.map((party, idx) => (
                            <th key={`pres-${idx}`} style={{
                                padding: '5px', border: '1px solid black',
                                textAlign: 'center', whiteSpace: 'nowrap', backgroundColor: '#80DEEA', color: '#003D33'
                            }}>
                                {party}
                            </th>
                        ))}
                        {parliamentaryParties.map((party, idx) => (
                            <th key={`parl-${idx}`} style={{ padding: '5px', border: '1px solid black', textAlign: 'center', whiteSpace: 'nowrap', backgroundColor: '#d4edda', color: '#155724' }}>
                                {party}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {resultsData.map((station, idx) => {
                        const highestPresidentialParty = getHighestVoteParty(station.results?.presidential?.results || []);
                        const highestParliamentaryParty = getHighestVoteParty(station.results?.parliamentary?.results || []);

                        return (
                            <tr
                                key={`station-${idx}`}
                                onClick={() => navigate("/polling-station/" + station.code)}
                                style={{
                                    backgroundColor: station.hasDiscrepancies ? '#dc3545' : '#f8f9fa',
                                    color: station.hasDiscrepancies ? 'white' : 'black',
                                    textAlign: 'center', cursor: "pointer"
                                }}
                            >
                                <td style={{ padding: '5px', border: '1px solid black', verticalAlign: 'middle', whiteSpace: 'nowrap' }}>
                                    {station.name}
                                </td>
                                <td style={{ padding: '5px', border: '1px solid black', verticalAlign: 'middle', whiteSpace: 'nowrap' }}>
                                    {station.code}
                                </td>
                                {/* Presidential Results */}
                                {station.results?.presidential?.results.map((result, idx) => (
                                    <td key={`pres-result-${idx}`} style={{
                                        padding: '5px',
                                        border: '1px solid black',
                                        ...getCellStyle(result.party, highestPresidentialParty)
                                    }}>
                                        {result.percentage}
                                    </td>
                                ))}
                                {/* Parliamentary Results */}
                                {station.results?.parliamentary?.results.map((result, idx) => (
                                    <td key={`parl-result-${idx}`} style={{
                                        padding: '5px',
                                        border: '1px solid black',
                                        ...getCellStyle(result.party, highestParliamentaryParty)
                                    }}>
                                        {result.percentage}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default TablePollingStationResults;
